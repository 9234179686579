<template>
  <!-- Modal konfirmasi-->
  <div id="modalKonfirmasi" class="modal fade">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">{{ datakonfirmasi.title }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    {{ datakonfirmasi.body }}
                </div>
                <div class="col-lg-8"></div>
                <div class="col-lg-4 pt-3">
                    <button
                        class="btn btn-link btn-block"
                        type="button"
                        @click="dismissModalKonfirmasi">
                        OK
                    </button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ //disabled eslint compiler
import $ from "jquery";
export default {
  name: "KonfirmasiModal_c",
  data() {
    return {
      
    }
  },
  props: {
    datakonfirmasi : {
      title: '',
      body: ''
    }
  },
  methods: {
    dismissModalKonfirmasi(){
        $("#modalKonfirmasi").modal("hide")
    }
  },
  computed: {

  },
  watch: {
   
  },
  mounted(){

  },
};
</script>
<style scoped>

</style>