<template>
  <div style="font-size:14px">
    <!-- Modal data produk-->
    <div id="modalProduk" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-bolder">Daftar Produk</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row py-2">
              <div class="col-6">
                <input
                  class="form-control sprod"
                  v-model="searchByName"
                  type="text"
                  placeholder="Cari nama produk atau barcode"
                />
              </div>
              <div class="col-6">
                <select
                  class="form-control"
                  :required="true"
                  v-model="searchByCategory"
                  name="OutletID"
                >
                  <option value="">Semua Kategori</option>
                  <option
                    v-for="dataCategory in category"
                    v-bind:key="
                      dataCategory.CategoryID + '.' + dataCategory.DeviceNo
                    "
                    v-bind:value="
                      dataCategory.CategoryID + '.' + dataCategory.DeviceNo
                    "
                  >
                    {{ dataCategory.CategoryName }}
                  </option>
                  <option value="0.0">Tanpa Kategori</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 scrollable">
                <table class="table table-bordered table-striped table-sm">
                  <thead>
                    <tr style="background-color: #00ae2b">
                      <th class="text-center text-white th-prod">
                        Barcode
                      </th>
                      <th class="text-center text-white th-prod">
                        Produk
                      </th>
                      <th class="text-center text-white th-prod">
                        Satuan
                      </th>
                      <th class="text-center text-white th-prod">
                        Harga
                      </th>
                    </tr>
                  </thead>
                  <tbody style="cursor: pointer;" v-if="searchProductResult.length > 0">
                    <tr v-for="dataProduk in searchProductResult" v-bind:key="dataProduk.ItemID + '.' + dataProduk.DeviceNo" @click="actAddToCart(dataProduk)">
                      <td class="text-left">{{ dataProduk.Barcode }}</td>
                      <td class="text-left">{{ dataProduk.ItemName }} {{ dataProduk.Unit == "#paket#" ? isiPaket(dataProduk.ItemID + '.' + dataProduk.DeviceNo) : null }}</td>
                      <td class="text-left">
                        {{ dataProduk.Unit.replace("#paket#", "Paket") }}
                      </td>
                      <td class="text-right">
                        {{ $filters.numberTocurrencyIDR(dataProduk.SellPrice) }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody style="cursor: pointer;" v-else>
                    <tr>
                      <td class="text-left" colspan="4">Produk tidak ditemukan</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
export default {
  data() {
    return {
      searchByName: "",
      searchByCategory: "",
    };
  },
  name: "ProdukModal_c",
  computed: {
    ...mapGetters(["products", "category", "itemingredients", "variant"]),
    //filter produk berdasarkan nama/barcode atau kategori
    searchProductResult() {
      let tempProducts = this.products;
      tempProducts = tempProducts.filter((item) => {
        return item.IsProduct == "true";
      });
      if (this.searchByName != "" && this.searchByName) {
        tempProducts = tempProducts.filter((item) => {
          return (
            item.ItemName.toLowerCase().includes(
              this.searchByName.toLowerCase()
            ) ||
            item.Barcode.toLowerCase().includes(this.searchByName.toLowerCase())
          );
        });
      }

      if (this.searchByCategory)
        tempProducts = tempProducts.filter((item) => {
          return item.CategoryID + "." + item.CategoryDeviceNo == this.searchByCategory;
        });

      return tempProducts;
    },
  },
  methods: {
    ...mapActions(["actAddToCart"]),
    //menampilkan isi paket
    isiPaket(id){
      let tempIngd = this.itemingredients;
      let product = this.products;
      let varian = this.variant;
      var paket = []
      var paketTxt = ""
      tempIngd = tempIngd.filter((item) => {
        return (item.ItemID + "." + item.ItemDeviceNo == id);
      });
      
      tempIngd.forEach(function (isiPaket) {
        var getProd = product.find((b) => b.ItemID + '.' + b.DeviceNo == isiPaket.IngredientsID + '.' + isiPaket.IngredientsDeviceNo);
        var getVariant = varian.find((c) => c.ItemID + '.' + c.ItemDeviceNo == isiPaket.IngredientsID + '.' + isiPaket.IngredientsDeviceNo && c.VarianID + '.' + c.DeviceNo == isiPaket.VariantID + '.' + isiPaket.VariantDeviceNo);
        if(getProd){
          paket.push(isiPaket.QtyNeed + " " + getProd.ItemName + (getVariant ? " - " + getVariant.VarianName : ""))
        }
      });

      paket.forEach(function (dataPaket, i) {
        if(i == 0){
          paketTxt += "(" + dataPaket
        } else {
          paketTxt += "," + dataPaket
        }
      })
      if(paketTxt != ""){
        paketTxt += ")"
      }
      return paketTxt
    }
  },
  mounted() {
    $(document).ready(function () {
     document.onkeyup = function(event) {
        if (event.which == 113) {
          $(".sprod").focus();
        }
      };
    });
  },
};
</script>
<style scoped>

.scrollable {
  height: 315px;
  overflow-y: auto;
}

.th-prod {
  position: sticky;
  background-color:#00ae2b;
  z-index: 2;
  top: 0;
}
</style>