<template>
  <div id="modalkonfirmasioffline" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Konfirmasi</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12">
              <p style="text-align: left" v-html="keterangan">
              </p>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    @click="close"
                    class="btn btn-green btn-block"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "KonfirmasiOffline",
  data() {
    return {
        keterangan:""
    };
  },
  computed: {},
  props: {
    datakonfirmasi : Object
  },
  watch: {
    datakonfirmasi: function(){
        this.setdata()     
    } 
  },
  methods: {
    setdata(){
        this.keterangan = this.datakonfirmasi.keterangan
    },
    close(){
      $("#modalkonfirmasioffline").modal("hide");
    }
  },
};

</script>
<style scoped>

</style>