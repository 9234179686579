<template>
  <!-- Modal data produk-->
  <div id="modaladdPelanggan" class="modal fade">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Tambah Pelanggan</h4>
          <button
            type="button"
            class="close"
            v-on:click="kembali"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-11">
            <form @submit.prevent="addCustomer">  
                <div class="form-group row ">
                    <label class="control-label-bold col-3 text-left" style="margin-top:9px">Nama</label>
                    <div class="col-9">
                      <input
                        class="form-control"
                        v-model="form.nama"
                        type="text"
                        name="nama"
                        placeholder=""
                      />
                    </div>
                    <label class="mb-1 col-lg-12 text-left error" v-if="errors">
                        {{ errors }}
                    </label>
                  </div>
                  <div class="form-group row ">
                    <label class="control-label-bold col-3 text-left" style="margin-top:9px">Email</label>
                    <div class="col-9">
                      <input
                        class="form-control"
                        v-model="form.email"
                        type="text"
                        placeholder=""
                      />
                      <label class="mb-1 col-lg-12 text-left error2" v-if="errors2">
                        {{ errors2 }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group row ">
                    <label class="control-label-bold col-3 text-left" style="margin-top:9px">No. HP</label>
                    <div class="col-9">
                      <input
                        class="form-control"
                        v-model="form.nohp"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="form-group row ">
                    <label class="control-label-bold col-3 text-left" style="margin-top:9px">Tanggal Lahir</label>
                    <div class="col-9">
                      <input
                        class="form-control"
                        v-model="form.tgllahir"
                        type="date"
                        placeholder=""
                      />
                    </div>
                </div>
                <div class="form-group row ">
                    <label class="control-label-bold col-3 text-left" style="margin-top:9px">Alamat</label>
                    <div class="col-9">
                      <textarea
                        class="form-control"
                        v-model="form.alamat"
                        placeholder=""
                        >
                      </textarea>
                    </div>
                </div>
                <div class="row">
                      <label 
                        v-on:click="kembali"
                        class="control-label-bold col-7 text-green ml-auto" 
                        style="margin-top:9px;text-align: right !important;"
                      >
                      Kembali
                      </label>
                      <button 
                        class="col-4 btn btn-green btn-block ml-auto"
                        style="margin-right:17px;"
                        :disabled="submitted"
                        >
                        <span v-html="btnSimpan"></span>  
                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
export default {
  data() {
    return {
      form: {
        nama: '',
        email: '',
        nohp: '',
        tgllahir: '',
        alamat:'',
      },
      submitted: false,
      errors: null,
      errors2: null,
      btnSimpan:"Simpan"
    };
  },
  name: "PelangganAddModal_c",
  methods: {
    kembali(){
        this.form.nama= '';
        this.form.email= '';
        this.form.nohp= '';
        this.form.tgllahir= '';
        this.form.alamat='';
        this.errors= "";
        this.errors2= "";
        $("#modalPelanggan").modal("show");
        $("#modaladdPelanggan").modal("hide");
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    cekpelanggandouble: function(pelanggan){
        var cus = this.customer;
        cus = cus.filter(cus => cus.CustomerName.toLowerCase() == pelanggan.toLowerCase()); 
        if (cus.length>0){
            return true
        }
        else{
            return false
        }
    },
    addCustomer() {
      this.btnSimpan = "<div class='fa fa-circle-notch fa-spin'></div>";
      if (this.form.nama==""){
          this.errors = "Nama pelanggan tidak boleh kosong";
          this.btnSimpan = "Simpan";
          return false
      }
      if (!this.validEmail(this.form.email)){
          if (this.form.email!=""){
            this.errors2 = "Format email salah";
            this.btnSimpan = "Simpan";
            return false
          }
      }
      if (this.cekpelanggandouble(this.form.nama)==true){
          this.errors = "Nama pelanggan sudah pernah digunakan";
          this.btnSimpan = "Simpan";
          return false
      }
      this.$store
        .dispatch("addCustomer", this.form)
        .then((response) => {
          if (response.error==false) 
          {
            this.btnSimpan = "Simpan";
            this.form.nama= '';
            this.form.email= '';
            this.form.nohp= '';
            this.form.tgllahir= '';
            this.form.alamat='';
            this.errors= "";
            this.errors2= "";
            this.$store.dispatch("actReSyncDataSale")
            $('#alert-update-delete, #alert-update-delete alert-success').show();
            
            setTimeout(function() {
                $('#alert-update-delete, #alert-update-delete alert-success').hide();
            }, 2000);
            $("#modalPelanggan").modal("show");
            $("#modaladdPelanggan").modal("hide");
            
          }
        })
        .catch((error) => {
          console.log(error)
          this.btnSimpan = "Simpan";
        });
    },
  },
  computed: {
    ...mapGetters(["customer"]),    
  },
};
</script>
<style scoped>
  .error {
      font-size: 0.8rem;
      transform: translateY(0%) translateX(25%);
      color:red;
  }
  .error2 {
      font-size: 0.8rem;
      transform: translateY(0%) translateX(-5%);
      color:red;
  }
</style>