<template>
  <div style="font-size:14px">
    <table
      class="table table-bordered table-striped table-sm"
      id="dynamic-table"
    >
      <thead>
        <tr style="background-color: #00ae2b">
          <th class="text-center text-white" style="width: 5%">No</th>
          <th class="text-center text-white" style="width: 30%">Produk</th>
          <th class="text-center text-white" style="width: 7%">Qty</th>
          <th class="text-center text-white" style="width: 10%">Satuan</th>
          <th class="text-center text-white" style="width: 15%">Harga</th>
          <th class="text-center text-white" style="width: 15%" colspan="2">
            Diskon
          </th>
          <th class="text-center text-white" style="width: 15%">Sub Total</th>
          <th class="text-center text-white" style="width: 3%">Hapus</th>
        </tr>
      </thead>
      <tbody v-if="stCart.length > 0">
        <tr v-for="(cartData, k) in stCart" v-bind:key="k">
          <td class="text-center" v-if="cartData.ItemID > 0">{{ k + 1 }}</td>
          <td class="text-left" v-if="cartData.ItemID > 0">
            {{ cartData.ItemName }} {{ cartData.Unit == "#paket#" ? isiPaket(cartData.ItemID + '.' + cartData.DeviceNo) : null }}
          </td>
          <td class="text-center" v-if="cartData.ItemID > 0">
            <div class="input-group input-value">
              <currency-input
                :readonly="cartData.qty < 0 && cartData.DetailID > 0"
                type="text"
                class="form-control text-right"
                v-model="cartData.qty"
                :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: true }"
                autocomplete="off"
                autocorrect="off"
              />
            </div>
          </td>
          <td class="text-left" v-if="cartData.ItemID > 0">
            {{ cartData.Unit.replace("#paket#", "Paket") }}
          </td>
          <td class="text-right" v-if="cartData.ItemID > 0">
            <div class="input-group input-value">
              <currency-input
                :readonly="cartData.qty <= 0 && cartData.DetailID > 0"
                type="text"
                class="form-control text-right"
                v-model="cartData.SellPrice"
                :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                autocomplete="off"
                autocorrect="off"
              />
            </div>
          </td>
          <td class="text-center" v-if="cartData.ItemID > 0" width="10%">
            <div class="input-group input-value">
              <currency-input
                :readonly="cartData.qty <= 0 && cartData.DetailID > 0"
                type="text"
                name="diskon[]"
                class="form-control text-right"
                v-model="cartData.diskon"
                :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                autocomplete="off"
                autocorrect="off"
              />
            </div>
          </td>
          <td class="text-center" v-if="cartData.ItemID > 0" width="5%">
            <select
              class="form-control jenis-diskon"
              name="jenisDiskon[]"
              v-model="cartData.jenisDiskon"
            >
              <option value="%" selected="">%</option>
              <option value="Rp">Rp</option>
            </select>
          </td>
          <td class="text-right" v-if="cartData.ItemID > 0">
            {{ $filters.numberTocurrencyIDR(cartData.subtotal) }}
          </td>
          <td class="text-center" v-if="cartData.ItemID > 0">
            <button
              :disabled="cartData.qty <= 0 && cartData.DetailID > 0"
              class="btn"
              type="button"
              v-on:click="removeItemCart(cartData)"
              style="color: #00ae2b"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="9">&nbsp; Belum ada produk terpilih</td>
        </tr>
      </tbody>
    </table>

    <div class="row mb-3">
      <div class="col-7">
        <button
          class="btn"
          type="button"
          data-toggle="modal"
          data-target="#modalConfirmClearCart"
          style="color: #00ae2b; margin-left: 500px"
          v-if="stCart.length > 0"
        >
          <i class="fas fa-trash mr-10"></i> Kosongkan Daftar Produk
        </button>
      </div>
      <div class="col-5">
        <div class="card">
          <div class="card-body">

            <div class="show-div" v-if="stCart.length > 0">
              <div class="row" v-if="valueDiscountFinal > 0 || stTax.length > 0 || stDiscountOutlet.length > 0 ">
                <div class="col-6 text-left">Total</div>
                <div class="col-2 text-center">Rp</div>
                <div class="col-4 text-right">{{ $filters.numberTocurrencyIDR(totalOrder) }}</div>
              </div>

              <div class="row" v-if="valueDiscountFinal > 0">
                <div class="col-6 text-left">Diskon Final
                  <span v-if="stTypeDiscount == 'yes'">{{ $filters.numberTocurrencyIDR(stDiscountFinal) }}%</span>
                </div>
                <div class="col-2 text-center">Rp</div>
                <div class="col-4 text-right">{{ $filters.numberTocurrencyIDR(valueDiscountFinal) }}</div>
              </div>

              <div class="row" v-for="(discOutlet, index) in stDiscountOutlet" v-bind:key="index">
                <div class="col-6 text-left" >
                  {{ discOutlet.DiscountName }} 
                  <span v-if="discOutlet.Discount.search('%') > 0">{{ $filters.numberTocurrencyIDR(discOutlet.Discount.replace("%", "")) }}%</span>
                </div>
                <div class="col-2 text-center">Rp</div>
                <div class="col-4 text-right">{{ $filters.numberTocurrencyIDR(valueDiscOutlet(index)) }}</div>
              </div>

              <div class="row" v-if="stTax.length > 0 && (stDiscountOutlet.length > 0 || valueDiscountFinal > 0)">
                <div class="col-6 text-left">Total Sebelum Pajak</div>
                <div class="col-2 text-center">Rp</div>
                <div class="col-4 text-right">{{ $filters.numberTocurrencyIDR(beforeTax) }}</div>
              </div>

              <div class="row" v-for="(dataPajak, index) in stTax" v-bind:key="index">
                <div class="col-6 text-left" v-if="dataPajak.PriceIncludeTax == '0'">{{ dataPajak.TaxName.toLowerCase().substr(-4) == '(rp)' ? dataPajak.TaxName.substr(0, dataPajak.TaxName.length - 5) : dataPajak.TaxName }} {{ dataPajak.TaxName.toLowerCase().substr(-4) == '(rp)' ? '' :dataPajak.TaxPercent }}{{ dataPajak.TaxName.toLowerCase().substr(-4) == '(rp)' ? '' :'%' }}</div>
                <div class="col-2 text-center" v-if="dataPajak.PriceIncludeTax == '0'">Rp</div>
                <div class="col-4 text-right" v-if="dataPajak.PriceIncludeTax == '0'">{{ $filters.numberTocurrencyIDR(valueTax(dataPajak.TaxID)) }}</div>
              </div>

              <div class="row" v-if="options[0].RoundingInTransaction == '1' && rounding != 0">
                <div class="col-6 text-left">Pembulatan</div>
                <div class="col-2 text-center">Rp</div>
                <div class="col-4 text-right">{{ $filters.numberTocurrencyIDR(rounding) }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-6 text-left">Grand Total</div>
              <div class="col-2 text-center">Rp</div>
              <div class="col-4 text-right">{{ $filters.numberTocurrencyIDR(grandTotal) }}</div>
            </div>

            <div class="row" v-if="txtIncludeTax && stCart.length > 0">
              <div class="col-12 text-left">Harga Sudah Termasuk : </div>
            </div>

            <div class="show-div" v-if="stCart.length > 0">
              <div class="row" v-for="(dataPajakIn, index) in stTax" v-bind:key="index">
                <div class="col-6 text-left" v-if="dataPajakIn.PriceIncludeTax == '1'">{{ dataPajakIn.TaxName }} {{ dataPajakIn.TaxPercent }}%</div>
                <div class="col-2 text-center" v-if="dataPajakIn.PriceIncludeTax == '1'">Rp</div>
                <div class="col-4 text-right" v-if="dataPajakIn.PriceIncludeTax == '1'">{{ $filters.numberTocurrencyIDR(valueTaxIn(dataPajakIn.TaxID)) }}</div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- komponen modal konfirm hapus item cart-->
    <KonfirmHapusItemCart :dataCartToDelete="sentToDeleteItemCart" />
    <!-- komponen modal konfirm hapus data cart-->
    <KonfirmHapusCart />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import KonfirmHapusItemCart from "@/components/konfirmasi_modal/KonfirmHapusItemCartModal.vue";
import KonfirmHapusCart from "@/components/konfirmasi_modal/KonfirmHapusCartModal.vue";
import CurrencyInput from '@/components/CurrencyInput_c';

export default {
  name: "Kasir_c",
  data() {
    return {
      sentToDeleteItemCart: {}
    };
  },
  components: {
    KonfirmHapusCart,
    KonfirmHapusItemCart,
    CurrencyInput,
  },
  methods: {
    ...mapActions(["actSetTotalCart", "actTotalBeforeRounding", "actTotalRounding", "actSetTotalHeader", "actSetDiscountValue"]),
    //menampilkan isi paket
    isiPaket(id){
      let tempIngd = this.itemingredients;
      let product = this.products;
      let varian = this.variant;
      var paket = []
      var paketTxt = ""
      tempIngd = tempIngd.filter((item) => {
        return (item.ItemID + "." + item.ItemDeviceNo == id);
      });
      
      tempIngd.forEach(function (isiPaket) {
        var getProd = product.find((b) => b.ItemID + '.' + b.DeviceNo == isiPaket.IngredientsID + '.' + isiPaket.IngredientsDeviceNo);
        var getVariant = varian.find((c) => c.ItemID + '.' + c.ItemDeviceNo == isiPaket.IngredientsID + '.' + isiPaket.IngredientsDeviceNo && c.VarianID + '.' + c.DeviceNo == isiPaket.VariantID + '.' + isiPaket.VariantDeviceNo);
        if(getProd){
          paket.push(isiPaket.QtyNeed + " " + getProd.ItemName + (getVariant ? " - " + getVariant.VarianName : ""))
        }
      });

      paket.forEach(function (dataPaket, i) {
        if(i == 0){
          paketTxt += "(" + dataPaket
        } else {
          paketTxt += "," + dataPaket
        }
      })
      if(paketTxt != ""){
        paketTxt += ")"
      }
      return paketTxt
    },
    //calc diskon outlet by id diskon
    valueDiscOutlet(index) {
      var totalTrans = this.stTotalCart
      var totDisc = []
      this.stDiscountOutlet.forEach(function (discData, i) {
        if(discData.Discount.search("%") > 0){
          totDisc[i] = parseFloat((totalTrans / 100)* discData.Discount.replace("%", ""))
          totalTrans = parseFloat(totalTrans - totDisc[i]);
        } else {
          totDisc[i] = parseFloat(discData.Discount)
          totalTrans = parseFloat(totalTrans - totDisc[i]);
        }
      });
      return totDisc[index];
    },
    //calc tax exclude by id tax
    valueTax(id) {
      let resTax = 0;
      let tax = this.stTax.find((taxData) => taxData.TaxID == id && taxData.PriceIncludeTax == 0);
      if(tax.ApplyToAllItems == 1){
        if(tax.TaxName.toLowerCase().substr(-4) == '(rp)') {
          resTax = tax.TaxPercent;
        } else {
          resTax = (this.beforeTax / 100) * tax.TaxPercent;
        }
      } else {
        let resTaxItem = 0;
        if(tax.ApplicableItems != " "){
          var explode = tax.ApplicableItems.split(",");
          for (let index = 0; index < explode.length; index++) {
            resTaxItem += ((this.totalSub(explode[index])) / 100) * tax.TaxPercent;
          }
        } else if(tax.ApplicableCategories != " "){
          var explodeCat = tax.ApplicableCategories.split(",");
          for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
            resTaxItem += ((this.totalSubByCat(explodeCat[indexCat])) / 100) * tax.TaxPercent;
          }
        }
        resTax = resTaxItem;
      }
      return resTax;
    },
    //calc tax include by id tax
    valueTaxIn(id) {
      let resTax = 0;
      let tax = this.stTax.find((taxData) => taxData.TaxID == id && taxData.PriceIncludeTax == 1);
      if(tax.ApplyToAllItems == 1){
        if(tax.TaxName.toLowerCase().substr(-4) == '(rp)') {
          resTax = tax.TaxPercent;
        } else {
          resTax = this.beforeTax - ((this.beforeTax / (100 + parseFloat(tax.TaxPercent))) * 100);
        }
      } else {
        let resTaxItem = 0;
        if(tax.ApplicableItems != " "){
          var explode = tax.ApplicableItems.split(",");
          for (let index = 0; index < explode.length; index++) {
            resTaxItem += this.totalSub(explode[index]) - ((this.totalSub(explode[index]) / (100 + parseFloat(tax.TaxPercent))) * 100);
          }
        } else if(tax.ApplicableCategories != " "){
          var explodeCat = tax.ApplicableCategories.split(",");
          for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
            resTaxItem += this.totalSubByCat(explodeCat[indexCat]) - ((this.totalSubByCat(explodeCat[indexCat]) / (100 + parseFloat(tax.TaxPercent))) * 100);
          }
        }
        resTax = resTaxItem;
      }
      return Math.ceil(resTax);
    },
    //calc total tax exclude
    valueTaxTotalExclude(){
      let total = 0;
      for (let index = 0; index < this.stTax.length; index++) {
          if (this.stTax[index].PriceIncludeTax == 0) {
            if(this.stTax[index].ApplyToAllItems == 1){
              if(this.stTax[index].TaxName.toLowerCase().substr(-4) == '(rp)') {
                total += parseFloat(this.stTax[index].TaxPercent);
              } else {
                total += (this.beforeTax / 100) * this.stTax[index].TaxPercent;
              }
            } else {
              let resTaxItem = 0;
              if(this.stTax[index].ApplicableItems != " "){
                var explode = this.stTax[index].ApplicableItems.split(",");
                for (let indexItem = 0; indexItem < explode.length; indexItem++) {
                  resTaxItem += ((this.totalSub(explode[indexItem])) / 100) * this.stTax[index].TaxPercent;
                }
              } else if(this.stTax[index].ApplicableCategories != " "){
                var explodeCat = this.stTax[index].ApplicableCategories.split(",");
                for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                  resTaxItem += ((this.totalSubByCat(explodeCat[indexCat])) / 100) * this.stTax[index].TaxPercent;
                }
              }
              total += resTaxItem;
            }
            
          }
      }
      this.$store.dispatch("actSetTotalTaxExclude", total);
      return total;
    },
    //calc total tax include
    valueTaxTotalInclude(){
      let total = 0;
      for (let index = 0; index < this.stTax.length; index++) {
          if (this.stTax[index].PriceIncludeTax == 1) {
            if(this.stTax[index].ApplyToAllItems == 1){
              total += this.beforeTax - ((this.beforeTax / (100 + parseFloat(this.stTax[index].TaxPercent))) * 100);
            } else {
              let resTaxItem = 0;
              if(this.stTax[index].ApplicableItems != " "){
                var explode = this.stTax[index].ApplicableItems.split(",");
                for (let indexItem = 0; indexItem < explode.length; indexItem++) {
                  resTaxItem += this.totalSub(explode[indexItem]) - ((this.totalSub(explode[indexItem]) / (100 + parseFloat(this.stTax[index].TaxPercent))) * 100);
                }
              } else if(this.stTax[index].ApplicableCategories != " "){
                var explodeCat = this.stTax[index].ApplicableCategories.split(",");
                for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                  resTaxItem += this.totalSubByCat(explodeCat[indexCat]) - ((this.totalSubByCat(explodeCat[indexCat]) / (100 + parseFloat(this.stTax[index].TaxPercent))) * 100);
                }
              }
              total += resTaxItem;
            }
            
          }
      }
      this.$store.dispatch("actSetTotalTaxInclude", Math.ceil(total));
    },
    //calc total order in cart
    calcTotalOrder() {
      let sumCart = 0;
      //console.log(this.stCart)
      const stCart = [...this.stCart]
      stCart.forEach(function (cartData) {
        var total = parseFloat(cartData.qty * (cartData.SellPrice + cartData.markupValuePerItem))
        var disc = 0
        if(cartData.diskon > 0){
          if(cartData.jenisDiskon == "%"){
            if(cartData.diskon > 100){
              cartData.diskon = 0
            } else {
              disc = ((cartData.qty * (cartData.SellPrice + cartData.markupValuePerItem)) / 100) * cartData.diskon
            }
          } else {
            if(cartData.diskon > total){
              cartData.diskon = 0
            } else {
              disc = cartData.diskon
            }
          }
        }
        cartData.subtotal = total - disc
          /*cartData.subtotal =
            parseFloat(cartData.qty * cartData.SellPrice) -
            (cartData.diskon > 0 ? cartData.jenisDiskon == "%"
                ? ((cartData.qty * cartData.SellPrice) / 100) * cartData.diskon
                : cartData.diskon
              : 0);*/
          sumCart += cartData.subtotal
      });
      this.$store.dispatch("actSetTotalCart", sumCart);
      return sumCart;
    },
    //calc sub total item in cart by id items
    totalSub(id) {
      let sumCart = 0;
      this.stCart.forEach(function (cartData) {
        if (cartData.qty > 0) {
          if(cartData.DeviceNo+"."+cartData.ItemID == id){
            sumCart += cartData.subtotal
          }
          
        }
      });
      return sumCart;
    },
    //calc sub total item in cart by id category items
    totalSubByCat(idCat){
      let sumCart = 0;
      this.stCart.forEach(function (cartData) {
        if (cartData.qty > 0) {
          if(cartData.CategoryDeviceNo+"."+cartData.CategoryID == idCat){
            sumCart += cartData.subtotal
          }
        }
      });
      return sumCart;
    },
    //delete item in cart
    removeItemCart(cartData){
      this.sentToDeleteItemCart = cartData
       $("#modalConfirmDeleteItemCart").modal("show")
    },
  },
  computed: {
    ...mapGetters(["stCart", "stTotalCart" , "stDiscountOutlet", "stDiscountFinal", "stTypeDiscount", "stTax", "stTotalTaxExclude", "stTotalTaxInclude", "options", "products", "itemingredients", "variant", "stOpsiMakan"]),
    //total order dari cart 
    totalOrder(){
      return this.calcTotalOrder()
    },
    //nilai diskon final
    valueDiscountFinal() {
      let discFinal = 0;
      if (this.stTypeDiscount == "no") {
        discFinal = this.stDiscountFinal;
      } else {
        discFinal = (this.totalOrder / 100) * this.stDiscountFinal;
      }
      this.$store.dispatch("actSetDiscountValue", discFinal);
      return discFinal;
    },
    //total sebelum pajak exclude
    beforeTax() {
      let beforeTax = 0;
      var tot = this.totalOrder
      if(this.stDiscountOutlet.length > 0){
        this.stDiscountOutlet.forEach(function (discOutlet) {
          if(discOutlet.Discount.search("%") > 0){
            tot = parseFloat(tot - ((tot / 100)* discOutlet.Discount.replace("%", "")));
          } else {
            tot = parseFloat(tot - discOutlet.Discount);
          }
        });
      }
      beforeTax = tot - this.valueDiscountFinal;
      return beforeTax;
    },
    //total sebelum pembulatan
    totalBeforeRounding(){
      let grandTotal = 0;
      this.valueTaxTotalInclude();
      grandTotal = this.beforeTax + this.valueTaxTotalExclude();
      this.$store.dispatch("actTotalBeforeRounding", grandTotal);
      return grandTotal;
    },
    //perhitungan pembulatan
    calcRounding(){
      var round = 0;
      var rounding = 0;
      if(this.options[0].RoundingInTransaction == "1" && this.totalBeforeRounding){
        var RoundingTo = this.options[0].RoundingTo;
        var totalBeforeRounding = this.totalBeforeRounding;
        var bagi = totalBeforeRounding / RoundingTo;
        if(this.options[0].RoundingMode == "0"){
          round = Math.round(bagi);
        } else if(this.options[0].RoundingMode == "1"){
          round = Math.floor(bagi);
        } else if(this.options[0].RoundingMode == "2"){
          round = Math.ceil(bagi);
        }
        rounding = round * RoundingTo;
      }
      
      return rounding
    },
    //nilai pembulatan
    rounding(){
      var roundingRes = 0; 

      roundingRes = this.calcRounding - this.totalBeforeRounding;
      this.$store.dispatch("actTotalRounding", roundingRes);
      return roundingRes; 
    },
    //grand total
    grandTotal() {
      let grandTotal = 0;
      if(this.stCart.length > 0) {
        grandTotal = (this.options[0].RoundingInTransaction == "1" ? this.calcRounding : this.totalBeforeRounding);
      } 
      this.$store.dispatch("actSetTotalHeader", grandTotal);
      return grandTotal;
    },
    //show text ketika terdapat tax include
    txtIncludeTax(){
      let res = false;
      for (let index = 0; index < this.stTax.length; index++) {
          if (this.stTax[index].PriceIncludeTax == 1) {
            res = true
          }
      }
      return res
    },
  },
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dynamic-table tr td {
  padding: 0px 5px 0px 5px;
  vertical-align: middle;
}
#dynamic-table .form-control,
#dynamic-table .input-group-addon,
#dynamic-table .select2-choice {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none;
}
</style>
