<template>
  <!-- Modal data diskon-->
  <div id="modalDiskon" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Diskon</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="selectDiskon">
            <div class="row py-2">
              <div class="col-12">
                <!-- start alert-->
                <div class="alert alert-danger fade show" role="alert" v-if="alertDiscount">
                  <i class="fa fa-exclamation-circle"></i> {{ msgAlertDiscount }}
                </div>
                <!-- end alert-->
                <div class="input-group">
                  <currency-input
                    class="form-control"
                    v-model="form.inputDiscount"
                    :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                    :disabled="form.checkedDisc.length >= max"
                    name="inputDiscount"
                    type="text"
                    placeholder="Input diskon final"
                    autocomplete="off"
                    autocorrect="off"
                  />
                  <div
                    class="input-group-addon background-white"
                    style="padding: 7px 12px 7px 12px"
                  >
                    <div class="switch-field mb-0">
                      <input
                        type="radio"
                        id="percent-yes"
                        v-model="form.r_percent"
                        :disabled="form.checkedDisc.length >= max"
                        name="r_percent"
                        value="yes"
                      />
                      <label for="percent-yes" class="mb-0 mr-0">%</label>
                      <input
                        type="radio"
                        id="percent-no"
                        v-model="form.r_percent"
                        :disabled="form.checkedDisc.length >= max"
                        name="r_percent"
                        value="no"
                      />
                      <label for="percent-no" class="mb-0 mr-0">Rp</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table class="table table-bordered table-striped table-sm">
              <tbody>
                <tr
                  v-for="dataDiskon in discount"
                  v-bind:key="dataDiskon.DiscountID"
                >
                  <td class="text-center">
                    <!--:disabled="form.checkedDisc.length >= max && form.checkedDisc.indexOf(dataDiskon) == -1"-->
                    <div class="form-check">
                      <label class="switch">
                        <input
                          type="checkbox"
                          :id="dataDiskon.DiscountID"
                          :value="dataDiskon"
                          v-model="form.checkedDisc"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td class="text-left">{{ dataDiskon.DiscountName }}</td>
                  <td class="text-right">
                    <span v-if="dataDiskon.Discount.search('%') > 0">{{ $filters.numberTocurrencyIDR(dataDiskon.Discount.replace("%", "")) }}%</span>
                    <span v-else>{{ $filters.numberTocurrencyIDR(dataDiskon.Discount) }}</span>
                    </td>
                </tr>
              </tbody>
            </table>
            <div class="row py-2">
              <div class="col-6"></div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <button
                      class="btn btn-link btn-block"
                      type="button"
                      data-dismiss="modal"
                    >
                      Kembali
                    </button>
                  </div>
                  <div class="col-6">
                    <button type="submit" class="btn btn-green btn-block">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    {{ cekDisc }}
  </div>
</template>

<script>
/* eslint-disable */ //disabled eslint compiler
import CurrencyInput from '@/components/CurrencyInput_c'
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DiskonModal_c",
  data() {
    return {
      msgAlertDiscount: "",
      alertDiscount: false,
      form: {
        inputDiscount: null,
        r_percent: "yes",
        checkedDisc: [],
      },
      max: 1,
    };
  },
  components: {
    CurrencyInput
  },
  computed: {
    ...mapGetters(["discount", "stCart", "stTotalCart", "stDiscountFinal", "stDiscountOutlet", "stTypeDiscount"]),
    cekDisc() {
      return this.resetDiskon();
    },
  },
  methods: {
    ...mapActions(["actSetDiscount"]),
    selectDiskon() {
      if(this.form.checkedDisc.length > 0) {
        this.form.inputDiscount = null
        this.form.r_percent = "yes"
        this.submitDiscount()
      } else {
        if(this.form.inputDiscount > 0 && this.form.r_percent != ""){
          if(this.form.r_percent == "yes") {
              if(this.form.inputDiscount > 100){
                this.msgAlertDiscount = "Diskon tidak boleh melebihi 100 persen"
                this.alertDiscount = true
                setTimeout(() => this.alertDiscount = false, 5000);
              } else {
                this.submitDiscount()
              }
            } else {
              if(this.stCart.length > 0){
                var stTotalCart = this.stTotalCart
                if(this.form.inputDiscount > stTotalCart){
                  this.msgAlertDiscount = "Diskon melebihi total belanja"
                  this.alertDiscount = true
                  setTimeout(() => this.alertDiscount = false, 5000);
                } else {
                  this.submitDiscount()
                }
              } else {
                this.submitDiscount()
              }
            }
        } else {
          this.submitDiscount()
        }
      }
    },
    submitDiscount(){
      this.msgAlertDiscount = ""
      this.alertDiscount = false
      this.$store.dispatch("actSetDiscount", this.form);
      $("#modalDiskon").modal("hide");
    },
    resetDiskon() {
      this.form.inputDiscount = this.stDiscountFinal;
      this.form.r_percent = this.stTypeDiscount;
      this.form.checkedDisc = this.stDiscountOutlet;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>