<template>
  <div>
    <!-- start print receipt template small -->
    <div id="ticket-print-simpan-order" class="ticket" style="height: 100px; overflow: auto; display: none">
      <img v-if="getLogo" :src="getLogo" alt="Logo" class="center" style="width: 150px; height: auto" />
      <p class="centered">
        {{ options[0].CompanyName }} <br />
        {{ options[0].CompanyAddress }} <br />
        {{ options[0].CompanyPhone }}
      </p>
      <div style="width: 100%; border-top-style: dashed">
        <p style="margin-left: 0px; text-align: left" v-if="getPendingSale">
          Nomor Pesanan : {{ getPendingSale.SaleOrderNumber }} <br />
          <span v-if="getPendingSale.NamaOpsiMakan">
            {{ getPendingSale.NamaOpsiMakan }} <br />
          </span>
          <span v-if="getPendingSale.CustomerName">
            Pelanggan : {{ getPendingSale.CustomerName }} <br />
          </span>
          <span v-if="getPendingSale.WaitressName">
            Pelayan : {{ getPendingSale.WaitressName }} <br />
          </span>
          Kasir : {{ pendingsale[0].CreatedBy }} <br />
          Bill Date : {{ getPendingSale.DateIndo }} {{ getPendingSale.SaleTime }} <br />
        </p>
      </div>
      <table style="margin-top: 20px" v-if="getPendingsaleitemdetail">
        <tbody v-for="(itemDetail, k) in getPendingsaleitemdetail" v-bind:key="k">
          <tr>
            <td class="description" colspan="3">
              {{ itemDetail.ItemName }}
              <span v-if="itemDetail.Discount != ''">
                - Diskon
                <span v-if="itemDetail.Discount.indexOf('%') > 0">
                  {{ itemDetail.Discount }}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="quantity" colspan="2">
              &nbsp;
              {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }} X
              {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
              <span v-if="itemDetail.Discount != ''">
                - {{ $filters.numberTocurrencyIDR(itemDetail.Quantity * itemDetail.UnitPrice - itemDetail.SubTotal) }}
                </span>
            </td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
            </td>
          </tr>
          <tr v-for="(isiPaket, kk) in itemDetail.IsiPaket" v-bind:key="kk">
            <td class="quantity" colspan="3">
              &nbsp;&nbsp;&nbsp;&nbsp; >>
              {{ $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity) }}
              {{ isiPaket.ProductName }}
              {{ isiPaket.VariantName ? "- " + isiPaket.VariantName : "" }}
            </td>
          </tr>
          <tr v-if="itemDetail.IsiPaket.length > 0">
            <td class="quantity" colspan="3">&nbsp;&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <table style="margin-top: 20px" v-if="getPendingSale">
        <tbody>
          <tr>
            <td class="subtotal">
              <span v-if="getPendingsalediscountdetail.length > 0 ||getPendingSale.FinalDiscount">
                Total Sebelum Diskon
              </span>
              <span v-else> Total </span>
            </td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getSubtotal) }}
            </td>
          </tr>
          <tr v-for="(saleDisc, d) in getPendingsalediscountdetail" v-bind:key="d">
            <td class="subtotal">
              {{ saleDisc.DiscountName }}
              <span v-if="saleDisc.Discount.search('%') > 0">{{
                saleDisc.Discount
              }}</span>
            </td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }}
            </td>
          </tr>
          <tr v-if="getPendingsalediscountdetail.length == 0 && getPendingSale.FinalDiscount">
            <td class="subtotal">
              Diskon Final
              <span v-if="getPendingSale.FinalDiscount.toString().search('%') > 0">
                {{ getPendingSale.FinalDiscount }}
              </span>
            </td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getDiscountFinal) }}
            </td>
          </tr>
          <tr v-if="getPendingsaletaxexclude.length > 0 && (getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount)">
            <td class="subtotal">Total</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getBeforeTax) }}
            </td>
          </tr>
          <tr v-for="(saleTaxEx, t) in getPendingsaletaxexclude" v-bind:key="t">
            <td class="subtotal">{{ saleTaxEx.taxName }}</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(saleTaxEx.taxVal) }}
            </td>
          </tr>
          <tr v-if="getPendingSale.Rounding">
            <td class="subtotal">Pembulatan</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getPendingSale.Rounding) }}
            </td>
          </tr>
          <tr v-if="getSubtotal != parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding)">
            <td class="subtotal">Grand Total</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{
                $filters.numberTocurrencyIDR(
                  parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding)
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end print receipt template small -->

    <!-- start print receipt template large -->
    <div id="ticket-print-simpan-order-lg" style="height:100px;overflow: auto; display: none;">
        <table width="100%" v-if="getLogo">
            <tr>
                <td style="width: 20%; text-align: center;">
                    <img v-if="getLogo" :src="getLogo" alt="Logo" style="width:120px; height:auto;" />
                </td>
                <td style="width: 60%; text-align: left;">
                      <span style="font-size: 19px;">{{ options[0].CompanyName }}</span><br />
                      <span style="font-size: 15px;">{{ options[0].CompanyAddress }}</span><br />
                      <span style="font-size: 15px;">{{ options[0].CompanyPhone }}</span>
                </td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
        <table width="100%" v-else>
            <tr>
                <td style="text-align: left;">
                    <span style="font-size: 19px;">{{ options[0].CompanyName }}</span><br />
                    <span style="font-size: 15px;">{{ options[0].CompanyAddress }}</span><br />
                    <span style="font-size: 15px;">{{ options[0].CompanyPhone }}</span>
                </td>
            </tr>
        </table>
        <div style="width: 100%; font-size: 15px;" v-if="getPendingSale">
        <hr/>
        <div style="width: 55%; float: left">
          <table>
            <tr>
              <td>Nomor Pesanan</td>
              <td>:</td>
              <td>{{ getPendingSale.SaleOrderNumber }}</td>
            </tr>
            <tr>
              <td>Pelayan</td>
              <td>:</td>
              <td>{{ getPendingSale.WaitressName }}</td>
            </tr>
            <tr>
              <td>Kasir</td>
              <td>:</td>
              <td>{{ getPendingSale.CreatedBy }}</td>
            </tr>
          </table>
        </div>
        <div style="width: 45%; float: left">
          <table>
            <tr>
              <td>Tipe Penjualan</td>
              <td>:</td>
              <td>{{ getPendingSale.NamaOpsiMakan }}</td>
            </tr>
            <tr>
              <td>Bill Date</td>
              <td>:</td>
              <td>{{ getPendingSale.DateIndo }} {{ getPendingSale.SaleTime }}</td>
            </tr>
            <tr>
              <td>Pelanggan</td>
              <td>:</td>
              <td>{{ getPendingSale.CustomerName }}</td>
            </tr>
          </table>
        </div>
      </div>  
      <table width="100%" style="border-collapse: collapse;" v-if="getPendingsaleitemdetail">
        <tr>
          <th style="border: 1px solid black; padding: 5px;">NO</th>
          <th style="border: 1px solid black; padding: 5px;">NAMA ITEM</th>
          <th style="border: 1px solid black; padding: 5px;">QTY</th>
          <th style="border: 1px solid black; padding: 5px;">HARGA SATUAN</th>
          <th style="border: 1px solid black; padding: 5px;">DISKON</th>
          <th style="border: 1px solid black; padding: 5px;">SUB TOTAL</th>
        </tr>
        <tr v-for="(itemDetail, k) in getPendingsaleitemdetail" v-bind:key="k">
          <td style=" border: 1px solid black; text-align: center; padding: 3px;">
            {{ k + 1 }}
          </td>
          <td style=" border: 1px solid black; text-align: left; padding: 3px;">
            {{ itemDetail.ItemName }} <br />
            <div class="print-isipaket" v-for="(isiPaket, kk) in itemDetail.IsiPaket" v-bind:key="kk">
              <span style="padding-left: 30px">
                - {{ $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity) }} {{ isiPaket.ProductName }} {{ isiPaket.VariantName ? '- '+isiPaket.VariantName : '' }}
              </span><br />
            </div>
          </td>
          <td style=" border: 1px solid black; text-align: center; padding: 3px;">
            {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }}
          </td>
          <td style=" border: 1px solid black; text-align: right; padding: 3px;">
            {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
          </td>
          <td style=" border: 1px solid black; text-align: right; padding: 3px;">
            {{ itemDetail.Discount }}
          </td>
          <td style=" border: 1px solid black; text-align: right; padding: 3px;">
            {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
          </td>
        </tr>
      </table>
      <table width="100%" v-if="getPendingSale">
        <tr>
          <td style="width: 60%"></td>
          <td>
            <span v-if="getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount">
              Total Sebelum Diskon
            </span>
            <span v-else>
              Total
            </span>
          </td>
          <td></td>
          <td style="text-align:right">{{ $filters.numberTocurrencyIDR(getSubtotal) }}</td>
        </tr>
        <tr v-for="(saleDisc, d) in getPendingsalediscountdetail" v-bind:key="d">
          <td style="width: 60%"></td>
          <td>{{ saleDisc.DiscountName }} <span v-if="saleDisc.Discount.search('%') > 0">{{saleDisc.Discount}}</span></td>
          <td></td>
          <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }} </td>
        </tr>
        <tr v-if="getPendingsalediscountdetail.length == 0 && getPendingSale.FinalDiscount">
          <td style="width: 60%"></td>
          <td>Diskon Final <span v-if="getPendingSale.FinalDiscount.toString().search('%') > 0">{{getPendingSale.FinalDiscount}}</span></td>
          <td></td>
          <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(getDiscountFinal) }} </td>
        </tr>
        <tr v-if="getPendingsaletaxexclude.length > 0 && (getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount)">
          <td style="width: 60%"></td>
          <td>Total</td>
          <td></td>
          <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(getBeforeTax) }} </td>
        </tr>
        <tr v-for="(saleTaxEx, t) in getPendingsaletaxexclude" v-bind:key="t">
          <td style="width: 60%"></td>
          <td> {{ (saleTaxEx.taxName) }} </td>
          <td></td>
          <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(saleTaxEx.taxVal) }} </td>
        </tr>
        <tr v-if="getPendingSale.Rounding">
          <td style="width: 60%"></td>
          <td>Pembulatan</td>
          <td></td>
          <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(getPendingSale.Rounding) }} </td>
        </tr>
        <tr v-if="(getSubtotal != (parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding)))">
          <td style="width: 60%"></td>
          <td>Grand Total</td>
          <td></td>
          <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding)) }} </td>
        </tr>
      </table>
      <table width="100%" style="padding-top:5px; padding-bottom: 20px">
        <tr>
          <td style="text-align:center">Penerima,</td>
          <td style="text-align:center">Pengirim,</td>
          <td style="text-align:center">Hormat Kami,</td>
        </tr>
        <tr>
          <td style="text-align:center" colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td style="text-align:center" colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td style="text-align:center">
            (_____________)
          </td>
          <td style="text-align:center">
            (_____________)
          </td>
          <td style="text-align:center">
            (_____________)
          </td>
        </tr>
      </table>
      <table width="100%">
        <tr>
          <td style="text-align:left">
            {{ options[0].FootNoteSatu }}
          </td>
        </tr>
        <tr>
          <td style="text-align:left">
            {{ options[0].FootNoteDua }}
          </td>
        </tr>
        <tr>
          <td style="text-align:left">
            {{ options[0].FootNoteTiga }}
          </td>
        </tr>
        <tr>
          <td style="text-align:left">
            {{ options[0].FootNoteEmpat }}
          </td>
        </tr>
        <tr>
          <td style="text-align:left">
            powered by nutapos.com
          </td>
        </tr>
      </table>
    </div>
    <!-- end print receipt template large -->    
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PrintSimpanOrder_c",
  components: {},
  data() {
    return {};
  },
  props: {
    dataTransactionID: String,
  },
  computed: {
    ...mapGetters([
      "options",
      "pendingsale",
      "pendingsaleitemdetail",
      "pendingsaleitemproduct",
      "pendingsalediscountdetail",
      "wsBaseUrl"
    ]),
    // logo outlet
    getLogo() {
        var logoReceipt = null
        var tempDataOptions = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
        tempDataOptions = tempDataOptions.filter((opt) => {
          return (opt.OnlineImagePath != "");
        });
        if(tempDataOptions.length > 0){
          if(tempDataOptions[0].OnlineImagePath.substring(-1, 4) == "http") {
            logoReceipt = tempDataOptions[0].OnlineImagePath
          } else {
            logoReceipt = this.wsBaseUrl + tempDataOptions[0].OnlineImagePath
          }
        } else {
          var tempLocalImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
          tempLocalImage = tempLocalImage.filter((loc) => {
            return (loc.Table == "options" && loc.TableID == "1");
          });
          if(tempLocalImage.length > 0){
            logoReceipt = tempLocalImage[0].Image
          }
        }
        return logoReceipt
    },
    // data pending sale
    getPendingSale() {
      var data = {};
      data = this.pendingsale.find(
        (b) => b.TransactionID + "." + b.DeviceNo == this.dataTransactionID
      );
      if (data) {
        data.DateIndo = this.convertDateDBtoIndo(data.SaleDate);
      }
      return data;
    },
    // data pending sale item detail
    getPendingsaleitemdetail() {
      var data = [];
      data = this.pendingsaleitemdetail.filter((b) => {
        return (
          b.TransactionID + "." + b.TransactionDeviceNo ==
          this.dataTransactionID
        );
      });
      if (data) {
        for (let i = 0; i < data.length; i++) {
          var saleItemProductData = this.pendingsaleitemproduct.filter(
            (itemsprod) => {
              return (
                itemsprod.TransactionID + "." + itemsprod.TransactionDeviceNo ==
                  this.dataTransactionID &&
                itemsprod.DetailID == data[i].DetailID &&
                itemsprod.DetailDeviceNo == data[i].DeviceNo
              );
            }
          );
          data[i].IsiPaket = saleItemProductData;
        }
      }
      return data;
    },
    // sub total item
    getSubtotal() {
      var subTotal = 0;
      var data = this.pendingsaleitemdetail.filter((b) => {
        return (
          b.TransactionID + "." + b.TransactionDeviceNo ==
          this.dataTransactionID
        );
      });
      if (data) {
        for (let i = 0; i < data.length; i++) {
          subTotal += parseFloat(data[i].SubTotal);
        }
      }
      return subTotal;
    },
    // pending sale discount detail
    getPendingsalediscountdetail() {
      var data = [];
      data = this.pendingsalediscountdetail.filter((b) => {
        return (
          b.TransactionID + "." + b.TransactionDeviceNo ==
            this.dataTransactionID && b.Discount != "0"
        );
      });
      return data;
    },
    // diskon final
    getDiscountFinal() {
      let discFinal = 0;
      if (this.getPendingsalediscountdetail.length == 0) {
        if (this.getPendingSale.length) {
          var dataDics = this.getPendingSale.FinalDiscount.toString();
          if (dataDics.search("%") > 0) {
            discFinal =
              (this.getSubtotal / 100) *
              parseFloat(this.getPendingSale.FinalDiscount.replace("%", ""));
          } else {
            discFinal = this.getPendingSale.FinalDiscount;
          }
        }
      }
      return discFinal;
    },
    // data pajak exclude
    getPendingsaletaxexclude() {
        var arr = []
        if(this.getPendingSale){
            var explode = this.getPendingSale.DetailExcludeTaxValues.split("#")
            if(explode){
                for (let i = 0; i < explode.length; i++) {
                    if(explode[i]){
                        var explode2 = explode[i].split("$")
                        var taxexarr = {
                            taxName: explode2[0],
                            taxVal: explode2[1],
                        };
                        arr.push(taxexarr)
                    }
                    
                }
            }
        }
        return arr
    },
    // sebelum pajak
    getBeforeTax() {
      let beforeTax = 0;
      var tot = this.getSubtotal
      if(this.getPendingsalediscountdetail.length > 0){
        this.getPendingsalediscountdetail.forEach(function (discOutlet) {
          if(discOutlet.Discount.search("%") > 0){
            tot = parseFloat(tot - ((tot / 100)* discOutlet.Discount.replace("%", "")));
          } else {
            tot = parseFloat(tot - discOutlet.Discount);
          }
        });
      }
      beforeTax = tot - this.getDiscountFinal;
      return beforeTax;
    },
  },
  methods: {
    // cetak struk
    printSimpanOrder() {
      var templateData = localStorage.getItem("receipttemplate") ? JSON.parse(localStorage.getItem("receipttemplate")) : [];
      if (templateData.length > 0) {
        if (templateData[0].template == "small") {
          this.printReceiptSm()
        } else if (templateData[0].template == "big") {
          this.printSimpanOrderLg()
        }
      } else {
        this.printSimpanOrderSm()
      }
    },
    // cetak struk small
    printSimpanOrderSm() {
      /*let routeData = this.$router.resolve({
          name: 'Cetak Struk', 
          query: {TransactionID: this.stTransactionID}
        });
        window.open(routeData.href, '_blank');*/

      // Get HTML to print from element
      const prtHtml = document.getElementById(
        "ticket-print-simpan-order"
      ).innerHTML;

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          table {
            /*border: 1px solid black;*/
            border-collapse: collapse;
            width: 100%;
          }

          td.description {
            text-align: left;
          }

          td.quantity {
            text-align: left;
          }

          td.subtotal {
            text-align: right;
          }

          .centered {
            text-align: center;
            align-content: center;
          }

          .ticket {
            width: 280px;
            max-width: 280px;
          }

          img {
            max-width: inherit;
            width: inherit;
          }
          .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 110px;
            width: auto;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
      setTimeout(() => WinPrint.close(), 1000);

      return true;
    },
    // cetak struk big
    printSimpanOrderLg() {
      const prtHtml = document.getElementById(
        "ticket-print-simpan-order-lg"
      ).innerHTML;

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
      setTimeout(() => WinPrint.close(), 1000);

      return true;
    },
    convertDateDBtoIndo(string) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];

      var tanggal = string.split("-")[2];
      var bulan = string.split("-")[1];
      var tahun = string.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
  },
};
</script>
<style scoped>
table {
  /*border: 1px solid black;*/
  border-collapse: collapse;
  width: 100%;
}

td.description {
  text-align: left;
}

td.quantity {
  text-align: left;
}

td.subtotal {
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 280px;
  max-width: 280px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 110px;
  width: auto;
}

@media print {
}
</style>