<template>
  <div>
    <div id="modalExpired" class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12" style="text-align: left; padding-bottom: 10px;">
                Masa aktif Anda telah habis.<br />
                Silakan lakukan Aktivasi melalui website nutacloud.com !
              </div>
            </div>
            <div class="row py-2">
              <div class="col-lg-5"></div>
              <div class="col-lg-7">
                <div class="row">
                  <div class="col-lg-6">
                    <button class="btn btn-link btn-block" type="button" @click="logoutPerusahaan">
                      Logout
                    </button>
                  </div>
                  <div class="col-lg-6" v-if="btnBerlangganan == 1">
                    <button class="btn btn-green btn-block" type="button" @click="berlangganan">
                      Berlangganan Sekarang
                    </button>
                  </div>
                  <div class="col-lg-6" v-if="btnBerlangganan == 2">
                    <button class="btn btn-green btn-block" type="button" @click="cekAgainExpired">
                      Cek Berlangganan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import $ from 'jquery';
export default {
  name: "ExpiredModal",
  data() {
    return {
      btnBerlangganan: 1,
    }
  },
  components: {
  },
  computed: {
  },
  watch: {
    
  },
  methods: {
    ...mapActions(["downloadtglexpired","logoutClearAllData"]),
    cekAgainExpired() {
      this.btnBerlangganan = 1
      this.$store.dispatch("downloadtglexpired")
      this.dismissModalExpired()
    },
    dismissModalExpired(){
      $("#modalExpired").modal('hide');
    },
    berlangganan(){
      this.btnBerlangganan = 2
      window.open("https://www.nutacloud.com/billing", "_blank")
    },
    logoutPerusahaan(){
      this.btnBerlangganan = 1
      this.$store.dispatch("logoutClearAllData")
      this.dismissModalExpired()
    },
  },
};
</script>
<style scoped>
.footerModal{
  padding-left: 30px;
  padding-right: 30px
}
</style>