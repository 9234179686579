<template>
  <div>
    <div id="modalPembayaranQRIS" class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12" style="text-align: center">
                <img :src="dataPembayaran ? dataPembayaran.QRISPayment.LinkLogoEnable : ''" style="height: 70px; width: auto;"><br />
                <span>{{ msgHeader }}</span>
                <h5 style="padding-top: 10px">Rp {{ $filters.numberTocurrencyIDR(dataPembayaran ? (dataPembayaran.Change > 0 ? Math.ceil(dataPembayaran.Change) : Math.ceil(stTotalHeader)) : 0) }}</h5>
                <img :src="qrImg" style="height: 250px; width: auto;">
              </div>
              <div class="col-12" style="text-align: center">
                <img :src="qrisImg" style="height: 20px; width: auto;"><br />
                <div class="footerModal">
                  <h5>{{ msgFooterH5 }}</h5>
                  <span>{{ msgFooter }}</span> <span v-if="showCountingDown">{{ minutesLeft }}:{{ secondsLeft }}</span>
                </div>
              </div>
            </div>
            <div class="row py-2">
              <div class="col" v-if="statusPayment == 0 || statusPayment == 1 || statusPayment == 2">
                <button class="btn btn-link btn-block" type="button" @click="backToPembayaranModal">
                  Kembali
                </button>
              </div>
              <div class="col" v-if="statusPayment == 1">
                <button class="btn btn-green btn-block" type="button" @click="statusQRIS">
                  Cek status pembayaran
                </button>
              </div>
              <div class="col" v-if="statusPayment == 2">
                <button class="btn btn-green btn-block" type="button" @click="requestQRIS">
                  Perbarui QR
                </button>
              </div>
              <div class="col" v-if="statusPayment == 3">
                <button class="btn btn-green btn-block" type="button" @click="paymentSave">
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- komponen modal pembayaran berhasil-->
    <PembayaranBerhasilModal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PembayaranBerhasilModal from "@/components/PembayaranBerhasilModal_c.vue";

import axios from 'axios'
import $ from 'jquery'
export default {
  name: "PembayaranQRISModal_c",
  data() {
    return {
      showCountingDown: false,
      minutesLeft: 14,
      secondsLeft: 59,
      secondsAfter: 0,
      invervalTimer: "",
      order_id: null,
      transaction_id: null,
      qrImg: require("@/assets/images/qris/disable/QRdisabled.svg"),
      qrisImg: require("@/assets/images/qris/enable/QRIS.svg"),
      statusPayment: 0,
      msgHeader: "Silahkan tunjukan QR ini ke pelanggan untuk discan", 
      msgFooter: "", 
      msgFooterH5: "",
    }
  },
  props: {
    dataPembayaran : Object
  },
  components: {
    PembayaranBerhasilModal,
  },
  computed: {
    ...mapGetters(["stTotalHeader","options","cashbank","masterdigitalpayment", "deviceNo", "connectionStatus"]),
  },
  watch: {
    dataPembayaran: function(){
      this.requestQRIS()      
    }
  },
  methods: {
    ...mapActions(["actCreateTransaction"]),
    // timer
    startTimer() {
      this.invervalTimer = setInterval(() => {
        if (this.secondsLeft > 0) {
          this.secondsLeft--;
          this.secondsAfter++;
          if((this.secondsAfter % 10) == 0){
            this.statusPayment = 1
            this.statusQRIS();
          }
        } else if (this.secondsLeft == 0 && this.minutesLeft > 0) {
          this.secondsLeft = 59;
          this.minutesLeft--;
        } else {
          this.resetTimer()
          this.failedGenerateQRIS("expired")
          // Timer hits 0 do what you want to do here.
        }            
      }, 1000)
    },
    // reset timer
    resetTimer() {
      clearInterval(this.invervalTimer)
      this.showCountingDown = false
      this.minutesLeft = 14
      this.secondsLeft = 59
      this.secondsAfter = 0
    },
    // request QRIS ke midtrans
    requestQRIS(){
      this.resetTimer()
      if(this.connectionStatus == "Online"){
        // get latesttransactionid
        var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];

        //create TransactionID
        var TransactionID = 1;
        if(latesttransactionid){
          if(latesttransactionid.saleLatestId){
            TransactionID = parseInt(latesttransactionid.saleLatestId) + 1;
          }
        }

        // get server key
        var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];

        //create TransactionID
        var serverKey = null;
        if(cashbank.length > 0){
          serverKey = cashbank.find((b) => b.IsDigitalPayment == '1' && b.AccountType == '2');
        }

        //faormat date time today
        let today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = String(today.getFullYear());
        var dFormat = yyyy + "-" + mm + "-" + dd;

        var hh = String(today.getHours()).padStart(2, '0');
        var mi =  String(today.getMinutes()).padStart(2, '0');
        var ss = String(today.getSeconds()).padStart(2, '0');
        var tFormat = hh + "-" + mi + "-" + ss;

        //order_id: "DeviceID-DeviceNo-TransactionID-RowVersion#CreateDate#CreateTime#app-dev",
        this.order_id = this.options[0].DeviceID +"-"+ this.deviceNo +"-"+ TransactionID +"-1#"+ dFormat +"#"+ tFormat +"#app-dev"
        var amount = (this.dataPembayaran ? (this.dataPembayaran.Change > 0 ? this.dataPembayaran.Change : this.stTotalHeader) : 0)
        amount = Math.ceil(amount)
        var server_key = btoa(serverKey.GopayServerKey) //"TWlkLXNlcnZlci1vRXpZa25aMmx0ODdSM1hPSGdaTWVENU4="
        var result = null
        //var data = "{\"status_code\":\"201\",\"status_message\":\"QRIS transaction is created\",\"transaction_id\":\"5f72c357-6f07-479c-8aeb-241013c27ee7\",\"order_id\":\"3243-47-960-1#2021-07-22#11-30-00#app-dev\",\"merchant_id\":\"G124752473\",\"gross_amount\":\"1000.00\",\"currency\":\"IDR\",\"payment_type\":\"qris\",\"transaction_time\":\"2021-07-22 11:31:17\",\"transaction_status\":\"pending\",\"fraud_status\":\"accept\",\"actions\":[{\"name\":\"generate-qr-code\",\"method\":\"GET\",\"url\":\"https:\/\/api.veritrans.co.id\/v2\/qris\/5f72c357-6f07-479c-8aeb-241013c27ee7\/qr-code\"}],\"qr_string\":\"00020101021226610014COM.GO-JEK.WWW01189360091431247524730210G1247524730303UME51440014ID.CO.QRIS.WWW0215ID20200388428580303UME5204573453033605802ID5925PT Nusantara Berkah Digit6008SIDOARJO61056125654071000.00623950280220210722043118nu3WtpoqcSID0703A01630427C6\",\"acquirer\":\"gopay\"}";
        //data = JSON.parse(data)
       
       $.ajax({
            url: axios.defaults.baseURLWS + "wssale/getqris",
            data: {
                order_id: this.order_id,
                amount: amount,
                server_key: server_key
            },
            method: "POST",
            success: (data) => {
              result = JSON.parse(data)
              if(result.status_code == "201"){
                this.transaction_id = result.transaction_id
                this.qrImg = result.actions[0].url
                this.msgFooter = "QR ini akan kadaluarsa dalam"
                this.showCountingDown = true
                this.startTimer()
              } else {
                this.failedGenerateQRIS("failed")
              }
            },
            error: () => {
              this.failedGenerateQRIS("failed")
            },
        })
        /*$.ajax({
            url: "https://api.midtrans.com/v2/charge",
            headers: {
              "Authorization": "Basic TWlkLXNlcnZlci1vRXpZa25aMmx0ODdSM1hPSGdaTWVENU4=",
              "X-Override-Notification": "https://ws-dev.nutadev.com/gopay/notification",
              "Content-Type": "application/json",
            },
            method: 'POST',
            dataType: 'JSON',
            data: {
              payment_type : "qris",
              transaction_details: {
                order_id: this.order_id,
                gross_amount: amount,
                }
              },
            success: function (data) {
              alert(JSON.stringify(data));
            },
            error: function(data){
              alert(JSON.stringify(data));
            }
        });*/
      } else {
        this.failedGenerateQRIS("offline")
      }
    },
    // status QRIS ke midtrans
    statusQRIS(){
      var server_key = "TWlkLXNlcnZlci1vRXpZa25aMmx0ODdSM1hPSGdaTWVENU4="
      var result = null
      if(this.connectionStatus == "Online" && this.transaction_id != null){
        $.ajax({
            url: axios.defaults.baseURLWS + "wssale/statusqris",
            data: {
                order_id: this.transaction_id,
                server_key: server_key
            },
            method: "POST",
            success: (data) => {
              result = JSON.parse(data)
              if(result.status_code == "200"){
                if(result.transaction_status == "settlement"){
                  this.paymentSuccess()
                }
              }
            },
            error: () => {

            },
        })
      /*$.ajax({
          url: "https://api.midtrans.com/v2/"+ this.order_id +"/status",
          headers: {
            "Authorization": "Basic TWlkLXNlcnZlci1vRXpZa25aMmx0ODdSM1hPSGdaTWVENU4=",
            "X-Override-Notification": "https://ws-dev.nutadev.com/gopay/notification",
            "Content-Type": "application/json",
          },
          method: 'GET',
          dataType: 'JSON',
          success: function (data) {
            alert(JSON.stringify(data));
          },
          error: function(data){
            alert(JSON.stringify(data));
          }
      });*/
      } 
    },
    // gagal generate QRIS
    failedGenerateQRIS(sts){
      this.showCountingDown = false
      this.statusPayment = 2
      this.transaction_id = null
      this.qrImg = require("@/assets/images/qris/disable/QRdisabled.svg")
      var msg = ""
      if(sts == "failed"){
        msg = "Gagal menampilkan QR. Silahkan tekan tombol perbarui QR untuk menampilkan QR kembali"
      } else if(sts == "offline") {
        msg = "Tidak ada koneksi internet. Silahkan cek koneksi internet anda lalu coba lagi"
      } else if(sts == "expired") {
        msg = "QR sudah kadaluarsa. Silahkan tekan tombol perbarui QR untuk menampilkan QR baru"
      }
      this.msgFooter = msg
    },
    // payment QRIS success
    paymentSuccess(){
      this.resetTimer()
      this.statusPayment = 3
      this.qrImg = require("@/assets/images/qris/disable/QRberhasil.svg")
      this.msgFooterH5 = "Berhasil"
      this.msgFooter = ""
    },
    // simpan transaksi
    paymentSave(){
      //get rekening digunakan untuk digital payment
      let tempBank = this.cashbank;
      tempBank = tempBank.filter((item) => {
        return (
          item.IsDigitalPayment == "1" && item.AccountType == "2"
        );
      });

      let paymentReview = {
        PaymentMode: this.dataPembayaran.PaymentMode,
        CashPayment: this.dataPembayaran.CashPayment,
        Change: this.dataPembayaran.Change,
        TfPayment: this.dataPembayaran.TfPayment,
        EDCPayment: this.dataPembayaran.EDCPayment,
        EDCPercent: this.dataPembayaran.EDCPercent,
        EDCTotal: this.dataPembayaran.EDCTotal,
        QRStatisPayment: this.dataPembayaran.QRStatisPayment,
        QRISPayment: tempBank[0].AccountID + "." + tempBank[0].DeviceNo,
      }
      this.$store.dispatch("actCreateTransaction", paymentReview)
      this.printReceipt();
    },
    // on click kembali ke pembayaran
    backToPembayaranModal(){
      this.statusPayment = 0
      this.order_id = null
      this.transaction_id = null
      this.qrImg = require("@/assets/images/qris/disable/QRdisabled.svg")
      this.msgFooter = ""
      this.msgFooterH5 = ""
      this.resetTimer()
      $("#modalPembayaranQRIS").modal('hide');
      setTimeout(() => $("#modalPembayaran").modal("show") , 500);
    },
    // on click print struk
    printReceipt(){
      this.statusPayment = 0
      this.order_id = null
      this.transaction_id = null
      this.qrImg = require("@/assets/images/qris/disable/QRdisabled.svg")
      this.msgFooter = ""
      this.msgFooterH5 = ""
      this.resetTimer()
      $("#modalPembayaranQRIS").modal('hide');
      setTimeout(() => $("#modalPembayaranBerhasil").modal("show") , 500);
    },
  },
};
</script>
<style scoped>
.footerModal{
  padding-left: 30px;
  padding-right: 30px
}
</style>