import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

/*import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyA76iIAJdTQ4Q4UG8PISmvNjs5olZJs1Xs",
  authDomain: "nuta-dev.firebaseapp.com",
  databaseURL: "https://nuta-dev.firebaseio.com",
  projectId: "nuta-dev",
  storageBucket: "nuta-dev.appspot.com",
  messagingSenderId: "689411877624",
  appId: "1:689411877624:web:d3e3b97822fb321d98690d"
});

// Retrieve an instance of Firebase Messaging so that it can handle background messages.
const messaging = getMessaging(firebaseApp);*/

var base_url = window.location.origin;
if (base_url == "https://kasir.nutacloud.com") {
  axios.defaults.baseURL = 'https://api.nutacloud.com/'
  axios.defaults.baseURLWS = 'https://wsup.nutacloud.com/'
} else if (base_url == "https://kasir.nutadev.com") {
  axios.defaults.baseURL = 'https://api.nutadev.com/'
  axios.defaults.baseURLWS = 'https://ws.nutadev.com/'
} else {
  axios.defaults.baseURL = 'https://api-dev.nutadev.com/'
  axios.defaults.baseURLWS = 'https://ws-dev.nutadev.com/'
}

const app = createApp(App);
//app.config.globalProperties.$messaging = messaging
app.config.globalProperties.$filters = {
  currencyIDR(value) {
    var a = value;
    if ("" != a && null != a) {
      a = a.toString();

      a = a.replace(/[^\d\,]/g, "").split(","); // eslint-disable-line
      for (var b = "", c = 0, d = a[0].length; 0 < d; d--)
        c += 1, b = 1 == c % 3 && 1 != c ? a[0].substr(d - 1, 1) + "." + b : a[0].substr(d - 1, 1) + b;
      1 < a.length && (b = 0 < a[1].length ? b + ("," + a[1].substr(0, 2)) : b + ",");
      return b;
    }
    return 0;
  },
  numberTocurrencyIDR(value) {
    var a = value;
    var hasMinus = "";
    if ("" != a && null != a) {
      a = a.toString();
      hasMinus = a.search('-')
      a = a.replace("-", "")
      a = a.split(".");
      for (var b = "", c = 0, d = a[0].length; 0 < d; d--)
        c += 1, b = 1 == c % 3 && 1 != c ? a[0].substr(d - 1, 1) + "." + b : a[0].substr(d - 1, 1) + b;
      1 < a.length && (b = 0 < a[1].length ? b + ("," + a[1].substr(0, 2)) : b + ",");
      return (hasMinus == "" ? "-" + b : b);
    }
    return 0;
  },
}
app.use(store);
app.use(router);
app.mount("#app");
