<template>
  <div style="font-size:14px">
  <!-- Modal data produk-->
  <div id="modalWaitress" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Daftar Waitress</h4>
          <button
            type="button"
            class="close"
            v-on:click="hidemodalwaitress"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12">
              <div class="form-group row">
                <label class="control-label-bold col-2" style="margin-top:9px;">Waitress</label>
                <div class="col-6">
                  <input
                    class="form-control"
                    v-model="searchByName"
                    type="text"
                    placeholder="Masukan nama, no hp, atau email pelanggan"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
          <div class="col-12 scrollable">
            <table class="table table-bordered table-striped table-sm">
              <thead>
                <tr style="background-color: #00ae2b">
                  <th class="text-center text-white th-prod" style="width: 40%">Nama</th>
                  <th class="text-center text-white th-prod" style="width: 25%">Email</th>
                  <th class="text-center text-white th-prod" style="width: 25%">No HP</th>
                </tr>
              </thead>
              <tbody style="cursor: pointer;" v-if="waitress.length === 0">
                  <tr>
                      <td colspan="4" style="height:325px;text-align: center" >
                          Belum terdapat data waitress
                      </td>
                  </tr>
              </tbody>
              <tbody style="cursor: pointer;" v-if="searchWaitressResult.length > 0">
                <tr
                  v-for="dataWaitress in searchWaitressResult"
                  v-bind:key="dataWaitress.WaitressID+ '.' + dataWaitress.DeviceNo"
                >
                  <td class="text-left"
                  @click="setWaitress(dataWaitress)"
                  >{{ dataWaitress.WaitressName }}</td>
                  <td class="text-left"
                  @click="setWaitress(dataWaitress)"
                  >{{ dataWaitress.WaitressEmail }}</td>
                  <td class="text-left"
                  @click="setWaitress(dataWaitress)"
                  >{{ dataWaitress.WaitressPhone }}</td>
                </tr>
              </tbody>
              <tbody style="cursor: pointer;" v-if="searchWaitressResult.length == 0 && waitress.length != 0">
                  <tr>
                      <td colspan="4" style="height:325px; text-align:center" >
                          Waitress tidak ditemukan
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import $ from "jquery";
export default {
  data() {
    return {
      searchByName: "",
      searchByCategory: "",
      setdatawaitress: null,
    };
  },
  name: "WaitressModal_c",
  computed: {
    ...mapGetters(["waitress"]),
    searchWaitressResult() {
      let waitressData = this.waitress;
      
      if (this.searchByName !== "" && this.searchByName) {
        waitressData = waitressData.filter((item) => {
          return (
            item.WaitressName.toLowerCase().includes(this.searchByName.toLowerCase()) ||
            item.WaitressPhone.toLowerCase().includes(this.searchByName.toLowerCase()) ||
            item.WaitressEmail.toLowerCase().includes(this.searchByName.toLowerCase()) 
          )
        })
      }

      return waitressData;
    },
  },
  methods: {
    ...mapActions(["actSetWaitress"]),
    setWaitress(data){
      this.$store.dispatch("actSetWaitress", data);
      this.searchByName = ""
      $("#modalWaitress").modal("hide");
    },
    hidemodalwaitress(){
      this.searchByName= "";
      $("#modalWaitress").modal("hide");
    }
  },
};
</script>
<style scoped>
  .scrollable {
    height: 325px;
    overflow-y: auto;
  }
  .th-prod {
    position: sticky;
    background-color:#00ae2b;
    z-index: 2;
    top: 0;
  }
</style>