<template>
    <div>
        <div id="modalDetailRiwayatTutupOutlet" class="modal hide fade in">
            <div class="card card0" style="width: 60%;height: 700px;margin-top: 5%;margin-left: 20%;background-color: #fff;">
                <div class="atas"></div>
                    <div class="modal-dialog modal-lg">
                    <div class="modal-content"
                        style="height: 530px;margin-top: 30px;border-radius: 10px;border: 0px;">
                        <div class="modal-body">
                        <div class="row py-2">
                            <img src="/close_outlet.png" class="iconclose" />
                        </div>
                        <div class="text-center"><strong>Tutup Outlet</strong></div>
                        <div class="text-center">
                            <strong>Perangkat ke - {{ dataCloseOutlet.DeviceNo }}</strong>
                        </div>
                        <div class="row">
                            <div class="col-12 scrollable">
                            <table class="table" v-if="stSetUserTablet">
                                <tbody style="cursor: pointer">
                                <tr>
                                    <td colspan="2" class="text-left">Buka Outlet</td>
                                    <td colspan="2" class="text-left">
                                    {{ tglopenoutlet }}, {{ dataCloseOutlet.OpenTime }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">Outlet dibuka oleh</td>
                                    <td>:</td>
                                    <td>{{ dataCloseOutlet.OpenedBy }}</td>
                                    <td class="text-left"></td>
                                </tr>
                                <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                    <td class="text-left">
                                    1. Modal Awal perangkat ke - {{ dataCloseOutlet.DeviceNo }}
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.StartingCashOri) }}
                                    </td>
                                </tr>
                                <tr v-if="perubahanModal != 0">
                                    <td class="text-left">
                                    &emsp;&emsp; &#8226; Perubahan Modal <i class="fa fa-info-circle" title="Perubahan modal tampil akibat adanya edit / hapus data pada shift atau tanggal sebelumnya"></i>
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    {{ $filters.numberTocurrencyIDR(perubahanModal) }}
                                    </td>
                                </tr>
                                <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                    <td class="text-left">
                                    2. Total Penjualan Perangkat ke - {{ dataCloseOutlet.DeviceNo }}
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    <a href="#" v-on:click="showringkasanpenjualan" class="text-green">
                                        {{ $filters.numberTocurrencyIDR(dataCloseOutlet.TotalSales) }}
                                    </a>
                                    </td>
                                </tr>
                                <tr v-for="(detailjual, index) in dataPenjualan.TotalPenjualan[0].Detail" v-bind:key="index">
                                    <td v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1" colspan="4">
                                    <div class="row">
                                        <div class="col-9">
                                        &emsp;&emsp; &#8226; Pembayaran
                                        {{ detailjual.PaymentName }}
                                        </div>
                                        <div class="col-3 text-right">
                                        Rp
                                        {{ $filters.numberTocurrencyIDR(detailjual.Total) }}
                                        </div>
                                    </div>
                                    <div class="row py-3" v-for="(detailtrans, index2) in detailjual.detail" v-bind:key="index2">
                                        <div class="col-9">
                                        &emsp;&emsp; &emsp;&emsp; -
                                        {{ detailtrans.AccountName }}
                                        </div>
                                        <div class="col-3 text-right">
                                        Rp
                                        {{ $filters.numberTocurrencyIDR(detailtrans.Total) }}
                                        </div>
                                    </div>
                                    </td>
                                </tr>
                                <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                    <td class="text-left">3. Pemasukan Lain Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">{{ $filters.numberTocurrencyIDR(dataCloseOutlet.OtherIncome)}}</td>
                                </tr>
                                <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                    <td class="text-left">4. Total Pengeluaran Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">{{ $filters.numberTocurrencyIDR(dataCloseOutlet.Expense) }}</td>
                                </tr>
                                <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                    <td class="text-left">
                                    5. Uang di laci (1) + (2a) + (3) - (4)
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    {{ $filters.numberTocurrencyIDR(datauangdilaci) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                    6. Uang yang ditarik di laci (Aktual)<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;diserahkan ke manager/
                                    owner
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.Withdrawal) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                    7. Sisa uang di laci (Aktual)
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.ActualBalance) }}
                                    </td>
                                </tr>
                                <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                    <td class="text-left">
                                    8. Selisih (Aktual - Sistem) = (5) - (6) - (7)
                                    </td>
                                    <td>:</td>
                                    <td>Rp</td>
                                    <td class="text-right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.Difference) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-left">Tutup Outlet</td>
                                    <td colspan="2" class="text-left">
                                    {{ tglcloseoutlet }}, {{ dataCloseOutlet.CloseTime }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">Outlet ditutup oleh</td>
                                    <td>:</td>
                                    <td>{{ dataCloseOutlet.ClosedBy }}</td>
                                    <td class="text-left"></td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <label
                            v-on:click="kembali"
                            class="control-label-bold col-5 text-green ml-auto"
                            style="margin-top: 9px; text-align: right !important">
                            Kembali
                        </label>
                        <button
                            class="col-5 btn btn-green btn-block ml-auto"
                            style="margin-right: 17px"
                            v-on:click="cetakUlang">
                            Cetak Ulang
                        </button>
                        </div>
                    </div>
                    </div>
            </div> 
            <!-- start print receipt template small -->
            <div id="ticket-print-close-history" class="ticket" style="display:none" v-if="stSetUserTablet">
                <img
                    v-if="getLogo" :src="getLogo" alt="Logo" class="center" style="width: 150px; height: auto" />
                    <p class="centered">
                    {{ options[0].CompanyName }} <br />{{ options[0].CompanyAddress }} <br />{{ options[0].CompanyPhone }}
                </p>
                <div style="width: 100%">
                    <table>
                        <tr>
                            <td class="text-left" style="width: 50%">Buka Outlet</td>
                            <td class="text-left" style="width: 1%">:</td>
                            <td class="text-left" style="width: 49%">
                                {{ tglopenoutlet }}, {{ dataCloseOutlet.OpenTime }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left" style="width: 50%">Dibuka Oleh</td>
                            <td class="text-left" style="width: 1%">:</td>
                            <td class="text-left" style="width: 49%">
                                {{ dataCloseOutlet.OpenedBy }}
                            </td>
                        </tr>
                        <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                            <td class="text-left" style="width: 50%">Modal Awal</td>
                            <td class="text-left" style="width: 1%">:</td>
                            <td class="text-left" style="width: 49%">
                                Rp {{ $filters.numberTocurrencyIDR(dataCloseOutlet.StartingCashOri) }}
                            </td>
                        </tr>
                        <tr v-if="perubahanModal != 0">
                            <td class="text-left" style="width: 50%">Perubahan Modal</td>
                            <td class="text-left" style="width: 1%">:</td>
                            <td class="text-left" style="width: 49%">
                                Rp {{ $filters.numberTocurrencyIDR(perubahanModal) }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1" 
                style="width: 100%; border-top-style: dashed; border-bottom-style: dashed;">
                    PENJUALAN
                </div>
                <div 
                v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1"
                style="width: 100%">
                    <table>
                        <tr
                            v-for="(cartData, k) in dataPenjualan.SalesSummary"
                            v-bind:key="k"
                            >
                            <td style="width: 60%">{{ cartData.Item }} x {{ cartData.Qty }}</td>
                            <td> Rp</td>
                            <td align="right">
                                {{ $filters.numberTocurrencyIDR(cartData.Total) }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="width: 100%; border-top-style: dashed;"></div>
                <div style="width: 100%">
                    <table class="table">
                        <tbody style="cursor: pointer">
                            <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                <td class="text-left">Total Penjualan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.TotalSales) }}
                                </td>
                            </tr>
                            <span v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                            <tr v-for="(detailjual, index) in dataPenjualan.TotalPenjualan[0].Detail" v-bind:key="index">
                                <td colspan="5">
                                    <table width="100%">
                                        <tr>
                                            <td style="width: 50%" colspan="2">
                                                &emsp;&#8226; {{ detailjual.PaymentName }}
                                            </td>
                                            <td style="width: 10%">Rp</td>
                                            <td align="right" colspan="2">
                                                {{ $filters.numberTocurrencyIDR(detailjual.Total) }}
                                            </td>
                                        </tr>
                                        <tr v-for="(detailtrans, index2) in detailjual.detail" v-bind:key="index2">
                                            <td style="width: 50%" colspan="2" v-if="detailjual.PaymentName !== 'Tunai'">
                                                &emsp;&emsp; - {{ detailtrans.AccountName }}
                                            </td>
                                            <td style="width: 10%" v-if="detailjual.PaymentName !== 'Tunai'">
                                                Rp
                                            </td>
                                            <td align="right" colspan="2" v-if="detailjual.PaymentName !== 'Tunai'">
                                                {{ $filters.numberTocurrencyIDR(detailtrans.Total) }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            </span>
                            <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                <td class="text-left">Pemasukan Lain Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">{{ $filters.numberTocurrencyIDR(dataCloseOutlet.OtherIncome)}}</td>
                            </tr>
                            <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                <td class="text-left">Total Pengeluaran Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">{{ $filters.numberTocurrencyIDR(dataCloseOutlet.Expense) }}</td>
                            </tr>
                            <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                <td class="text-left">Uang di laci</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">
                                    {{ $filters.numberTocurrencyIDR(datauangdilaci) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">Uang Ditarik</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.Withdrawal) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">Sisa Uang Aktual</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.ActualBalance) }}
                                </td>
                            </tr>
                            <tr v-if="stSetUserTablet.AllowLaporanTutupOutlet == 1">
                                <td class="text-left">Selisih</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td align="right">
                                    {{ $filters.numberTocurrencyIDR(dataCloseOutlet.Difference) }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5">Tutup Outlet : {{ tglcloseoutlet }}, {{ dataCloseOutlet.CloseTime }}</td>
                            </tr>
                            <tr>
                                <td colspan="5">Ditutup Oleh : {{ dataCloseOutlet.ClosedBy }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- end print receipt template small -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";

export default {
    name: "DetailRiwayatTutupOutletModal_c",
    data() {
        return {
            dataCloseOutlet: {
                OpenID: "",
                OpenDate: "",
                OpenTime: "",
                OpenedBy: "",
                CloseDate: "",
                CloseTime: "",
                ClosedBy: "",
                Varian: "",
                DeviceID: "",
                DeviceNo: "",
                PerusahaanNo: "",
                PerusahaanID: "",
                RowVersion: "",
                CreatedVersionCode: "",
                EditedVersionCode: "",
                TglJamUpdate: "",
                StartingCash: "",
                TotalSales: "",
                TotalCashSales: "",
                OtherIncome: "",
                Expense: "",
                Taken: "",
                Withdrawal: "",
                ExpectedBalance: "",
                ActualBalance: "",
                Difference: "",
                StartingCashOri: "",
            },
            dataPenjualan: {
                ModalAwal: 0,
                PemasukanLain: 0,
                TotalPengeluaran: 0,
                TotalPenjualan: [{
                    Detail: null,
                }],
            },
            tglopenoutlet: "",
            tglcloseoutlet: null,
            perubahanModal: "",
            datauangdilaci: 0,
        }
    },
    components: {
    },
    props: {
        dataOpenID: Number,
        dataOpenDeviceNo: Number
    },
    watch: {
        dataOpenID: function(){
            this.getDataCloseOutlet()      
        }
    },
    methods: {
        ...mapActions(["actGetHistoryCloseOutlet"]),
        getDataCloseOutlet() {
            var param = {
                OpenID: this.dataOpenID,
                OpenDeviceNo: this.dataOpenDeviceNo
            }
            this.$store.dispatch("actGetHistoryCloseOutlet", param).then((data) => {
                this.$emit('dataForRingkasanPenjualan', data);
                this.dataPenjualan = data.dataPenjualan;
                this.datauangdilaci = parseFloat(data.dataCloseOutlet.StartingCashOri) + parseFloat(data.dataCloseOutlet.TotalCashSales) + parseFloat(data.dataCloseOutlet.OtherIncome);
                
                this.datauangdilaci = parseFloat(this.datauangdilaci) - parseFloat(data.dataCloseOutlet.Expense);
                if(data.dataCloseOutlet.StartingCash != data.dataCloseOutlet.StartingCashOri){
                    this.perubahanModal = parseFloat(data.dataCloseOutlet.StartingCash - data.dataCloseOutlet.StartingCashOri)
                }
                this.dataCloseOutlet = data.dataCloseOutlet;
                this.tglopenoutlet = this.convertDateDBtoIndo(this.dataCloseOutlet.OpenDate);
                this.tglcloseoutlet = this.convertDateDBtoIndo(this.dataCloseOutlet.CloseDate);
            })
            .catch((error) => {
            console.log(error);
            });
        },
        showringkasanpenjualan() {
            this.$emit('pageHistory', "riwayat-tutup-outlet");
            $("#modalDetailRiwayatTutupOutlet").modal("hide");
            setTimeout(() => $("#ringkasanpenjualan").modal("show"), 500);
        },
        cetakUlang() {
        const prtHtml = document.getElementById("ticket-print-close-history").innerHTML;
        const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
            <style>
            body {
                font-family: Avenir, Helvetica, Arial, sans-serif;
            }
            td,
            th,
            tr,
            table {
                /*border: 1px solid black;*/
                border-collapse: collapse;
                width: 100%;
            }

            td.description {
                text-align: left;
            }

            td.quantity {
                text-align: left;
            }

            td.subtotal {
                text-align: right;
            }

            .centered {
                text-align: center;
                align-content: center;
            }

            .ticket {
                width: 280px;
                max-width: 280px;
            }

            img {
                max-width: inherit;
                width: inherit;
            }
            .center {
                display: block;
                margin-left: auto;
                margin-right: auto;
                height: 110px;
                width: auto;
            }
            </style>
            </head>
            <body style="background-color:white;">
                ${prtHtml}
            </body>
            </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        //WinPrint.close();
        setTimeout(() => WinPrint.close(), 1000);

        return true;
        },
        kembali(){
            $("#modalDetailRiwayatTutupOutlet").modal("hide");
            setTimeout(() => $("#modalRiwayatTutupOutlet").modal("show"), 500);
        },
        convertDateDBtoIndo(string) {
            var bulanIndo = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep' , 'Okt', 'Nov', 'Des'];
        
            var tanggal = string.split("-")[2];
            var bulan = string.split("-")[1];
            var tahun = string.split("-")[0];
        
            return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
        },
    },
     computed: {
        ...mapGetters(["options", "wsBaseUrl","connectionStatus", "stSetUserTablet"]),
        getLogo() {
            var logoReceipt = null
            var tempDataOptions = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
            tempDataOptions = tempDataOptions.filter((opt) => {
            return (opt.OnlineImagePath != "");
            });
            if(tempDataOptions.length > 0){
            if(tempDataOptions[0].OnlineImagePath.substring(-1, 4) == "http") {
                logoReceipt = tempDataOptions[0].OnlineImagePath
            } else {
                logoReceipt = this.wsBaseUrl + tempDataOptions[0].OnlineImagePath
            }
            } else {
            var tempLocalImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
            tempLocalImage = tempLocalImage.filter((loc) => {
                return (loc.Table == "options" && loc.TableID == "1");
            });
            if(tempLocalImage.length > 0){
                logoReceipt = tempLocalImage[0].Image
            }
            }
            return logoReceipt
        },
     }
}
</script>
<style scoped>
.scrollable {
  margin-top: 10px;
  height: 430px;
  overflow-y: auto;
}

.th-prod {
  position: sticky;
  background-color: #00ae2b;
  z-index: 2;
  top: 0;
}
.atas {
  background-color: #ccefd4;
  width: 100%;
  height: 150px;
  border-radius: 0px 0px 80px 80px;
  position: absolute;
}
.iconclose {
  position: absolute;
  margin: -50px 0px 0px 45%;
  width: 60px;
  height: 60px;
}
.error {
  font-size: 0.5em;
  transform: translateY(-50%) translateX(-9%);
  color: red;
}
td,
th,
tr,
table {
  /*border: 1px solid black;*/
  border-collapse: collapse;
  width: 100%;
}

td.description {
  text-align: left;
}

td.quantity {
  text-align: left;
}

td.subtotal {
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 280px;
  max-width: 280px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 110px;
  width: auto;
}

@media print {
}
</style>