<template>
  <div>
    <div class="page-container hide-list-menu">
      <div class="page-content">
        <!-- komponen header halaman-->
        <Header @cetakUlangSimpanOrderFromHeader="cetakUlangSimpanOrderFromHeader"/>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">

        <!-- TOTAL HARGA -->
        <div class="col-6">
          <div class="card" style="height: calc(100% - 1rem)">
            <div class="card-body">
              <h2 class="card-title d-flex h-100 justify-content-center align-items-center">
                Rp {{ $filters.numberTocurrencyIDR(stTotalHeader) }}
              </h2>
            </div>
          </div>
        </div>

        <div class="col-6">
          <!-- Tipe Penjualan -->
          <button style="margin-bottom: 15px;" class="btn btn-outline-success btn-block" type="button" @click="modalTipePenjualan" v-if="stOpsiMakan">{{ opsiMakan }}</button>

          <!-- CARI PELANGGAN -->
          <div style="position: relative">
            <div class="input-group mb-3">
              {{setCustomer}}
              <input
                type="text"
                class="form-control sprod"
                :class="{ 'is-invalid': pelangganRequiredError }"
                placeholder="Cari Pelanggan"
                aria-label="Pilih Pelanggan"
                aria-describedby="pelanggan-addon"
                autocomplete="off"
                v-model="searchByNamePelanggan"
                @input="filterPelanggan"
                @keydown.up="upPelanggan"
                @keydown.down="downPelanggan"
                @keydown.enter="enterPelanggan"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text btn-success-inverse"
                  id="pelanggan-addon"
                  data-toggle="modal"
                  data-target="#modalPelanggan"
                  style="cursor: pointer">
                    <i class="fas fa-search mr-2"></i> Cari
                </span>
              </div>
              <div class="invalid-feedback">
                Pelanggan harus diisi
              </div>
            </div>
            <div class="dropdownprod" ref="pelangganList" v-if="filteredPelanggan.length > 0">
              <ul>
                <li
                  v-for="(pelangganData, index) in filteredPelanggan"
                  v-bind:key="index"    
                  :class="{ 'selectedPelanggan': (selectedPel == index)}"
                  @click="selectedPelanggan(pelangganData)"
                  @mouseover="mouseOverPelanggan">
                    <b>{{ pelangganData.CustomerName }}</b>
                    <br />
                    No HP : {{ pelangganData.CustomerPhone }}
                    <span style="margin-left: 200px">
                      Email : {{ pelangganData.CustomerEmail }}
                    </span>
                </li>
                <li class="text-center">
                  <label 
                    v-on:click="hidemodalpelanggan"
                    class="control-label-bold text-green" 
                    style="margin-top:9px;text-align: right !important;"
                    data-toggle="modal"
                    data-target="#modaladdPelanggan">
                      + Tambah Pelanggan
                  </label>
                </li>
              </ul>  
            </div>
            <div class="dropdownprod" v-if="filteredPelanggan.length == 0 && searchByNamePelanggan && pilihpelanggan == false ">
              <ul>
                <li>
                  Pelanggan tidak ditemukan
                </li>
                <li class="text-center">
                    <label 
                      v-on:click="hidemodalpelanggan"
                      class="control-label-bold text-green" 
                      style="margin-top:9px;text-align: right !important;"
                      data-toggle="modal"
                      data-target="#modaladdPelanggan"
                      >+ Tambah Pelanggan</label>
                </li>   
              </ul>
            </div>
          </div>

          <!-- CARI WAITRESS -->
          <div style="position: relative" v-if="options[0].WaitressModule == 1">
            <div class="input-group mb-3">
              {{setWaitress}}
              <input
                type="text"
                class="form-control sprod"
                :class="{ 'is-invalid': waitressRequiredError }"
                placeholder="Cari Waitress"
                aria-label="Pilih Waitress"
                aria-describedby="waitress-addon"
                autocomplete="off"
                v-model="searchByNameWaitress"
                @input="filterWaitress"
                @keydown.up="upWaitress"
                @keydown.down="downWaitress"
                @keydown.enter="enterWaitress"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text btn-success-inverse"
                  id="waitress-addon"
                  data-toggle="modal"
                  data-target="#modalWaitress"
                  style="cursor: pointer"
                  >
                    <i class="fas fa-search mr-2"></i> Cari
                </span>
              </div>
              <div class="invalid-feedback">
                Waitress harus diisi
              </div>
            </div>
            
            <div class="dropdownprod" v-if="filteredWaitress.length > 0">
              <ul>
                <li
                  v-for="(dataWaitress, index) in filteredWaitress"
                  v-bind:key="index"    
                  :class="{ 'selectedWaitress': (selectedWress == index)}"
                  @click="selectedWaitress(dataWaitress)"
                  @mouseover="mouseOverWaitress"
                >
                  <b>{{ dataWaitress.WaitressName }}</b>
                  <br />
                  No HP : {{ dataWaitress.WaitressPhone }}
                  <span style="margin-left: 200px">
                    Email : {{ dataWaitress.WaitressEmail }}
                  </span>
                </li>
              </ul>  
            </div>
            <div class="dropdownprod" v-if="filteredWaitress.length == 0 && searchByNameWaitress && pilihwaitress == false ">
              <ul>
                <li>
                  Waitress tidak ditemukan
                </li> 
              </ul>
            </div>
          </div>
          
          <!-- DARI PRODUK -->
          <div style="pos ition: relative">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control sprod" 
                placeholder="Cari Produk"
                autocomplete="off"
                v-model="searchByName"
                @keydown.up="upProd"
                @keydown.down="downProd"
                @keydown.enter="enterProd"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text btn-success-inverse"
                  data-toggle="modal"
                  data-target="#modalProduk"
                  style="cursor: pointer"
                  ><i class="fas fa-search mr-2"></i> Cari</span
                >
              </div>
            </div>
            <div class="dropdownprod"  ref="productList" v-if="filteredProduct.length > 0">
              <ul>
                <li
                  v-for="(productsData, index) in filteredProduct"
                  v-bind:key="index"
                  :class="{ 'selectedProd': (selectedProd == index)}"
                  @click="selectedProduct(productsData)"
                  @mouseover="mouseOverProd"
                >
                  <b>{{ productsData.ItemName }}</b>
                  <br />
                  Harga : Rp {{ $filters.numberTocurrencyIDR(productsData.SellPrice) }}
                  <span v-if="productsData.Barcode">
                    Kode : {{ productsData.Barcode }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="dropdownprod" v-if="filteredProduct.length == 0 && searchByName">
              <ul>
                <li>
                  Produk tidak ditemukan
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12">
        <!-- start alert-->
        <div class="alert alert-danger fade show" role="alert" v-if="alertKasir">
          <i class="fa fa-exclamation-circle"></i> {{ msgAlertKasir }}
        </div>
        <!-- end alert-->
        </div>
      </div>
      <!-- komponen tabel kasir-->
      <Kasir />

      <div class="row">
        <div class="col-7"></div>
        <div class="col-5">
          <div class="row">
            <div class="col-6">
              <button
                class="btn btn-warning btn-block"
                style="color: white"
                type="button"
                 @click="simpanOrder"
                >Simpan</button
              >
            </div>
            <div class="col-6">
              <button
                class="btn btn-green btn-block"
                type="button"
                 @click="modalPembayaran"
                >Bayar</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- komponen modal produk-->
    <ProdukModal />
    <!-- komponen modal pelanggan-->
    <PelangganModal />
    <!-- komponen modal pembayaran-->
    <PembayaranModal />
    <!-- komponen modal expired-->
    <ExpiredModal />
    <!-- komponen modal waitress -->
    <WaitressModal />
    <!-- komponen modal saldo topup-->
    <SaldoModal />
    <!-- komponen modal opsi makan-->
    <OpsiMakanModal />
    <!-- komponen modal pembayaran opsi makan-->
    <PembayaranOpsiMakanModal />
    <!-- komponen modal pembayaran opsi makan-->
    <KonfirmasiModal :datakonfirmasi="datakonfirmasi"/>
    <!-- Print Simpan Order-->
    <PrintSimpanOrder :dataTransactionID="dataTransactionID" ref="refSimpanOrder"/>
    <!-- komponen modal pembayaran uang muka-->
    <!-- <PembayaranUangMukaModal />-->
  </div>
</template>

<script>
/* eslint-disable */ //disabled eslint compiler
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import $ from 'jquery';
import Kasir from "@/components/Kasir_c.vue";
import Header from "@/components/Header_c.vue";
import ProdukModal from "@/components/ProdukModal_c.vue";
import PembayaranModal from "@/components/PembayaranModal_c.vue";
import WaitressModal from "@/components/WaitressModal_c.vue";
import PelangganModal from "@/components/pelanggan/PelangganModal.vue";
import ExpiredModal from "@/components/konfirmasi_modal/ExpiredModal.vue";
import SaldoModal from "@/components/konfirmasi_modal/SaldoModal.vue";
import OpsiMakanModal from "@/components/OpsiMakanModal_c.vue";
import PembayaranOpsiMakanModal from "@/components/PembayaranOpsiMakanModal_c.vue";
import KonfirmasiModal from "@/components/konfirmasi_modal/KonfirmasiModal_c.vue";
import PrintSimpanOrder from "@/components/PrintSimpanOrder_c.vue";
//import PembayaranUangMukaModal from "@/components/PembayaranUangMukaModal_c.vue";

export default {
  name: "Kasir_v",
  components: {
    Kasir,
    Header,
    ProdukModal,
    PelangganModal,
    PembayaranModal,
    WaitressModal,
    ExpiredModal,
    SaldoModal,
    OpsiMakanModal,
    PembayaranOpsiMakanModal,
    KonfirmasiModal,
    PrintSimpanOrder,
    //PembayaranUangMukaModal,
  },
  data: function () {
    return {
      pilihpelanggan:false,
      pilihwaitress: false,
      alertKasir: false,
      msgAlertKasir: "",
      searchByName: "",
      searchByNamePelanggan: "",
      searchByNameWaitress: "",
      selectedProd: 0,
      selectedPel: 0,
      selectedWress: 0,
      filteredPelanggan: [],
      filteredWaitress: [],
      totalOnHeader: 0,
      showSuggest: false,
      itemHeight: 20,
      invervalTimer: "",
      waitressRequired: false,
      waitressRequiredError: false,
      pelangganRequiredError: false,
      datakonfirmasi: {},
      dataTransactionID: "0.0"
    };
  },
  methods: {
    ...mapActions([
      "actAddToCart", 
      "actSetCustomer", 
      "actSetWaitress", 
      "downloaddataoptions", 
      "actSetOpsiMakanBegin", 
      "actSetUserTablet", 
      "actSetJenisTransaksi", 
      "actCreateTransaction", 
      "actUpdateTransaction",
      "actResetTransaction",
    ]),
    //key up filter produk
    upProd() {
      if (this.selectedProd == 0) {
        return;
      }
      this.selectedProd -= 1;
      this.scrollToProduct();
    },
    //key down filter produk
    downProd() {
      if (this.selectedProd >= this.filteredProduct.length - 1) {
        return;
      }
      this.selectedProd += 1;
      this.scrollToProduct();
    },
    scrollToProduct() {
      this.$refs.productList.scrollTop = this.selectedProd * this.itemHeight;
    },
    //key enter filter produk
    enterProd() {
      if (!this.filteredProduct.length) {
        return;
      }
      var data = this.filteredProduct[this.selectedProd];
      this.selectedProduct(data)
    },
    //mouse over filter produk
    mouseOverProd(){
      this.selectedProd = null;
    },
    // selected data product form dropdown
    selectedProduct(data) {
      this.$store.dispatch("actAddToCart", data);
      this.searchByName = "";
      this.selectedProd = 0;
    },

     //key up filter pelanggan
    upPelanggan() {
      this.pilihpelanggan = false
      if (this.selectedPel == 0) {
        return;
      }
      this.selectedPel -= 1;
      this.scrollToPelanggan();
    },
    //key down filter pelanggan
    downPelanggan() {
      this.pilihpelanggan = false
      if (this.selectedPel >= this.filteredPelanggan.length - 1) {
        return;
      }     
      this.selectedPel += 1;
      this.scrollToPelanggan();
    },
    scrollToPelanggan() {
      this.pilihpelanggan = false
      this.$refs.pelangganList.scrollTop = this.selectedPel * this.itemHeight;
    },
    // key down waitress
    downWaitress() {
      this.pilihwaitress = false
      if (this.selectedWress >= this.filteredWaitress.length - 1) {
        return;
      }
      this.selectedWress += 1;
    },
    // key up waitress
    upWaitress() {
      this.pilihwaitress = false
      if (this.selectedWress === 0) {
        return;
      }     
      this.selectedWress -= 1;
    },
    // mouse over waitress
    mouseOverWaitress() {
      this.selectedWress = null;
    },
    // key enter waitress
    enterWaitress() {
      this.pilihwaitress = false;
      if (!this.filteredWaitress.length) {
        return;
      }
      this.selectedWaitress(this.filteredWaitress[this.selectedWress]);
    },
    //key enter filter pelanggan 
    enterPelanggan() {
      this.pilihpelanggan = false
      if (!this.filteredPelanggan.length) {
        return;
      }
      var data = this.filteredPelanggan[this.selectedPel];
      this.selectedPelanggan(data)
    },
    //mouse over filter pelanggan
    mouseOverPelanggan(){
      this.selectedPel = null;
    },
    selectedPelanggan(data) {   
      this.pilihpelanggan = true
      this.filteredPelanggan= []
      this.showSuggest = false;
      var dataCustomer = {
        CustomerName: data.CustomerName,
        CustomerID: data.CustomerID,
        CustomerDeviceNo: data.DeviceNo,
      }
      this.searchByNamePelanggan = data.CustomerName;
      this.$store.dispatch("actSetCustomer", dataCustomer);
      this.selectedPel = 0;
      //this.filteredPelanggan = [];
    },      
    filterPelanggan() {
      this.pilihpelanggan = false
      let tempPelanggan = [];
      if (this.searchByNamePelanggan) {
        tempPelanggan = this.customer;
        this.pelangganRequiredError = false;
        this.filteredPelanggan = tempPelanggan.filter((item) => {
          return item.CustomerName.toLowerCase().includes(
            this.searchByNamePelanggan.toLowerCase()
            //console.log(item.CustomerName)
          );
        });
      } else {
        this.filteredPelanggan = [];
        this.$store.dispatch("actSetCustomer", null)
      }
    },
    selectedWaitress(data) {
      this.pilihwaitress = true;
      this.filteredWaitress = [];
      this.searchByNameWaitress = data.WaitressName;
      this.$store.dispatch("actSetWaitress", data);
      this.selectedWress = 0;
    },
    filterWaitress() {
      this.pilihwaitress = false;
      let waitressData = [];
      if (this.searchByNameWaitress) {
        waitressData = this.waitress;
        this.waitressRequiredError = false;
        this.filteredWaitress = waitressData.filter((item) => 
          item.WaitressName.toLowerCase().includes(
            this.searchByNameWaitress.toLowerCase()
          ));
      } else {
        this.waitressRequiredError = (this.options[0].WaitressRequired == "1");
        this.filteredWaitress = [];
        this.$store.dispatch("actSetWaitress", null)
      }
    },
    // cek expired
    cekExpired(){
        var billingType = this.options[0].billing_type;
        if(billingType == "1"){
          this.invervalTimer = setInterval(() => {
            var expired = localStorage.getItem("tglexpired") ? JSON.parse(localStorage.getItem("tglexpired")) : null
            if(expired) {
              var g1 = new Date();
              var g2 = new Date(expired.TglExpired);
              if(g1.getTime() >= g2.getTime()){
                $("#modalExpired").modal("show")
              } else {
                $("#modalExpired").modal('hide');
                clearInterval(this.invervalTimer)
              }
            } else {
              clearInterval(this.invervalTimer)
            }
          }, 6000)
        } else if(billingType == "2") {
          var fee = parseFloat(this.options[0].service_fee);
          var balance = parseFloat(this.options[0].topup_balance);
          if(balance >= fee) {
            $("#modalSaldo").modal('hide');
          } else {
            $("#modalSaldo").modal("show")
            this.$store.dispatch("downloaddataoptions")
          }
        } 
    },
    // modal pembayaran
    modalPembayaran(){
      var billingType = this.options[0].billing_type;
      var fee = parseFloat(this.options[0].service_fee);
      var balance = parseFloat(this.options[0].topup_balance);
      if(billingType == "2") { 
        if(balance >= fee) {
          $("#modalSaldo").modal('hide')
          this.doModalPembayaran()
        } else {
          $("#modalSaldo").modal("show")
          this.$store.dispatch("downloaddataoptions")
        }
      } else {
        this.doModalPembayaran()
      }
    },
    // modal pembayaran
    doModalPembayaran(){
      this.$store.dispatch("actSetJenisTransaksi", "bayar");
      const stCart = [...this.stCart]
      var filCart = stCart.filter((cartData) => {
        return cartData.qty == null || cartData.SellPrice == null;
      });
      if(stCart.length > 0){
        if(filCart.length > 0) {
          this.msgAlertKasir = "Pastikan 'Qty' dan 'Harga' terisi."
          this.alertKasir = true
          setTimeout(() => this.alertKasir = false, 5000);
        } else {
          if (this.waitressRequiredError || this.options[0].WaitressModule == "1" && this.options[0].WaitressRequired == "1" && (this.stWaitress === "" || this.stWaitress === null)) {
            this.waitressRequiredError = true;
          } else {
            if(this.stOpsiMakan) {
              if(this.stOpsiMakan.OjekOnline == '1') {
                if(this.stOpsiMakan.AccountID != '0') {
                  $("#modalPembayaranOpsiMakan").modal("show")
                } else {
                  $("#modalPembayaran").modal("show")
                }
              } else {
                $("#modalPembayaran").modal("show")
              }
            } else {
              $("#modalPembayaran").modal("show")
            }
          }
        }
      } else {
        this.msgAlertKasir = "Keranjang belanja masih kosong."
        this.alertKasir = true
        setTimeout(() => this.alertKasir = false, 5000);
      }
    },
    // simpan order
    simpanOrder(){
      this.$store.dispatch("actSetJenisTransaksi", "simpan");
      const stCart = [...this.stCart]
      var filCart = stCart.filter((cartData) => {
        return cartData.qty == null || cartData.SellPrice == null;
      });
      if(stCart.length > 0){
        if(filCart.length > 0) {
          this.msgAlertKasir = "Pastikan 'Qty' dan 'Harga' terisi."
          this.alertKasir = true
          setTimeout(() => this.alertKasir = false, 5000);
        } else {
          var validate = 0
          if ((this.stCustomer === "" || this.stCustomer === null)) {
            this.pelangganRequiredError = true;
            validate = 1
            return false
          } else {
            var cekCustomer = []
            if(this.stOpenTransaction != null) {
              cekCustomer = this.pendingsale.filter((el) => {
                return el.CustomerID == this.stCustomer.CustomerID && el.CustomerDeviceNo == this.stCustomer.CustomerDeviceNo && el.Pending == 'true' && el.TransactionID +'.'+ el.DeviceNo != this.stOpenTransaction.TransactionID +'.'+ this.stOpenTransaction.DeviceNo;
              });
            } else {
              cekCustomer = this.pendingsale.filter((el) => {
                return el.CustomerID == this.stCustomer.CustomerID && el.CustomerDeviceNo == this.stCustomer.CustomerDeviceNo && el.Pending == 'true';
              });
            }
            if(cekCustomer.length > 0) {
              this.datakonfirmasi = {
                "title": "Konfirmasi",
                "body": this.stCustomer.CustomerName + " sudah dipakai. silahkan pakai nama lain!"
              }
              this.$store.dispatch("actSetCustomer", null)  
              $("#modalKonfirmasi").modal("show")
              return false
            }
          }
          if (this.waitressRequiredError || this.options[0].WaitressModule == "1" && this.options[0].WaitressRequired == "1" && (this.stWaitress === "" || this.stWaitress === null)) {
            this.waitressRequiredError = true;
            validate = 1
            return false
          }
          
          if(validate == 0) {
            let paymentReview = {
              PaymentMode: null,
              CashPayment: 0,
              Change: null,
              TfPayment: null,
              EDCPayment: '',
              EDCPercent: '',
              EDCTotal: '',
              QRStatisPayment: '',
              QRISPayment: '',
            }
            if(this.stOpenTransaction) {
              this.$store.dispatch("actUpdateTransaction", paymentReview)
              .then((response) => {
                if(response.status == "OK"){
                  this.dataTransactionID = this.stTransactionID
                  //setTimeout(() => this.$refs.refSimpanOrder.printSimpanOrder(), 500);
                  this.$store.dispatch("actResetTransaction")
                  this.$store.dispatch("actSetOpsiMakanBegin")
                  //this.resetDataReceipt()
                }
              })
            } else {
              this.$store.dispatch("actCreateTransaction", paymentReview)
              .then((response) => {
                if(response.status == "OK"){
                  this.dataTransactionID = this.stTransactionID
                  //setTimeout(() => this.$refs.refSimpanOrder.printSimpanOrder(), 500);
                  this.$store.dispatch("actResetTransaction")
                  this.$store.dispatch("actSetOpsiMakanBegin")
                  //this.resetDataReceipt()
                }
              })
            }
          }
        }
      } else {
        this.msgAlertKasir = "Keranjang belanja masih kosong."
        this.alertKasir = true
        setTimeout(() => this.alertKasir = false, 5000);
      }
    },
    // uang muka
    /*uangMuka(){
      this.$store.dispatch("actSetJenisTransaksi", "bayar");
      const stCart = [...this.stCart]
      var filCart = stCart.filter((cartData) => {
        return cartData.qty == null || cartData.SellPrice == null;
      });
      if(stCart.length > 0){
        if(filCart.length > 0) {
          this.msgAlertKasir = "Pastikan 'Qty' dan 'Harga' terisi."
          this.alertKasir = true
          setTimeout(() => this.alertKasir = false, 5000);
        } else {
          var validate = 0
          if ((this.stCustomer === "" || this.stCustomer === null)) {
            this.pelangganRequiredError = true;
            validate = 1
            return false
          } else {
            var cekCustomer = []
            if(this.stOpenTransaction != null) {
              cekCustomer = this.pendingsale.filter((el) => {
                return el.CustomerID == this.stCustomer.CustomerID && el.CustomerDeviceNo == this.stCustomer.CustomerDeviceNo && el.Pending == 'true' && el.TransactionID +'.'+ el.DeviceNo != this.stOpenTransaction.TransactionID +'.'+ this.stOpenTransaction.DeviceNo;
              });
            } else {
              cekCustomer = this.pendingsale.filter((el) => {
                return el.CustomerID == this.stCustomer.CustomerID && el.CustomerDeviceNo == this.stCustomer.CustomerDeviceNo && el.Pending == 'true';
              });
            }
            if(cekCustomer.length > 0) {
              this.datakonfirmasi = {
                "title": "Konfirmasi",
                "body": this.stCustomer.CustomerName + " sudah dipakai. silahkan pakai nama lain!"
              }
              this.$store.dispatch("actSetCustomer", null)  
              $("#modalKonfirmasi").modal("show")
              return false
            }
          }
          if (this.waitressRequiredError || this.options[0].WaitressModule == "1" && this.options[0].WaitressRequired == "1" && (this.stWaitress === "" || this.stWaitress === null)) {
            this.waitressRequiredError = true;
            validate = 1
            return false
          }
          if(validate == 0) {
            $("#modalPembayaranUangMuka").modal("show")
          }
        }
      } else {
        this.msgAlertKasir = "Keranjang belanja masih kosong."
        this.alertKasir = true
        setTimeout(() => this.alertKasir = false, 5000);
      }
    },*/
    // cek opsi makan
    cekOpsiMakan(){
      this.$store.dispatch("actSetOpsiMakanBegin"); 
    },
    // modal opsi makan
    modalTipePenjualan() {
      $("#modalOpsiMakan").modal("show")
    },
    // cek set user tablet
    cekSetUserTablet(){
      if($.isEmptyObject(this.stSetUserTablet) == true) {
        this.$store.dispatch("actSetUserTablet"); 
      }
    },
    cetakUlangSimpanOrderFromHeader(val){
      this.dataTransactionID = val
      setTimeout(() => this.$refs.refSimpanOrder.printSimpanOrder(), 500);
    }
  },
  computed: {
    ...mapGetters([
      "stTotalHeader", 
      "products", 
      "customer", 
      "waitress", 
      "stCustomer", 
      "stSetUserTablet", 
      "stCart", 
      "stWaitress", 
      "options", 
      "stTransactionID",
      "stOpsiMakan", 
      "stJenisTransaksi",
      "pendingsale",
      "stOpenTransaction",
    ]),
    // filter product by input
    filteredProduct() {
      let tempProducts = [];
      if (this.searchByName) {
        tempProducts = this.products;
        tempProducts = tempProducts.filter((item) => {
          return item.IsProduct == "true";
        });
        tempProducts = tempProducts.filter((item) => {
          return (
            item.ItemName.toLowerCase().includes(
              this.searchByName.toLowerCase()
            ) ||
            item.Barcode.toLowerCase().includes(this.searchByName.toLowerCase())
          );
        });
      } else {
        tempProducts = [];
        this.selectedProd = 0
      }
      return tempProducts
    },
    setWaitress() {
      if (this.stWaitress !== null) {
        this.pilihwaitress = true;
        this.searchByNameWaitress = this.stWaitress.WaitressName;
      } else {
        this.searchByNameWaitress = "";
      }
      return "";
    },
    setCustomer(){
      if (this.stCustomer !== null ){
          this.pilihpelanggan = true
          this.searchByNamePelanggan = this.stCustomer.CustomerName
      }
      else{
        this.searchByNamePelanggan = ""
      }
      return ""
    },
    opsiMakan(){
      if (this.stOpsiMakan){
          return this.stOpsiMakan.NamaOpsiMakan
      }
      else{
        return ""
      }
    }
  },
  watch: {
    // is triggered whenever the store state changes
    stCustomer(val, oldVal) {
      if(val){
        this.pelangganRequiredError = false;
      }
    },
    stWaitress(val, oldVal) {
      if(val){
        this.waitressRequiredError = false;
      }
    }
  },
  mounted() {
    this.cekExpired();
    this.cekOpsiMakan();
    this.cekSetUserTablet();
    $(document).ready(function () {
      document.onkeyup = function(event) {
        if (event.which == 113) {
          $(".sprod").focus();
        }
        // else if (e.ctrlKey && e.altKey && e.shiftKey && e.which == 85) {
        //  alert("Ctrl + Alt + Shift + U shortcut combination was pressed");
        //}
      };
    });
  },
};
</script>
<style scoped>
.dropdownprod {
  width: 100%;
  text-align: left;
  position: absolute;
  z-index: 1;
  
  max-height: 420px;
  overflow-y: scroll;
  margin-top: -15px;
}
.dropdownprod ul {
  padding-left: 0px;
  background-color: #ffffff;
  list-style-type: none;
}
.dropdownprod ul li {
  padding: 5px;
  cursor: pointer;
  border: 1px solid #ebebeb;
}
.dropdownprod ul li:hover {
  color: #000000;
}
.dropdownprod ul li:not(.selectedProd):hover {
  color: #00ae2b;
}
.dropdownprod ul li.selectedProd {
  color: #00ae2b;
}
.dropdownprod ul li:not(.selectedPelanggan):hover {
  color: #00ae2b;
}
.dropdownprod ul li.selectedPelanggan {
  color: #00ae2b;
}
.dropdownprod ul li:not(.selectedWaitress):hover {
  color: #00ae2b;
}
.dropdownprod ul li.selectedWaitress {
  color: #00ae2b;
}
</style>