<template>
  <div>
    <div id="modalPembayaran" class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <form @submit.prevent="createTransaction">
            <div class="row">
              <div class="col-12">
                <h5 class="mb-0" style="text-align: center">Total</h5>
                <h3 style="text-align: center">Rp {{ $filters.numberTocurrencyIDR(stTotalHeader) }}</h3>
                <!-- start alert-->
                <div class="alert alert-danger fade show" role="alert" v-if="alertPayment">
                  <i class="fa fa-exclamation-circle"></i> {{ msgAlert }}
                </div>
                <!-- end alert-->
              </div>
              <div class="col-12">
                  <hr style="margin-top:0px; margin-bottom:10px">
              </div>
            </div>
            <!-- start pembayaran tunai-->
            <div class="row">
              <div class="col-3 label-pembayaran">
                  <h6>Tunai</h6>
              </div>
              <div class="col-9">
                  <div class="row">
                      <div class="col-12" style="margin-left:-5px; margin-bottom: -10px;text-align: center;">
                          <div class="form-check form-check-inline btn-radio">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="cashPaymentRadio" v-on:change="cashPaymentSuggest" id="rekom99" :value="stTotalHeader">
                              <label class="form-check-label" for="rekom99">Uang Pas</label>
                          </div>
                          <div v-if="suggestPay.length > 0">
                            <div class="form-check form-check-inline btn-radio" 
                              v-for="(suggestPayData, i) in suggestPay"
                              v-bind:key="i">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="cashPaymentRadio" v-on:change="cashPaymentSuggest" :id="'rekom'+ i" :value="suggestPayData.payment">
                              <label class="form-check-label" :for="'rekom'+ i">{{ $filters.numberTocurrencyIDR(suggestPayData.payment) }}</label>
                            </div>
                          </div>
                      </div>
                      <div class="col-6">
                          <div class="form-group">
                              <label class="floating-custom">Custom</label>
                              <currency-input 
                              class="form-control" 
                              type="text" 
                              v-model="cashPaymentInput"
                              v-on:keyup="cashPaymentInputManual"
                              :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                              placeholder="Masukan Nominal Custom" 
                              autocomplete="off"
                              autocorrect="off"/>
                          </div>
                      </div>
                      <div class="col-6 field-kembali">
                          <div class="form-group">
                              <label class="floating-custom">{{ kembali }}</label>
                              <input class="form-control" :style="fieldStyleChange"
                              type="text" 
                              :value="$filters.numberTocurrencyIDR(cashPaymentChange)"
                              readonly
                              placeholder="Kembali / Kurang">
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end pembayaran tunai-->
            <!-- start dompet digital-->
            <div class="row" v-if="options[0].TipePembayaranDompetDigital == '1'">
              <div class="col-12">
                  <hr style="margin-top:0px; margin-bottom:10px">
              </div>
              <div class="col-3 label-pembayaran">
                  <h6>Dompet Digital</h6><br>
                  <div v-if="masterRekDGPayment.length <= 0" style="margin-top: -28px">
                    Aktifkan melalui <a href="https://www.nutacloud.com/digitalpayment" target="blank_">Nutacloud</a>
                  </div>
              </div>
              <div class="col-9">
                  <div class="row" v-if="masterRekDGPayment.length <= 0">
                      <div class="col-3" 
                      v-for="(dataDGPayment, index) in masterdigitalpayment"
                      v-bind:key="index"
                      style="padding-top:5px; padding-bottom:7px; ">
                          <button type="button" class="btn btn-block" style="padding: 0px; border: 1px solid rgb(90, 90, 90); background-color: #f7f9f8;">
                              <img :src="dataDGPayment.LinkLogoDisable" style="height: 50px; width: auto;">
                          </button>
                      </div>
                  </div>
                  <div class="row" v-if="masterRekDGPayment.length > 0">
                      <div class="col-3" 
                      v-for="(dataDGPayment, index) in masterdigitalpayment"
                      v-bind:key="index"
                      style="padding-top:5px; padding-bottom:7px; ">
                          <button type="button" class="btn btn-block" @click="selectingQRIS(dataDGPayment)" style="padding: 0px; border: 1px solid rgb(90, 90, 90);">
                              <img :src="dataDGPayment.LinkLogoEnable" style="height: 50px; width: auto;">
                          </button>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end dompet digital-->
            <!-- start EDC-->
            <div class="row" v-if="options[0].TipePembayaranEDC == '1'">
              <div class="col-12">
                  <hr style="margin-top:0px; margin-bottom:10px">
              </div>
              <div class="col-3 label-pembayaran">
                  <h6>EDC</h6>
              </div>
              <div class="col-9">
                  <div class="row" v-if="!masterEDC">
                    <div class="col-12">
                      <div style="text-align:left; color:#999">Belum ada data rekening EDC.<br>
                      Silahkan tambahkan data rekening melalui <a href="https://www.nutacloud.com/datarekening" target="blank_">Nutacloud</a> terlebih dahulu.</div>
                    </div>
                  </div>
                  <div class="row" v-if="masterEDC">
                      <div class="col-12">
                          <div class="form-group">
                              <select class="form-control" 
                              v-model="selectedEDC"
                              v-on:change="selectingEDC">
                              <option value="" selected disabled>Pilih Rekening</option>
                              <option v-for="(dataEDC, index) in masterEDC" 
                              :key="index"
                              :value="dataEDC.AccountID + '.' + dataEDC.DeviceNo">
                              {{ dataEDC.BankName }} {{ dataEDC.AccountNumber }} {{ dataEDC.AccountName }}
                              </option>
                              </select>
                          </div>
                      </div>
                      <div class="col-12" v-if="selectedEDC" style="margin-top: -20px">
                        <div class="row">
                          <div class="col-4">
                            <div class="form-group">
                              <label class="floating-custom">&nbsp;</label>
                              <div class="input-group">
                                <currency-input 
                                  class="form-control"
                                  name="chargeEDCPercent"
                                  v-model="chargeEDCPercent"
                                  :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                                  type="text"
                                  placeholder="Charge %"
                                  autocomplete="off"
                                  autocorrect="off"
                                  maxlength="3"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <label class="floating-custom">Charge</label>
                              <input
                                  class="form-control"
                                  name="chargeEDCRp"
                                  :value="$filters.numberTocurrencyIDR(chargeEDCRp)"
                                  type="text"
                                  placeholder="Charge Rp"
                                  readonly
                                />
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <label class="floating-custom">Total + Charge</label>
                              <input
                                  class="form-control"
                                  name="chargeEDCPlusTotal"
                                  :value="$filters.numberTocurrencyIDR(chargeEDCPlusTotal)"
                                  type="text"
                                  placeholder="Total + Charge"
                                  readonly
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end EDC-->
            <!-- start transfer-->
            <div class="row" v-if="options[0].TipePembayaranTransfer == '1'">
              <div class="col-12">
                  <hr style="margin-top:0px; margin-bottom:10px">
              </div>
              <div class="col-3 label-pembayaran">
                  <h6>Transfer</h6>
              </div>
              <div class="col-9">
                  <div class="row" v-if="!masterTransfer">
                    <div class="col-12">
                      <div style="text-align:left; color:#999">Belum ada data rekening transfer.<br>
                      Silahkan tambahkan data rekening melalui <a href="https://www.nutacloud.com/datarekening" target="blank_">Nutacloud</a> terlebih dahulu.</div>
                    </div>
                  </div>
                  <div class="row" v-if="masterTransfer">
                      <div class="col-12">
                          <div class="form-group">
                              <select class="form-control" 
                              v-model="selectedTf"
                              v-on:change="selectingTf">
                              <option value="" selected disabled>Pilih Rekening</option>
                              <option v-for="(dataTransfer, index) in masterTransfer" 
                              :key="index"
                              :value="dataTransfer.AccountID + '.' + dataTransfer.DeviceNo">
                              {{ dataTransfer.BankName }} {{ dataTransfer.AccountNumber }} {{ dataTransfer.AccountName }}
                              </option>
                              </select>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end transfer-->
            <!-- start QR statis-->
            <div class="row" v-if="options[0].TipePembayaranQRStatis == '1'">
              <div class="col-12">
                  <hr style="margin-top:0px; margin-bottom:10px">
              </div>
              <div class="col-3 label-pembayaran">
                  <h6>QR Statis</h6>
              </div>
              <div class="col-9">
                  <div class="row" v-if="!masterQRStatis">
                    <div class="col-12">
                      <div style="text-align:left; color:#999">Belum ada data rekening transfer.<br>
                      Silahkan tambahkan data rekening melalui <a href="https://www.nutacloud.com/datarekening" target="blank_">Nutacloud</a> terlebih dahulu.</div>
                    </div>
                  </div>
                  <div class="row" v-if="masterQRStatis">
                      <div class="col-12" style="margin-left:-5px; text-align: center;">
                          <div class="form-check form-check-inline btn-radio" 
                          v-for="(dataQRStatis, index) in masterQRStatis" 
                          v-bind:key="index"
                          >
                              <input class="form-check-input" type="radio" name="inlineqr" 
                              :id="'qr-'+index" 
                              :value="dataQRStatis.AccountID + '.' + dataQRStatis.DeviceNo"
                              v-model="selectedQRStatisRadio" 
                              v-on:change="selectingQRStatisRadio">
                              <label class="form-check-label" :for="'qr-'+index">{{ dataQRStatis.BankName.slice(0, 15) }}</label>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end QR statis-->
            <div class="row py-2">
              <div class="col-6">
                <button
                  class="btn btn-link btn-block"
                  type="button"
                  @click="dismissModalPembayaran"
                  :disabled="submitted"
                >
                  Kembali
                </button>
              </div>
              <div class="col-6">
                <button type="submit" class="btn btn-green btn-block" :disabled="submitted">
                  Simpan
                </button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- komponen modal pembayaran berhasil-->
    <PembayaranBerhasilModal />
    <!-- komponen modal pembayaran QRIS-->
    <PembayaranQRISModal :dataPembayaran="sentToQRISModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PembayaranBerhasilModal from "@/components/PembayaranBerhasilModal_c.vue";
import PembayaranQRISModal from "@/components/PembayaranQRISModal_c.vue";
import CurrencyInput from '@/components/CurrencyInput_c';

import $ from 'jquery'
export default {
  name: "PembayaranModal_c",
  data() {
    return {
      msgAlert: "Pembayaran kurang",
      alertPayment: false,
      kembali: "Kembali",
      submitted: false,
      cashPaymentRadio: null,
      cashPaymentInput: null,
      cashPaymentChange: null,
      selectedEDC: "",
      chargeEDCPercent: null,
      selectedTf: "",
      selectedQRStatisRadio: null,
      sentToQRISModal: null,
      paymentMode: 0,
      form: {},
      fieldStyleChange: {
        color: 'green',
      }
    };
  },
  components: {
    PembayaranBerhasilModal,
    PembayaranQRISModal,
    CurrencyInput,
  },
  computed: {
    ...mapGetters(["stCart", "stTotalHeader","options","cashbank","masterdigitalpayment", "stOpenTransaction"]),
    //perhitungan charge EDC RP
    chargeEDCRp(){
      var change = this.cashPaymentChange
          change = Math.abs(change);
      var totalOrder = this.stTotalHeader
      var chargePercent = this.chargeEDCPercent
      var chargeRp = 0
      if(change){
        chargeRp = parseFloat((change / 100) * chargePercent)
      } else {
        chargeRp = parseFloat((totalOrder / 100) * chargePercent)
      }
      return chargeRp > 0 ? chargeRp : null
    },
    //perhitungan charge Total
    chargeEDCPlusTotal(){
      var change = this.cashPaymentChange
          change = Math.abs(change);
      var totalOrder = this.stTotalHeader
      var chargeRp = this.chargeEDCRp
      var chargeTotal = 0
      if(change){
        chargeTotal = parseFloat(change + chargeRp)
      } else {
        chargeTotal = parseFloat(totalOrder + chargeRp)
      }
      return chargeTotal > 0 ? chargeTotal : null
    },
    //filter data transfer from mastercashbank
    masterTransfer() {
        let tempBank = this.cashbank;
        tempBank = tempBank.filter((item) => {
          return (
            item.IsTransfer == "1" && item.AccountType == "2"
          );
        });
        return tempBank;
    },
    //filter data EDC from mastercashbank
    masterEDC() {
        let tempBank = this.cashbank;
        tempBank = tempBank.filter((item) => {
          return (
            item.IsEDC == "1" && item.AccountType == "2"
          );
        });
        return tempBank;
    },
    //filter data QrStatis from mastercashbank
    masterQRStatis() {
        let tempBank = this.cashbank;
        tempBank = tempBank.filter((item) => {
          return (
            item.IsQRStatic == "1" && item.AccountType == "2"
          );
        });
        return tempBank;
    },
    //filter data digital payment from mastercashbank
    masterRekDGPayment() {
        let tempBank = this.cashbank;
        tempBank = tempBank.filter((item) => {
          return (
            item.IsDigitalPayment == "1" && item.AccountType == "2"
          );
        });
        return tempBank;
    },
    //suggest Payment
    suggestPay(){
        var recommendations = [];
        if(this.stTotalHeader > 0){
          var moneySheet = [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000];
          for (var i = moneySheet.length - 1; i >= 0; i--) {
              var roundingTo = moneySheet[i];
              var payment = Math.ceil(this.stTotalHeader / roundingTo) * roundingTo;
              //skip if payment is exist
              var skip = false;
              var sheetEachCurrent = [0, 0, 0, 0, 0, 0, 0, 0];
              var origAmount = payment;
              var remainAmount = 0;
              for (var k = moneySheet.length - 1; k >= 0; k--) {
                  if ((origAmount % moneySheet[k]) < origAmount) {
                      sheetEachCurrent[k] = Math.floor(origAmount / moneySheet[k]);
                      remainAmount = origAmount % moneySheet[k];
                      origAmount = remainAmount;
                  }
              }
              for (var j=0; j<recommendations.length; j++) {
                  var cp = recommendations[j];
                  var amount=payment;
                  var exist = cp.payment == amount;
                  if (!exist) {
                      var subset = true;
                      for (var kk = 0; kk < cp.sheetEach.length; kk++) {
                          if (cp.sheetEach[kk] > sheetEachCurrent[kk])
                          {
                              subset = false;
                          }
                      }
                      exist = subset;
                  }
                  if (exist) {
                      skip = true;
                  }
              }
              if (!skip) {
                  var cpp = {};
                  cpp.payment = payment;
                  cpp.sheetEach = sheetEachCurrent;

                  cpp.sheetCount = 0;
                  for (var kkk = 0; kkk < cpp.sheetEach.length; kkk++) {
                      cpp.sheetCount += cpp.sheetEach[kkk];
                  }
                  recommendations.push(cpp);
              }
          }
        }
        return recommendations.sort(function(a, b) {return a.payment - b.payment}).filter(e => e.payment != this.stTotalHeader).slice(0, 3);
    },
  },
  methods: {
    ...mapActions(["actCreateTransaction", "actUpdateTransaction"]),
    
    //submit simpan pembayaran
    createTransaction(){
        this.submitted = true;
        if(this.paymentMode == 0){
          this.msgAlert = "Pembayaran kurang"
          this.alertPayment = true
          setTimeout(() => this.alertPayment = false, 5000);
          this.submitted = false;
        } else if(this.stCart.length <= 0){
          this.msgAlert = "Keranjang belanja kosong"
          this.alertPayment = true
          setTimeout(() => this.alertPayment = false, 5000);
          this.submitted = false;
        } else {
          if(this.stTotalHeader < 0){
            this.msgAlert = "Transaksi anda minus"
            this.alertPayment = true
            setTimeout(() => this.alertPayment = false, 5000);
            this.submitted = false;
          } else {
            if(this.stTotalHeader > 0 && this.paymentMode == 1 && this.cashPaymentInput == 0){
              this.msgAlert = "Pembayaran kurang"
              this.alertPayment = true
              setTimeout(() => this.alertPayment = false, 5000);
              this.submitted = false;
            } else {
              this.alertPayment = false
              let paymentReview = {
                PaymentMode: this.paymentMode,
                CashPayment: this.cashPaymentRadio > 0 ? this.cashPaymentRadio : this.cashPaymentInput,
                Change: this.cashPaymentChange,
                TfPayment: this.selectedTf,
                EDCPayment: this.selectedEDC,
                EDCPercent: this.chargeEDCPercent,
                EDCTotal: this.chargeEDCPlusTotal,
                QRStatisPayment: this.selectedQRStatisRadio,
                QRISPayment: null,
              }
              if(this.stOpenTransaction) {
                this.$store.dispatch("actUpdateTransaction", paymentReview)
                .then((response) => {
                  if(response.status == "OK"){
                    this.dismissModalPembayaran();
                    setTimeout(() => $("#modalPembayaranBerhasil").modal("show") , 500);
                  }
                })
              } else {
                this.$store.dispatch("actCreateTransaction", paymentReview)
                .then((response) => {
                  if(response.status == "OK"){
                    this.dismissModalPembayaran();
                    setTimeout(() => $("#modalPembayaranBerhasil").modal("show") , 500);
                  }
                })
              }
            }
          }
      }
    },
    //on change radio button cash suggestion
    cashPaymentSuggest() {
      var paymentRadio = this.cashPaymentRadio
      var totalOrder = this.stTotalHeader
      var change = 0
      if(paymentRadio >= 0){
        this.cashPaymentInput = null
        this.selectedEDC = ""
        this.selectedTf = ""
        this.selectedQRStatisRadio = null
        this.paymentMode = 1
        change = parseFloat(paymentRadio - totalOrder)
      }
      if(change < 0){
        this.fieldStyleChange.color = "red"
        this.kembali = "Kurang"
      } else {
        this.fieldStyleChange.color = "green"
        this.kembali = "Kembali"
      }
      this.cashPaymentChange = Math.abs(change)
    },
    // on keyup input cash manual
    cashPaymentInputManual(){
      var paymentInput = this.cashPaymentInput
      var totalOrder = this.stTotalHeader
      var change = 0
      if (String(paymentInput).length >= 12) {
        var substr = String(paymentInput).substring(0, 12)
        this.cashPaymentInput = parseFloat(substr)
      }
      if(paymentInput > 0){
        if(paymentInput >= totalOrder){
          this.selectedEDC = ""
          this.selectedTf = ""
          this.selectedQRStatisRadio = null
        }
        this.cashPaymentRadio = null
        if (String(paymentInput).length >= 12) {
          paymentInput = String(paymentInput).substring(0, 12)
        }
        change = parseFloat(paymentInput - totalOrder)
      }
      if(change < 0){
        this.paymentMode = 0
        if(this.selectedQRStatisRadio){
          this.paymentMode = 34
        } else if(this.selectedTf){
          this.paymentMode = 33
        } else if(this.selectedEDC){
          this.paymentMode = 32
        }
        this.fieldStyleChange.color = "red"
        this.kembali = "Kurang"
      } else {
        if(paymentInput == null){
          this.paymentMode = 0
          if(this.selectedQRStatisRadio){
            this.paymentMode = 24
          } else if(this.selectedTf){
            this.paymentMode = 23
          } else if(this.selectedEDC){
            this.paymentMode = 22
          }
        } else if(paymentInput >= 0) {
          this.cashPaymentRadio = null
          this.selectedEDC = ""
          this.selectedTf = ""
          this.selectedQRStatisRadio = null
          this.paymentMode = 1
        }
        this.fieldStyleChange.color = "green"
        this.kembali = "Kembali"
      }
      this.cashPaymentChange = Math.abs(change)
    },
    // on change pilih rekening EDC
    selectingEDC(){
      var totalTunai = parseFloat(this.cashPaymentInput + this.cashPaymentRadio)
      var totalOrder = this.stTotalHeader
      var res = totalTunai - totalOrder
      if(res >= 0){
        this.cashPaymentInput = null
        this.cashPaymentRadio = null
        this.cashPaymentChange = null
      } 
      var totalTunaiAfter = parseFloat(this.cashPaymentInput)
      if(res < 0 && totalTunaiAfter > 0){
        if(this.selectedEDC){
          this.paymentMode = 32
        } else {
          this.paymentMode = 0
        }
      } else {
        if(this.selectedEDC){
          this.paymentMode = 22
        } else {
          this.paymentMode = 0
        }
      }
      this.selectedTf = ""
      this.selectedQRStatisRadio = null
    },
    // on change pilih rekening transfer
    selectingTf(){
      var totalTunai = parseFloat(this.cashPaymentInput + this.cashPaymentRadio)
      var totalOrder = this.stTotalHeader
      var res = totalTunai - totalOrder
      if(res >= 0){
        this.cashPaymentInput = null
        this.cashPaymentRadio = null
        this.cashPaymentChange = null
      } 
      var totalTunaiAfter = parseFloat(this.cashPaymentInput)
      if(res < 0 && totalTunaiAfter > 0){
        if(this.selectedTf){
          this.paymentMode = 33
        } else {
          this.paymentMode = 0
        }
      } else {
        if(this.selectedTf){
          this.paymentMode = 23
        } else {
          this.paymentMode = 0
        }
      }
      this.selectedEDC = ""
      this.selectedQRStatisRadio = null
    },
    // on change pilih rekening QR statis
    selectingQRStatisRadio(){
      var totalTunai = parseFloat(this.cashPaymentInput + this.cashPaymentRadio)
      var totalOrder = this.stTotalHeader
      var res = totalTunai - totalOrder
      if(res >= 0){
        this.cashPaymentInput = null
        this.cashPaymentRadio = null
        this.cashPaymentChange = null
      } 
      var totalTunaiAfter = parseFloat(this.cashPaymentInput)
      if(res < 0 && totalTunaiAfter > 0){
        this.paymentMode = 34
      } else {
        this.paymentMode = 24
      }
      this.selectedEDC = ""
      this.selectedTf = ""
    },
    // on click pembayaran digital
    selectingQRIS(dataDGPayment){
      var totalTunai = parseFloat(this.cashPaymentInput + this.cashPaymentRadio)
      var totalOrder = this.stTotalHeader
      var res = totalTunai - totalOrder
      if(totalOrder > 0 || res < 0) {
        if(res >= 0){
          this.cashPaymentInput = null
          this.cashPaymentRadio = null
          this.cashPaymentChange = null
        } 
        var totalTunaiAfter = parseFloat(this.cashPaymentInput)
        if(res < 0 && totalTunaiAfter > 0){
          this.paymentMode = "31" + dataDGPayment.DigitalPaymentID
        } else {
          this.paymentMode = "21" + dataDGPayment.DigitalPaymentID
        }
        this.selectedEDC = ""
        this.selectedTf = ""
        this.selectedQRStatisRadio = null

        var paymentReview = {
          PaymentMode: this.paymentMode,
          CashPayment: this.cashPaymentRadio > 0 ? this.cashPaymentRadio : this.cashPaymentInput,
          Change: this.cashPaymentChange,
          TfPayment: this.selectedTf,
          EDCPayment: this.selectedEDC,
          EDCPercent: this.chargeEDCPercent,
          EDCTotal: this.chargeEDCPlusTotal,
          QRStatisPayment: this.selectedQRStatisRadio,
          QRISPayment: dataDGPayment,
        }
        this.sentToQRISModal = paymentReview

        this.dismissModalPembayaran();
        setTimeout(() => $("#modalPembayaranQRIS").modal("show") , 500);
      } else {
        this.msgAlert = "Pembayaran dompet digital tidak boleh Rp 0"
        this.alertPayment = true
        setTimeout(() => this.alertPayment = false, 5000);
      }
    },
    // on click dismiss modal
    dismissModalPembayaran(){
      this.msgAlert = "Pembayaran kurang",
      this.alertPayment = false,
      this.kembali = "Kembali",
      this.submitted = false,
      this.cashPaymentRadio = null,
      this.cashPaymentInput = null,
      this.cashPaymentChange = null,
      this.selectedEDC = "",
      this.chargeEDCPercent = null,
      this.selectedTf = "",
      this.selectedQRStatisRadio = null,
      this.paymentMode = 0,
      this.fieldStyleChange = {
        color: 'green',
      }
      $("#modalPembayaran").modal('hide');
    },
  },
};
</script>
<style scoped>
.btn-radio {
  float: left;
  margin: 5px;
  width: 131px;
  height: 40px;
  position: relative;
}
.btn-radio label,
.btn-radio input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  font-size: 16px;
}
.btn-radio input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}
.btn-radio input[type="radio"]:checked+label,
.Checked+label {
  background: #00ae2b;
  color: #ffffff;
}
.btn-radio label {
  padding: 6px;
  border: 1px solid rgb(90, 90, 90);
  cursor: pointer;
  z-index: 90;
}
.btn-radio label:hover {
  background: #DDD;
}
.field-kembali {
  padding-left:0px;
}
.label-pembayaran{
    margin-top: auto; 
    margin-bottom: auto;
    text-align:left;
}
input[readonly] {
  background-color: white;
}
.floating-custom {
    font-size: 14px;
    transform: translateY(90%) translateX(25%);
    background:#fff;
}
</style>