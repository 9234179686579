<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="padding-top:0px; padding-bottom:0px; margin-bottom:20px; font-size: 14px;">
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" @click="openDropdownMenu" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="fa fa-bars"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="font-size:14px">
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modalDiskon">
                <i class="fa fa-percent"></i> Diskon
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modalPajak">
                <i class="fa fa-cut"></i> Pajak
              </a>
              <div v-if="stSetUserTablet">
                <div class="dropdown-divider" v-if="stSetUserTablet.AllowRiwayatPenjualan == '1'"></div>
                <a v-if="stSetUserTablet.AllowRiwayatPenjualan == '1'" class="dropdown-item" style="cursor: pointer;" v-on:click="riwayatPage">
                  <i class="fa fa-shopping-cart"></i> Riwayat Penjualan
                </a>
              </div>
              <div v-if="stSetUserTablet">
                <div class="dropdown-divider" v-if="stSetUserTablet.AllowTambahUangMasuk == '1' || stSetUserTablet.AllowEditUangMasuk == '1' || stSetUserTablet.AllowHapusUangMasuk == '1'"></div>
                <a v-if="stSetUserTablet.AllowTambahUangMasuk == '1' || stSetUserTablet.AllowEditUangMasuk == '1' || stSetUserTablet.AllowHapusUangMasuk == '1'" class="dropdown-item" style="cursor: pointer;" v-on:click="uangMasukPage">
                  <i class="fa fa-wallet"></i> Uang Masuk
                </a>
              </div>
              <div v-if="stSetUserTablet">
                <div class="dropdown-divider" v-if="stSetUserTablet.AllowTambahUangKeluar == '1' || stSetUserTablet.AllowEditUangKeluar == '1' || stSetUserTablet.AllowHapusUangKeluar == '1'"></div>
                <a v-if="stSetUserTablet.AllowTambahUangKeluar == '1' || stSetUserTablet.AllowEditUangKeluar == '1' || stSetUserTablet.AllowHapusUangKeluar == '1'" class="dropdown-item" style="cursor: pointer;" v-on:click="uangKeluarPage">
                  <i class="fa fa-file-invoice"></i> Uang Keluar
                </a>
              </div>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="cursor: pointer;" v-on:click="settingPage">
                <i class="fa fa-cogs"></i> Pengaturan
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click="mTutupOutlet">
                <i class="fa fa-bookmark"></i> Tutup Outlet
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="cursor: pointer;" v-on:click="actReSyncDataSale" v-if="dataNotSync > 0">
                <i class="fa fa-sync-alt"></i> Sinkron Ulang ({{ dataNotSync }})
              </a>
              <div class="dropdown-divider" v-if="dataNotSync > 0"></div>
              <a class="dropdown-item" style="cursor: pointer;" v-on:click="mDownloadUlangData">
                <i class="fa fa-cloud-download-alt"></i> Download Ulang Data
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="mInfoOffline" style="width:100px" >
              <div style="color: #00ae2b;" v-if="connectionStatus == 'Online'">
                <b><span class="dotOnline"></span> {{connectionStatus}}</b>
              </div>
              <div style="color: #e20000;" v-if="connectionStatus == 'Offline'">
                <b><span class="dotoffline"></span> {{connectionStatus}}</b>
              </div>
            </a>
          </li>
          <li class="nav-item" v-if="stOpenTransaction">
            <a class="nav-link" href="#" style="padding-left:0px" >
              <b>{{ stOpenTransaction.SaleOrderNumber }}</b>
            </a>
          </li>
        </ul>
      </div>
      <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
          <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="#" @click="modalSimpanOrder" style="padding-right:0px; padding-top: 10px;" >
                  <i class="fa badge fa-lg" style="font-size: 20px" :value="totalPendingSale">&#xf290;</i>
                </a>
              </li>
              <li class="nav-item dropdown navbar-right">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <strong>{{usernamekasir}} - {{outletName}} </strong>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="right:0px; left:auto; font-size:14px">
                    <a class="dropdown-item" v-if="outletAddress">
                      <div>{{outletName}} <br /> <span style="font-size:12px">{{outletAddress}}</span> </div>
                    </a>
                    <div class="dropdown-divider" v-if="outletAddress"></div>
                    <a class="dropdown-item" href="#" @click="mInfoAplikasi">
                      <i class="fa fa-info-circle"></i> Tentang Aplikasi
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modalLogoutKasir">
                      <i class="fa fa-sign-out-alt"></i> Logout
                    </a>
                  </div>
              </li>
          </ul>
      </div>
    </nav>
  <!-- Modal Diskon -->
  <DiskonModal />
  <!-- Modal Pajak -->
  <PajakModal />
  <!-- Awal Modal Tutup Outlet -->
  <TutupOutletModal :dataForTutupOutlet="dataDate"/>
  <!-- Konfirmasi Logout Kasir -->
  <KonfirmLogoutKasir />
  <!-- Konfirmasi sedang offline -->
  <KonfirmasiOffline :datakonfirmasi="datakonfrim" />
  <!-- info sedang offline -->
  <InfoOfflineContModal :dataInfo="dataInfoTxt" />
  <!-- komponen modal data simpan order-->
  <SimpanOrderModal @cetakUlangSimpanOrder="cetakUlangSimpanOrder" />
  </div>
</template>

<script>

// @ is an alias to /src
import DiskonModal from "@/components/DiskonModal_c.vue";
import PajakModal from "@/components/PajakModal_c.vue";
import { mapGetters, mapActions } from "vuex";
import KonfirmLogoutKasir from "@/components/konfirmasi_modal/KonfirmLogoutKasir.vue";
import TutupOutletModal from "@/components/tutup_outlet/TutupOutletModal.vue";
import KonfirmasiOffline from "@/components/konfirmasi_modal/KonfirmasiOffline.vue";
import InfoOfflineContModal from "@/components/konfirmasi_modal/InfoOfflineContModal.vue";
import SimpanOrderModal from "@/components/SimpanOrderModal_c.vue";
import $ from "jquery";

export default {
  name: "Header_c",
   data() {
    return {
      dataDate: new Date(),
      datakonfrim:{
          keterangan:""
      },
      dataInfoTxt: "",
      dataNotSync: 0,
      totalPendingSale: 0
    };
  },
  components: {
    DiskonModal,
    PajakModal,
    KonfirmLogoutKasir,
    TutupOutletModal,
    KonfirmasiOffline,
    InfoOfflineContModal,
    SimpanOrderModal,
  },
  methods: {
    ...mapActions([
      "logoutClearAllData",
      "reDownloadItems",
      "actReSyncDataSale",
      "actReloadAllPendingSaleFromStorage",
      "downloadpendingsale",
      "downloadpendingsaleitemdetail",
      "downloadpendingsaleitemdetailingredients",
      "downloadpendingsaleitemproduct",
      "downloadpendingsaleitemdetailmodifier",
      "downloadpendingsalemodifierdetailingredients",
      "downloadpendingsaleitemdetailtax",
      "downloadpendingsalediningtabledetail",
      "downloadpendingsalediscountdetail",
      ]),
    settingPage(){
        this.$router.push({name: 'Setting'})
    },
    uangMasukPage(){
      this.$router.push({name: 'Uang Masuk'})
    },
    uangKeluarPage(){
      this.$router.push({name: 'Uang Keluar'})
    },
    riwayatPage() {
      this.$router.push({name: 'Riwayat Penjualan'})
    },
    mDownloadUlangData(){
      this.datakonfrim = {keterangan:"Untuk melakukan download ulang data diperlukan koneksi internet. Silahkan cek<br/>koneksi internet Anda kemudian coba lagi!"}
      if (this.connectionStatus == "Offline"){
          $("#modalkonfirmasioffline").modal("show");
      }else{
         this.$router.push({ name: 'Download Data' })
      }
    },
    mTutupOutlet(){
      this.datakonfrim = {keterangan:"Untuk melakukan tutup outlet diperlukan koneksi internet. Silahkan cek<br/>koneksi internet Anda kemudian coba lagi!"}
      if (this.connectionStatus == "Offline"){
        $("#modalkonfirmasioffline").modal("show");
      }else{
        this.dataDate = new Date();
        $("#modalTutupOutletBegin").modal("show");
      }
    },
    mInfoOffline(){
      this.dataInfoTxt = '<div style="margin:10px">'
                    + '<h5>Anda sedang offline</h5>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Anda dapat melakukan transaksi penjualan meskipun sedang offline,'
                    + 'seperti saat perbaikan jaringan internet atau di tempat yang tidak terdapat sambungan internet.'
                    + '</p>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Data transaksi penjualan akan tersimpan pada memory perangkat Anda.' 
                    + 'Peringatan, Anda tidak dianjurkan untuk melakukan transaksi penjualan ketika sedang offline dengan kurun waktu yang sangat lama,'
                    + 'karena ruang penyimpanan transaksi anda terbatas. Dan data transaksi penjualan akan tersinkron ke nutacloud.com ketika perangkat online kembali.'
                    + '</p>'
                    + '<h5>Hal yang memerlukan koneksi internet/online</h5>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Ada beberapa aksi yang memerlukan koneksi internet ketika Anda menggunakan Kasir Nutacloud'
                    + '</p>'
                    + '<ul style="font-size:14px">'
                    + '<li>Melakukan login. </li>'
                    + '<li>Melakukan buka outlet. </li>'
                    + '<li>Melakukan download ulang data dari nutacloud. </li>'
                    + '<li>Melakukan tutup outlet. </li>'
                    + '</ul>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Dan ketika akan menutup outlet, pastikan transaksi penjualan Anda sudah tersinkron semua dengan nutacloud.'
                    + '</p>'
                    + '</div>';
      if (this.connectionStatus == "Offline"){
        $("#modalinfooffline").modal("show");
      }
    },
    mInfoAplikasi(){
      this.dataInfoTxt = '<div style="margin:10px">'
                    + '<h5>Tentang Kasir Nutacloud</h5>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Kasir Nutacloud merupakan aplikasi POS(point of sale) yang berjalan pada browser dan diakses melalui komputer / laptop Anda.'
                    + 'Tidak perlu waktu lama untuk mencatat transaksi penjualan, cukup mengakses kasir.nutacloud.com pada browser Anda.'
                    + '</p>'
                    + '<h5>Fitur pada Kasir Nutacloud</h5>' 
                    + '<p style="padding-left:0px;font-size:14px">'
                    + '<ul style="font-size:14px">'
                    + '<li>Login dan Logout Perusahaan.</li>' 
                    + '<li>Buka Outlet dan Tutup Outlet.</li>' 
                    + '<li>Multiuser → Kasir Nutacloud bisa digunakan untuk login beberapa user kasir secara bergantian.</li>'
                    + '<li>Layar Kasir Mode list → Menampilkan data pelanggan dan data produk dalam bentuk daftar atau list.</li>' 
                    + '<li>Atur Nota → Mengatur informasi bisnis, footnotes dan logo untuk nota penjualan.</li>' 
                    + '<li>Diskon → Anda bisa menambahkan diskon final atau diskon yang sudah disetting di outlet ke dalam transaksi.</li>'
                    + '<li>Pajak → Anda bisa menambahkan pajak yang sudah disetting di outlet ke dalam transaksi.</li>'
                    + '<li>Pembayaran → Pembayaran yang dimaksud adalah :  Pembayaran tunai , Pembayaran dompet digital, Pembayaran transfer, Pembayaran edc, dan Pembayaran qr statis.</li>'
                    + '<li>Cetak Struk → Dapat mengirim struk via Email dan WA dan dapat mencetak struk memnggunakan printer usb, bluetooth, dan wifi (disarankan menggunakan printer USB).</li>'
                    + '<li>Terintegrasi dengan laporan di nutacloud.</li>'
                    + '<li>Terhubung dengan stok retail → Transaksi pada kasir.nutacloud akan mengurangi stok yang ada di outlet.</li>'
                    + '<li>Transaksi Offline → Kasir dapat melakukan secara offline menggunakan Kasir Nutacloud. Transaksi akan tersinkron di nutacloud secara otomatis setelah online.</li>'
                    + '</ul>'
                    + '</p>'
                    + '<h5>Persyaratan penggunaan Kasir Nutacloud</h5>' 
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Untuk dapat menggunakan fitur Kasir Nutacloud, Anda dapat menggunakan komputer / laptop dan disarankan menggunakan browser chrome. Untuk mencetak nota, Anda perlu menginstal dan menghubungkan printer dengan komputer / laptop Anda.' 
                    + '</p>'
                    + '<h5>Hal yang perlu diperhatikan</h5>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + '<ul style="font-size:14px">'
                    + '<li>Data yang terdapat pada kasir.nutacloud.com merupakan data dari nutacloud.com.</li>'
                    + '<li>Apabila Anda memodifikasi data di nutacloud.com maka pada kasir.nutacloud.com, Anda perlu melakukan download ulang data.</li>'
                    + '<li>Aksi yang memerlukan koneksi internet, antara lain: melakukan login, melakukan buka outlet, melakukan download ulang data dari nutacloud.com, melakukan tutup outlet.</li>'
                    + '<li>Ketika akan menutup outlet, pastikan transaksi penjualan Anda sudah tersinkron semua dengan nutacloud.com.</li>'
                    + '<li>Membatalkan atau menghapus transaksi penjualan dapat dilakukan di menu riwayat penjualan pada nutacloud.com.</li>'
                    + '</ul>'
                    + '</p>'
                    + '<h5>Printer Untuk Kasir Nutacloud </h5>' 
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Untuk ukuran struk kecil disarankan menggunakan : '
                    + '<ul style="font-size:14px">'
                    + '<li>Printer USB Panda PRJ-58D</li>'
                    + '<li>Printer USB Blueprint TMU B250</li>'
                    + '</ul>'
                    + 'Untuk ukuran struk besar disarankan menggunakan :'
                    + '<ul style="font-size:14px">'
                    + '<li>Printer Epson LX-310 Dot Matrix</li>'
                    + '</ul>'
                    + '</p>'
                    + '<h5>Memerlukan bantuan</h5>'
                    + '<p style="padding-left:0px;font-size:14px">'
                    + 'Anda dapat menonton video tutorial Kasir Nutacloud di youtube chanel <a href="https://www.youtube.com/watch?v=jlcwzw4aLGE&list=PLuq6Ms6unBVqGmuDmUs0AU0ZKnfCDES98&ab_channel=Nutapos" target="_blank">Nutapos.</a> '
                    + 'Apabila terdapat kendala atau memerlukan bantuan, dapat menghubungi Customer Service kami.'
                    + '</p>'
                    + '</div>';
      $("#modalinfooffline").modal("show");
    },
    openDropdownMenu(){
      var totPending = 0
      //get data sale
      var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
      pendingsale = pendingsale.filter((sale) => {
        return ((sale.SynMode == "1" || sale.SynMode == "2" || sale.SynMode == "3") && sale.DeviceNo == this.deviceNo);
      }); 

      //get data cashbank
      var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
      cashbank = cashbank.filter((cash) => {
        return ((cash.SynMode == "1" || cash.SynMode == "2" || cash.SynMode == "3") && cash.DeviceNo == this.deviceNo);
      }); 

      //get data cashin
      var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
      cashbankin = cashbankin.filter((cashin) => {
        return ((cashin.SynMode == "1" || cashin.SynMode == "2" || cashin.SynMode == "3") && cashin.DeviceNo == this.deviceNo);
      }); 

      //get data cashout
      var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
      cashbankout = cashbankout.filter((cashout) => {
        return ((cashout.SynMode == "1" || cashout.SynMode == "2" || cashout.SynMode == "3") && cashout.DeviceNo == this.deviceNo);
      });
      
      //get data pelanggan
      var pelanggan = localStorage.getItem("pelanggan") ? JSON.parse(localStorage.getItem("pelanggan")) : [];
      pelanggan = pelanggan.filter((cust) => {
        return ((cust.SynMode == "1" || cust.SynMode == "2" || cust.SynMode == "3") && cust.DeviceNo == this.deviceNo);
      });
      
      //get data openclose
      var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
      opencloseoutlet = opencloseoutlet.filter((ocoutlet) => {
        return ((ocoutlet.SynMode == "1" || ocoutlet.SynMode == "2" || ocoutlet.SynMode == "3") && ocoutlet.DeviceNo == this.deviceNo);
      });
      totPending = (pendingsale.length + cashbank.length + cashbankin.length + cashbankout.length + pelanggan.length + opencloseoutlet.length)
      this.dataNotSync = totPending
    },
    // modal simpan order
    modalSimpanOrder() {
      this.$store.dispatch("downloadpendingsale");
      this.$store.dispatch("downloadpendingsaleitemdetail");
      this.$store.dispatch("downloadpendingsaleitemdetailingredients");
      this.$store.dispatch("downloadpendingsaleitemproduct");
      this.$store.dispatch("downloadpendingsaleitemdetailmodifier");
      this.$store.dispatch("downloadpendingsalemodifierdetailingredients");
      this.$store.dispatch("downloadpendingsaleitemdetailtax");
      this.$store.dispatch("downloadpendingsalediningtabledetail");
      this.$store.dispatch("downloadpendingsalediscountdetail");
      $("#modalSimpanOrder").modal("show")
      this.$store.dispatch("actReloadAllPendingSaleFromStorage");
    },
    cetakUlangSimpanOrder(val){
      this.$emit('cetakUlangSimpanOrderFromHeader', val)
    },
    countPendingSale(){
      if(this.pendingsale) {
        var data = this.pendingsale.filter((sale) => {return (sale.Pending == "true");});
        this.totalPendingSale = data.length
      }
    },
  },
  computed: {
    ...mapGetters(["usernamekasir","connectionStatus", "deviceNo","outletName","outletAddress","stOpenTransaction","pendingsale","stSetUserTablet"]),
  },
  watch: {
    pendingsale: function(){
      this.countPendingSale();   
    }
  },
  mounted(){
      this.countPendingSale();
  },
}
</script>

<style scoped>
.dotOnline {
  height: 10px;
  width: 10px;
  background-color: #00ae2b;
  border-radius: 50%;
  display: inline-block;
}
.dotoffline {
  height: 10px;
  width: 10px;
  background-color: #e20000;
  border-radius: 50%;
  display: inline-block;
}
.badge:after{
        content:attr(value);
        font-size:12px;
        color: #fff;
        background: rgb(255, 196, 0);
        border-radius:50%;
        padding: 0 5px;
        position:relative;
        left:-8px;
        top:-10px;
        opacity:0.9;
    }
</style>