/* eslint-disable */ //disabled eslint compiler
// @ is an alias to /src
import axios from 'axios'
import $ from "jquery";
export default ({
    state: {
    },
    getters: {

    },
    actions: {
        async actGetHistoryOpen() {
            var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
            var myDays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu'];
            var dataopenclose = JSON.parse(localStorage.getItem("opencloseoutlet"));
            dataopenclose = dataopenclose.filter((item) => {
                return (item.CloseDate != "" && item.DeviceNo == localStorage.getItem("deviceNo"));
            });
            if (dataopenclose.length > 0) {
                var dt = dataopenclose[dataopenclose.length - 1];
                var thisDay = new Date(dt.CloseDate);
                return "pada hari " + myDays[thisDay.getDay()] + ", " + thisDay.getDate() + ' ' + months[thisDay.getMonth()] + ' ' + thisDay.getFullYear() + ' pukul ' + dt.CloseTime;
            } else {
                return ""
            }
        },
        async actGetSaldoKasDevice({ rootState }) {
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            cashbank = cashbank.filter((cash) => {
                return (cash.AccountType == 1 && cash.DeviceNo == rootState.auth.deviceNo);
            });
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "report/getsaldokasdevice",
                    data: {
                        devid: rootState.auth.outletID,
                        devno: rootState.auth.deviceNo,
                        accountid: cashbank[0].AccountID,
                    },
                    method: "POST",
                    success: (data) => {
                        resolve(data.Saldo)
                    },
                    error: (error) => {
                        reject(error)
                    },
                });
            })
        },
        async actOpenOutlet({ commit, rootState, dispatch }, dataForm) {
            return new Promise((resolve) => {
                let today = new Date()
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = String(today.getFullYear());

                var hh = String(today.getHours()).padStart(2, '0');
                var mi = String(today.getMinutes()).padStart(2, '0');
                var ss = String(today.getSeconds()).padStart(2, '0');

                var idclose = 1
                var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
                var opencloseoutletData = opencloseoutlet.filter((openData) => {
                    return (openData.DeviceNo == rootState.auth.deviceNo);
                });
                if (opencloseoutletData.length > 0) {
                    idclose = parseInt(opencloseoutletData[opencloseoutletData.length - 1].OpenID) + 1
                }
                const dataopen = {
                    OpenID: idclose,
                    OpenDate: yyyy + '-' + mm + '-' + dd,
                    OpenTime: hh + ":" + mi + ":" + ss,
                    OpenedBy: localStorage.getItem("usernamekasir"),
                    CloseDate: "",
                    CloseTime: "",
                    ClosedBy: "",
                    Varian: "Nuta",
                    DeviceID: rootState.auth.outletID,
                    DeviceNo: localStorage.getItem("deviceNo"),
                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                    PerusahaanID: rootState.items.options[0].PerusahaanID,
                    RowVersion: 1,
                    CreatedVersionCode: rootState.versionCode,
                    EditedVersionCode: 0,
                    TglJamUpdate: yyyy + '-' + mm + '-' + dd + ' ' + hh + ":" + mi + ":" + ss,
                    StartingCash: 0,
                    TotalSales: 0,
                    TotalCashSales: 0,
                    OtherIncome: 0,
                    Expense: 0,
                    //Taken: 0,
                    Withdrawal: 0,
                    ExpectedBalance: 0,
                    ActualBalance: 0,
                    Difference: 0,
                    StartingCashOri: parseFloat(dataForm.modalSebelumnya + dataForm.modalInput) ,
                    SynMode: 1,
                }
                opencloseoutlet.push(dataopen)

                localStorage.setItem('opencloseoutlet', JSON.stringify(opencloseoutlet))
                dispatch('actUploadOpenCloseOutlet')

                var dataForCashBankIn = {
                    AccountID: "1",
                    AccountDeviceNo: localStorage.getItem("deviceNo"),
                    ReceivedFrom: "Manajemen",
                    Note: "Uang modal saat buka outlet untuk kembalian ke pelanggan",
                    Amount: dataForm.modalInput,
                    IncomeType: "2",
                    OpenID: idclose,
                    OpenDeviceNo: localStorage.getItem("deviceNo"),
                    IsAuto: "1",
                    CreatedBy: localStorage.getItem("usernamekasir"),
                    SynMode: 1,
                }
                dispatch('actCashBankInInsert', dataForCashBankIn)
                commit('set_isOpenOutlet',true)
                resolve({ status: true })
            })
        },
        async actCashBankInInsert({ commit, rootState, dispatch }, payload) {
            return new Promise((resolve) => {
            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi = String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            // get TransactionNumber
            var TransactionNumber = "UM/" + yyyy.slice(2, 4) + '' + mm + '' + dd + "/" + localStorage.getItem("deviceNo")
            var getTransactionNumber = 1
            var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            if(cashbankin.length > 0){
                var cashbankinFilter = cashbankin.filter((bankin) => {
                    return (bankin.TransactionDate == yyyy + '-' + mm + '-' + dd && bankin.DeviceNo == localStorage.getItem("deviceNo") /*&& bankin.TransactionNumber.slice(0, 11) == TransactionNumber*/);
                });

                getTransactionNumber = cashbankinFilter.length + 1
            }
            
            // get latesttransactionid
            var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];

            //create TransactionID
            let TransactionID = 1;
            if(latesttransactionid){
                if(latesttransactionid.cashbankinLatestId){
                    TransactionID = parseInt(latesttransactionid.cashbankinLatestId) + 1;
                }
            }

            //get data bankaccount
            let getAccCash = null
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            if(cashbank.length > 0){
                getAccCash = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == payload.AccountID+'.'+ payload.AccountDeviceNo /*&& b.AccountType == '1'*/);
            }

            const datacashbankin = {
                TransactionID: TransactionID,
                TransactionNumber: TransactionNumber + "/" + getTransactionNumber,
                TransactionDate: yyyy + '-' + mm + '-' + dd,
                TransactionTime: hh + ":" + mi + ":" + ss,
                AccountID: payload.AccountID,
                AccountDeviceNo: payload.AccountDeviceNo,
                ReceivedFrom: payload.ReceivedFrom,
                Note: payload.Note,
                Amount: payload.Amount,
                CashBankAccountName: getAccCash ? getAccCash.AccountName : "",
                IncomeType: payload.IncomeType,
                OpenID: payload.OpenID,
                OpenDeviceNo: payload.OpenDeviceNo,
                IsAuto: payload.IsAuto,
                CreatedBy: payload.CreatedBy,
                CreatedDate: yyyy + '-' + mm + '-' + dd,
                CreatedTime: hh + ":" + mi + ":" + ss,
                EditedBy: "",
                EditedDate: "",
                EditedTime: "",
                //IgnoreInCloud: "0",
                Varian: "Nuta",
                DeviceID: rootState.auth.outletID,
                DeviceNo: localStorage.getItem("deviceNo"),
                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                PerusahaanID: rootState.items.options[0].PerusahaanID,
                CreatedVersionCode: rootState.versionCode,
                EditedVersionCode: "0",
                RowVersion: "1",
                HasBeenDownloaded: "1",
                //LedgerAccountID: "16",
                SynMode: payload.SynMode,
            }

            //update latesttransactionid
            latesttransactionid.cashbankinLatestId = TransactionID
            localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))

            cashbankin.push(datacashbankin)
            localStorage.setItem('cashbankin', JSON.stringify(cashbankin))
            commit('mutOpenUangMasuk', null)
            dispatch('actCashBankin')
                .then((response) => {
                    resolve({status: "success"})
                })
            })
        },
        async actCashBankInUpdate({ commit, rootState, dispatch }, payload) {
            return new Promise((resolve) => {
            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi = String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            // get Transaction
            var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            var cashbankinFilter = []
            if(cashbankin.length > 0){
                cashbankinFilter = cashbankin.filter((el) => {
                    return (el.TransactionID == payload.TransactionID && el.DeviceNo == payload.DeviceNo);
                });
            }
            //get data bankaccount
            let getAccCash = null
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            if(cashbank.length > 0){
                getAccCash = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == payload.AccountID+'.'+ payload.AccountDeviceNo /*&& b.AccountType == '1'*/);
            }

            if(cashbankinFilter.length <= 0) {
                const datacashbankin = {
                    TransactionID: payload.TransactionID,
                    TransactionNumber: payload.TransactionNumber,
                    TransactionDate: payload.TransactionDate,
                    TransactionTime: payload.TransactionTime,
                    AccountID: payload.AccountID,
                    AccountDeviceNo: payload.AccountDeviceNo,
                    ReceivedFrom: payload.ReceivedFrom,
                    Note: payload.Note,
                    Amount: payload.Amount,
                    CashBankAccountName: getAccCash ? getAccCash.AccountName : "",
                    IncomeType: payload.IncomeType,
                    OpenID: payload.OpenID,
                    OpenDeviceNo: payload.OpenDeviceNo,
                    IsAuto: payload.IsAuto,
                    CreatedBy: payload.CreatedBy,
                    CreatedDate: payload.CreatedDate,
                    CreatedTime: payload.CreatedTime,
                    EditedBy: payload.EditedBy,
                    EditedDate: yyyy + '-' + mm + '-' + dd,
                    EditedTime: hh + ":" + mi + ":" + ss,
                    //IgnoreInCloud: "0",
                    Varian: "Nuta",
                    DeviceID: rootState.auth.outletID,
                    DeviceNo: payload.DeviceNo,
                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                    PerusahaanID: rootState.items.options[0].PerusahaanID,
                    CreatedVersionCode: rootState.versionCode,
                    EditedVersionCode: "0",
                    RowVersion: "1",
                    HasBeenDownloaded: "1",
                    //LedgerAccountID: "16",
                    SynMode: payload.SynMode,
                }
                cashbankin.push(datacashbankin)
            } else {
                for(var i = 0; i < cashbankin.length; i++) {
                    if(cashbankin[i].TransactionID == rootState.transaction.stOpenUangMasuk.TransactionID && cashbankin[i].DeviceNo == rootState.transaction.stOpenUangMasuk.DeviceNo) {
                        cashbankin[i].AccountID = payload.AccountID
                        cashbankin[i].AccountDeviceNo = payload.AccountDeviceNo
                        cashbankin[i].ReceivedFrom = payload.ReceivedFrom
                        cashbankin[i].Note = payload.Note
                        cashbankin[i].Amount = payload.Amount
                        cashbankin[i].CashBankAccountName = getAccCash ? getAccCash.AccountName : ""
                        cashbankin[i].IncomeType = payload.IncomeType
                        cashbankin[i].OpenID = payload.OpenID
                        cashbankin[i].OpenDeviceNo = payload.OpenDeviceNo
                        cashbankin[i].IsAuto = payload.IsAuto
                        cashbankin[i].EditedBy = payload.EditedBy
                        cashbankin[i].EditedDate = yyyy + '-' + mm + '-' + dd
                        cashbankin[i].EditedTime = hh + ":" + mi + ":" + ss
                        cashbankin[i].DeviceID = rootState.auth.outletID
                        cashbankin[i].PerusahaanNo = rootState.items.options[0].PerusahaanNo
                        cashbankin[i].PerusahaanID = rootState.items.options[0].PerusahaanID
                        cashbankin[i].EditedVersionCode = rootState.versionCode
                        cashbankin[i].SynMode = payload.SynMode
                    }
                }
            }

            localStorage.setItem('cashbankin', JSON.stringify(cashbankin))
            commit('mutOpenUangMasuk', null)
            dispatch('actCashBankin')
                .then((response) => {
                    resolve({status: "success"})
                })
            })
            
        },
        async actCashBankInDelete({ commit, rootState, dispatch }, payload) {
            return new Promise((resolve) => {
                // get Transaction
                var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
                var cashbankinFilter = []
                if(cashbankin.length > 0){
                    cashbankinFilter = cashbankin.filter((el) => {
                        return (el.TransactionID == payload.TransactionID && el.DeviceNo == payload.DeviceNo);
                    });
                }
    
                if(cashbankinFilter.length <= 0) {
                    const datacashbankin = {
                        TransactionID: payload.TransactionID,
                        TransactionNumber: payload.TransactionNumber,
                        TransactionDate: payload.TransactionDate,
                        TransactionTime: payload.TransactionTime,
                        AccountID: payload.AccountID,
                        AccountDeviceNo: payload.AccountDeviceNo,
                        ReceivedFrom: payload.ReceivedFrom,
                        Note: payload.Note,
                        Amount: payload.Amount,
                        CashBankAccountName: payload.CashBankAccountName,
                        IncomeType: payload.IncomeType,
                        OpenID: payload.OpenID,
                        OpenDeviceNo: payload.OpenDeviceNo,
                        IsAuto: payload.IsAuto,
                        CreatedBy: payload.CreatedBy,
                        CreatedDate: payload.CreatedDate,
                        CreatedTime: payload.CreatedTime,
                        EditedBy: payload.EditedBy,
                        EditedDate: payload.EditedDate,
                        EditedTime: payload.EditedTime,
                        //IgnoreInCloud: payload.IgnoreInCloud,
                        Varian: "Nuta",
                        DeviceID: rootState.auth.outletID,
                        DeviceNo: payload.DeviceNo,
                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                        PerusahaanID: rootState.items.options[0].PerusahaanID,
                        CreatedVersionCode: payload.CreatedVersionCode,
                        EditedVersionCode: payload.EditedVersionCode,
                        RowVersion: payload.RowVersion,
                        HasBeenDownloaded: payload.HasBeenDownloaded,
                        //LedgerAccountID: "16",
                        SynMode: payload.SynMode,
                    }
                    cashbankin.push(datacashbankin)
                } else {
                    for(var i = 0; i < cashbankin.length; i++) {
                        if(cashbankin[i].TransactionID == rootState.transaction.stOpenUangMasuk.TransactionID && cashbankin[i].DeviceNo == rootState.transaction.stOpenUangMasuk.DeviceNo) {
                            cashbankin[i].SynMode = payload.SynMode
                        }
                    }
                }
    
                localStorage.setItem('cashbankin', JSON.stringify(cashbankin))
                commit('mutOpenUangMasuk', null)
                dispatch('actCashBankinDelete')
                    .then((response) => {
                        resolve({status: "success"})
                })
            })
        },
        async actCashBankOutInsert({ commit, rootState, dispatch }, payload) {
            return new Promise((resolve) => {
            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi = String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            // get TransactionNumber
            var TransactionNumber = "UK/" + yyyy.slice(2, 4) + '' + mm + '' + dd + "/" + localStorage.getItem("deviceNo")
            var getTransactionNumber = 1
            var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            if(cashbankout.length > 0){
                var cashbankoutFilter = cashbankout.filter((bankout) => {
                    return (bankout.TransactionDate == yyyy + '-' + mm + '-' + dd && bankout.DeviceNo == localStorage.getItem("deviceNo") /*&& bankout.TransactionNumber.slice(0, 11) == TransactionNumber*/);
                });

                getTransactionNumber = cashbankoutFilter.length + 1
            }
            
            // get latesttransactionid
            var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];

            //create TransactionID
            let TransactionID = 1;
            if(latesttransactionid){
                if(latesttransactionid.cashbankoutLatestId){
                    TransactionID = parseInt(latesttransactionid.cashbankoutLatestId) + 1;
                }
            }

            //get data bankaccount
            let getAccCash = null
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            if(cashbank.length > 0){
                getAccCash = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == payload.AccountID+'.'+ payload.AccountDeviceNo && b.AccountType == '1');
            }

            var datacashbankout  =  {
                TransactionID: TransactionID,
                TransactionNumber: TransactionNumber + "/" + getTransactionNumber,
                TransactionDate: yyyy + '-' + mm + '-' + dd,
                TransactionTime: hh + ":" + mi + ":" + ss,
                AccountID: payload.AccountID,
                AccountDeviceNo: payload.AccountDeviceNo,
                PaidTo: payload.PaidTo,
                Note: payload.Note,
                Amount: payload.Amount,
                CashBankAccountName: getAccCash ? getAccCash.AccountName : "",
                SpendingType: payload.SpendingType,
                OpenID: payload.OpenID,
                OpenDeviceNo: payload.OpenDeviceNo,
                IsAuto:payload.IsAuto,
                CreatedBy: payload.CreatedBy,
                CreatedDate: yyyy + '-' + mm + '-' + dd,
                CreatedTime: hh + ":" + mi + ":" + ss,
                EditedBy:"",
                EditedDate:"",
                EditedTime:"",
                Varian: "Nuta",
                DeviceID: rootState.auth.outletID,
                DeviceNo: localStorage.getItem("deviceNo"),
                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                PerusahaanID: rootState.items.options[0].PerusahaanID,
                CreatedVersionCode: rootState.versionCode,
                EditedVersionCode: "0",
                RowVersion:"1",
                HasBeenDownloaded:"1",
                //LedgerAccountID:  "18",
                SynMode:payload.SynMode,
            }
            //update latesttransactionid
            latesttransactionid.cashbankoutLatestId = TransactionID
            localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))

            cashbankout.push(datacashbankout)
            localStorage.setItem('cashbankout', JSON.stringify(cashbankout))

            dispatch('actCashBankout')
            resolve({status: "success"})
            })
        },
        async actCashBankOutUpdate({ commit, rootState, dispatch }, payload) {
            return new Promise((resolve) => {
            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi = String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            // get Transaction
            var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            var cashbankoutFilter = []
            if(cashbankout.length > 0){
                cashbankoutFilter = cashbankout.filter((el) => {
                    return (el.TransactionID == payload.TransactionID && el.DeviceNo == payload.DeviceNo);
                });
            }
            //get data bankaccount
            let getAccCash = null
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            if(cashbank.length > 0){
                getAccCash = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == payload.AccountID+'.'+ payload.AccountDeviceNo /*&& b.AccountType == '1'*/);
            }

            if(cashbankoutFilter.length <= 0) {
                const datacashbankout = {
                    TransactionID: payload.TransactionID,
                    TransactionNumber: payload.TransactionNumber,
                    TransactionDate: payload.TransactionDate,
                    TransactionTime: payload.TransactionTime,
                    AccountID: payload.AccountID,
                    AccountDeviceNo: payload.AccountDeviceNo,
                    PaidTo: payload.PaidTo,
                    Note: payload.Note,
                    Amount: payload.Amount,
                    CashBankAccountName: getAccCash ? getAccCash.AccountName : "",
                    SpendingType: payload.SpendingType,
                    OpenID: payload.OpenID,
                    OpenDeviceNo: payload.OpenDeviceNo,
                    IsAuto: payload.IsAuto,
                    CreatedBy: payload.CreatedBy,
                    CreatedDate: payload.CreatedDate,
                    CreatedTime: payload.CreatedTime,
                    EditedBy: payload.EditedBy,
                    EditedDate: yyyy + '-' + mm + '-' + dd,
                    EditedTime: hh + ":" + mi + ":" + ss,
                    //IgnoreInCloud: "0",
                    Varian: "Nuta",
                    DeviceID: rootState.auth.outletID,
                    DeviceNo: payload.DeviceNo,
                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                    PerusahaanID: rootState.items.options[0].PerusahaanID,
                    CreatedVersionCode: rootState.versionCode,
                    EditedVersionCode: "0",
                    RowVersion: "1",
                    HasBeenDownloaded: "1",
                    //LedgerAccountID: "16",
                    SynMode: payload.SynMode,
                }
                cashbankout.push(datacashbankout)
            } else {
                for(var i = 0; i < cashbankout.length; i++) {
                    if(cashbankout[i].TransactionID == rootState.transaction.stOpenUangKeluar.TransactionID && cashbankout[i].DeviceNo == rootState.transaction.stOpenUangKeluar.DeviceNo) {
                        cashbankout[i].AccountID = payload.AccountID
                        cashbankout[i].AccountDeviceNo = payload.AccountDeviceNo
                        cashbankout[i].PaidTo = payload.PaidTo
                        cashbankout[i].Note = payload.Note
                        cashbankout[i].Amount = payload.Amount
                        cashbankout[i].CashBankAccountName = getAccCash ? getAccCash.AccountName : ""
                        cashbankout[i].SpendingType = payload.SpendingType
                        cashbankout[i].OpenID = payload.OpenID
                        cashbankout[i].OpenDeviceNo = payload.OpenDeviceNo
                        cashbankout[i].IsAuto = payload.IsAuto
                        cashbankout[i].EditedBy = payload.EditedBy
                        cashbankout[i].EditedDate = yyyy + '-' + mm + '-' + dd
                        cashbankout[i].EditedTime = hh + ":" + mi + ":" + ss
                        cashbankout[i].DeviceID = rootState.auth.outletID
                        cashbankout[i].PerusahaanNo = rootState.items.options[0].PerusahaanNo
                        cashbankout[i].PerusahaanID = rootState.items.options[0].PerusahaanID
                        cashbankout[i].EditedVersionCode = rootState.versionCode
                        cashbankout[i].SynMode = payload.SynMode
                    }
                }
            }

            localStorage.setItem('cashbankout', JSON.stringify(cashbankout))
            commit('mutOpenUangKeluar', null)
            dispatch('actCashBankout')
                .then((response) => {
                    resolve({status: "success"})
                })
            })
            
        },
        async actCashBankOutDelete({ commit, rootState, dispatch }, payload) {
            return new Promise((resolve) => {
                // get Transaction
                var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
                var cashbankoutFilter = []
                if(cashbankout.length > 0){
                    cashbankoutFilter = cashbankout.filter((el) => {
                        return (el.TransactionID == payload.TransactionID && el.DeviceNo == payload.DeviceNo);
                    });
                }
    
                if(cashbankoutFilter.length <= 0) {
                    const datacashbankout = {
                        TransactionID: payload.TransactionID,
                        TransactionNumber: payload.TransactionNumber,
                        TransactionDate: payload.TransactionDate,
                        TransactionTime: payload.TransactionTime,
                        AccountID: payload.AccountID,
                        AccountDeviceNo: payload.AccountDeviceNo,
                        PaidTo: payload.PaidTo,
                        Note: payload.Note,
                        Amount: payload.Amount,
                        CashBankAccountName: payload.CashBankAccountName,
                        SpendingType: payload.SpendingType,
                        OpenID: payload.OpenID,
                        OpenDeviceNo: payload.OpenDeviceNo,
                        IsAuto: payload.IsAuto,
                        CreatedBy: payload.CreatedBy,
                        CreatedDate: payload.CreatedDate,
                        CreatedTime: payload.CreatedTime,
                        EditedBy: payload.EditedBy,
                        EditedDate: payload.EditedDate,
                        EditedTime: payload.EditedTime,
                        //IgnoreInCloud: payload.IgnoreInCloud,
                        Varian: "Nuta",
                        DeviceID: rootState.auth.outletID,
                        DeviceNo: payload.DeviceNo,
                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                        PerusahaanID: rootState.items.options[0].PerusahaanID,
                        CreatedVersionCode: payload.CreatedVersionCode,
                        EditedVersionCode: payload.EditedVersionCode,
                        RowVersion: payload.RowVersion,
                        HasBeenDownloaded: payload.HasBeenDownloaded,
                        //LedgerAccountID: "16",
                        SynMode: payload.SynMode,
                    }
                    cashbankout.push(datacashbankout)
                } else {
                    for(var i = 0; i < cashbankout.length; i++) {
                        if(cashbankout[i].TransactionID == rootState.transaction.stOpenUangKeluar.TransactionID && cashbankout[i].DeviceNo == rootState.transaction.stOpenUangKeluar.DeviceNo) {
                            cashbankout[i].SynMode = payload.SynMode
                        }
                    }
                }
    
                localStorage.setItem('cashbankout', JSON.stringify(cashbankout))
                commit('mutOpenUangKeluar', null)
                dispatch('actCashBankoutDelete')
                    .then((response) => {
                        resolve({status: "success"})
                })
            })
        },
        async actGetDataCloseOutlet({rootState }){
            return new Promise((resolve, reject) => {
                let openid = [];
                const dataopenid =JSON.parse(localStorage.getItem("opencloseoutlet"));
                for (let index = 0; index < dataopenid.length; index++) {
                    if (dataopenid[index].DeviceID == rootState.auth.outletID
                        && dataopenid[index].PerusahaanNo == rootState.items.options[0].PerusahaanNo
                        && dataopenid[index].DeviceNo == localStorage.getItem("deviceNo")
                        && dataopenid[index].CloseDate=="" 
                        && dataopenid[index].CloseTime==""
                        )
                    {
                        openid = dataopenid[index]; 
                    }
                }
                var today = new Date();
                $.ajax({
                    url: axios.defaults.baseURLWS + "report/getcloseoutlet2",
                    data: {
                        devid: rootState.auth.outletID,
                        devno: rootState.auth.deviceNo,
                        openid: openid.OpenID,
                        dt1: today.getFullYear()+'-'+ ("0" +(today.getMonth()+1)).slice(-2) +'-'+ ("0" +today.getDate()).slice(-2),
                        dt2: today.getHours()+':'+today.getMinutes()+':'+today.getSeconds(),
                        accountid:1,
                        opendeviceno: openid.DeviceNo,
                    },
                    method: "POST",
                    success: (data) => {
                        resolve({
                            dataCloseOutlet: openid,
                            dataPenjualan: data
                            })
                    },
                    error: (error) => {

                        reject(error)
                    },
                });
            })
        },
        async actGetHistoryCloseOutlet({rootState }, payload){
            return new Promise((resolve, reject) => {
                let openid = [];
                const dataopenid =JSON.parse(localStorage.getItem("opencloseoutlet"));
                for (let index = 0; index < dataopenid.length; index++) {
                    if (dataopenid[index].DeviceID == rootState.auth.outletID
                        && dataopenid[index].PerusahaanNo == rootState.items.options[0].PerusahaanNo
                        && dataopenid[index].DeviceNo == localStorage.getItem("deviceNo")
                        && dataopenid[index].OpenID==payload.OpenID 
                        && dataopenid[index].DeviceNo==payload.OpenDeviceNo
                        )
                    {
                        openid = dataopenid[index]; 
                    }
                }
                var today = new Date();
                $.ajax({
                    url: axios.defaults.baseURLWS + "report/getcloseoutlet2",
                    data: {
                        devid: rootState.auth.outletID,
                        devno: rootState.auth.deviceNo,
                        openid: openid.OpenID,
                        dt1: today.getFullYear()+'-'+ ("0" +(today.getMonth()+1)).slice(-2) +'-'+ ("0" +today.getDate()).slice(-2),
                        dt2: today.getHours()+':'+today.getMinutes()+':'+today.getSeconds(),
                        accountid:1,
                        opendeviceno: openid.DeviceNo,
                    },
                    method: "POST",
                    success: (data) => {
                        resolve({
                            dataCloseOutlet: openid,
                            dataPenjualan: data
                            })
                    },
                    error: (error) => {

                        reject(error)
                    },
                });
            })
        },
        async actCloseOutlet({commit,dispatch},form) {
            return new Promise((resolve) => {
                let today = new Date()
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = String(today.getFullYear());

                var hh = String(today.getHours()).padStart(2, '0');
                var mi = String(today.getMinutes()).padStart(2, '0');
                var ss = String(today.getSeconds()).padStart(2, '0');

                var cashSales = 0

                for (let x = 0; x < form.dataPenjualan.TotalPenjualan.length; x++) {
                    if(form.dataPenjualan.TotalPenjualan[x].Detail.length){
                        for (let xx = 0; xx < form.dataPenjualan.TotalPenjualan[x].Detail.length; xx++) {
                            if(form.dataPenjualan.TotalPenjualan[x].Detail[xx].PaymentName == "Tunai"){
                                cashSales = parseFloat(form.dataPenjualan.TotalPenjualan[x].Detail[xx].Total);
                            }
                        }
                    }
                }
                
                var totCash = (parseFloat(form.dataPenjualan.ModalAwal) + parseFloat(cashSales) + parseFloat(form.dataPenjualan.PemasukanLain)) - parseFloat(form.dataPenjualan.TotalPengeluaran)
                var ExpectedBalance = parseFloat(totCash - form.Withdrawal)
                var Difference = parseFloat((form.Withdrawal - totCash) + form.ActualBalance)
                const dataopenid = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
                for (let index = 0; index < dataopenid.length; index++) {
                    if (dataopenid[index].OpenID == form.dataOpenClose.OpenID 
                        && dataopenid[index].DeviceID == form.dataOpenClose.DeviceID 
                        && dataopenid[index].PerusahaanNo == form.dataOpenClose.PerusahaanNo
                        && dataopenid[index].DeviceNo == form.dataOpenClose.DeviceNo
                        )
                    {
                        dataopenid[index].StartingCash = form.dataPenjualan.ModalAwal
                        dataopenid[index].CloseDate = yyyy + '-' + mm + '-' + dd
                        dataopenid[index].CloseTime = hh + ":" + mi + ":" + ss
                        dataopenid[index].ClosedBy = localStorage.getItem("usernamekasir")
                        dataopenid[index].TglJamUpdate = yyyy + '-' + mm + '-' + dd + ' ' + hh + ":" + mi + ":" + ss
                        dataopenid[index].TotalSales = form.TotalSales
                        dataopenid[index].TotalCashSales = cashSales
                        dataopenid[index].OtherIncome = form.dataPenjualan.PemasukanLain
                        dataopenid[index].Expense = form.dataPenjualan.TotalPengeluaran
                        //dataopenid[index].Taken = 0
                        dataopenid[index].Withdrawal = form.Withdrawal
                        dataopenid[index].ExpectedBalance = ExpectedBalance
                        dataopenid[index].ActualBalance = form.ActualBalance
                        dataopenid[index].Difference = Difference
                        dataopenid[index].SynMode = 2
                    }
                }

                localStorage.setItem("opencloseoutlet", JSON.stringify(dataopenid));
                dispatch('actUploadOpenCloseOutlet')
                if(form.Withdrawal != null){
                    var dataForCashBankOut = {
                        AccountID: "1",
                        AccountDeviceNo: localStorage.getItem("deviceNo"),
                        PaidTo: "Manajemen",
                        Note: "Penarikan uang dari laci kasir saat tutup outlet",
                        Amount: form.Withdrawal,
                        SpendingType: "2",
                        OpenID: form.dataOpenClose.OpenID,
                        OpenDeviceNo: localStorage.getItem("deviceNo"),
                        IsAuto: "1",
                        CreatedBy: localStorage.getItem("usernamekasir"),
                        SynMode: 1
                    }
                    dispatch('actCashBankOutInsert', dataForCashBankOut)
                    .then((response) => {
                        if(Difference > 0){
                            var dataForCashBankIn = {
                                AccountID: "1",
                                AccountDeviceNo: localStorage.getItem("deviceNo"),
                                ReceivedFrom: "Keuntungan",
                                Note: "Selisih uang aktual dan sistem saat tutup outlet",
                                Amount: Difference,
                                IncomeType: "1",
                                OpenID: form.dataOpenClose.OpenID,
                                OpenDeviceNo: localStorage.getItem("deviceNo"),
                                IsAuto: "1",
                                CreatedBy: localStorage.getItem("usernamekasir"),
                                SynMode: 1,
                            }
                            dispatch('actCashBankInInsert', dataForCashBankIn)
                            .then((response) => {
                                resolve({
                                    error:false, 
                                    closeDate: yyyy + '-' + mm + '-' + dd, 
                                    closeTime: hh + ":" + mi + ":" + ss,
                                    closedBy: localStorage.getItem("usernamekasir")
                                })
                            })
                        } else if(Difference < 0){
                            var dataForCashBankOut = {
                                AccountID: "1",
                                AccountDeviceNo: localStorage.getItem("deviceNo"),
                                PaidTo: "Kerugian",
                                Note: "Selisih uang aktual dan sistem saat tutup outlet",
                                Amount: Math.abs(Difference),
                                SpendingType: "1",
                                OpenID: form.dataOpenClose.OpenID,
                                OpenDeviceNo: localStorage.getItem("deviceNo"),
                                IsAuto: "1",
                                CreatedBy: localStorage.getItem("usernamekasir"),
                                SynMode: 1
                            }
                            dispatch('actCashBankOutInsert', dataForCashBankOut)
                            .then((response) => {
                                resolve({
                                    error:false, 
                                    closeDate: yyyy + '-' + mm + '-' + dd, 
                                    closeTime: hh + ":" + mi + ":" + ss,
                                    closedBy: localStorage.getItem("usernamekasir")
                                })
                            })
                        } else {
                            resolve({
                                error:false, 
                                closeDate: yyyy + '-' + mm + '-' + dd, 
                                closeTime: hh + ":" + mi + ":" + ss,
                                closedBy: localStorage.getItem("usernamekasir")
                            })
                        }
                        dispatch('downloadpendingsale')
                        dispatch('downloadpendingsaleitemdetail')
                        dispatch('downloadpendingsaleitemdetailingredients')
                        dispatch('downloadpendingsaleitemproduct')
                        dispatch('downloadpendingsaleitemdetailmodifier')
                        dispatch('downloadpendingsalemodifierdetailingredients')
                        dispatch('downloadpendingsaleitemdetailtax')
                        dispatch('downloadpendingsalediningtabledetail')
                        dispatch('downloadpendingsalediscountdetail')
                    })
                }
            })
        },
    },
    mutations: {

    }
})