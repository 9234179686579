<template>
  <!-- Modal konfirmasi hapus data cart-->
  <div id="modalConfirmClearCart" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Konfirmasi</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12">
              <p style="text-align: left">
                Apakah Anda yakin untuk mengkosongkan semua produk yang sudah
                dipilih?
              </p>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btn-link btn-block"
                    type="button"
                    data-dismiss="modal"
                  >
                    Batal
                  </button>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    @click="removeAllItemCart"
                    class="btn btn-green btn-block"
                  >
                    Ya
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import $ from "jquery";
export default {
  name: "KonfirmHapusCartModal",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    ...mapActions(["actRemoveAllItemCart"]),
    removeAllItemCart() {
      this.$store.dispatch("actRemoveAllItemCart");
      $("#modalConfirmClearCart").modal("hide");
    },
  },
};
</script>
<style scoped>

</style>