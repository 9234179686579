<template>
  <div id="modalinfooffline" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12" v-html="dataInfo">
            </div>
          </div>
          <div class="row py-2">
            <div class="col-6"></div>
            <div class="col-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfoOfflineContModal",
  data() {
    return {

    };
  },
  computed: {

  },
  props: {
    dataInfo: String,
  },
  watch: {
    
  },
  methods: {

  },
};

</script>
<style scoped>

</style>