<template>
  <div>
    <div id="modalPembayaranBerhasil" class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 text-center">
                <a href="#" style="padding-top: 30px">
                  <span class="fa fa-check-circle fa-4x"></span>
                </a>
                <h5 class="mb-0 pb-3">{{ paymentModeMsg }}</h5>
                <h6 v-if="paymentModeMsg == 'Pembayaran Tunai'">Uang kembali Rp {{ $filters.numberTocurrencyIDR(changeMsg) }}</h6>
                <h6 v-if="paymentModeMixMsg">{{ paymentModeMixMsg }}</h6>
                <h6 v-if="bankMsg">{{ bankMsg }}</h6>
                <!-- start alert-->
                <div :class="cAlertReceipt" role="alert" v-if="alertReceipt">
                  <i class="fa fa-exclamation-circle"></i> {{ msgAlertReceipt }}
                </div>
                <!-- end alert-->
              </div>
              <div class="col-12" style="padding-top:20px;font-size:14px" v-if="options[0].SendReceiptToCustomerViaEmail == '1'">
                <div class="text-left" style="padding-bottom:5px">
                  Bagaimana cara anda menyerahkan struk ini ke pelanggan?
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      class="form-control"
                      v-model="emailCust"
                      name="Email"
                      type="text"
                      placeholder="Email"
                    />
                    <div style="cursor: pointer;" class="input-group-append" >
                      <button class="btn btn-outline-success" type="button" @click="sendReceiptEmail" :disabled="sendEmail">Kirim</button>
                    </div>
                  </div>
                  <i v-if="errorEmailSts"><small class="text-danger font-weight-bold" style="font-size:12px;">{{errorEmailMsg}}</small></i>
                </div>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      class="form-control"
                      v-model="nowaCust"
                      @keypress="isNumber($event)"
                      name="WhatsApp"
                      type="text"
                      placeholder="WhatsApp"
                    />
                    <div style="cursor: pointer;" class="input-group-append" >
                      <button class="btn btn-outline-success" type="button" @click="sendReceiptWA">Kirim</button>
                    </div>
                  </div>
                  <i v-if="errorWaSts"><small class="text-danger font-weight-bold" style="font-size:12px;">{{errorWaMsg}}</small></i>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <button 
                  type="button" 
                  class="btn btn-outline-success btn-block"
                  @click="printReceipt">
                  Cetak Struk
                </button>
              </div>
              <div class="col-sm" v-if="options[0].SendReceiptToCustomerViaEmail == '1'">
                <button 
                  type="button" 
                  class="btn btn-outline-success btn-block"
                  @click="printAndSendReceipt">
                  Cetak dan Kirim Struk
                </button>
              </div>
            </div>
            <div class="row py-10">
              <div class="col-sm">
                <button
                  class="btn btn-green btn-block"
                  type="button"
                  @click="dismissModalBerhasilPembayaran"
                >
                  Transaksi Baru
                </button>
              </div>
            </div>

            <!-- start print receipt template small -->
            <div id="ticket-print" class="ticket" style="height:100px;overflow: auto; display: none;">
              <img
                v-if="logoReceipt"
                :src="logoReceipt"
                alt="Logo"
                class="center"
                style="width:150px; height:auto;"
              />
              <p class="centered">
                {{ options[0].CompanyName }} <br />{{ options[0].CompanyAddress }} <br />{{ options[0].CompanyPhone }}
              </p>
              <div style="width: 100%; border-top-style: dashed;">
                <p style="margin-left: 0px; text-align: left">
                  Nomor Nota : {{ pendingsale[0].SaleNumber }} <br />
                  <span v-if="pendingsale[0].NamaOpsiMakan"> {{ pendingsale[0].NamaOpsiMakan }} <br /></span>
                  <span v-if="pendingsale[0].WaitressName">Pelayan : {{ pendingsale[0].WaitressName }} <br /></span>
                  Kasir : {{ pendingsale[0].CreatedBy }} <br />
                  <span v-if="pendingsale[0].CustomerName">Pelanggan : {{ pendingsale[0].CustomerName }} <br /></span>
                  Perangkat ke-{{ pendingsale[0].DeviceNo }} <br />
                  Tanggal : {{ timesale }} {{ pendingsale[0].SaleTime }} <br />
                </p>
              </div>
              <table style="margin-top: 20px">
                <div>
                  <tbody v-for="(itemDetail, k) in pendingsaleitemdetail" v-bind:key="k">
                    <tr>
                      <td class="description" colspan="3">
                        {{ itemDetail.ItemName }} 
                        <span v-if="itemDetail.Discount != ''">
                          - Diskon 
                          <span v-if="itemDetail.Discount.indexOf('%') > 0"> {{ itemDetail.Discount }} </span>
                        </span>
                        </td>
                    </tr>
                    <tr>
                      <td class="quantity" colspan="2">
                        &nbsp;
                        {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }} X {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
                        <span v-if="itemDetail.Discount != ''"> - {{ $filters.numberTocurrencyIDR((itemDetail.Quantity * itemDetail.UnitPrice) - itemDetail.SubTotal) }}</span>
                      </td>
                      <td class="subtotal">
                        {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
                      </td>
                    </tr>
                    <tr v-for="(isiPaket, kk) in itemDetail.IsiPaket" v-bind:key="kk">
                      <td class="quantity" colspan="3">
                        &nbsp;&nbsp;&nbsp;&nbsp; >>
                        {{ $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity) }} {{ isiPaket.ProductName }} {{ isiPaket.VariantName ? '- '+isiPaket.VariantName : '' }}
                      </td>
                    </tr>
                    <tr v-if="itemDetail.IsiPaket.length > 0">
                      <td class="quantity" colspan="3">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </div>
              </table>

              <table style="margin-top: 20px">
                <tbody>
                  <tr>
                    <td class="subtotal">
                      <span v-if="pendingsalediscount.length > 0 || pendingsale[0].FinalDiscount">
                        Total Sebelum Diskon
                      </span>
                      <span v-else>
                        Total
                      </span>
                    </td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(totalcart) }} </td>
                  </tr>
                  <tr v-for="(saleDisc, d) in pendingsalediscount" v-bind:key="d">
                    <td class="subtotal">{{ saleDisc.DiscountName }} <span v-if="saleDisc.Discount.search('%') > 0">{{saleDisc.Discount}}</span></td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }} </td>
                  </tr>
                  <tr v-if="pendingsalediscount.length == 0 && pendingsale[0].FinalDiscount">
                    <td class="subtotal">Diskon Final <span v-if="pendingsale[0].FinalDiscount.toString().search('%') > 0">{{pendingsale[0].FinalDiscount}}</span></td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(valueDiscountFinal) }} </td>
                  </tr>
                  <tr v-if="pendingsaletaxexclude.length > 0 && (pendingsalediscount.length > 0 || pendingsale[0].FinalDiscount)">
                    <td class="subtotal">Total</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(beforeTax) }} </td>
                  </tr>
                  <tr v-for="(saleTaxEx, t) in pendingsaletaxexclude" v-bind:key="t">
                    <td class="subtotal"> {{ (saleTaxEx.name) }} </td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(saleTaxEx.val) }} </td>
                  </tr>
                  <tr v-if="pendingsale[0].Rounding">
                    <td class="subtotal">Pembulatan</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Rounding) }} </td>
                  </tr>
                  <tr v-if="(totalcart != (pendingsale[0].Total + (pendingsale[0].Rounding)))">
                    <td class="subtotal">Grand Total</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Total + (pendingsale[0].Rounding)) }} </td>
                  </tr>
                  <tr v-if="charge && payment && !payment2">
                    <td class="subtotal">Charge</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(charge) }}% </td>
                  </tr>
                  <tr v-if="paymentModeMsg1">
                    <td class="subtotal">{{ paymentModeMsg1 }}</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(payment) }} </td>
                  </tr>
                  <tr v-if="charge && payment2">
                    <td class="subtotal">Charge</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(charge) }}% </td>
                  </tr>
                  <tr v-if="paymentModeMsg2">
                    <td class="subtotal">{{ paymentModeMsg2 }}</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(payment2) }} </td>
                  </tr>
                  <tr v-if="pendingsale[0].Change">
                    <td class="subtotal">Kembali</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Change) }} </td>
                  </tr>
                  <tr v-if="pendingsaletaxinclude.length > 0">
                    <td class="subtotal">Sudah Termasuk</td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"></td>
                  </tr>
                  <tr v-for="(saleTaxIn, t) in pendingsaletaxinclude" v-bind:key="t">
                    <td class="subtotal"> {{ (saleTaxIn.name) }} </td>
                    <td class="quantity"> : </td>
                    <td class="subtotal"> {{ $filters.numberTocurrencyIDR(saleTaxIn.val) }} </td>
                  </tr>
                </tbody>
              </table>

              <p class="centered">
                <span v-if="options[0].FootNoteSatu"> {{ options[0].FootNoteSatu }} <br /> </span>
                <span v-if="options[0].FootNoteDua"> {{ options[0].FootNoteDua }} <br /> </span>
                <span v-if="options[0].FootNoteTiga"> {{ options[0].FootNoteTiga }} <br /> </span>
                <span v-if="options[0].FootNoteEmpat"> {{ options[0].FootNoteEmpat }} <br /> </span>
                powered by nutapos.com
              </p>
            </div>
            <!-- end print receipt template small -->

            <!-- start print receipt template large -->
            <div id="ticket-print-lg" style="height:100px;overflow: auto; display: none;">
              <table width="100%" v-if="logoReceipt">
                  <tr>
                    <td style="width: 20%; text-align: center;">
                      <img
                          v-if="logoReceipt"
                          :src="logoReceipt"
                          alt="Logo" style="width:120px; height:auto;"
                        />
                    </td>
                    <td style="width: 60%; text-align: left;">
                      <span style="font-size: 19px;">{{ options[0].CompanyName }}</span><br />
                      <span style="font-size: 15px;">{{ options[0].CompanyAddress }}</span><br />
                      <span style="font-size: 15px;">{{ options[0].CompanyPhone }}</span>
                    </td>
                    <td style="width: 20%;"></td>
                  </tr>
                </table>
                <table width="100%" v-else>
                  <tr>
                    <td style="text-align: left;">
                      <span style="font-size: 19px;">{{ options[0].CompanyName }}</span><br />
                      <span style="font-size: 15px;">{{ options[0].CompanyAddress }}</span><br />
                      <span style="font-size: 15px;">{{ options[0].CompanyPhone }}</span>
                    </td>
                  </tr>
                </table>
                <div style="width: 100%; font-size: 15px;">
                  <hr/>
                  <div style="width: 55%; float: left">
                    <table>
                        <tr>
                            <td>Nomor</td>
                            <td>:</td>
                            <td>{{ pendingsale[0].SaleNumber }}</td>
                        </tr>
                        <tr>
                            <td>Pelayan</td>
                            <td>:</td>
                            <td>{{ pendingsale[0].WaitressName }}</td>
                        </tr>
                        <tr>
                            <td>Kasir</td>
                            <td>:</td>
                            <td>{{ pendingsale[0].CreatedBy }}</td>
                        </tr>
                    </table>
                  </div>
                  <div style="width: 45%; float: left">
                    <table>
                        <tr>
                            <td>Tipe Penjualan</td>
                            <td>:</td>
                            <td>{{ pendingsale[0].NamaOpsiMakan }}</td>
                        </tr>
                        <tr>
                            <td>Tanggal</td>
                            <td>:</td>
                            <td>{{ timesale }} {{ pendingsale[0].SaleTime }}</td>
                        </tr>
                        <tr>
                            <td>Pelanggan</td>
                            <td>:</td>
                            <td>{{ pendingsale[0].CustomerName }}</td>
                        </tr>
                        <tr>
                            <td>Alamat/Phone</td>
                            <td>:</td>
                            <td>{{ stCustomer ? stCustomer.CustomerAddress : '' }} {{ stCustomer ? stCustomer.CustomerPhone : '' }}</td>
                        </tr>
                    </table>
                  </div>
                </div>  
                <table width="100%" style="border-collapse: collapse;">
                  <tr>
                      <th style="border: 1px solid black; padding: 5px;">NO</th>
                      <th style="border: 1px solid black; padding: 5px;">NAMA ITEM</th>
                      <th style="border: 1px solid black; padding: 5px;">QTY</th>
                      <th style="border: 1px solid black; padding: 5px;">HARGA SATUAN</th>
                      <th style="border: 1px solid black; padding: 5px;">DISKON</th>
                      <th style="border: 1px solid black; padding: 5px;">SUB TOTAL</th>
                  </tr>
                    <tr v-for="(itemDetail, k) in pendingsaleitemdetail" v-bind:key="k">
                      <td style=" border: 1px solid black; text-align: center; padding: 3px;">
                        {{ k + 1 }}
                      </td>
                      <td style=" border: 1px solid black; text-align: left; padding: 3px;">
                        {{ itemDetail.ItemName }} <br />
                        <div class="print-isipaket" v-for="(isiPaket, kk) in itemDetail.IsiPaket" v-bind:key="kk">
                          <span style="padding-left: 30px">
                            - {{ $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity) }} {{ isiPaket.ProductName }} {{ isiPaket.VariantName ? '- '+isiPaket.VariantName : '' }}
                          </span><br />
                        </div>
                      </td>
                      <td style=" border: 1px solid black; text-align: center; padding: 3px;">
                        {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }}
                      </td>
                      <td style=" border: 1px solid black; text-align: right; padding: 3px;">
                        {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
                      </td>
                      <td style=" border: 1px solid black; text-align: right; padding: 3px;">
                        {{ itemDetail.Discount }}
                      </td>
                      <td style=" border: 1px solid black; text-align: right; padding: 3px;">
                        {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
                      </td>
                    </tr>
                </table>
                <table width="100%">
                  <tr>
                    <td style="width: 60%"></td>
                    <td>
                      <span v-if="pendingsalediscount.length > 0 || pendingsale[0].FinalDiscount">
                        Total Sebelum Diskon
                      </span>
                      <span v-else>
                        Total
                      </span>
                    </td>
                    <td></td>
                    <td style="text-align:right">{{ $filters.numberTocurrencyIDR(totalcart) }}</td>
                  </tr>
                  <tr v-for="(saleDisc, d) in pendingsalediscount" v-bind:key="d">
                    <td style="width: 60%"></td>
                    <td>{{ saleDisc.DiscountName }} <span v-if="saleDisc.Discount.search('%') > 0">{{saleDisc.Discount}}</span></td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }} </td>
                  </tr>
                  <tr v-if="pendingsalediscount.length == 0 && pendingsale[0].FinalDiscount">
                    <td style="width: 60%"></td>
                    <td>Diskon Final <span v-if="pendingsale[0].FinalDiscount.toString().search('%') > 0">{{pendingsale[0].FinalDiscount}}</span></td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(valueDiscountFinal) }} </td>
                  </tr>
                  <tr v-if="pendingsaletaxexclude.length > 0 && (pendingsalediscount.length > 0 || pendingsale[0].FinalDiscount)">
                    <td style="width: 60%"></td>
                    <td>Total</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(beforeTax) }} </td>
                  </tr>
                  <tr v-for="(saleTaxEx, t) in pendingsaletaxexclude" v-bind:key="t">
                    <td style="width: 60%"></td>
                    <td> {{ (saleTaxEx.name) }} </td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(saleTaxEx.val) }} </td>
                  </tr>
                  <tr v-if="pendingsale[0].Rounding">
                    <td style="width: 60%"></td>
                    <td>Pembulatan</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Rounding) }} </td>
                  </tr>
                  <tr v-if="(totalcart != (pendingsale[0].Total + (pendingsale[0].Rounding)))">
                    <td style="width: 60%"></td>
                    <td>Grand Total</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Total + (pendingsale[0].Rounding)) }} </td>
                  </tr>
                  <tr v-if="charge && payment && !payment2">
                    <td style="width: 60%"></td>
                    <td>Charge</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(charge) }}% </td>
                  </tr>
                  <tr v-if="paymentModeMsg1">
                    <td style="width: 60%"></td>
                    <td>{{ paymentModeMsg1 }}</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(payment) }} </td>
                  </tr>
                  <tr v-if="charge && payment2">
                    <td style="width: 60%"></td>
                    <td>Charge</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(charge) }}% </td>
                  </tr>
                  <tr v-if="paymentModeMsg2">
                    <td style="width: 60%"></td>
                    <td>{{ paymentModeMsg2 }}</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(payment2) }} </td>
                  </tr>
                  <tr v-if="pendingsale[0].Change">
                    <td style="width: 60%"></td>
                    <td>Kembali</td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(pendingsale[0].Change) }} </td>
                  </tr>
                  <tr v-if="pendingsaletaxinclude.length > 0">
                    <td style="width: 60%"></td>
                    <td>Sudah Termasuk</td>
                    <td></td>
                    <td style="text-align:right"></td>
                  </tr>
                  <tr v-for="(saleTaxIn, t) in pendingsaletaxinclude" v-bind:key="t">
                    <td style="width: 60%"></td>
                    <td> {{ (saleTaxIn.name) }} </td>
                    <td></td>
                    <td style="text-align:right"> {{ $filters.numberTocurrencyIDR(saleTaxIn.val) }} </td>
                  </tr>
                </table>
                <table width="100%" style="padding-top:5px; padding-bottom: 20px">
                  <tr>
                    <td style="text-align:center">Penerima,</td>
                    <td style="text-align:center">Pengirim,</td>
                    <td style="text-align:center">Hormat Kami,</td>
                  </tr>
                  <tr>
                    <td style="text-align:center" colspan="3">&nbsp;</td>
                  </tr>
                  <tr>
                    <td style="text-align:center" colspan="3">&nbsp;</td>
                  </tr>
                  <tr>
                    <td style="text-align:center">
                      (_____________)
                    </td>
                    <td style="text-align:center">
                      (_____________)
                    </td>
                    <td style="text-align:center">
                      (_____________)
                    </td>
                  </tr>
                </table>
                <table width="100%">
                  <tr>
                    <td style="text-align:left">
                      {{ options[0].FootNoteSatu }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">
                      {{ options[0].FootNoteDua }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">
                      {{ options[0].FootNoteTiga }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">
                      {{ options[0].FootNoteEmpat }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">
                      powered by nutapos.com
                    </td>
                  </tr>
                </table>
            </div>
            <!-- end print receipt template large -->

          </div>
        </div>
      </div>
    </div>
    {{ comGetTransaction }}
    <!-- komponen modal saldo topup-->
    <SaldoModal />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from 'axios'
import $ from 'jquery'
import SaldoModal from "@/components/konfirmasi_modal/SaldoModal.vue";
export default {
  name: "PembayaranBerhasilModal_c",
  components: {
    SaldoModal,
  },
  data() {
    return {
      logoReceipt: null,
      paymentModeMsg: null,
      paymentModeMixMsg: null,
      bankMsg: null,
      changeMsg: "0",
      errorWaSts: false,
      errorWaMsg: "",
      errorEmailSts: false,
      errorEmailMsg: "",
      cAlertReceipt: "alert alert-danger fade show",
      alertReceipt: false,
      msgAlertReceipt: "",
      sendEmail: false,

      totalcart : 0,
      timesale: null,
      paymentModeMsg1: null,
      paymentModeMsg2: null,
      payment: 0,
      payment2: 0,
      pendingsale: [{}],
      pendingsalediscount: [],
      pendingsaletaxexclude: [],
      pendingsaletaxinclude: [],
      pendingsaleitemdetail: [],

      emailCust: null,
      nowaCust: null,
    };
  },
  computed: {
    ...mapGetters(["stTransactionID", "stTransactionSts", "options", "masterdigitalpayment", "outletID", "apiBaseUrl", "stCustomer", "connectionStatus", "wsBaseUrl"]),
    //menjalankan method untuk memanggil data transaksi 
    comGetTransaction(){
      return this.getTransaction()
    },
    //diskon final
    valueDiscountFinal() {
      let discFinal = 0;
      if(this.pendingsalediscount.length == 0){
        if(this.pendingsale.length){
          var dataDics = this.pendingsale[0].FinalDiscount.toString()
          if (dataDics.search('%') > 0) {
            discFinal = (this.totalcart / 100) * parseFloat(this.pendingsale[0].FinalDiscount.replace("%", ""));
          } else {
            discFinal = this.pendingsale[0].FinalDiscount;
          }
        }
      }
      return discFinal;
    },
    //total sebelum pajak exclude
    beforeTax() {
      let beforeTax = 0;
      var tot = this.totalcart
      if(this.pendingsalediscount.length > 0){
        this.pendingsalediscount.forEach(function (discOutlet) {
          if(discOutlet.Discount.search("%") > 0){
            tot = parseFloat(tot - ((tot / 100)* discOutlet.Discount.replace("%", "")));
          } else {
            tot = parseFloat(tot - discOutlet.Discount);
          }
        });
      }
      beforeTax = tot - this.valueDiscountFinal;
      return beforeTax;
    },
    //perhitungan charge
    charge(){
      var charge = 0
      var a = (parseFloat(this.pendingsale[0].CashDownPayment) + parseFloat(this.pendingsale[0].BankDownPayment) + parseFloat(this.pendingsale[0].CashPaymentAmount))
      var b = parseFloat((this.pendingsale[0].Total + this.pendingsale[0].Rounding)) - a;
      var c = parseFloat(this.pendingsale[0].BankPaymentAmount) - (parseFloat((this.pendingsale[0].Total + this.pendingsale[0].Rounding)) - a);
      var d = (c / b) * 100;
      if(d > 0 && b > 0) {
        charge = d
      } else {
        charge = 0
      }
      return charge
    },
  },
  methods: {
    ...mapActions(["actResetTransaction", "actSetOpsiMakanBegin"]),
    //cetak dan kirim receipt
    printAndSendReceipt(){
      this.sendReceiptEmail(true)
      this.sendReceiptWA(true)
      this.printReceipt()
    },
    //kirim receipt ke Email
    sendReceiptEmail(cannull = true){
      if(this.connectionStatus == "Online"){
        if(this.emailCust){
          if(this.emailCust.search('@') > 0){
            this.sendEmail = true
            this.errorEmailSts = false
            this.errorEmailMsg = ""
            new Promise((resolve, reject) => {
              $.ajax({
                //url: "http://localhost:70/ci-vue/get-crc32",
                url: axios.defaults.baseURL + "mailer/sendreceipt2",
                data: {
                  i: this.outletID,
                  s: (this.stTransactionID ? this.stTransactionID : ''),
                  e: this.emailCust,
                },
                method: "POST",
                success: (data) => {
                  this.sendEmail = false
                  if(data.status == "OK"){
                    this.cAlertReceipt = "alert alert-success fade show"
                    this.msgAlertReceipt = "Struk berhasil dikirim ke Email pelanggan"
                    this.alertReceipt = true
                    setTimeout(() => this.alertReceipt = false, 5000);
                  }
                  resolve(data)
                },
                error: (error) => {
                  this.sendEmail = false
                  reject(error)
                },
              })
            })  
          } else {
            this.errorEmailSts = true
            this.errorEmailMsg = "Format Email tidak valid"
          }     
        } else {
          if(cannull){
            this.errorEmailSts = true
            this.errorEmailMsg = "Email tidak boleh kosong"
          }
        }
      } else {
        this.cAlertReceipt = "alert alert-danger fade show"
        this.msgAlertReceipt = "Tidak terhubung ke internet, struk tidak dapat dikirim ke pelanggan"
        this.alertReceipt = true
        setTimeout(() => this.alertReceipt = false, 5000);
      }
    },
    //kirim receipt ke WA
    sendReceiptWA(cannull = true){
      if(this.connectionStatus == "Online"){
        if(this.nowaCust){
          this.errorWaSts = false
          this.errorWaMsg = ""

          var noWa = this.nowaCust.toString() // NoWA
          if(noWa.startsWith("0")){
            var l = noWa.length;
            var noWas = noWa.slice(1, l);
            var indCode = "62";
            noWa = indCode.concat(noWas);
          }    

          if(!noWa.startsWith("0")){

            var i = btoa(this.outletID) // DeviceID
            var e = "" // Email
            var s = btoa((this.stTransactionID ? this.stTransactionID : '')) // SaleNumber
            var theURL = encodeURIComponent("" + this.apiBaseUrl + "receipt/viewnew2?i=" + i + "&e=" + e + "&s=" + s + "")
            //var theURL = "https://api-dev.nutadev.com/receipt/viewnew2?i=MzI0Mw==&e=&s=MTAzOC41MA=="
            var text = ""
            var textFooter = ""
            var textFinal = ""
            text += "Terima kasih telah berbelanja di " + (this.options ? this.options[0].CompanyName : '') + " dengan total " + this.numberTocurrencyIDR(this.pendingsale ? (this.totalcart != (this.pendingsale[0].Total + (this.pendingsale[0].Rounding)) ? (this.pendingsale[0].Total + (this.pendingsale[0].Rounding)) : this.totalcart) : 0) + ". \n\n"
            text += "Nomor Nota : " + (this.pendingsale ? this.pendingsale[0].SaleNumber : '') + " \n"
            //text += "Makan di Tempat \n\n"

            if (this.pendingsale && (this.pendingsale[0].WaitressName  !== '')) {
              text += `Waitress : ${this.pendingsale[0].WaitressName} \n`
            }
            
            text += "Kasir : " + (this.pendingsale ? this.pendingsale[0].CreatedBy : '') + " \n"
            text += "Tanggal : " + this.timesale + " " + (this.pendingsale ? this.pendingsale[0].SaleTime : '') + " \n\n" 
            
            const itemdetail = [...this.pendingsaleitemdetail]
            itemdetail.forEach((isiItem) => {
              text += isiItem.ItemName + " " + (isiItem.Discount != '' ? '- Diskon ' + (isiItem.Discount.indexOf('%') > 0 ? (isiItem.Discount) : '') : '') + " \n"
              text += this.numberTocurrencyIDR(isiItem.Quantity) + " x " + this.numberTocurrencyIDR(isiItem.UnitPrice) + "" + (isiItem.Discount != ''? ' - ' + this.numberTocurrencyIDR((isiItem.Quantity * isiItem.UnitPrice) - isiItem.SubTotal) : '') + " = " + this.numberTocurrencyIDR(isiItem.SubTotal) + " \n"
              
              const itemdetailpaket = [...isiItem.IsiPaket]
              itemdetailpaket.forEach((isiPaket) => {
                text += "  >> " + this.numberTocurrencyIDR(isiPaket.QtyUsed * isiItem.Quantity) + " " + isiPaket.ProductName + " " + (isiPaket.VariantName ? '- '+ isiPaket.VariantName : '') + " \n"
              });
              text += "\n"
            });
            if(this.pendingsale){
              if(this.pendingsalediscount.length > 0 || this.pendingsale[0].FinalDiscount){
                text += "Total Sebelum Diskon : " + this.numberTocurrencyIDR(this.totalcart) +" \n"
              } else {
                text += "Total : " + this.numberTocurrencyIDR(this.totalcart) +" \n"
              }
            }
            const salediscount = [...this.pendingsalediscount] 
            salediscount.forEach((isiDisctOutlet) => {
              text += isiDisctOutlet.DiscountName + " " + (isiDisctOutlet.Discount.search('%') > 0 ? isiDisctOutlet.Discount :  '') + " : " + this.numberTocurrencyIDR(isiDisctOutlet.DiscountValue) + " \n" 
            });
            if(this.pendingsalediscount.length == 0 && this.pendingsale){
              if(this.pendingsale[0].FinalDiscount){
                text += "Diskon Final " + (this.pendingsale[0].FinalDiscount.toString().search('%') > 0 ? this.pendingsale[0].FinalDiscount : '') + ": " + this.numberTocurrencyIDR(this.valueDiscountFinal) + " \n"
              }
            }
            if(this.pendingsale){
              if(this.pendingsaletaxexclude.length > 0 && (this.pendingsalediscount.length > 0 || this.pendingsale[0].FinalDiscount)){
                text += "Total : " + this.numberTocurrencyIDR(this.beforeTax) +" \n"
              }
            }
            const saletaxexclude = [...this.pendingsaletaxexclude]
            saletaxexclude.forEach((isiTaxEx) => {
              text += isiTaxEx.name + " : " + this.numberTocurrencyIDR(isiTaxEx.val) + " \n"
            });
            if(this.pendingsale){
              if(this.pendingsale[0].Rounding){
                text += "Pembulatan : " + this.numberTocurrencyIDR(this.pendingsale[0].Rounding) + " \n" 
              }
            }
            if(this.pendingsale){
              if(this.totalcart != (this.pendingsale[0].Total + (this.pendingsale[0].Rounding))){
                text += "Grand Total : " + this.numberTocurrencyIDR(this.pendingsale[0].Total + (this.pendingsale[0].Rounding)) + " \n"
              }
            }
            if(this.charge && this.payment && !this.payment2){
              text += "Charge : " + this.numberTocurrencyIDR(this.charge) + "% \n" 
            }
            if(this.paymentModeMsg1){
              text += this.paymentModeMsg1 + " : " + this.numberTocurrencyIDR(this.payment) + " \n"
            } 
            if(this.charge && this.payment2){
              text += "Charge : " + this.numberTocurrencyIDR(this.charge) + "% \n" 
            }
            if(this.paymentModeMsg2){
              text += this.paymentModeMsg2 + " : " + this.numberTocurrencyIDR(this.payment2) + " \n"
            }
            if(this.pendingsale){ 
              if(this.pendingsale[0].Change){ 
                text += "Kembali : " + this.numberTocurrencyIDR(this.pendingsale[0].Change) + " \n" 
              }
            }
            if(this.pendingsaletaxinclude.length > 0){
              text += "Sudah Termasuk : \n"
            }
            const saletaxinclude = [...this.pendingsaletaxinclude]
            saletaxinclude.forEach((isiTaxIn) => {
              text += isiTaxIn.name + " : " + this.numberTocurrencyIDR(isiTaxIn.val) + " \n"
            });
            
            text += "\n"
            text += "Lihat struk dan beri feedback di \n" 

            textFooter += "\n" 
            textFooter += "\n"
            textFooter += "Balas pesan ini dengan mengetik OK, agar link di atas bisa diklik sehingga kamu bisa memberikan saran dan kritik atas outlet ini." 
            
            textFinal += encodeURI(text)
            textFinal += theURL
            textFinal += encodeURI(textFooter)

            //console.log(textFinal);
            //console.log('https://wa.me/'+ noWa +'?text=' + encodeURI(text));
            //https://wa.me/6281215621810?text=Saya%20tertarik%20dengan%20mobil%20Anda%20yang%20dijual

            window.open('https://wa.me/'+ noWa +'?text=' + textFinal,'_blank');
          }
        } else {
          if(cannull){
            this.errorWaSts = true
            this.errorWaMsg = "Nomor WhatsApp tidak boleh kosong"
          }
        }
      } else {
        this.cAlertReceipt = "alert alert-danger fade show"
        this.msgAlertReceipt = "Tidak terhubung ke internet, struk tidak dapat dikirim ke pelanggan"
        this.alertReceipt = true
        setTimeout(() => this.alertReceipt = false, 5000);
      }
    },
    //get data transaksi 
    getTransaction(){
      var stTransactionSts = this.stTransactionSts
      var stTransactionID = this.stTransactionID
      this.paymentModeMsg = null
      this.paymentModeMixMsg = null
      this.bankMsg = null
      this.changeMsg = "0"
      if(stTransactionSts == "success"){
        if(this.stCustomer){
          this.emailCust = this.stCustomer.CustomerEmail
          this.nowaCust = this.stCustomer.CustomerPhone
        }
        var tempDataOptions = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
        tempDataOptions = tempDataOptions.filter((opt) => {
          return (opt.OnlineImagePath != "");
        });
        if(tempDataOptions.length > 0){
          this.logoReceipt = this.wsBaseUrl + tempDataOptions[0].OnlineImagePath
        } else {
          var tempLocalImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
          tempLocalImage = tempLocalImage.filter((loc) => {
            return (loc.Table == "options" && loc.TableID == "1");
          });
          if(tempLocalImage.length > 0){
            this.logoReceipt = tempLocalImage[0].Image
          }
        }

        let tempDataTr = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
        let tempFilterTr = tempDataTr.filter((tr) => {
          return (
            tr.TransactionID + "." + tr.DeviceNo == stTransactionID
          );
        });
        if(tempFilterTr.length > 0){
          let tempTr = tempFilterTr[0];
          var paymentMode = tempTr.PaymentMode.toString();
          if(paymentMode.substring(0, 1) == "1"){
            this.paymentModeMsg = "Pembayaran Tunai"
            this.changeMsg = tempTr.Change ? tempTr.Change : "0"
          } else if(paymentMode.substring(0, 1) == "2"){
            this.bankMsg = tempTr.CashBankAccountName.replaceAll('#', ' ')
            if(paymentMode.substring(0, 2) == "21"){
              var dgPayment = this.masterdigitalpayment.find((b) => b.DigitalPaymentID == paymentMode.substring(2, 4));
              this.paymentModeMsg = "Pembayaran Dompet Digital - " + dgPayment.QRName
            } else if(paymentMode == "22"){
              this.paymentModeMsg = "Pembayaran EDC"
            } else if(paymentMode == "23"){
              this.paymentModeMsg = "Pembayaran Transfer"
            } else if(paymentMode == "24"){
              this.paymentModeMsg = "Pembayaran QR Statis"
            }
          } else if(paymentMode.substring(0, 1) == "3"){
            this.paymentModeMsg = "Pembayaran Campuran"
            if(paymentMode.substring(0, 2) == "31"){
              this.paymentModeMixMsg = "Tunai & Dompet Digital"
            } else if(paymentMode == "32"){
              this.paymentModeMixMsg = "Tunai & EDC"
            } else if(paymentMode == "33"){
              this.paymentModeMixMsg = "Tunai & Transfer"
            } else if(paymentMode == "34"){
              this.paymentModeMixMsg = "Tunai & QR Statis"
            }
          }
          this.dataReceipt()
        }
      }
    },
    //get data transaction for receipt
    dataReceipt() {
      if(this.stTransactionID){
        var stTransactionID = this.stTransactionID
        // get data sale
        var sale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
        var saleData = sale.filter((item) => {
          return (item.TransactionID + "." + item.DeviceNo == stTransactionID);
        });
        // get data sale discount
        var saleDiscount = localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [];
        var saleDiscountData = saleDiscount.filter((disc) => {
          return (disc.TransactionID + "." + disc.TransactionDeviceNo == stTransactionID);
        });
        // get data sale item detail
        var saleItemDetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
        var saleItemDetailData = saleItemDetail.filter((items) => {
          return (items.TransactionID + "." + items.TransactionDeviceNo == stTransactionID);
        });
        // get data sale item detail product(isi paket)
        var saleItemProduct = localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [];
        this.totalcart = 0
        for(var d=0; saleItemDetailData.length > d; d++){
            //var detailarr = [];
            this.totalcart += parseFloat(saleItemDetailData[d].SubTotal);

            var saleItemProductData = saleItemProduct.filter((itemsprod) => {
              return (itemsprod.TransactionID + "." + itemsprod.TransactionDeviceNo == stTransactionID && itemsprod.DetailID == saleItemDetailData[d].DetailID && itemsprod.DetailDeviceNo == saleItemDetailData[d].DeviceNo);
            });
            saleItemDetailData[d].IsiPaket = saleItemProductData;
        }
        if(saleData.length > 0){
          var taxex = saleData[0].DetailExcludeTaxValues.split("#");
          this.pendingsaletaxexclude = []
          for(var i=0; taxex.length > i; i++){
              if(taxex[i]){
                  var taxexarr = {
                      name: taxex[i].split("$")[0],
                      val: taxex[i].split("$")[1],
                  };
                  this.pendingsaletaxexclude.push(taxexarr);
              }
          }

          var taxin = saleData[0].DetailIncludeTaxValues.split("#");
          this.pendingsaletaxinclude = []
          for(var tin=0; taxin.length > tin; tin++){
              if(taxin[tin]){
                  var taxinarr = {
                      name: taxin[tin].split("$")[0],
                      val: taxin[tin].split("$")[1],
                  };
                  this.pendingsaletaxinclude.push(taxinarr);
              }
          }

          // metode pembayaran
          if(saleData.length > 0){
            let tempTr = saleData[0];
            var paymentMode = tempTr.PaymentMode.toString();
            if(paymentMode.substring(0, 1) == "1"){
              this.paymentModeMsg1 = "Bayar Tunai"
              this.payment = saleData[0].CashPaymentAmount
            } else if(paymentMode.substring(0, 1) == "2"){
              this.payment = saleData[0].BankPaymentAmount
              if(paymentMode.substring(0, 2) == "21"){
                this.paymentModeMsg1 = "Bayar Dompet Digital"
              } else if(paymentMode == "22"){
                this.paymentModeMsg1 = "Bayar EDC"
              } else if(paymentMode == "23"){
                this.paymentModeMsg1 = "Bayar Transfer"
              } else if(paymentMode == "24"){
                this.paymentModeMsg1 = "Bayar QR Statis"
              }
            } else if(paymentMode.substring(0, 1) == "3"){
              this.payment = saleData[0].CashPaymentAmount
              this.payment2 = saleData[0].BankPaymentAmount
              this.paymentModeMsg1 = "Bayar Tunai"
              if(paymentMode.substring(0, 2) == "31"){
                this.paymentModeMsg2 = "Bayar Dompet Digital"
              } else if(paymentMode == "32"){
                this.paymentModeMsg2 = "Bayar EDC"
              } else if(paymentMode == "33"){
                this.paymentModeMsg2 = "Bayar Transfer"
              } else if(paymentMode == "34"){
                this.paymentModeMsg2 = "Bayar QR Statis"
              }
            }
          }

          this.timesale = this.convertDateDBtoIndo(saleData[0].SaleDate);
        }
        this.pendingsale = saleData;
        this.pendingsalediscount = saleDiscountData;
        this.pendingsaleitemdetail = saleItemDetailData;
      }
    },
    convertDateDBtoIndo(string) {
        var bulanIndo = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep' , 'Okt', 'Nov', 'Des'];
    
        var tanggal = string.split("-")[2];
        var bulan = string.split("-")[1];
        var tahun = string.split("-")[0];
    
        return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
    // on click dismiss modal
    dismissModalBerhasilPembayaran(){
      this.$store.dispatch("actResetTransaction")
      this.$store.dispatch("actSetOpsiMakanBegin")
      this.resetDataReceipt()
      $("#modalPembayaranBerhasil").modal('hide');
      var billingType = this.options[0].billing_type;
      var balance = parseFloat(this.options[0].topup_balance);
      var warningBalance = parseFloat(this.options[0].warning_balance);
      if(billingType == "2") { 
        if(balance >= warningBalance) {
          $("#modalSaldo").modal('hide')
        } else {
          setTimeout(() => $("#modalSaldo").modal("show") , 500);
        }
      } 
      
    },
    // cetak struk
    printReceipt(){
      var templateData = localStorage.getItem("receipttemplate") ? JSON.parse(localStorage.getItem("receipttemplate")) : [];
      if(templateData.length > 0){
        if(templateData[0].template == "small"){
          this.printReceiptSm()
        } else if(templateData[0].template == "big"){
          this.printReceiptLg()
        }
      } else {
        this.printReceiptSm()
      }
    },
    // cetak struk small
    printReceiptSm(){
      /*let routeData = this.$router.resolve({
          name: 'Cetak Struk', 
          query: {TransactionID: this.stTransactionID}
        });
        window.open(routeData.href, '_blank');*/

        // Get HTML to print from element
        const prtHtml = document.getElementById('ticket-print').innerHTML;

        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          td,
          th,
          tr,
          table {
            /*border: 1px solid black;*/
            border-collapse: collapse;
            width: 100%;
          }

          td.description {
            text-align: left;
          }

          td.quantity {
            text-align: left;
          }

          td.subtotal {
            text-align: right;
          }

          .centered {
            text-align: center;
            align-content: center;
          }

          .ticket {
            width: 280px;
            max-width: 280px;
          }

          img {
            max-width: inherit;
            width: inherit;
          }
          .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 110px;
            width: auto;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        //WinPrint.close();
        setTimeout(() => WinPrint.close(), 1000);

        return true;
    },
    // cetak struk big
    printReceiptLg(){
      const prtHtml = document.getElementById('ticket-print-lg').innerHTML;

        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        //WinPrint.close();
        setTimeout(() => WinPrint.close(), 1000);

        return true;
    },
    //reset data receipt
    resetDataReceipt(){
      this.paymentModeMsg = null
      this.paymentModeMixMsg = null
      this.bankMsg = null
      this.changeMsg = "0"
      this.errorWaSts = false
      this.errorWaMsg = ""
      this.errorEmailSts = false
      this.errorEmailMsg = ""
      this.cAlertReceipt = "alert alert-danger fade show"
      this.alertReceipt = false
      this.msgAlertReceipt = ""
      this.sendEmail = false

      this.totalcart = 0
      this.timesale = null
      this.paymentModeMsg1 = null
      this.paymentModeMsg2 = null
      this.payment = 0
      this.payment2 = 0
      this.pendingsale = [{}]
      this.pendingsalediscount = []
      this.pendingsaletaxexclude = []
      this.pendingsaletaxinclude = []
      this.pendingsaleitemdetail = []

      this.emailCust = null
      this.nowaCust = null
    },
    //input hanya angka
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    //convert format rupiah
    numberTocurrencyIDR(value) {
      var a = value;
      var hasMinus = "";
      if ("" != a && null != a) {
        a = a.toString();
        hasMinus = a.search('-')
        a = a.replace("-", "")
        a = a.split(".");
        for (var b = "", c = 0, d = a[0].length; 0 < d; d--)
          c += 1, b = 1 == c % 3 && 1 != c ? a[0].substr(d - 1, 1) + "." + b : a[0].substr(d - 1, 1) + b;
        1 < a.length && (b = 0 < a[1].length ? b + ("," + a[1].substr(0, 2)) : b + ",");
        return (hasMinus == "" ? "-" + b : b);
      }
      return 0;
    },
  },
  mounted() {
    
  },
};
</script>

<style scoped>
td,
th,
tr,
table {
  /*border: 1px solid black;*/
  border-collapse: collapse;
  width: 100%;
}

td.description {
  text-align: left;
}

td.quantity {
  text-align: left;
}

td.subtotal {
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 280px;
  max-width: 280px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 110px;
  width: auto;
}

@media print {

}
</style>