<template>
  <!-- Modal konfirmasi hapus data cart-->
  <div id="modalHapusPelanggan" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Konfirmasi</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="hapus">
        
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12">
              <p style="text-align: left">
                Apakah Anda yakin untuk menghapus pelanggan {{namapelanggan}} ?
              </p>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <label 
                        v-on:click="kembali"
                        class="control-label-bold col-7 text-green ml-auto" 
                        style="margin-top:9px;text-align: right !important;"
                      >
                      Batal
                  </label>
                </div>
                <div class="col-6">
                  <button 
                    class="col-10 btn btn-green btn-block ml-auto"
                    style="margin-right:17px;"
                    :disabled="submitted"
                    >
                    <span v-html="btnSimpan"></span>  
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import $ from "jquery";
export default {
  name: "KonfirmHapusPelanggan",
  data() {
    return {
      btnSimpan:"Ya",
      submitted: false,
    };
  },
  computed: {
    ...mapGetters(["stCustomer"]),
    namapelanggan(){
      if (this.stCustomer !== null ){
          return this.stCustomer.CustomerName
      }
      else{
          return ""
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions(["deleteCustomer"]),
    hapus(){
      this.btnSimpan = "<div class='fa fa-circle-notch fa-spin'></div>";
      this.$store
        .dispatch("deleteCustomer", this.stCustomer)
        .then((response) => {
          if (response.error==false) 
          {
            this.btnSimpan = "Ya";
            $('#alert-update-delete3, #alert-update-delete alert-success').show();
            setTimeout(function() {
                $('#alert-update-delete3, #alert-update-delete alert-success').hide();
            }, 2000);
            $("#modalHapusPelanggan").modal("hide");
            $("#modalPelanggan").modal("show");
          }
        })
        .catch(() => {
          this.btnSimpan = "Ya";
        });
    },
    kembali(){
        $("#modalPelanggan").modal("show");
        $("#modalHapusPelanggan").modal("hide");
    },
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>