<template>
  <div>
    <!-- Modal data produk-->
    <div id="ringkasanpenjualan"  class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="card card0" style="width:60%;height:620px;margin-top:5%;margin-left:20%;background-color:#fff">
      <div class="atas">
      </div>
      <div class="">
        <div class="modal-dialog modal-lg">
        <div class="modal-content" style="width:90%;height:530px;margin-top:30px;border-radius:10px;border:0px">
          <div class="modal-body">
              <div class="row py-2" >  
               <img src="/close_outlet.png" class="iconclose" />
                
              </div>
              <div class="text-center" ><strong>Ringkasan Penjualan</strong></div>
              <div class="text-center" >{{data.dataCloseOutlet.OpenDate}} - {{data.dataCloseOutlet.OpenTime}}  - Sekarang Total Omset {{$filters.numberTocurrencyIDR(data.dataPenjualan.TotalPenjualan[0].Total)}}
              </div>
                <div class="row">
                  <div class="col-12 scrollable">
                    <table class="table">
                      <tbody style="cursor: pointer;">
                        <tr v-for="(cartData, k) in data.dataPenjualan.SalesSummary" v-bind:key="k">
                          <td class="text-left">{{cartData.Item}}</td>
                          <td class="text-left">{{cartData.Qty}}</td>
                          <td class="text-right">Rp</td> 
                          <td class="text-right">{{ $filters.numberTocurrencyIDR(cartData.Total)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
                <div class="text-right">
                  <label 
                    v-on:click="kembali"
                    class="control-label-bold col-7 text-green ml-auto" 
                    style="margin-top:9px;text-align: right !important;"
                  >
                  Kembali
                  </label>
                </div>
          </div>          
          </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      data: {
          dataCloseOutlet: {
            OpenID:"",
            OpenDate:"",
            OpenTime:"",
            OpenedBy:"",
            CloseDate:"",
            CloseTime:"",
            ClosedBy:"",
            Varian:"",
            DeviceID:"",
            DeviceNo:"",
            PerusahaanNo:"",
            PerusahaanID:"",
            RowVersion:"",
            CreatedVersionCode:"",
            EditedVersionCode:"",
            TglJamUpdate:"",
            StartingCash:"",
            TotalSales:"",
            TotalCashSales:"",
            OtherIncome:"",
            Expense:"",
            Taken:"",
            Withdrawal:"",
            ExpectedBalance:"",
            ActualBalance:"",
            Difference:"",
            StartingCashOri:""
        },
        dataPenjualan:{
            ModalAwal:0,
            PemasukanLain:0,
            TotalPengeluaran:0,
            TotalPenjualan:[{
                Total:0,
                Detail: null
            }]
        }
      }
    };
  },
  name: "RingkasanPenjualan",
  props: {
    datapenjualan : Object,
    dataPageHistory: String
  },
  watch: {
    datapenjualan: function(){
      this.showdata()      
    }
  },
  methods: {
    showdata(){
        this.data =  this.datapenjualan;
    },
    kembali(){
        $("#ringkasanpenjualan").modal("hide");
        if(this.dataPageHistory == "riwayat-tutup-outlet") {
          setTimeout(() => $("#modalDetailRiwayatTutupOutlet").modal("show"), 500);
        } else if(this.dataPageHistory == "tutup-outlet") {
          setTimeout(() => $("#modalTutupOutlet").modal("show"), 500);
        }
    },
  }
 };
</script>
<style scoped>

.scrollable {
  margin-top:10px;
  height: 430px;
  overflow-y: auto;
}

.th-prod {
  position: sticky;
  background-color:#00ae2b;
  z-index: 2;
  top: 0;
}
.atas{
   background-color:#ccefd4;
   width:100%;
   height:150px;
   border-radius: 0px 0px 80px 80px;
   position:absolute;
}
.iconclose{
  position:absolute;
  margin:-50px 0px 0px 45%;
  width :60px;
  height:60px;
}
.error {
    font-size: 0.5em;
    transform: translateY(-50%) translateX(-9%);
    color:red;
}
</style>