import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Kasir_v.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/loginperusahaan',
    name: 'Login Perusahaan',
    component: () => import('../views/LoginPerusahaan.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/loginkasir',
    name: 'Login Kasir',
    component: () => import('../views/LoginKasir.vue')
  },
  {
    path: '/selectingoutlet',
    name: 'Pilih Outlet',
    component: () => import('../views/SelectingOutlet.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/downloaddata',
    name: 'Download Data',
    component: () => import('../views/DownloadData.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/kasir',
    name: 'Kasir',
    component: () => import('../views/Kasir_v.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/Setting_v.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/openoutlet',
    name: 'Open Outlet',
    component: () => import('../views/OpenOutlet.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/riwayatpenjualan',
    name: 'Riwayat Penjualan',
    component: () => import('../views/RiwayatPenjualan.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/uangmasuk',
    name: 'Uang Masuk',
    component: () => import('../views/UangMasuk.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/uangkeluar',
    name: 'Uang Keluar',
    component: () => import('../views/UangKeluar.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/printreceipt',
    name: 'Cetak Struk',
    component: () => import('../views/PrintReceipt.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('../views/ForbiddenPage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => { 
  if (to.matched.some(record => record.meta.auth)) {
      if (store.getters.isLoggedIn == "true" || store.getters.isLoggedIn == true) {
        if(store.getters.isLoggedInKasir == "true" || store.getters.isLoggedInKasir == true){
          next()
          return
        } else {
          next('/loginkasir')
        }
      } else {
        next('/loginperusahaan')
      }
  }

  /*if (to.matched.some(record => record.meta.guest)) {
    if (!store.getters.isLoggedIn) {
      next()
      return
    }
    next('/profile')
  }*/

  next()
})

export default router
