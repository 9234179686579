/* eslint-disable */ //disabled eslint compiler
// @ is an alias to /src
import axios from 'axios'
export default ({
    state: {
        stCart: [],
        stTotalCart: 0,
        stTotalBeforeRounding: 0,
        stTotalRounding: 0,
        stTotalHeader: 0,
        stDiscountOutlet: [],
        stDiscountFinal: null,
        stDiscountValue: 0,
        stTypeDiscount: "yes",
        stTax: localStorage.getItem("setTax") ? JSON.parse(localStorage.getItem("setTax")) : [],
        stTotalTaxExclude: 0,
        stTotalTaxInclude: 0,
        stTransactionID: 0,
        stTransactionSts: null,
        stCustomer: null,
        stWaitress: null,
        stOpsiMakan: null,
        stJenisTransaksi: "bayar",
        stOpenTransaction: null,
        stRiwayatPenjualan: [],
        stDetailRiwayatPenjualan: [],
        stRiwayatUangMasuk: [],
        stRiwayatUangKeluar: [],
        stOpenUangMasuk: null,
        stOpenUangKeluar: null,
        stSaleDate: null,
        stSaleTime: null,
    },
    getters: {
        stCart: state => state.stCart,
        stTotalCart : state => state.stTotalCart,
        stDiscountOutlet: state => state.stDiscountOutlet,
        stDiscountFinal: state => state.stDiscountFinal,
        stDiscountValue: state => state.stDiscountValue,
        stTypeDiscount: state => state.stTypeDiscount,
        stTax: state => state.stTax,
        stCustomer: state => state.stCustomer,
        stTotalBeforeRounding: state => state.stTotalBeforeRounding,
        stTotalRounding: state => state.stTotalRounding,
        stTotalHeader: state => state.stTotalHeader,
        stTotalTaxExclude: state => state.stTotalTaxExclude,
        stTotalTaxInclude: state => state.stTotalTaxInclude,
        stTransactionID: state => state.stTransactionID,
        stTransactionSts: state => state.stTransactionSts,
        stWaitress: state => state.stWaitress,
        stOpsiMakan: state => state.stOpsiMakan,
        stJenisTransaksi: state => state.stJenisTransaksi,
        stOpenTransaction: state => state.stOpenTransaction,
        stRiwayatPenjualan: state => state.stRiwayatPenjualan,
        stDetailRiwayatPenjualan: state => state.stDetailRiwayatPenjualan,
        stRiwayatUangMasuk: state => state.stRiwayatUangMasuk,
        stRiwayatUangKeluar: state => state.stRiwayatUangKeluar,
        stOpenUangMasuk: state => state.stOpenUangMasuk,
        stOpenUangKeluar: state => state.stOpenUangKeluar,
        stSaleDate: state => state.stSaleDate,
        stSaleTime: state => state.stSaleTime,
    },
    actions: {
        async actOpenUangMasuk({ rootState, commit }, data) {
            if(data == null) {
                commit("mutOpenUangMasuk", null);
            } else {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: axios.defaults.baseURLWS + "wsfinance/loadCashBankIn",
                        data: {
                            devid: rootState.auth.outletID,
                            transactionid: data.TransactionID,
                            devno: data.DeviceNo
                        },
                        method: "POST",
                        success: (data) => {
                            if (data.status == "OK") {
                                commit("mutOpenUangMasuk", data.data);
                            }
                            resolve(data)
                        },
                        error: () => {
                            reject(error)
                        },
                    });
                });
            }
        },
        async actOpenUangKeluar({ rootState, commit }, data) {
            if(data == null) {
                commit("mutOpenUangKeluar", null);
            } else {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: axios.defaults.baseURLWS + "wsfinance/loadCashBankOut",
                        data: {
                            devid: rootState.auth.outletID,
                            transactionid: data.TransactionID,
                            devno: data.DeviceNo
                        },
                        method: "POST",
                        success: (data) => {
                            if (data.status == "OK") {
                                commit("mutOpenUangKeluar", data.data);
                            }
                            resolve(data)
                        },
                        error: () => {
                            reject(error)
                        },
                    });
                });
            }
        },
        async actGetRiwayatUangMasuk({ rootState, commit }, data){
            $.ajax({
                url: axios.defaults.baseURLWS + "report/getcashbankinhistory",
                data: {
                    devid: rootState.auth.outletID,
                    devno: rootState.auth.deviceNo,
                    dt1: data.dt1,
                    dt2: data.dt2,
                },
                method: "POST",
                success: (data) => {
                    commit('mutRiwayatUangMasuk', data)
                },
                error: () => {
                    //commit('reset_getfcmnotreceived')
                },
            });
        },
        async actGetRiwayatUangKeluar({ rootState, commit }, data){
            $.ajax({
                url: axios.defaults.baseURLWS + "report/getcashbankouthistory",
                data: {
                    devid: rootState.auth.outletID,
                    devno: rootState.auth.deviceNo,
                    dt1: data.dt1,
                    dt2: data.dt2,
                },
                method: "POST",
                success: (data) => {
                    commit('mutRiwayatUangKeluar', data)
                },
                error: () => {
                    //commit('reset_getfcmnotreceived')
                },
            });
        },
        async actHapusOrder({ rootState, commit, dispatch }, data) {
            //push to localstorage pendingsaledelete
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var filterPendingSale = pendingsale.filter((el) => {
                return (el.TransactionID == data.TransactionID && el.DeviceNo == data.DeviceNo);
            });
            if(filterPendingSale) {
                filterPendingSale[0].SynMode= 1
                var pendingsaledelete = localStorage.getItem("pendingsaledelete") ? JSON.parse(localStorage.getItem("pendingsaledelete")) : [];
                pendingsaledelete.push(filterPendingSale)
                localStorage.setItem('pendingsaledelete', JSON.stringify(pendingsale))
                if(rootState.connectionStatus == "Online"){
                    var gt = data.TransactionID +'.'+ data.DeviceNo
                    dispatch('actSyncDataSaleDelete', gt)
                }
            }
        },
        async actGetRiwayatPenjualan({ rootState, commit }, data){
            $.ajax({
                url: axios.defaults.baseURLWS + "report/getsaleshistory",
                data: {
                    devid: rootState.auth.outletID,
                    dt1: data.dt1,
                    dt2: data.dt2,
                },
                method: "POST",
                success: (data) => {
                    commit('mutRiwayatPenjualan', data)
                },
                error: () => {
                    //commit('reset_getfcmnotreceived')
                },
            });
        },
        async actGetDetailRiwayatPenjualan({ rootState, commit }, data){
            data.DeviceID = rootState.auth.outletID
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "wssale/loadSaleCloudReadOnly2",
                    data: {
                        devicesender: rootState.auth.outletID,
                        data: JSON.stringify(data)
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            commit('mutDetailRiwayatPenjualan', data)
                        }
                        resolve(data)
                    },
                    error: () => {
                        reject(error)
                    },
                });
            });
        },
        async actOpenTransaction({ state, rootState, dispatch, commit }, data) {
            dispatch('actResetTransaction');
            commit("mutOpenTransaction", data);
            var SaleDateTime = new Date(data.SaleDate+" "+data.SaleTime)
            var CreatedDateTime = new Date(data.CreatedDate+" "+data.CreatedTime)
            var EditedDateTime = new Date(data.EditedDate+" "+data.EditedTime)
            var diffMs = SaleDateTime - CreatedDateTime
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
            if(data.EditedDate != "") {
                diffMs = SaleDateTime - EditedDateTime
                diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
            }
            
            var itemDetail = rootState.items.pendingsaleitemdetail.filter((detail) => {
                return (detail.TransactionID == data.TransactionID && detail.TransactionDeviceNo == data.DeviceNo);
            });
            if(itemDetail) {
                itemDetail.forEach(el => {
                    var getItem = rootState.items.products.find((b) => b.ItemID == el.ItemID && b.DeviceNo == el.ItemDeviceNo);
                    var rowDisc = el.Discount
                    var searchPercent = rowDisc.search("%")
                    state.stCart.push({ 
                        DetailID: el.DetailID,
                        DetailDeviceNo: el.DeviceNo,
                        ItemID: el.ItemID, 
                        DeviceNo: el.ItemDeviceNo, 
                        CategoryID: getItem.CategoryID, 
                        CategoryDeviceNo: getItem.CategoryDeviceNo, 
                        ItemName: el.ItemName, 
                        Unit: getItem.Unit, 
                        SellPrice: el.UnitPrice, 
                        PurchasePrice: getItem.PurchasePrice,
                        qty: el.Quantity, 
                        diskon: rowDisc == "" ? 0 : rowDisc, 
                        jenisDiskon: rowDisc == "" ? '%' : (searchPercent >= 0 ? '%' : 'Rp'), 
                        /*markupPercent: 0,
                        markupValuePerItem: 1000,
                        markupRounding: 0,
                        shareRevenuePercent: 1000,
                        shareRevenueValuePerItem: 0,*/
                        subtotal: el.SubTotal
                    });
                });
            }
            if(data.OpsiMakanID != '0'){
                var getOpsiMakan = rootState.items.masteropsimakan.find((b) => b.OpsiMakanID == data.OpsiMakanID && b.DeviceNo == data.OpsiMakanDeviceNo);
                if(getOpsiMakan){
                    if(diffMins != 0) {
                        dispatch('actSetOpsiMakan', {opsiMakan: getOpsiMakan, SaleDate: data.SaleDate, SaleTime: data.SaleTime})
                    } else {
                        dispatch('actSetOpsiMakan', {opsiMakan: getOpsiMakan})
                    }
                } else {
                    dispatch('actSetOpsiMakan', {opsiMakan: null})
                }
            } else {
                dispatch('actSetOpsiMakan', {opsiMakan: null})
            }
            if(data.WaitressID != '0') {
                var getWaitress = rootState.items.waitress.find((b) => b.WaitressID == data.WaitressID && b.DeviceNo == data.WaitressDeviceNo);
                if(getWaitress){
                    dispatch('actSetWaitress', getWaitress)
                }
            }
            
            if(data.CustomerID) {
                var dataCustomer = {
                    CustomerName: data.CustomerName,
                    CustomerID: data.CustomerID,
                    CustomerDeviceNo: data.CustomerDeviceNo,
                  }
                dispatch('actSetCustomer', dataCustomer)
            }
            
        },
        async actAddToCart({ commit, dispatch }, dataProduk) {
            commit("mutAddToCart", dataProduk);
            dispatch('actCalcOpsiMakan')
        },
        async actRemoveItemCart({ commit }, dataProduk) {
            commit("mutRemoveItemCart", dataProduk);
        },
        async actRemoveAllItemCart({ commit, dispatch }) {
            commit("mutRemoveAllItemCart");
            dispatch('actSetOpsiMakanBegin');
        },
        async actTotalBeforeRounding({ commit }, data) {
            commit("mutSetTotalBeforeRounding", data);
        },
        async actTotalRounding({ commit }, data) {
            commit("mutSetTotalRounding", data);
        },
        async actSetTotalHeader({ commit }, data) {
            commit("mutSetTotalHeader", data);
        },
        async actSetTotalCart({ commit }, data) {
            commit("mutSetTotalCart", data);
        },
        async actSetDiscount({ commit }, data) {
            if (data.checkedDisc.length > 0) {
                commit("mutSetDiscountOutlet", data.checkedDisc);
                commit("mutResetDiscount");
            } else {
                commit("mutSetDiscount", data);
                commit("mutResetDiscountOutlet");
            }

        },
        async actSetTax({ commit }, data) {
            commit("mutSetTax", data);
        },
        async actSetOpsiMakanBegin({ commit, rootState, dispatch }) {
            var setData = rootState.items.masteropsimakan.filter((opsi) => {
                return (opsi.IsActive == "1");
            });
            if(setData.length > 0){
                commit("mutSetOpsiMakan", setData[0]);
            } else {
                commit("mutSetOpsiMakan", null);
            }
            $.ajax({
                url: axios.defaults.baseURLWS + "export/getmasterpricebytype",
                data: {
                    devid: rootState.auth.outletID,
                },
                method: "POST",
                success: (data) => {
                    commit('set_masterpricebytype', data)
                },
                error: () => {
                    //commit('reset_getfcmnotreceived')
                },
            });
        },
        async actSetOpsiMakan({ commit, dispatch }, data) {
            commit("mutSetOpsiMakan", data.opsiMakan);
            dispatch('actCalcOpsiMakan')
            if(data.SaleDate){
                commit("mutSetSaleDate", data.SaleDate);
                commit("mutSetSaleTime", data.SaleTime);
            } else {
                commit("mutSetSaleDate", null);
                commit("mutSetSaleTime", null);
            }
        },
        
        async actCalcOpsiMakan({ state }) {
            state.stCart.forEach(function (cartData, i) {
                var markupPercent = 0
                var markupValuePerItem = 0
                var markupRounding = 0
                var shareRevenuePercent = 0
                var shareRevenueValuePerItem = 0
                var qty = 1
                var subtotal = parseFloat(cartData.SellPrice * qty)
                if(state.stOpsiMakan) {
                    if(state.stOpsiMakan.CustomMarkup == '0') {
                        if(state.stOpsiMakan.MarkupPersen != '0') {
                            markupPercent = parseFloat(state.stOpsiMakan.MarkupPersen)
                            markupValuePerItem = (subtotal/100) * markupPercent
                            if(state.stOpsiMakan.MarkupRounding != '') {
                                markupRounding = state.stOpsiMakan.MarkupRounding
                                var exRounding = markupRounding.split("#");
                                var round = 0;
                                var RoundingTo = parseFloat(exRounding[0]);
                                var totalBeforeRounding = markupValuePerItem;
                                var bagi = totalBeforeRounding / RoundingTo;
                                if(exRounding[1] == "0"){
                                round = Math.round(bagi);
                                } else if(exRounding[1] == "1"){
                                round = Math.floor(bagi);
                                } else if(exRounding[1] == "2"){
                                round = Math.ceil(bagi);
                                }
                                markupValuePerItem = round * RoundingTo;
                            }
                        }
                    } else {
                        var masterpricebytype = localStorage.getItem("masterpricebytype") ? JSON.parse(localStorage.getItem("masterpricebytype")) : []
                        if(masterpricebytype.length > 0) {
                            var priceByType = masterpricebytype.find((b) => b.OpsiMakanID == state.stOpsiMakan.OpsiMakanID && b.OpsiMakanDeviceNo == state.stOpsiMakan.DeviceNo && b.ItemID == cartData.ItemID && b.ItemDeviceNo == cartData.DeviceNo && b.VarianID == '0');
                            if(priceByType) {
                                markupValuePerItem = parseFloat(priceByType.Price) - subtotal
                                markupRounding = state.stOpsiMakan.MarkupRounding
                            } else {
                                if(state.stOpsiMakan.MarkupPersen != '0') {
                                    markupPercent = parseFloat(state.stOpsiMakan.MarkupPersen)
                                    markupValuePerItem = (subtotal/100) * markupPercent
                                    if(state.stOpsiMakan.MarkupRounding != '') {
                                        markupRounding = state.stOpsiMakan.MarkupRounding
                                        var exRounding = markupRounding.split("#");
                                        var round = 0;
                                        var RoundingTo = parseFloat(exRounding[0]);
                                        var totalBeforeRounding = markupValuePerItem;
                                        var bagi = totalBeforeRounding / RoundingTo;
                                        if(exRounding[1] == "0"){
                                        round = Math.round(bagi);
                                        } else if(exRounding[1] == "1"){
                                        round = Math.floor(bagi);
                                        } else if(exRounding[1] == "2"){
                                        round = Math.ceil(bagi);
                                        }
                                        markupValuePerItem = round * RoundingTo;
                                    }
                                }
                            }
                        } else {
                            if(state.stOpsiMakan.MarkupPersen != '0') {
                                markupPercent = parseFloat(state.stOpsiMakan.MarkupPersen)
                                markupValuePerItem = (subtotal/100) * markupPercent
                                if(state.stOpsiMakan.MarkupRounding != '') {
                                    markupRounding = state.stOpsiMakan.MarkupRounding
                                    var exRounding = markupRounding.split("#");
                                    var round = 0;
                                    var RoundingTo = parseFloat(exRounding[0]);
                                    var totalBeforeRounding = markupValuePerItem;
                                    var bagi = totalBeforeRounding / RoundingTo;
                                    if(exRounding[1] == "0"){
                                    round = Math.round(bagi);
                                    } else if(exRounding[1] == "1"){
                                    round = Math.floor(bagi);
                                    } else if(exRounding[1] == "2"){
                                    round = Math.ceil(bagi);
                                    }
                                    markupValuePerItem = round * RoundingTo;
                                }
                            }
                        }
                    }
                    if(state.stOpsiMakan.ShareRevenue != '0') {
                        shareRevenuePercent = parseFloat(state.stOpsiMakan.ShareRevenue)
                        shareRevenueValuePerItem = (((subtotal + markupValuePerItem)/100) * shareRevenuePercent)
                    }
                }
                cartData.markupPercent = markupPercent
                cartData.markupValuePerItem = markupValuePerItem
                cartData.markupRounding = markupRounding
                cartData.shareRevenuePercent = shareRevenuePercent
                cartData.shareRevenueValuePerItem = shareRevenueValuePerItem
            })
        },
        async actSetTotalTaxExclude({ commit }, data) {
            commit("mutSetTotalTaxExclude", data);
        },
        async actSetTotalTaxInclude({ commit }, data) {
            commit("mutSetTotalTaxInclude", data);
        },
        async actSetDiscountValue({ commit }, data) {
            commit("mutSetDiscountValue", data);
        },
        async actSetCustomer({ commit }, data) {
            commit("mutSetCustomer", data);
        },
        async actSetWaitress({ commit }, data) {
            commit("mutSetWaitress", data);
        },
        async actCreatePendingSale({ commit, state, rootState }, data) {
            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi =  String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');
            var pending = "false"
            var saleNumberText = ""
            var saleOrderNumberText = ""
            var paymentMode = "0"

            var SaleDate =  yyyy + '-' + mm + '-' + dd
            var SaleTime = hh + ":" + mi + ":" + ss
            var CreatedDate = yyyy + '-' + mm + '-' + dd
            var CreatedTime = hh + ":" + mi + ":" + ss
            if(state.stSaleDate != null) {
                SaleDate = state.stSaleDate
                SaleTime = state.stSaleTime + ":00"
            }
            

            //get sale order number
            if(state.stJenisTransaksi == "simpan") {
                pending = "true"
                var getSaleOrderNumber = 1
                var pendingSale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
                if(pendingSale.length > 0) {
                    var filterPendingSale = pendingSale.filter((sNumber) => {
                        return (sNumber.DeviceID == rootState.items.options[0].DeviceID && sNumber.DeviceNo == rootState.auth.deviceNo && sNumber.PerusahaanNo == rootState.items.options[0].PerusahaanNo && sNumber.SaleDate == yyyy + '-' + mm + '-' + dd && sNumber.SaleOrderNumber != '');
                    });
                    if(filterPendingSale.length > 0) {
                        getSaleOrderNumber = filterPendingSale.length + 1;
                    }
                }
                saleOrderNumberText = "SO/" + yyyy.slice(2, 4) + '' + mm + '' + dd + "/" + rootState.auth.deviceNo + "/" + getSaleOrderNumber
            }

            //get sale number
            if(state.stJenisTransaksi == "bayar") {
                paymentMode = data.PaymentMode
                var getSaleNumber = 1
                var valSaleNumber = null
                var saleNumber = localStorage.getItem("latestsalenumber") ? JSON.parse(localStorage.getItem("latestsalenumber")) : [];
                if(saleNumber.length > 0) {
                    var filterSaleNumber = saleNumber.filter((sNumber) => {
                        return (sNumber.DeviceID == rootState.items.options[0].DeviceID && sNumber.DeviceNo == rootState.auth.deviceNo && sNumber.PerusahaanNo == rootState.items.options[0].PerusahaanNo && sNumber.TglSaleNumber == yyyy + '-' + mm + '-' + dd);
                    });
                    if(filterSaleNumber.length > 0){
                        for (var sn = 0; sn < saleNumber.length; sn++) {
                            if(saleNumber[sn].TglSaleNumber == yyyy + '-' + mm + '-' + dd && saleNumber[sn].DeviceNo == rootState.auth.deviceNo && saleNumber[sn].DeviceID == rootState.items.options[0].DeviceID && saleNumber[sn].PerusahaanNo == rootState.items.options[0].PerusahaanNo){ 
                                saleNumber[sn].SaleNumber = parseInt(saleNumber[sn].SaleNumber + 1);
                                getSaleNumber = parseInt(saleNumber[sn].SaleNumber);
                                break;  
                            }
                        }
                        localStorage.setItem("latestsalenumber", JSON.stringify(saleNumber));
                    } else {
                        valSaleNumber = {
                            DeviceID: rootState.items.options[0].DeviceID,
                            DeviceNo: rootState.auth.deviceNo,
                            PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                            TglSaleNumber: yyyy + '-' + mm + '-' + dd,
                            SaleNumber: 1
                        }
                        getSaleNumber = 1
                        saleNumber.push(valSaleNumber)
                        localStorage.setItem('latestsalenumber', JSON.stringify(saleNumber))
                    }
                } else {
                    valSaleNumber = {
                        DeviceID: rootState.items.options[0].DeviceID,
                        DeviceNo: rootState.auth.deviceNo,
                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                        TglSaleNumber: yyyy + '-' + mm + '-' + dd,
                        SaleNumber: 1
                    }
                    getSaleNumber = 1
                    saleNumber.push(valSaleNumber)
                    localStorage.setItem('latestsalenumber', JSON.stringify(saleNumber))
                }
                saleNumberText = "S/" + yyyy.slice(2, 4) + '' + mm + '' + dd + "/" + rootState.auth.deviceNo + "/" + getSaleNumber
            }

            //get data openclose outlet
            var opencloseoutletdata = null
            var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
            if(opencloseoutlet.length > 0){
                var opencloseoutletData = opencloseoutlet.filter((openData) => {
                    return (openData.CloseDate == "" && openData.CloseTime == "" && openData.DeviceNo == rootState.auth.deviceNo);
                });
                if(opencloseoutletData.length > 0){
                    opencloseoutletdata = opencloseoutletData[opencloseoutletData.length - 1]
                }
            }

            let getAccCash = null
            let getAccBank = null
            //get data bankaccount when transaction has tunai
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            if(data.CashPayment > 0){
                getAccCash = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == '1.'+ rootState.auth.deviceNo && b.AccountType == '1');
            }

            //get data bankaccount when transaction has non tunai
            if (data.TfPayment || data.EDCPayment || data.QRStatisPayment || data.QRISPayment) {
                let selectedBank = null
                if (data.TfPayment) {
                    selectedBank = data.TfPayment
                } else if (data.EDCPayment) {
                    selectedBank = data.EDCPayment
                } else if (data.QRStatisPayment) {
                    selectedBank = data.QRStatisPayment
                } else if(data.QRISPayment){
                    selectedBank = data.QRISPayment
                }
                getAccBank = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == selectedBank && b.AccountType == '2');
            }

            //create TaxEnableList, DetailIncludeTaxValues, DetailExcludeTaxValues untuk pendingsale
            let TaxEnableList = "";
            let DetailIncludeTaxValues = "";
            let DetailExcludeTaxValues = "";
            state.stTax.forEach(function (taxData, i) {
                let TaxValEx = 0;
                let TaxValIn = 0;
                if(i == 0){
                    TaxEnableList += taxData.TaxID+"."+taxData.DeviceNo;
                } else {
                    TaxEnableList += ","+taxData.TaxID+"."+taxData.DeviceNo;
                }

                if(taxData.TaxName.toLowerCase().substr(-4) != '(rp)') {
                    state.stCart.forEach(function (cartData) {
                        // diskon sebagai pengurang sub total
                        var pengurangTax = 0;
                        var totalTrans = cartData.subtotal
                        if(state.stDiscountFinal > 0) {
                            if(state.stTypeDiscount == "yes"){
                                pengurangTax = parseFloat((totalTrans / 100) * state.stDiscountFinal)
                            } else {
                                pengurangTax = parseFloat((totalTrans / state.stTotalCart) * (state.stDiscountFinal))
                            }
                        }
                        state.stDiscountOutlet.forEach(function (discData) {
                            if(discData.Discount.search("%") > 0){
                                pengurangTax += parseFloat((totalTrans / 100)* discData.Discount.replace("%", ""))
                                totalTrans = parseFloat(totalTrans - pengurangTax);
                            } else {
                                pengurangTax += parseFloat((totalTrans / state.stTotalCart) * (discData.Discount))
                                totalTrans = parseFloat(totalTrans - pengurangTax);
                            }
                        });
                        // ketika tax yg dipilih exclude
                        if(taxData.PriceIncludeTax == "0"){
                            if(taxData.ApplyToAllItems == "1"){
                                TaxValEx += (((cartData.subtotal - pengurangTax) / 100) * taxData.TaxPercent);
                            } else {
                                if(taxData.ApplicableItems != " ") {
                                    var explode = taxData.ApplicableItems.split(",");
                                    for (let index = 0; index < explode.length; index++) {
                                        if(cartData.DeviceNo + "." + cartData.ItemID == explode[index]){
                                            TaxValEx += (((cartData.subtotal - pengurangTax) / 100) * taxData.TaxPercent);
                                        }
                                    }
                                } else if(taxData.ApplicableCategories != " ") {
                                    var explodeCat = taxData.ApplicableCategories.split(",");
                                    for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                                        if(cartData.CategoryDeviceNo + "." + cartData.CategoryID == explodeCat[indexCat]){
                                            TaxValEx += (((cartData.subtotal - pengurangTax) / 100) * taxData.TaxPercent);
                                        }
                                    }
                                }
                            }
                        // ketika tax yg dipilih include
                        } else if(taxData.PriceIncludeTax == "1"){
                            if(taxData.ApplyToAllItems == "1"){
                                TaxValIn += (cartData.subtotal - pengurangTax) - (((cartData.subtotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100);
                            } else {
                                if(taxData.ApplicableItems != " ") {
                                    var explode = taxData.ApplicableItems.split(",");
                                    for (let index = 0; index < explode.length; index++) {
                                        if(cartData.DeviceNo + "." + cartData.ItemID == explode[index]){
                                            TaxValIn += (cartData.subtotal - pengurangTax) - (((cartData.subtotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100);
                                        }
                                    }
                                } else if(taxData.ApplicableCategories != " ") {
                                    var explodeCat = taxData.ApplicableCategories.split(",");
                                    for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                                        if(cartData.CategoryDeviceNo + "." + cartData.CategoryID == explodeCat[indexCat]){
                                            TaxValIn += (cartData.subtotal - pengurangTax) - (((cartData.subtotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100);
                                        }
                                    }
                                }
                            }
                        }
                    });
                    if(taxData.PriceIncludeTax == "0") {
                        DetailExcludeTaxValues += "#" + taxData.TaxName + "$" + TaxValEx;
                    }
                    if(taxData.PriceIncludeTax == "1") {
                        DetailIncludeTaxValues += "#" + taxData.TaxName + "$" + Math.ceil(TaxValIn);
                    }
                } else {
                    if(taxData.PriceIncludeTax == "0") {
                        TaxValEx = parseFloat(taxData.TaxPercent);
                        DetailExcludeTaxValues += "#" + taxData.TaxName + "$" + TaxValEx;
                    } else {
                        TaxValIn = parseFloat(taxData.TaxPercent);
                        DetailIncludeTaxValues += "#" + taxData.TaxName + "$" + Math.ceil(TaxValIn);
                    }
                }
            });

            //set FinalDiscount
            var FinalDiscount = ""
            if(state.stDiscountFinal > 0) {
                if(state.stTypeDiscount == "yes"){
                    FinalDiscount = state.stDiscountFinal + "%";
                } else {
                    FinalDiscount = state.stDiscountFinal
                }
            }
            state.stDiscountOutlet.forEach(function (discData, i) {
                if(i == 0) {
                    FinalDiscount = discData.Discount
                } else {
                    FinalDiscount = FinalDiscount + "+" + discData.Discount
                }
            });
            
            //** start pendingsale **
            // get latesttransactionid
            var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];

            //create TransactionID
            let TransactionID = 1;
            if(latesttransactionid){
                if(latesttransactionid.saleLatestId){
                    TransactionID = parseInt(latesttransactionid.saleLatestId) + 1;
                }
            }
            //set BankPaymentAmount
            var BankPaymentAmount = 0
            if(data.EDCPayment){
                BankPaymentAmount = data.EDCTotal
            } else if(data.Change && (data.TfPayment || data.QRStatisPayment || data.QRISPayment)){
                BankPaymentAmount = data.Change
            } else if(data.TfPayment || data.QRStatisPayment || data.QRISPayment){
                BankPaymentAmount = state.stTotalHeader
            }
            //set Opsi Makan
            var OpsiMakanID = 0
            var OpsiMakanDeviceNo = 0
            var NamaOpsiMakan = ""
            if(state.stOpsiMakan) {
                OpsiMakanID = state.stOpsiMakan.OpsiMakanID
                OpsiMakanDeviceNo = state.stOpsiMakan.DeviceNo
                NamaOpsiMakan = state.stOpsiMakan.NamaOpsiMakan
            }
            
            let sale = {
                TransactionID: TransactionID,
                Varian: "Nuta",
                DeviceID: rootState.items.options[0].DeviceID,
                DeviceNo: rootState.auth.deviceNo,
                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                SaleNumber: saleNumberText,
                SaleDate: SaleDate,
                SaleTime: SaleTime,
                CustomerName: state.stCustomer ? state.stCustomer.CustomerName: "",
                Total: state.stTotalBeforeRounding ? state.stTotalBeforeRounding : state.stTotalHeader,
                FinalDiscount: FinalDiscount,
                Rounding: parseFloat(state.stTotalRounding),
                Donation: 0,
                PaymentMode: paymentMode,
                CashAccountID: getAccCash ? getAccCash.AccountID : 0,
                CashAccountDeviceNo: getAccCash ? getAccCash.DeviceNo : 0,
                BankAccountID: getAccBank ? getAccBank.AccountID : 0,
                BankAccountDeviceNo: getAccBank ? getAccBank.DeviceNo : 0,
                CashPaymentAmount: data.CashPayment > 0 ? data.CashPayment : 0,
                BankPaymentAmount: BankPaymentAmount > 0 ? BankPaymentAmount : 0,
                TotalPayment: 0,
                Change: (data.EDCPayment || data.TfPayment || data.QRStatisPayment || data.QRISPayment) ? 0 : (data.Change ? data.Change : 0),
                ClearingDate: "",
                CardType: "",
                CardName: "",
                CardNumber: "",
                AutoClearing: "true",
                BatchNumberEDC: 0,
                Pending: pending,
                CashBankAccountName: getAccBank ? getAccBank.BankName+"#"+getAccBank.AccountNumber+"#"+getAccBank.AccountName : "",
                IsDetailSaved: null,
                CreatedBy: rootState.auth.usernamekasir,
                CreatedDate: CreatedDate,
                CreatedTime: CreatedTime,
                EditedBy: "",
                EditedDate: "",
                EditedTime: "",
                OpenID: opencloseoutletdata ? opencloseoutletdata.OpenID : 0,
                OpenDeviceNo: opencloseoutletdata ? opencloseoutletdata.DeviceNo : 0,
                PerusahaanID: rootState.items.options[0].PerusahaanID,
                SaleOrderNumber: saleOrderNumberText,
                CreatedVersionCode: rootState.versionCode,
                EditedVersionCode: "0",
                RowVersion: 1,
                Tax: 0,
                PriceIncludeTax: 0,
                TaxPercent: 0,
                TaxValue: 0,
                CustomerID: state.stCustomer ? state.stCustomer.CustomerID: 0,
                CustomerDeviceNo: state.stCustomer ? state.stCustomer.CustomerDeviceNo: 0,
                DiningTable: "",
                //DiningInOrTakeAway: 1,
                Pax: 0,
                TaxValueInclude: state.stTotalTaxInclude,
                TaxValueExclude: state.stTotalTaxExclude,
                DetailIncludeTaxValues: DetailIncludeTaxValues,
                DetailExcludeTaxValues: DetailExcludeTaxValues,
                TaxEnableList: TaxEnableList,
                QRinvoiceID: "",
                QRinvoiceTagStart: "",
                LastTotalInvoice: 0,
                QRPaymentAmount: 0,
                //TglJamUpdate: null,
                isBillPrinted: 0,
                RepeatOrderNumber: 0,
                EditingBy: "",
                EditingTime: "",
                DownPaymentOpenID: 0,
                DownPaymentOpenDeviceNo: 0,
                DownPaymentMode: 0,
                CashDownPaymentAccountID: 0,
                CashDownPaymentAccountDeviceNo: 0,
                BankDownPaymentAccountID: 0,
                BankDownPaymentAccountDeviceNo: 0,
                CashDownPayment: 0,
                BankDownPayment: 0,
                OpsiMakanID: OpsiMakanID,
                OpsiMakanDeviceNo: OpsiMakanDeviceNo,
                NamaOpsiMakan: NamaOpsiMakan,
                DriverName: "",
                ShareRevenuePercent: 0,
                ShareRevenueValue: 0,
                ShareTaxPercent: 0,
                ShareTaxValue: 0,
                WaitressID: state.stWaitress ? state.stWaitress.WaitressID : 0,
                WaitressDeviceNo: state.stWaitress ? state.stWaitress.DeviceNo : 0,
                WaitressName: state.stWaitress ? state.stWaitress.WaitressName : "",
                CancelerID: 0,
                CancelerDeviceNo: 0,
                CancelReason: "",
                CanceledBy: "",
                CanceledDate: "",
                CanceledTime: "",
                SaleNote: "",
                SynMode: 1,
            }

            //push to localstorage pendingsale
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            pendingsale.push(sale)
            localStorage.setItem('pendingsale', JSON.stringify(pendingsale))

            //update latesttransactionid
            latesttransactionid.saleLatestId = TransactionID
            localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))

            commit("mutSetTransactionID", TransactionID + "." + rootState.auth.deviceNo);
            //** end pendingsale **
        },
        async actCreatePendingSaleItemDetail({ getters, state, rootState }) {
            var gtTransactionID = getters.stTransactionID.split(".")
            var TransactionID = gtTransactionID[0]

            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi =  String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            //** start pendingsaleitemdetail **
            // get latesttransactionid
            var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];
            var pendingsaleitemdetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
            if(state.stOpenTransaction){
                /*for (let i = 0; i < pendingsaleitemdetail.length; i++) {
                    if (pendingsaleitemdetail[i].TransactionID == state.stOpenTransaction.TransactionID && pendingsaleitemdetail[i].TransactionDeviceNo == state.stOpenTransaction.DeviceNo){
                        pendingsaleitemdetail.splice(i,1)
                    }
                    
                }*/
                pendingsaleitemdetail = pendingsaleitemdetail.filter((el) => {
                    return (el.TransactionID +"."+ el.TransactionDeviceNo != state.stOpenTransaction.TransactionID +"."+ state.stOpenTransaction.DeviceNo && el.DeviceID == rootState.items.options[0].DeviceID);
                });
            }
            
            //create DetailID
            var DetailID = 1;
            if(latesttransactionid){
                if(latesttransactionid.saleitemdetailLatestId){
                    DetailID = parseInt(latesttransactionid.saleitemdetailLatestId) + 1;
                }
            }
            var totalShare = 0
            var share = 0
            state.stCart.forEach(function (cartData, i) {
                // get last pembelian of produk
                var purchaseitemdetail = localStorage.getItem("purchaseitemdetail") ? JSON.parse(localStorage.getItem("purchaseitemdetail")) : [];
                purchaseitemdetail = purchaseitemdetail.filter((purch) => {
                    return (purch.ItemID +"."+ purch.ItemDeviceNo == cartData.ItemID +"."+ cartData.DeviceNo && purch.DeviceID == rootState.items.options[0].DeviceID);
                });
                var cogs = 0
                if(purchaseitemdetail.length > 0){
                    var UnitPrice = 0
                    UnitPrice = purchaseitemdetail[purchaseitemdetail.length - 1].UnitPrice
                    cogs = UnitPrice * cartData.qty
                } else {
                    cogs = cartData.PurchasePrice * cartData.qty
                }
                let detail ={
                    DetailID: DetailID,
                    DeviceID: rootState.items.options[0].DeviceID,
                    DeviceNo: state.stOpenTransaction ? state.stOpenTransaction.DeviceNo : rootState.auth.deviceNo,
                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                    Varian: "Nuta",
                    TransactionID: TransactionID,
                    TransactionDeviceNo: state.stOpenTransaction ? state.stOpenTransaction.DeviceNo : rootState.auth.deviceNo,
                    DetailNumber: i + 1,
                    ItemID: cartData.ItemID,
                    ItemDeviceNo: cartData.DeviceNo,
                    ItemName: cartData.ItemName,
                    Quantity: cartData.qty,
                    Discount: cartData.diskon > 0 ? (cartData.diskon+""+(cartData.diskon > 0 && cartData.jenisDiskon == "%"? "%" : "")) : "",
                    UnitPrice: cartData.SellPrice,
                    SubTotal: cartData.subtotal,
                    Note: "",
                    COGS: cogs,
                    RepeatOrderNumber: 0,
                    QtyCanceled: 0,
                    QtyCanceler: 0,
                    SubTotalCanceled: 0,
                    VarianID: 0,
                    VarianDeviceNo: 0,
                    VarianName: "",
                    VarianPrice: 0,
                    DetailIdCanceled: 0,
                    DetailDeviceNoCanceled: 0,
                    PerusahaanID: -1,
                    CreatedVersionCode: rootState.versionCode,
                    EditedVersionCode: "0",
                    RowVersion: 1,
                    //TglJamUpdate: null,
                    COGSModifier: 0,
                    MarkupPercent: (cartData.markupPercent > 0 ? cartData.markupPercent : 0),
                    MarkupValue: (cartData.markupValuePerItem > 0 ? (cartData.markupValuePerItem * cartData.qty) : 0),
                    MarkupRounding: (cartData.markupRounding != 0 ? cartData.markupRounding : 0),
                    FreePromoID: 0,
                    PromoID: 0,
                    ShareRevenuePercent: (cartData.shareRevenuePercent > 0 ? cartData.shareRevenuePercent : 0),
                    ShareRevenueValue: (cartData.shareRevenueValuePerItem > 0 ? (cartData.shareRevenueValuePerItem * cartData.qty) : 0),
                    ShareTaxPercent: 0,
                    ShareTaxValue: 0,
                    WaitressID: state.stWaitress ? state.stWaitress.WaitressID : 0,
                    WaitressDeviceNo: state.stWaitress ? state.stWaitress.DeviceNo : 0,
                    WaitressName: state.stWaitress ? state.stWaitress.WaitressName : "",
                }
                share = (cartData.shareRevenuePercent > 0 ? cartData.shareRevenuePercent : 0)
                totalShare += (cartData.shareRevenueValuePerItem > 0 ? (cartData.shareRevenueValuePerItem * cartData.qty) : 0)
                
                //push to localstorage pendingsale
                pendingsaleitemdetail.push(detail)
                localStorage.setItem('pendingsaleitemdetail', JSON.stringify(pendingsaleitemdetail))

                //update latesttransactionid
                latesttransactionid.saleitemdetailLatestId = DetailID
                localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                DetailID++;
            });
            //if(totalShare > 0){
            var pendingsaleupdate = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            if(state.stOpsiMakan) {
                if(state.stOpsiMakan.ShareRevenueRp != '0') {
                    totalShare += parseFloat(state.stOpsiMakan.ShareRevenueRp)
                }
            }
            for (var i = 0; i < pendingsaleupdate.length; i++) {
                if(pendingsaleupdate[i].TransactionID == TransactionID && pendingsaleupdate[i].DeviceNo == rootState.auth.deviceNo && pendingsaleupdate[i].DeviceID == rootState.items.options[0].DeviceID) { 
                    pendingsaleupdate[i].ShareRevenuePercent = share;
                    pendingsaleupdate[i].ShareRevenueValue = totalShare;
                    break;  
                }
            }
            localStorage.setItem("pendingsale", JSON.stringify(pendingsaleupdate));
            //}
            //** end pendingsaleitemdetail **
        },
        async actCreatePendingSaleItemDetailTax({ getters, state, rootState }) {
            var gtTransactionID = getters.stTransactionID.split(".")
            var TransactionID = gtTransactionID[0]

            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi =  String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            //** start pendingsaleitemdetailtax **
            // get latesttransactionid
            var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];
            var pendingsaleitemdetailtax = localStorage.getItem("pendingsaleitemdetailtax") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailtax")) : [];
            if(state.stOpenTransaction){
                pendingsaleitemdetailtax = pendingsaleitemdetailtax.filter((el) => {
                    return (el.TransactionID +"."+ el.TransactionDeviceNo != state.stOpenTransaction.TransactionID +"."+ state.stOpenTransaction.DeviceNo && el.DeviceID == rootState.items.options[0].DeviceID);
                });
            }
            var produk = localStorage.getItem("produk") ? JSON.parse(localStorage.getItem("produk")) : [];

            //create DetailTaxID
            var DetailTaxID = 1;
            if(latesttransactionid){
                if(latesttransactionid.saleitemdetailtaxLatestId){
                    DetailTaxID = parseInt(latesttransactionid.saleitemdetailtaxLatestId) + 1;
                }
            }
            
            var pendingsaleitemdetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
            var DeviceNo = state.stOpenTransaction ? state.stOpenTransaction.DeviceNo : rootState.auth.deviceNo;
            pendingsaleitemdetail = pendingsaleitemdetail.filter((item) => {
                return (item.TransactionID == TransactionID && item.TransactionDeviceNo == DeviceNo);
            });
            pendingsaleitemdetail.forEach(function (itemDetail) {
                //get data masteritem untuk cek category
                var cekCat = produk.filter((prodCat) => {
                    return (prodCat.ItemID == itemDetail.ItemID && prodCat.DeviceNo == itemDetail.ItemDeviceNo && prodCat.DeviceID == rootState.items.options[0].DeviceID);
                });
                var pengurangTax = 0;
                if(state.stDiscountFinal > 0){
                    if(state.stTypeDiscount == "yes"){
                        pengurangTax = parseFloat((itemDetail.SubTotal / 100) * state.stDiscountFinal)
                    } else {
                        pengurangTax = parseFloat(state.stDiscountFinal)
                    }
                }
                var totalTrans = itemDetail.SubTotal
                state.stDiscountOutlet.forEach(function (discData) {
                    if(discData.Discount.search("%") > 0){
                        pengurangTax += parseFloat((totalTrans / 100)* discData.Discount.replace("%", ""))
                        totalTrans = parseFloat(totalTrans - pengurangTax);
                    } else {
                        pengurangTax += parseFloat((totalTrans / state.stTotalCart) * (discData.Discount))
                        totalTrans = parseFloat(totalTrans - pengurangTax);
                    }
                });
                state.stTax.forEach(function (taxData, i) {
                    var tax = null
                    if(taxData.PriceIncludeTax == "0"){
                        if(taxData.TaxName.toLowerCase().substr(-4) != '(rp)') {
                            if(taxData.ApplyToAllItems == "1"){
                                tax = {
                                    DetailTaxID: DetailTaxID,
                                    TransactionID: TransactionID,
                                    TransactionDeviceNo: DeviceNo,
                                    DetailID: itemDetail.DetailID,
                                    DetailDeviceNo: itemDetail.DeviceNo,
                                    DetailNumber: i + 1,
                                    TaxName: taxData.TaxName,
                                    TaxID: taxData.TaxID,
                                    TaxDeviceNo: taxData.DeviceNo,
                                    PriceIncludeTax: taxData.PriceIncludeTax,
                                    TaxPercent: taxData.TaxPercent,
                                    TaxValue: (((itemDetail.SubTotal - pengurangTax) / 100) * taxData.TaxPercent),
                                    SubTotalAfterDiscount: (itemDetail.SubTotal - pengurangTax),
                                    Varian: "Nuta",
                                    DeviceID: rootState.items.options[0].DeviceID,
                                    DeviceNo: DeviceNo,
                                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                    PerusahaanID: "-1",
                                    CreatedVersionCode: rootState.versionCode,
                                    EditedVersionCode: "0",
                                    RowVersion: 1,
                                    //TglJamUpdate: null,
                                    ShareTaxPercent: 0,
                                    ShareTaxValue: 0,
                                }
                            } else {
                                if(taxData.ApplicableItems != " ") {
                                    var explode = taxData.ApplicableItems.split(",");
                                    for (var index = 0; index < explode.length; index++) {
                                        if(itemDetail.ItemDeviceNo + "." + itemDetail.ItemID == explode[index]){
                                            tax = {
                                                DetailTaxID: DetailTaxID,
                                                TransactionID: TransactionID,
                                                TransactionDeviceNo: DeviceNo,
                                                DetailID: itemDetail.DetailID,
                                                DetailDeviceNo: itemDetail.DeviceNo,
                                                DetailNumber: i + 1,
                                                TaxName: taxData.TaxName,
                                                TaxID: taxData.TaxID,
                                                TaxDeviceNo: taxData.DeviceNo,
                                                PriceIncludeTax: taxData.PriceIncludeTax,
                                                TaxPercent: taxData.TaxPercent,
                                                TaxValue: (((itemDetail.SubTotal - pengurangTax) / 100) * taxData.TaxPercent),
                                                SubTotalAfterDiscount: (itemDetail.SubTotal - pengurangTax),
                                                Varian: "Nuta",
                                                DeviceID: rootState.items.options[0].DeviceID,
                                                DeviceNo: DeviceNo,
                                                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                                PerusahaanID: "-1",
                                                CreatedVersionCode: rootState.versionCode,
                                                EditedVersionCode: "0",
                                                RowVersion: 1,
                                                //TglJamUpdate: null,
                                                ShareTaxPercent: 0,
                                                ShareTaxValue: 0,
                                            }
                                        }
                                    }
                                } else if(taxData.ApplicableCategories != " ") {
                                    var explodeCat = taxData.ApplicableCategories.split(",");
                                    for (var indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                                        if(cekCat[0].CategoryDeviceNo + "." + cekCat[0].CategoryID == explodeCat[indexCat]){
                                            tax = {
                                                DetailTaxID: DetailTaxID,
                                                TransactionID: TransactionID,
                                                TransactionDeviceNo: DeviceNo,
                                                DetailID: itemDetail.DetailID,
                                                DetailDeviceNo: itemDetail.DeviceNo,
                                                DetailNumber: i + 1,
                                                TaxName: taxData.TaxName,
                                                TaxID: taxData.TaxID,
                                                TaxDeviceNo: taxData.DeviceNo,
                                                PriceIncludeTax: taxData.PriceIncludeTax,
                                                TaxPercent: taxData.TaxPercent,
                                                TaxValue: (((itemDetail.SubTotal - pengurangTax) / 100) * taxData.TaxPercent),
                                                SubTotalAfterDiscount: (itemDetail.SubTotal - pengurangTax),
                                                Varian: "Nuta",
                                                DeviceID: rootState.items.options[0].DeviceID,
                                                DeviceNo: DeviceNo,
                                                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                                PerusahaanID: "-1",
                                                CreatedVersionCode: rootState.versionCode,
                                                EditedVersionCode: "0",
                                                RowVersion: 1,
                                                //TglJamUpdate: null,
                                                ShareTaxPercent: 0,
                                                ShareTaxValue: 0,
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else if(taxData.PriceIncludeTax == "1") {
                        if(taxData.ApplyToAllItems == "1"){
                            tax = {
                                DetailTaxID: DetailTaxID,
                                TransactionID: TransactionID,
                                TransactionDeviceNo: DeviceNo,
                                DetailID: itemDetail.DetailID,
                                DetailDeviceNo: itemDetail.DeviceNo,
                                DetailNumber: i + 1,
                                TaxName: taxData.TaxName,
                                TaxID: taxData.TaxID,
                                TaxDeviceNo: taxData.DeviceNo,
                                PriceIncludeTax: taxData.PriceIncludeTax,
                                TaxPercent: taxData.TaxPercent,
                                TaxValue: Math.ceil((itemDetail.SubTotal - pengurangTax) - (((itemDetail.SubTotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100)),
                                SubTotalAfterDiscount: (itemDetail.SubTotal - pengurangTax),
                                Varian: "Nuta",
                                DeviceID: rootState.items.options[0].DeviceID,
                                DeviceNo: DeviceNo,
                                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                PerusahaanID: "-1",
                                CreatedVersionCode: rootState.versionCode,
                                EditedVersionCode: "0",
                                RowVersion: 1,
                                //TglJamUpdate: null,
                                ShareTaxPercent: 0,
                                ShareTaxValue: 0,
                            }
                        } else {
                            if(taxData.ApplicableItems != " ") {
                                var explode = taxData.ApplicableItems.split(",");
                                for (var index = 0; index < explode.length; index++) {
                                    if(itemDetail.ItemDeviceNo + "." + itemDetail.ItemID == explode[index]){
                                        tax = {
                                            DetailTaxID: DetailTaxID,
                                            TransactionID: TransactionID,
                                            TransactionDeviceNo: DeviceNo,
                                            DetailID: itemDetail.DetailID,
                                            DetailDeviceNo: itemDetail.DeviceNo,
                                            DetailNumber: i + 1,
                                            TaxName: taxData.TaxName,
                                            TaxID: taxData.TaxID,
                                            TaxDeviceNo: taxData.DeviceNo,
                                            PriceIncludeTax: taxData.PriceIncludeTax,
                                            TaxPercent: taxData.TaxPercent,
                                            TaxValue: Math.ceil((itemDetail.SubTotal - pengurangTax) - (((itemDetail.SubTotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100)),
                                            SubTotalAfterDiscount: (itemDetail.SubTotal - pengurangTax),
                                            Varian: "Nuta",
                                            DeviceID: rootState.items.options[0].DeviceID,
                                            DeviceNo: DeviceNo,
                                            PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                            PerusahaanID: "-1",
                                            CreatedVersionCode: rootState.versionCode,
                                            EditedVersionCode: "0",
                                            RowVersion: 1,
                                            //TglJamUpdate: null,
                                            ShareTaxPercent: 0,
                                            ShareTaxValue: 0,
                                        }
                                    }
                                }
                            } else if(taxData.ApplicableCategories != " ") {
                                var explodeCat = taxData.ApplicableCategories.split(",");
                                for (var indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                                    if(cekCat[0].CategoryDeviceNo + "." + cekCat[0].CategoryID == explodeCat[indexCat]){
                                        tax = {
                                            DetailTaxID: DetailTaxID,
                                            TransactionID: TransactionID,
                                            TransactionDeviceNo: DeviceNo,
                                            DetailID: itemDetail.DetailID,
                                            DetailDeviceNo: itemDetail.DeviceNo,
                                            DetailNumber: i + 1,
                                            TaxName: taxData.TaxName,
                                            TaxID: taxData.TaxID,
                                            TaxDeviceNo: taxData.DeviceNo,
                                            PriceIncludeTax: taxData.PriceIncludeTax,
                                            TaxPercent: taxData.TaxPercent,
                                            TaxValue: Math.ceil((itemDetail.SubTotal - pengurangTax) - (((itemDetail.SubTotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100)),
                                            SubTotalAfterDiscount: (itemDetail.SubTotal - pengurangTax),
                                            Varian: "Nuta",
                                            DeviceID: rootState.items.options[0].DeviceID,
                                            DeviceNo: DeviceNo,
                                            PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                            PerusahaanID: "-1",
                                            CreatedVersionCode: rootState.versionCode,
                                            EditedVersionCode: "0",
                                            RowVersion: 1,
                                            //TglJamUpdate: null,
                                            ShareTaxPercent: 0,
                                            ShareTaxValue: 0,
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if(tax){
                        //push to localstorage pendingsaleitemdetailtax
                        pendingsaleitemdetailtax.push(tax)
                        localStorage.setItem('pendingsaleitemdetailtax', JSON.stringify(pendingsaleitemdetailtax))

                        //update latesttransactionid
                        latesttransactionid.saleitemdetailtaxLatestId = DetailTaxID
                        localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                        DetailTaxID++;
                    }
                });
            });
            //** end pendingsaleitemdetailtax **
        },
        async actCreatePendingSaleDiscountDetail({ getters, state, rootState }) {
            var gt = getters.stTransactionID.split(".")
            var TransactionID = gt[0]

            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi =  String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');
            //** start pendingsalediscountdetail **

            // get latesttransactionid
            var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];
            let pendingsalediscountdetail = localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [];
            if(state.stOpenTransaction){
                pendingsalediscountdetail = pendingsalediscountdetail.filter((el) => {
                    return (el.TransactionID +"."+ el.TransactionDeviceNo != state.stOpenTransaction.TransactionID +"."+ state.stOpenTransaction.DeviceNo && el.DeviceID == rootState.items.options[0].DeviceID);
                });
            }
            //create DetailIDDisc
            var DetailIDDisc = 1;
            if(latesttransactionid){
                if(latesttransactionid.salediscountdetailLatestId){
                    DetailIDDisc = parseInt(latesttransactionid.salediscountdetailLatestId) + 1;
                }
            }
            var totalTrans = state.stTotalCart
            state.stDiscountOutlet.forEach(function (discData, i) {
                var totDisc = 0
                if(discData.Discount.search("%") > 0){
                    totDisc = parseFloat((totalTrans / 100)* discData.Discount.replace("%", ""))
                    totalTrans = parseFloat(totalTrans - totDisc);
                } else {
                    totDisc = parseFloat(discData.Discount)
                    totalTrans = parseFloat(totalTrans - totDisc);
                }
                var diskon = {
                    DetailID: DetailIDDisc,
                    TransactionID: TransactionID,
                    TransactionDeviceNo: state.stOpenTransaction ? state.stOpenTransaction.DeviceNo : rootState.auth.deviceNo,
                    DetailNumber: i + 1,
                    DiscountID: discData.DiscountID,
                    DiscountDeviceNo: discData.DiscountDeviceNo,
                    DiscountName: discData.DiscountName,
                    Discount: discData.Discount,
                    DiscountValue: totDisc,
                    Varian: "Nuta",
                    DeviceID: rootState.items.options[0].DeviceID,
                    DeviceNo: state.stOpenTransaction ? state.stOpenTransaction.DeviceNo : rootState.auth.deviceNo,
                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                    PerusahaanID: "-1",
                    CreatedVersionCode: rootState.versionCode,
                    EditedVersionCode: "0",
                    RowVersion: 1,
                    //TglJamUpdate: null,
                }

                //push to localstorage pendingsalediscountdetail
                pendingsalediscountdetail.push(diskon)
                localStorage.setItem('pendingsalediscountdetail', JSON.stringify(pendingsalediscountdetail))

                //update latesttransactionid
                latesttransactionid.salediscountdetailLatestId = DetailIDDisc
                localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                DetailIDDisc++
            });
            //** end pendingsalediscountdetail **
        },
        async actCreatePendingSaleItemDetailIngredients({ getters, state, rootState }) {
            var gt = getters.stTransactionID.split(".")
            var TransactionID = gt[0]

            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi =  String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');

            //** start pendingsaleitemdetailingredients **
            //get data from pendingsaleitemdetail
            var pendingsaleitemdetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
            var DeviceNo = state.stOpenTransaction ? state.stOpenTransaction.DeviceNo : rootState.auth.deviceNo;
            pendingsaleitemdetail = pendingsaleitemdetail.filter((item) => {
                return (item.TransactionID == TransactionID && item.DeviceNo == DeviceNo && item.DeviceID == rootState.items.options[0].DeviceID);
            });

            if(pendingsaleitemdetail.length > 0){
                // get latesttransactionid
                var latesttransactionid = localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [];
                let pendingsaleitemdetailingredients = localStorage.getItem("pendingsaleitemdetailingredients") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailingredients")) : [];
                if(state.stOpenTransaction){
                    pendingsaleitemdetailingredients = pendingsaleitemdetailingredients.filter((el) => {
                        return (el.TransactionID +"."+ el.TransactionDeviceNo != state.stOpenTransaction.TransactionID +"."+ state.stOpenTransaction.DeviceNo && el.DeviceID == rootState.items.options[0].DeviceID);
                    });
                }
                let pendingsaleitemproduct = localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [];
                if(state.stOpenTransaction){
                    pendingsaleitemproduct = pendingsaleitemproduct.filter((el) => {
                        return (el.TransactionID +"."+ el.TransactionDeviceNo != state.stOpenTransaction.TransactionID +"."+ state.stOpenTransaction.DeviceNo && el.DeviceID == rootState.items.options[0].DeviceID);
                    });
                }
                var itemingredients = localStorage.getItem("itemingredients") ? JSON.parse(localStorage.getItem("itemingredients")) : [];
                var produk = localStorage.getItem("produk") ? JSON.parse(localStorage.getItem("produk")) : [];
                var variant = localStorage.getItem("variant") ? JSON.parse(localStorage.getItem("variant")) : [];
                var purchaseitemdetail = localStorage.getItem("purchaseitemdetail") ? JSON.parse(localStorage.getItem("purchaseitemdetail")) : [];

                //create DetailIngredientsID
                var DetailIngredientsID = 1;
                if(latesttransactionid){
                    if(latesttransactionid.saleitemdetailingredientsLatestId){
                        DetailIngredientsID = parseInt(latesttransactionid.saleitemdetailingredientsLatestId) + 1;
                    }
                }
                //saleitemdetailproductLatestId
                var saleitemdetailproductLatestId = 1
                if(latesttransactionid){
                    if(latesttransactionid.saleitemdetailproductLatestId){
                        saleitemdetailproductLatestId = parseInt(latesttransactionid.saleitemdetailproductLatestId) + 1;
                    }
                }
                pendingsaleitemdetail.forEach(function (iDetail) {
                    var getCOGS = 0;
                    //get data masteritem untuk cek unit
                    var cekUnit = produk.filter((prodUnit) => {
                        return (prodUnit.ItemID == iDetail.ItemID && prodUnit.DeviceNo == iDetail.ItemDeviceNo && prodUnit.DeviceID == rootState.items.options[0].DeviceID);
                    });
                    //jika sale item detail bukan paket
                    if(cekUnit[0].Unit != "#paket#"){
                        //get data masteritemdetailingredients berdasarkan item detail
                        var dataitemingredients = itemingredients.filter((itemingd) => {
                            return (itemingd.ItemID == iDetail.ItemID && itemingd.ItemDeviceNo == iDetail.ItemDeviceNo && itemingd.DeviceID == rootState.items.options[0].DeviceID);
                        });
                        dataitemingredients.forEach(function (iIngredients, i) {
                            //get data masteritem from masteritemdetailingredients
                            var dataProduk = produk.filter((prod) => {
                                return (prod.ItemID == iIngredients.IngredientsID && prod.DeviceNo == iIngredients.IngredientsDeviceNo && prod.DeviceID == rootState.items.options[0].DeviceID);
                            });
                            //jika produk tidak memiliki bahan lagi
                            if(dataProduk[0].IsProductHasIngredients == "false") {
                                //set data PurchasePrice from pembelian atau data produk
                                var dataPurchaseItem = purchaseitemdetail.filter((purch) => {
                                    return (purch.ItemID +"."+ purch.ItemDeviceNo == dataProduk[0].ItemID +"."+ dataProduk[0].DeviceNo && purch.DeviceID == rootState.items.options[0].DeviceID);
                                });
                                var UnitPrice = 0
                                if(dataPurchaseItem.length > 0){
                                    UnitPrice = parseFloat(dataPurchaseItem[dataPurchaseItem.length - 1].UnitPrice)
                                } else {
                                    UnitPrice = parseFloat(dataProduk[0].PurchasePrice)
                                }
                                var saleingd = {
                                    DetailIngredientsID: DetailIngredientsID,
                                    TransactionID: TransactionID,
                                    TransactionDeviceNo: DeviceNo,
                                    DetailID: iDetail.DetailID,
                                    DetailDeviceNo: iDetail.DeviceNo,
                                    DetailNumber: i + 1,
                                    IngredientsID: iIngredients.IngredientsID,
                                    IngredientsDeviceNo: iIngredients.IngredientsDeviceNo,
                                    IngredientsName: dataProduk[0].ItemName,
                                    QtyUsed: parseFloat(iIngredients.QtyNeed * iDetail.Quantity),
                                    QtyUsedCancel: 0,
                                    IngredientsPrice: UnitPrice,
                                    DeviceID: rootState.items.options[0].DeviceID,
                                    DeviceNo: DeviceNo,
                                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                    Varian: "Nuta",
                                    PerusahaanID: "-1",
                                    CreatedVersionCode: rootState.versionCode,
                                    EditedVersionCode: "0",
                                    RowVersion: 1,
                                    //TglJamUpdate: null,
                                }
                                getCOGS += parseFloat(UnitPrice * (iIngredients.QtyNeed * iDetail.Quantity))
                                //push to localstorage pendingsaleitemdetailingredients
                                pendingsaleitemdetailingredients.push(saleingd)
                                localStorage.setItem('pendingsaleitemdetailingredients', JSON.stringify(pendingsaleitemdetailingredients))
                                
                                //update latesttransactionid
                                latesttransactionid.saleitemdetailingredientsLatestId = DetailIngredientsID
                                localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                                DetailIngredientsID++;

                            //jika produk memiliki bahan lagi
                            } else if(dataProduk[0].IsProductHasIngredients == "true"){
                                //get data masteritemdetailingredients from masteritem
                                var itemingredientsHas = itemingredients.filter((itemingdhas) => {
                                    return (itemingdhas.ItemID == dataProduk[0].ItemID && itemingdhas.ItemDeviceNo == dataProduk[0].DeviceNo && itemingdhas.DeviceID == rootState.items.options[0].DeviceID);
                                });
                                itemingredientsHas.forEach(function (iIngredientsHas, ii) {
                                    //get data masteritem from masteritem yang memiliki bahan
                                    var dataProdukHas = produk.filter((prodHas) => {
                                        return (prodHas.ItemID == iIngredientsHas.IngredientsID && prodHas.DeviceNo == iIngredientsHas.IngredientsDeviceNo && prodHas.DeviceID == rootState.items.options[0].DeviceID);
                                    });
                                    //set data PurchasePrice from pembelian atau data produk
                                    var dataPurchaseItem = purchaseitemdetail.filter((purch) => {
                                        return (purch.ItemID +"."+ purch.ItemDeviceNo == dataProdukHas[0].ItemID +"."+ dataProdukHas[0].DeviceNo && purch.DeviceID == rootState.items.options[0].DeviceID);
                                    });
                                    var UnitPrice = 0
                                    if(dataPurchaseItem.length > 0){
                                        UnitPrice = parseFloat(dataPurchaseItem[dataPurchaseItem.length - 1].UnitPrice)
                                    } else {
                                        UnitPrice = parseFloat(dataProdukHas[0].PurchasePrice)
                                    }
                                    var saleingdhas = {
                                        DetailIngredientsID: DetailIngredientsID,
                                        TransactionID: TransactionID,
                                        TransactionDeviceNo: DeviceNo,
                                        DetailID: iDetail.DetailID,
                                        DetailDeviceNo: iDetail.DeviceNo,
                                        DetailNumber: i + ii + 1,
                                        IngredientsID: iIngredientsHas.IngredientsID,
                                        IngredientsDeviceNo: iIngredientsHas.IngredientsDeviceNo,
                                        IngredientsName: dataProdukHas[0].ItemName,
                                        QtyUsed: parseFloat(iIngredientsHas.QtyNeed * iDetail.Quantity),
                                        QtyUsedCancel: 0,
                                        IngredientsPrice: UnitPrice,
                                        DeviceID: rootState.items.options[0].DeviceID,
                                        DeviceNo: DeviceNo,
                                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                        Varian: "Nuta",
                                        PerusahaanID: "-1",
                                        CreatedVersionCode: rootState.versionCode,
                                        EditedVersionCode: "0",
                                        RowVersion: 1,
                                        //TglJamUpdate: null,
                                    }
                                    getCOGS += parseFloat(UnitPrice * (iIngredientsHas.QtyNeed * iDetail.Quantity))
                                    //push to localstorage pendingsaleitemdetailingredients
                                    pendingsaleitemdetailingredients.push(saleingdhas)
                                    localStorage.setItem('pendingsaleitemdetailingredients', JSON.stringify(pendingsaleitemdetailingredients))
                                    
                                    //update latesttransactionid
                                    latesttransactionid.saleitemdetailingredientsLatestId = DetailIngredientsID
                                    localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                                    DetailIngredientsID++;
                                });
                            }
                        });
                    } else if(cekUnit[0].Unit == "#paket#") {
                        //get isi paket(masteritemdetailingredients) berdasarkan item detail
                        var isiPaket = itemingredients.filter((ipaket) => {
                            return (ipaket.ItemID == iDetail.ItemID && ipaket.ItemDeviceNo == iDetail.ItemDeviceNo && ipaket.DeviceID == rootState.items.options[0].DeviceID);
                        });
                        isiPaket.forEach(function (iIngredients, i) {
                            //get data masteritem from masteritemdetailingredients
                            var dataProduk = produk.filter((prod) => {
                                return (prod.ItemID == iIngredients.IngredientsID && prod.DeviceNo == iIngredients.IngredientsDeviceNo && prod.DeviceID == rootState.items.options[0].DeviceID);
                            });
                            //get data mastervariant from masteritemdetailingredients
                            var dataVariant = variant.filter((va) => {
                                return (va.ItemID == iIngredients.IngredientsID && va.ItemDeviceNo == iIngredients.IngredientsDeviceNo && va.VarianID == iIngredients.VariantID && va.DeviceNo == iIngredients.VariantDeviceNo && va.DeviceID == rootState.items.options[0].DeviceID);
                            });

                            var saleitemdetailprod = {
                                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                DeviceID: rootState.items.options[0].DeviceID,
                                DeviceNo: DeviceNo,
                                DetailProductID: saleitemdetailproductLatestId,
                                Varian: "Nuta",
                                TransactionID: TransactionID,
                                TransactionDeviceNo: DeviceNo,
                                DetailID: iDetail.DetailID,
                                DetailDeviceNo: iDetail.DeviceNo,
                                DetailNumber: i + 1,
                                ProductID: iIngredients.IngredientsID,
                                ProductDeviceNo: iIngredients.IngredientsDeviceNo,
                                VariantID: iIngredients.VariantID,
                                VariantDeviceNo: iIngredients.VariantDeviceNo,
                                ProductName: dataProduk[0].ItemName,
                                VariantName: dataVariant.length > 0 ? dataVariant[0].VarianName : "",
                                CategoryID: dataProduk[0].CategoryID,
                                CategoryDeviceNo: dataProduk[0].CategoryDeviceNo,
                                QtyUsed: parseFloat(iIngredients.QtyNeed),
                                QtyUsedCancel: "0",
                                ProductPrice: dataVariant.length > 0 ? dataVariant[0].SellPrice : dataProduk[0].SellPrice,
                                PerusahaanID: "-1",
                                CreatedVersionCode: rootState.versionCode,
                                EditedVersionCode: "0",
                                RowVersion: 1,
                                //TglJamUpdate: null,
                                //saleitemdetailproductLatestId: saleitemdetailproductLatestId,
                            }

                            //push to localstorage pendingsaleitemproduct
                            pendingsaleitemproduct.push(saleitemdetailprod)
                            localStorage.setItem('pendingsaleitemproduct', JSON.stringify(pendingsaleitemproduct))

                            //update saleitemdetailproductLatestId
                            latesttransactionid.saleitemdetailproductLatestId = saleitemdetailproductLatestId
                            localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                            saleitemdetailproductLatestId++;

                            //jika produk tidak memiliki bahan lagi
                            if(dataProduk[0].IsProductHasIngredients == "false") {
                                //set data PurchasePrice from pembelian atau data produk
                                var dataPurchaseItem = purchaseitemdetail.filter((purch) => {
                                    return (purch.ItemID +"."+ purch.ItemDeviceNo == dataProduk[0].ItemID +"."+ dataProduk[0].DeviceNo && purch.DeviceID == rootState.items.options[0].DeviceID);
                                });
                                var UnitPrice = 0
                                if(dataPurchaseItem.length > 0){
                                    UnitPrice = parseFloat(dataPurchaseItem[dataPurchaseItem.length - 1].UnitPrice)
                                } else {
                                    UnitPrice = parseFloat(dataProduk[0].PurchasePrice)
                                }
                                var saleingd = {
                                    DetailIngredientsID: DetailIngredientsID,
                                    TransactionID: TransactionID,
                                    TransactionDeviceNo: DeviceNo,
                                    DetailID: iDetail.DetailID,
                                    DetailDeviceNo: iDetail.DeviceNo,
                                    DetailNumber: i + 1,
                                    IngredientsID: iIngredients.IngredientsID,
                                    IngredientsDeviceNo: iIngredients.IngredientsDeviceNo,
                                    IngredientsName: dataProduk[0].ItemName,
                                    QtyUsed: parseFloat(iIngredients.QtyNeed * iDetail.Quantity),
                                    QtyUsedCancel: 0,
                                    IngredientsPrice: UnitPrice,
                                    DeviceID: rootState.items.options[0].DeviceID,
                                    DeviceNo: DeviceNo,
                                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                    Varian: "Nuta",
                                    PerusahaanID: "-1",
                                    CreatedVersionCode: rootState.versionCode,
                                    EditedVersionCode: "0",
                                    RowVersion: 1,
                                    //TglJamUpdate: null,
                                }
                                getCOGS += parseFloat(UnitPrice * (iIngredients.QtyNeed * iDetail.Quantity))
                                //push to localstorage pendingsaleitemdetailingredients
                                pendingsaleitemdetailingredients.push(saleingd)
                                localStorage.setItem('pendingsaleitemdetailingredients', JSON.stringify(pendingsaleitemdetailingredients))
                                
                                //update latesttransactionid
                                latesttransactionid.saleitemdetailingredientsLatestId = DetailIngredientsID
                                localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                                DetailIngredientsID++;

                            //jika produk memiliki bahan lagi
                            } else if(dataProduk[0].IsProductHasIngredients == "true"){
                                //get data masteritemdetailingredients from masteritem
                                var itemingredientsHas = itemingredients.filter((itemingdhas) => {
                                    return (itemingdhas.ItemID == dataProduk[0].ItemID && itemingdhas.ItemDeviceNo == dataProduk[0].DeviceNo && itemingdhas.DeviceID == rootState.items.options[0].DeviceID);
                                });
                                itemingredientsHas.forEach(function (iIngredientsHas, ii) {
                                    //get data masteritem from masteritem yang memiliki bahan
                                    var dataProdukHas = produk.filter((prodHas) => {
                                        return (prodHas.ItemID == iIngredientsHas.IngredientsID && prodHas.DeviceNo == iIngredientsHas.IngredientsDeviceNo && prodHas.DeviceID == rootState.items.options[0].DeviceID);
                                    });
                                    //set data PurchasePrice from pembelian atau data produk
                                    var dataPurchaseItem = purchaseitemdetail.filter((purch) => {
                                        return (purch.ItemID +"."+ purch.ItemDeviceNo == dataProdukHas[0].ItemID +"."+ dataProdukHas[0].DeviceNo && purch.DeviceID == rootState.items.options[0].DeviceID);
                                    });
                                    var UnitPrice = 0
                                    if(dataPurchaseItem.length > 0){
                                        UnitPrice = parseFloat(dataPurchaseItem[dataPurchaseItem.length - 1].UnitPrice)
                                    } else {
                                        UnitPrice = parseFloat(dataProdukHas[0].PurchasePrice)
                                    }
                                    var saleingdhas = {
                                        DetailIngredientsID: DetailIngredientsID,
                                        TransactionID: TransactionID,
                                        TransactionDeviceNo: DeviceNo,
                                        DetailID: iDetail.DetailID,
                                        DetailDeviceNo: iDetail.DeviceNo,
                                        DetailNumber: i + ii + 1,
                                        IngredientsID: iIngredientsHas.IngredientsID,
                                        IngredientsDeviceNo: iIngredientsHas.IngredientsDeviceNo,
                                        IngredientsName: dataProdukHas[0].ItemName,
                                        QtyUsed: parseFloat(iIngredients.QtyNeed * iIngredientsHas.QtyNeed * iDetail.Quantity),
                                        QtyUsedCancel: 0,
                                        IngredientsPrice: UnitPrice,
                                        DeviceID: rootState.items.options[0].DeviceID,
                                        DeviceNo: DeviceNo,
                                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                                        Varian: "Nuta",
                                        PerusahaanID: "-1",
                                        CreatedVersionCode: rootState.versionCode,
                                        EditedVersionCode: "0",
                                        RowVersion: 1,
                                        //TglJamUpdate: null,
                                    }
                                    getCOGS += parseFloat(UnitPrice * (iIngredients.QtyNeed * iIngredientsHas.QtyNeed * iDetail.Quantity))
                                    //push to localstorage pendingsaleitemdetailingredients
                                    pendingsaleitemdetailingredients.push(saleingdhas)
                                    localStorage.setItem('pendingsaleitemdetailingredients', JSON.stringify(pendingsaleitemdetailingredients))
                                    
                                    //update latesttransactionid
                                    latesttransactionid.saleitemdetailingredientsLatestId = DetailIngredientsID
                                    localStorage.setItem('latesttransactionid', JSON.stringify(latesttransactionid))
                                    DetailIngredientsID++;
                                });
                            }
                        });

                    }
                    //update COGS
                    if(getCOGS > 0){
                        var itemdetailupdate = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
                        for (var i = 0; i < itemdetailupdate.length; i++) {
                            if(itemdetailupdate[i].TransactionID == TransactionID && itemdetailupdate[i].TransactionDeviceNo == iDetail.TransactionDeviceNo && itemdetailupdate[i].DetailID == iDetail.DetailID && itemdetailupdate[i].DeviceNo == iDetail.DeviceNo && itemdetailupdate[i].ItemID == iDetail.ItemID && itemdetailupdate[i].ItemDeviceNo == iDetail.ItemDeviceNo && itemdetailupdate[i].DeviceID == rootState.items.options[0].DeviceID){ 
                                itemdetailupdate[i].COGS = getCOGS;
                                break;  
                            }
                        }
                        localStorage.setItem("pendingsaleitemdetail", JSON.stringify(itemdetailupdate));
                    }
                });
            }
            //** end pendingsaleitemdetailingredients **
        },
        async actCreateTransaction({ getters, rootState, dispatch, commit }, data) {
            return new Promise((resolve) => {
                dispatch('actCekCashBank')
                .then(function () {
                    dispatch('actCreatePendingSale', data)
                })
                .then(function () {
                    dispatch('actCreatePendingSaleItemDetail')
                })
                .then(function () {
                    dispatch('actCreatePendingSaleItemDetailTax')
                })
                .then(function () {
                    dispatch('actCreatePendingSaleDiscountDetail')
                })
                .then(function () {
                    dispatch('actCreatePendingSaleItemDetailIngredients')
                })
                .then(function () {
                    commit("mutSetTransactionSts", "success");
                    // cek jika connect internet untuk sync sale
                    if(rootState.connectionStatus == "Online"){
                        var gt = getters.stTransactionID
                        dispatch('actSyncDataSale', gt)
                    }
                    commit('mutReloadAllPendingSaleFromStorage')
                    resolve({status:"OK"});
                })
            })
            
        },
        async actUpdatePendingSale({ commit, state, rootState }, data) {
            let today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = String(today.getFullYear());

            var hh = String(today.getHours()).padStart(2, '0');
            var mi =  String(today.getMinutes()).padStart(2, '0');
            var ss = String(today.getSeconds()).padStart(2, '0');
            var pending = "false"
            var saleNumberText = ""
            var saleOrderNumberText = state.stOpenTransaction.SaleOrderNumber
            var paymentMode = "0"

            var SaleDate =  yyyy + '-' + mm + '-' + dd
            var SaleTime = hh + ":" + mi + ":" + ss
            if(state.stSaleDate != null) {
                SaleDate = state.stSaleDate
                SaleTime = state.stSaleTime + ":00"
            }

            //get sale order number
            if(state.stJenisTransaksi == "simpan") {
                pending = "true"
                var getSaleOrderNumber = 1
                var pendingSale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
                if(pendingSale.length > 0) {
                    var filterPendingSale = pendingSale.filter((sNumber) => {
                        return (sNumber.DeviceID == rootState.items.options[0].DeviceID && sNumber.DeviceNo == rootState.auth.deviceNo && sNumber.PerusahaanNo == rootState.items.options[0].PerusahaanNo && sNumber.SaleDate == yyyy + '-' + mm + '-' + dd && sNumber.SaleOrderNumber != '');
                    });
                    if(filterPendingSale.length > 0) {
                        getSaleOrderNumber = filterPendingSale.length + 1;
                    }
                }
                if(!state.stOpenTransaction.SaleOrderNumber){
                    saleOrderNumberText = "SO/" + yyyy.slice(2, 4) + '' + mm + '' + dd + "/" + rootState.auth.deviceNo + "/" + getSaleOrderNumber
                }
            }

            //get sale number
            if(state.stJenisTransaksi == "bayar") {
                paymentMode = data.PaymentMode
                var getSaleNumber = 1
                var valSaleNumber = null
                var saleNumber = localStorage.getItem("latestsalenumber") ? JSON.parse(localStorage.getItem("latestsalenumber")) : [];
                if(saleNumber.length > 0) {
                    var filterSaleNumber = saleNumber.filter((sNumber) => {
                        return (sNumber.DeviceID == rootState.items.options[0].DeviceID && sNumber.DeviceNo == rootState.auth.deviceNo && sNumber.PerusahaanNo == rootState.items.options[0].PerusahaanNo && sNumber.TglSaleNumber == yyyy + '-' + mm + '-' + dd);
                    });
                    if(filterSaleNumber.length > 0){
                        for (var sn = 0; sn < saleNumber.length; sn++) {
                            if(saleNumber[sn].TglSaleNumber == yyyy + '-' + mm + '-' + dd && saleNumber[sn].DeviceNo == rootState.auth.deviceNo && saleNumber[sn].DeviceID == rootState.items.options[0].DeviceID && saleNumber[sn].PerusahaanNo == rootState.items.options[0].PerusahaanNo){ 
                                saleNumber[sn].SaleNumber = parseInt(saleNumber[sn].SaleNumber + 1);
                                getSaleNumber = parseInt(saleNumber[sn].SaleNumber);
                                break;  
                            }
                        }
                        localStorage.setItem("latestsalenumber", JSON.stringify(saleNumber));
                    } else {
                        valSaleNumber = {
                            DeviceID: rootState.items.options[0].DeviceID,
                            DeviceNo: rootState.auth.deviceNo,
                            PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                            TglSaleNumber: yyyy + '-' + mm + '-' + dd,
                            SaleNumber: 1
                        }
                        getSaleNumber = 1
                        saleNumber.push(valSaleNumber)
                        localStorage.setItem('latestsalenumber', JSON.stringify(saleNumber))
                    }
                } else {
                    valSaleNumber = {
                        DeviceID: rootState.items.options[0].DeviceID,
                        DeviceNo: rootState.auth.deviceNo,
                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                        TglSaleNumber: yyyy + '-' + mm + '-' + dd,
                        SaleNumber: 1
                    }
                    getSaleNumber = 1
                    saleNumber.push(valSaleNumber)
                    localStorage.setItem('latestsalenumber', JSON.stringify(saleNumber))
                }
                saleNumberText = "S/" + yyyy.slice(2, 4) + '' + mm + '' + dd + "/" + rootState.auth.deviceNo + "/" + getSaleNumber
            }

            //get data openclose outlet
            var opencloseoutletdata = null
            var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
            if(opencloseoutlet.length > 0){
                var opencloseoutletData = opencloseoutlet.filter((openData) => {
                    return (openData.CloseDate == "" && openData.CloseTime == "" && openData.DeviceNo == rootState.auth.deviceNo);
                });
                if(opencloseoutletData.length > 0){
                    opencloseoutletdata = opencloseoutletData[opencloseoutletData.length - 1]
                }
            }

            let getAccCash = null
            let getAccBank = null
            //get data bankaccount when transaction has tunai
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            if(data.CashPayment > 0){
                getAccCash = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == '1.'+ rootState.auth.deviceNo && b.AccountType == '1');
            }

            //get data bankaccount when transaction has non tunai
            if (data.TfPayment || data.EDCPayment || data.QRStatisPayment || data.QRISPayment) {
                let selectedBank = null
                if (data.TfPayment) {
                    selectedBank = data.TfPayment
                } else if (data.EDCPayment) {
                    selectedBank = data.EDCPayment
                } else if (data.QRStatisPayment) {
                    selectedBank = data.QRStatisPayment
                } else if(data.QRISPayment){
                    selectedBank = data.QRISPayment
                }
                getAccBank = cashbank.find((b) => b.AccountID + '.' + b.DeviceNo == selectedBank && b.AccountType == '2');
            }

            //create TaxEnableList, DetailIncludeTaxValues, DetailExcludeTaxValues untuk pendingsale
            let TaxEnableList = "";
            let DetailIncludeTaxValues = "";
            let DetailExcludeTaxValues = "";
            state.stTax.forEach(function (taxData, i) {
                let TaxValEx = 0;
                let TaxValIn = 0;
                if(i == 0){
                    TaxEnableList += taxData.TaxID+"."+taxData.DeviceNo;
                } else {
                    TaxEnableList += ","+taxData.TaxID+"."+taxData.DeviceNo;
                }

                if(taxData.TaxName.toLowerCase().substr(-4) != '(rp)') {
                    state.stCart.forEach(function (cartData) {
                        // diskon sebagai pengurang sub total
                        var pengurangTax = 0;
                        var totalTrans = cartData.subtotal
                        if(state.stDiscountFinal > 0) {
                            if(state.stTypeDiscount == "yes"){
                                pengurangTax = parseFloat((totalTrans / 100) * state.stDiscountFinal)
                            } else {
                                pengurangTax = parseFloat((totalTrans / state.stTotalCart) * (state.stDiscountFinal))
                            }
                        }
                        state.stDiscountOutlet.forEach(function (discData) {
                            if(discData.Discount.search("%") > 0){
                                pengurangTax += parseFloat((totalTrans / 100)* discData.Discount.replace("%", ""))
                                totalTrans = parseFloat(totalTrans - pengurangTax);
                            } else {
                                pengurangTax += parseFloat((totalTrans / state.stTotalCart) * (discData.Discount))
                                totalTrans = parseFloat(totalTrans - pengurangTax);
                            }
                        });
                        // ketika tax yg dipilih exclude
                        if(taxData.PriceIncludeTax == "0"){
                            if(taxData.ApplyToAllItems == "1"){
                                TaxValEx += (((cartData.subtotal - pengurangTax) / 100) * taxData.TaxPercent);
                            } else {
                                if(taxData.ApplicableItems != " ") {
                                    var explode = taxData.ApplicableItems.split(",");
                                    for (let index = 0; index < explode.length; index++) {
                                        if(cartData.DeviceNo + "." + cartData.ItemID == explode[index]){
                                            TaxValEx += (((cartData.subtotal - pengurangTax) / 100) * taxData.TaxPercent);
                                        }
                                    }
                                } else if(taxData.ApplicableCategories != " ") {
                                    var explodeCat = taxData.ApplicableCategories.split(",");
                                    for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                                        if(cartData.CategoryDeviceNo + "." + cartData.CategoryID == explodeCat[indexCat]){
                                            TaxValEx += (((cartData.subtotal - pengurangTax) / 100) * taxData.TaxPercent);
                                        }
                                    }
                                }
                            }
                        // ketika tax yg dipilih include
                        } else if(taxData.PriceIncludeTax == "1"){
                            if(taxData.ApplyToAllItems == "1"){
                                TaxValIn += (cartData.subtotal - pengurangTax) - (((cartData.subtotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100);
                            } else {
                                if(taxData.ApplicableItems != " ") {
                                    var explode = taxData.ApplicableItems.split(",");
                                    for (let index = 0; index < explode.length; index++) {
                                        if(cartData.DeviceNo + "." + cartData.ItemID == explode[index]){
                                            TaxValIn += (cartData.subtotal - pengurangTax) - (((cartData.subtotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100);
                                        }
                                    }
                                } else if(taxData.ApplicableCategories != " ") {
                                    var explodeCat = taxData.ApplicableCategories.split(",");
                                    for (let indexCat = 0; indexCat < explodeCat.length; indexCat++) {
                                        if(cartData.CategoryDeviceNo + "." + cartData.CategoryID == explodeCat[indexCat]){
                                            TaxValIn += (cartData.subtotal - pengurangTax) - (((cartData.subtotal - pengurangTax) / (100 + parseFloat(taxData.TaxPercent))) * 100);
                                        }
                                    }
                                }
                            }
                        }
                    });
                    if(taxData.PriceIncludeTax == "0") {
                        DetailExcludeTaxValues += "#" + taxData.TaxName + "$" + TaxValEx;
                    }
                    if(taxData.PriceIncludeTax == "1") {
                        DetailIncludeTaxValues += "#" + taxData.TaxName + "$" + Math.ceil(TaxValIn);
                    }
                } else {
                    if(taxData.PriceIncludeTax == "0") {
                        TaxValEx = parseFloat(taxData.TaxPercent);
                        DetailExcludeTaxValues += "#" + taxData.TaxName + "$" + TaxValEx;
                    } else {
                        TaxValIn = parseFloat(taxData.TaxPercent);
                        DetailIncludeTaxValues += "#" + taxData.TaxName + "$" + Math.ceil(TaxValIn);
                    }
                }
            });

            //set FinalDiscount
            var FinalDiscount = ""
            if(state.stDiscountFinal > 0) {
                if(state.stTypeDiscount == "yes"){
                    FinalDiscount = state.stDiscountFinal + "%";
                } else {
                    FinalDiscount = state.stDiscountFinal
                }
            }
            state.stDiscountOutlet.forEach(function (discData, i) {
                if(i == 0) {
                    FinalDiscount = discData.Discount
                } else {
                    FinalDiscount = FinalDiscount + "+" + discData.Discount
                }
            });
            
            //** start pendingsale **
            //set BankPaymentAmount
            var BankPaymentAmount = 0
            if(data.EDCPayment){
                BankPaymentAmount = data.EDCTotal
            } else if(data.Change && (data.TfPayment || data.QRStatisPayment || data.QRISPayment)){
                BankPaymentAmount = data.Change
            } else if(data.TfPayment || data.QRStatisPayment || data.QRISPayment){
                BankPaymentAmount = state.stTotalHeader
            }
            //set Opsi Makan
            var OpsiMakanID = 0
            var OpsiMakanDeviceNo = 0
            var NamaOpsiMakan = ""
            if(state.stOpsiMakan) {
                OpsiMakanID = state.stOpsiMakan.OpsiMakanID
                OpsiMakanDeviceNo = state.stOpsiMakan.DeviceNo
                NamaOpsiMakan = state.stOpsiMakan.NamaOpsiMakan
            }

            //update to localstorage pendingsale
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            for (var i = 0; i < pendingsale.length; i++) {
                if(pendingsale[i].TransactionID + "." + pendingsale[i].DeviceNo == state.stOpenTransaction.TransactionID +"."+ state.stOpenTransaction.DeviceNo && pendingsale[i].DeviceID == rootState.items.options[0].DeviceID){ 
                    pendingsale[i].DeviceNo = state.stOpenTransaction.DeviceNo;
                    pendingsale[i].SaleNumber = saleNumberText;
                    pendingsale[i].SaleDate = SaleDate;
                    pendingsale[i].SaleTime = SaleTime;
                    pendingsale[i].CustomerName = state.stCustomer ? state.stCustomer.CustomerName: "";
                    pendingsale[i].Total = state.stTotalBeforeRounding ? state.stTotalBeforeRounding : state.stTotalHeader;
                    pendingsale[i].FinalDiscount = FinalDiscount;
                    pendingsale[i].Rounding = parseFloat(state.stTotalRounding);
                    pendingsale[i].PaymentMode = paymentMode;
                    pendingsale[i].CashAccountID = getAccCash ? getAccCash.AccountID : 0;
                    pendingsale[i].CashAccountDeviceNo = getAccCash ? getAccCash.DeviceNo : 0;
                    pendingsale[i].BankAccountID = getAccBank ? getAccBank.AccountID : 0;
                    pendingsale[i].BankAccountDeviceNo = getAccBank ? getAccBank.DeviceNo : 0;
                    pendingsale[i].CashPaymentAmount = data.CashPayment > 0 ? data.CashPayment : 0;
                    pendingsale[i].BankPaymentAmount = BankPaymentAmount > 0 ? BankPaymentAmount : 0;
                    pendingsale[i].TotalPayment = 0;
                    pendingsale[i].Change = (data.EDCPayment || data.TfPayment || data.QRStatisPayment || data.QRISPayment) ? 0 : (data.Change ? data.Change : 0);
                    pendingsale[i].Pending = pending;
                    pendingsale[i].CashBankAccountName = getAccBank ? getAccBank.BankName+"#"+getAccBank.AccountNumber+"#"+getAccBank.AccountName : "";
                    pendingsale[i].EditedBy = rootState.auth.usernamekasir;
                    pendingsale[i].EditedDate = yyyy + '-' + mm + '-' + dd;
                    pendingsale[i].EditedTime = hh + ":" + mi + ":" + ss;
                    pendingsale[i].OpenID = opencloseoutletdata ? opencloseoutletdata.OpenID : 0;
                    pendingsale[i].OpenDeviceNo = opencloseoutletdata ? opencloseoutletdata.DeviceNo : 0;
                    pendingsale[i].SaleOrderNumber = saleOrderNumberText;
                    pendingsale[i].EditedVersionCode = rootState.versionCode;
                    pendingsale[i].RowVersion = parseFloat(pendingsale[i].RowVersion) + 1;
                    pendingsale[i].CustomerID = state.stCustomer ? state.stCustomer.CustomerID: 0;
                    pendingsale[i].CustomerDeviceNo = state.stCustomer ? state.stCustomer.CustomerDeviceNo: 0;
                    pendingsale[i].TaxValueInclude = state.stTotalTaxInclude;
                    pendingsale[i].TaxValueExclude = state.stTotalTaxExclude;
                    pendingsale[i].DetailIncludeTaxValues = DetailIncludeTaxValues;
                    pendingsale[i].DetailExcludeTaxValues = DetailExcludeTaxValues;
                    pendingsale[i].TaxEnableList = TaxEnableList;
                    pendingsale[i].OpsiMakanID = OpsiMakanID;
                    pendingsale[i].OpsiMakanDeviceNo = OpsiMakanDeviceNo;
                    pendingsale[i].NamaOpsiMakan = NamaOpsiMakan;
                    pendingsale[i].WaitressID = state.stWaitress ? state.stWaitress.WaitressID : 0;
                    pendingsale[i].WaitressDeviceNo = state.stWaitress ? state.stWaitress.DeviceNo : 0;
                    pendingsale[i].WaitressName = state.stWaitress ? state.stWaitress.WaitressName : "";
                    pendingsale[i].SynMode = 1;
                    break;  
                }
             }
            localStorage.setItem('pendingsale', JSON.stringify(pendingsale))

            commit("mutSetTransactionID", state.stOpenTransaction.TransactionID + "." + state.stOpenTransaction.DeviceNo);
            //** end pendingsale **
        },
        async actUpdateTransaction({ getters, rootState, dispatch, commit }, data) {
            return new Promise((resolve) => {
                dispatch('actCekCashBank')
                .then(function () {
                    dispatch('actUpdatePendingSale', data)
                })
                .then(function () {
                    dispatch('actCreatePendingSaleItemDetail')
                })
                .then(function () {
                    dispatch('actCreatePendingSaleItemDetailTax')
                })
                .then(function () {
                    dispatch('actCreatePendingSaleDiscountDetail')
                })
                .then(function () {
                    dispatch('actCreatePendingSaleItemDetailIngredients')
                })
                .then(function () {
                    commit("mutSetTransactionSts", "success");
                    // cek jika connect internet untuk sync sale
                    if(rootState.connectionStatus == "Online"){
                        var gt = getters.stTransactionID
                        dispatch('actSyncDataSale', gt)
                    }
                    commit('mutReloadAllPendingSaleFromStorage')
                    resolve({status:"OK"});
                })
            })
            
        },
        async actResetTransaction({ commit }) {
            commit("mutRemoveAllItemCart");
            commit("mutResetTransaction");
        },
        async actSetJenisTransaksi({ commit }, data) {
            commit("mutSetJenisTransaksi", data);
        },
    },
    mutations: {
        mutOpenUangMasuk(state, data) {
            state.stOpenUangMasuk = data
        },
        mutOpenUangKeluar(state, data) {
            state.stOpenUangKeluar = data
        },
        mutDetailRiwayatPenjualan(state, data){
            state.stDetailRiwayatPenjualan = data.data;
        },
        mutRiwayatPenjualan(state, data){
            state.stRiwayatPenjualan = data;
        },
        mutRiwayatUangMasuk(state, data){
            state.stRiwayatUangMasuk = data;
        },
        mutRiwayatUangKeluar(state, data){
            state.stRiwayatUangKeluar = data;
        },
        mutAddToCart(state, dataProduk) {
            const getIndex = state.stCart.findIndex(el => el.ItemID == dataProduk.ItemID && el.diskon === 0);
            
            if (getIndex !== -1) {
                /* state.stCart = state.stCart.map((data) => {
                    if (data.ItemID === dataProduk.ItemID) {
                        return {
                            ...data,
                            qty: data.qty + 1,
                            subtotal: data.subtotal * (data.qty + 1)
                        };
                    }
                    return data;
                })
                const getIndex = state.stCart.findIndex(obj => obj.ItemID == dataProduk.ItemID);
                if (getIndex === -1) return; */
                var qty = state.stCart[getIndex].qty + 1
                var subtotal = state.stCart[getIndex].SellPrice * qty
                state.stCart[getIndex] = {
                    ...state.stCart[getIndex],
                    qty: qty,
                    subtotal: subtotal,
                };
            } else {
                var qty = 1
                var subtotal = parseFloat(dataProduk.SellPrice * 1)
                state.stCart.push({ 
                    DetailID: 0,
                    DetailDeviecNo: 0,
                    ItemID: dataProduk.ItemID, 
                    DeviceNo: dataProduk.DeviceNo, 
                    CategoryID: dataProduk.CategoryID, 
                    CategoryDeviceNo: dataProduk.CategoryDeviceNo, 
                    ItemName: dataProduk.ItemName, 
                    Unit: dataProduk.Unit, 
                    SellPrice: dataProduk.SellPrice, 
                    PurchasePrice: dataProduk.PurchasePrice,
                    qty: qty, 
                    diskon: 0, 
                    jenisDiskon: "%", 
                    subtotal: subtotal
                });
            }
        },
        mutSetTotalBeforeRounding(state, data) {
            state.stTotalBeforeRounding = data;
        },
        mutSetTotalRounding(state, data) {
            state.stTotalRounding = data;
        },
        mutSetTotalHeader(state, data) {
            state.stTotalHeader = data;
        },
        mutSetTotalCart(state, data) {
            state.stTotalCart = data;
        },
        mutSetTotalTaxExclude(state, data) {
            state.stTotalTaxExclude = data;
        },
        mutSetTotalTaxInclude(state, data) {
            state.stTotalTaxInclude = data;
        },
        mutSetDiscountValue(state, data) {
            state.stDiscountValue = data;
        },
        mutSetDiscount(state, data) {
            state.stDiscountFinal = data.inputDiscount
            state.stTypeDiscount = data.r_percent
        },
        mutResetDiscount(state) {
            state.stDiscountFinal = null
            state.stTypeDiscount = "yes"
        },
        mutSetDiscountOutlet(state, data) {
            state.stDiscountOutlet = data
        },
        mutResetDiscountOutlet(state) {
            state.stDiscountOutlet = []
        },
        mutSetTax(state, data) {
            state.stTax = data.checkedTax ? data.checkedTax : []
            localStorage.setItem('setTax', JSON.stringify(data.checkedTax))
        },
        mutSetOpsiMakan(state, data) {
            state.stOpsiMakan = data
        },
        mutSetSaleDate(state, data) {
            state.stSaleDate = data
        },
        mutSetSaleTime(state, data) {
            state.stSaleTime = data
        },
        mutOpenTransaction(state, data) {
            state.stOpenTransaction = data
        },
        mutResetSetTax(state) {
            state.stTax = []
            localStorage.removeItem('setTax')
        },
        mutSetCustomer(state, data) {
            state.stCustomer = data
        },
        mutSetWaitress(state, data) {
            state.stWaitress = data;
        },
        mutSetTransactionID(state, data){
            state.stTransactionID = data
        },
        mutSetTransactionSts(state, data){
            state.stTransactionSts = data
        },
        mutSetJenisTransaksi(state, data){
            state.stJenisTransaksi = data
        },
        mutResetTransaction(state){
            state.stTransactionID = 0
            state.stTransactionSts = null

            //state.stDiscountID = 0
            //state.stDiscountDevNo = 0
            state.stDiscountFinal = null
            state.stDiscountValue = 0
            state.stTypeDiscount = "yes"
            state.stDiscountOutlet = []

            state.stOpsiMakan = null
            state.stSaleDate = null
            state.stSaletime = null
            state.stCustomer = null
            state.stWaitress = null
            state.stJenisTransaksi = "bayar"
            state.stOpenTransaction = null
        },
        mutResetAllTransactionData(state){
            // reset state data transaksi
            state.stCart = []
            state.stTotalCart = 0
            state.stTotalBeforeRounding = 0
            state.stTotalRounding = 0
            state.stTotalHeader = 0
            state.stDiscountOutlet = []
            state.stDiscountFinal = null
            state.stDiscountValue = 0
            state.stTypeDiscount = "yes"
            state.stTax = []
            localStorage.removeItem('setTax')
            state.stTotalTaxExclude = 0
            state.stTotalTaxInclude = 0
            state.stTransactionID = 0
            state.stCustomer = null
        },
        mutRemoveItemCart(state, dataProduk) {
            var idx = state.stCart.indexOf(dataProduk);
            if (idx > -1) {
                state.stCart.splice(idx, 1);
            }
        },
        mutRemoveAllItemCart(state) {
            state.stCart = [],
            state.stTotalCart = 0,
            state.stDiscountFinal = null
            state.stDiscountValue = 0
            state.stTypeDiscount = "yes"
            state.stDiscountOutlet = []
            state.stTotalTaxExclude = 0,
            state.stTotalTaxInclude = 0,

            state.stTotalBeforeRounding = 0,
            state.stTotalRounding = 0,
            state.stTotalHeader = 0

            state.stOpsiMakan = null
            state.stSaleDate = null
            state.stSaletime = null
            state.stCustomer = null
            state.stWaitress = null
            state.stJenisTransaksi = "bayar"
            state.stOpenTransaction = null
        },
    }
})