<template>
  <!-- Modal data diskon-->
  <div id="modalPajak" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Pajak</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="selectPajak">
            <table class="table table-bordered table-striped table-sm">
              <tbody>
                <tr v-for="dataPajak in mastertax" v-bind:key="dataPajak.TaxID">
                  <td class="text-center" v-if="dataPajak.IsMyNutapos == '0'">
                    <div class="form-check">
                      <label class="switch">
                        <input
                          type="checkbox"
                          :value="dataPajak"
                          v-model="form.checkedTax"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td class="text-left" v-if="dataPajak.IsMyNutapos == '0'">{{ dataPajak.TaxName.toLowerCase().substr(-4) == '(rp)' ? dataPajak.TaxName.substr(0, dataPajak.TaxName.length - 5) : dataPajak.TaxName }}</td>
                  <td class="text-right" v-if="dataPajak.IsMyNutapos == '0'">{{ $filters.numberTocurrencyIDR(dataPajak.TaxPercent) }}{{ dataPajak.TaxName.toLowerCase().substr(-4) == '(rp)' ? '' :'%' }}</td>
                  <td class="text-left" v-if="dataPajak.IsMyNutapos == '0'"><span v-if="dataPajak.PriceIncludeTax == '1'">Include</span><span v-else>Exclude</span></td>
                </tr>
              </tbody>
            </table>
            <div class="row py-2" v-if="mastertax.length > 0">
              <div class="col-6"></div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <button
                      class="btn btn-link btn-block"
                      type="button"
                      data-dismiss="modal"
                    >
                      Kembali
                    </button>
                  </div>
                  <div class="col-6">
                    <button type="submit" class="btn btn-green btn-block">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <p v-if="mastertax.length <= 0">
            Pajak tidak ditemukan
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ //disabled eslint compiler
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PajakModal_c",
  data() {
    return {
      form: {
        checkedTax:[],
      },
    };
  },
  computed: {
    ...mapGetters(["mastertax", "stTax"]),
  },
  mounted(){
    this.form.checkedTax = this.stTax
  },
  methods: {
    ...mapActions(["actSetTax"]),
    selectPajak() {
      this.$store.dispatch("actSetTax", this.form);
      $("#modalPajak").modal("hide");
    },
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>