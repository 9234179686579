<template>
    <div>
        <div id="modalTutupOutletBegin" class="modal hide fade in">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" style="background-color: unset; border: unset;">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-4" style="text-align:center;"></div>
                            <div class="col-lg-4" style="text-align:center;">
                                <img src="/we_are_open.png" style="width: 100%; padding-bottom: 15px;"/>
                                <span style="color: white;">BUKA PADA</span>
                                <hr style="margin-top: -1px; margin-bottom: -1px; border-color: white;"/>
                                <span style="color: white;">{{ tglJam }} </span>

                                <button style="margin-bottom: 15px; margin-top: 15px; color: white; border-color: white;" class="btn btn-outline-success btn-block" type="button" @click="tutupOutlet" :disabled="submittedTutupShiftBegin">Tutup Outlet</button>
                                <button style="margin-bottom: 15px; color: white !important;" class="btn btn-link btn-block" type="button" @click="riwayatTutupOutlet">Lihat Riwayat</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <!-- Modal Tutup Outlet -->
        <TutupOutlet :dataForTutupOutletModal="dataForTutupOutletModal" @dataForRingkasanPenjualan="dataRingkasan" @pageHistory="dataHistory"/>
        <!-- Modal Riwayat Tutup Outlet -->
        <RiwayatTutupOutlet @dataForRingkasanPenjualan="dataRingkasan" @pageHistory="dataHistory"/>
        <!-- komponen modal ringkasan penjualan-->
        <RingkasanPenjualan :datapenjualan="setdataringksanpenjualan" :dataPageHistory="setPageHistory"/>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import TutupOutlet from "@/components/tutup_outlet/TutupOutlet.vue";
import RiwayatTutupOutlet from "@/components/tutup_outlet/LihatRiwayatTutupOutletModal_c.vue";
import RingkasanPenjualan from "@/components/tutup_outlet/RingkasanPenjualan.vue";
export default {
    name: "TutupOutletModal",
    data() {
        return {
            submittedTutupShiftBegin: false,
            setdataringksanpenjualan: null,
            setPageHistory: null,
            dataDate: new Date(),
            tglJam: null,
            dataForTutupOutletModal: {},
            datakonfrim:{
                keterangan:""
            },
        };
    },
    props: {
        dataForTutupOutlet: Date
    },
    watch: {
        dataForTutupOutlet: function(){
        this.createDateFormat()      
        }
    },
    components: {
        TutupOutlet,
        RiwayatTutupOutlet,
        RingkasanPenjualan
    },
    methods: {
        ...mapActions(["actGetDataCloseOutlet"]),
        dataRingkasan(data) {
            this.setdataringksanpenjualan = data
        },
        dataHistory(data) {
            this.setPageHistory = data
        },
        createDateFormat() {
            const weekday = ["Minggu","Senin","Selasa","Rabu","Kamis","Jumat","Sabtu"]
            var newDate = new Date()
            var tgl = ((newDate.getDate() < 10) ? "0" : "" ) + newDate.getDate();
            var bln = (((newDate.getMonth()+1) < 10)?"0":"") + (newDate.getMonth()+1);
            var th = newDate.getFullYear();
            var jam = ((newDate.getHours() < 10) ? "0" : "" ) + newDate.getHours();
            var menit = ((newDate.getMinutes() < 10)?"0":"") + newDate.getMinutes();
            var detik = ((newDate.getSeconds() < 10)?"0":"") + newDate.getSeconds();
            var formatTgl = this.convertDateDBtoIndo(th+'-'+bln+'-'+tgl);
            this.tglJam = weekday[newDate.getDay()] +', '+ formatTgl +', '+ jam +':'+ menit +':'+ detik
        },
        convertDateDBtoIndo(string) {
            var bulanIndo = ['', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September' , 'Oktober', 'November', 'Desember'];
        
            var tanggal = string.split("-")[2];
            var bulan = string.split("-")[1];
            var tahun = string.split("-")[0];
        
            return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
        },
        // riwayat tutup outlet
        riwayatTutupOutlet(){
            $("#modalTutupOutletBegin").modal("hide");
            setTimeout(() => $("#modalRiwayatTutupOutlet").modal("show"), 500);
        },
        // tutup outlet
        tutupOutlet() {
            this.submittedTutupShiftBegin = true
            this.datakonfrim = {keterangan:"Untuk melakukan tutup outlet diperlukan koneksi internet. Silahkan cek<br/>koneksi internet Anda kemudian coba lagi!"}
            if (this.connectionStatus == "Offline"){
                $("#modalkonfirmasioffline").modal("show");
                this.submittedTutupShiftBegin = false
            } else {
                this.$store.dispatch("actGetDataCloseOutlet").then((data) => {
                    this.submittedTutupShiftBegin = false
                    this.dataRingkasan(data)
                    this.dataForTutupOutletModal = data
                    $("#modalTutupOutletBegin").modal("hide");
                    setTimeout(() => $("#modalTutupOutlet").modal("show"), 500);
                    this.dataDate = new Date()
                })
                .catch((error) => {
                    this.submittedTutupShiftBegin = false
                    console.log(error);
                });
            }
        },
    },
    computed: {
    ...mapGetters(["connectionStatus"]),
    },
}
</script>
<style scoped>
.btn-outline-success:hover {
    background-color: transparent;
}
</style>