import axios from 'axios'
import $ from "jquery";

export default ({
    state: {
        products: localStorage.getItem("produk") ? JSON.parse(localStorage.getItem("produk")) : [],
        category: localStorage.getItem("kategori") ? JSON.parse(localStorage.getItem("kategori")) : [],
        discount: localStorage.getItem("diskon") ? JSON.parse(localStorage.getItem("diskon")) : [],
        customer: localStorage.getItem("pelanggan") ? JSON.parse(localStorage.getItem("pelanggan")) : [],
        totaldownload: localStorage.getItem("totaldownload") ? JSON.parse(localStorage.getItem("totaldownload")) : [],
        options: localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [],
        satuan: localStorage.getItem("satuan") ? JSON.parse(localStorage.getItem("satuan")) : [],
        itemingredients: localStorage.getItem("itemingredients") ? JSON.parse(localStorage.getItem("itemingredients")) : [],
        cashbank: localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [],
        supplier: localStorage.getItem("supplier") ? JSON.parse(localStorage.getItem("supplier")) : [],
        waitress: localStorage.getItem("waitress") ? JSON.parse(localStorage.getItem("waitress")) : [],
        variant: localStorage.getItem("variant") ? JSON.parse(localStorage.getItem("variant")) : [],
        mastertax: localStorage.getItem("mastertax") ? JSON.parse(localStorage.getItem("mastertax")) : [],
        modifier: localStorage.getItem("modifier") ? JSON.parse(localStorage.getItem("modifier")) : [],
        modifierdetail: localStorage.getItem("modifierdetail") ? JSON.parse(localStorage.getItem("modifierdetail")) : [],
        modifierdetailingredients: localStorage.getItem("modifierdetailingredients") ? JSON.parse(localStorage.getItem("modifierdetailingredients")) : [],
        masteritemdetailmodifier: localStorage.getItem("masteritemdetailmodifier") ? JSON.parse(localStorage.getItem("masteritemdetailmodifier")) : [],
        masterdiningtable: localStorage.getItem("masterdiningtable") ? JSON.parse(localStorage.getItem("masterdiningtable")) : [],
        masterarea: localStorage.getItem("masterarea") ? JSON.parse(localStorage.getItem("masterarea")) : [],
        masteropsimakan: localStorage.getItem("masteropsimakan") ? JSON.parse(localStorage.getItem("masteropsimakan")) : [],
        masterpromo: localStorage.getItem("masterpromo") ? JSON.parse(localStorage.getItem("masterpromo")) : [],
        masterdigitalpayment: localStorage.getItem("masterdigitalpayment") ? JSON.parse(localStorage.getItem("masterdigitalpayment")) : [],
        usertablet: localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : [],
        cashbankin: localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [],
        cloudcashbankin: localStorage.getItem("cloudcashbankin") ? JSON.parse(localStorage.getItem("cloudcashbankin")) : [],
        cashbankout: localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [],
        cloudcashbankout: localStorage.getItem("cloudcashbankout") ? JSON.parse(localStorage.getItem("cloudcashbankout")) : [],
        purchase: localStorage.getItem("purchase") ? JSON.parse(localStorage.getItem("purchase")) : [],
        purchaseitemdetail: localStorage.getItem("purchaseitemdetail") ? JSON.parse(localStorage.getItem("purchaseitemdetail")) : [],
        pendingsale: localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [],
        pendingsaleitemdetail: localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [],
        pendingsaleitemdetailingredients: localStorage.getItem("pendingsaleitemdetailingredients") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailingredients")) : [],
        pendingsaleitemproduct: localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [],
        pendingsaleitemdetailmodifier: localStorage.getItem("pendingsaleitemdetailmodifier") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailmodifier")) : [],
        pendingsalemodifierdetailingredients: localStorage.getItem("pendingsalemodifierdetailingredients") ? JSON.parse(localStorage.getItem("pendingsalemodifierdetailingredients")) : [],
        pendingsaleitemdetailtax: localStorage.getItem("pendingsaleitemdetailtax") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailtax")) : [],
        stockopname: localStorage.getItem("stockopname") ? JSON.parse(localStorage.getItem("stockopname")) : [],
        stockopnamedetail: localStorage.getItem("stockopnamedetail") ? JSON.parse(localStorage.getItem("stockopnamedetail")) : [],
        opencloseoutlet: localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [],
        pendingsalediningtabledetail: localStorage.getItem("pendingsalediningtabledetail") ? JSON.parse(localStorage.getItem("pendingsalediningtabledetail")) : [],
        pendingsalediscountdetail: localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [],
        latesttransactionid: localStorage.getItem("latesttransactionid") ? JSON.parse(localStorage.getItem("latesttransactionid")) : [],
        imageitems2: localStorage.getItem("imageitems2") ? JSON.parse(localStorage.getItem("imageitems2")) : [],
        tglexpired: localStorage.getItem("tglexpired") ? JSON.parse(localStorage.getItem("tglexpired")) : [],
        synoption: localStorage.getItem("synoption") ? JSON.parse(localStorage.getItem("synoption")) : [],
        unlockSaleCloud: localStorage.getItem("unlockSaleCloud") ? JSON.parse(localStorage.getItem("unlockSaleCloud")) : [],
        synlogsync2: localStorage.getItem("synlogsync2") ? JSON.parse(localStorage.getItem("synlogsync2")) : [],
        fcmnotreceived: localStorage.getItem("fcmnotreceived") ? JSON.parse(localStorage.getItem("fcmnotreceived")) : [],
        masterpricebytype: localStorage.getItem("masterpricebytype") ? JSON.parse(localStorage.getItem("masterpricebytype")) : [], 
    },
    getters: {
        products: state => state.products,
        category: state => state.category,
        discount: state => state.discount,
        customer: state => state.customer,
        totaldownload: state => state.totaldownload,
        options: state => state.options,
        satuan: state => state.satuan,
        itemingredients: state => state.itemingredients,
        cashbank: state => state.cashbank,
        supplier: state => state.supplier,
        waitress: state => state.waitress,
        variant: state => state.variant,
        mastertax: state => state.mastertax,
        masterdiscount: state => state.masterdiscount,
        modifier: state => state.modifier,
        modifierdetail: state => state.modifierdetail,
        modifierdetailingredients: state => state.modifierdetailingredients,
        masteritemdetailmodifier: state => state.masteritemdetailmodifier,
        masterdiningtable: state => state.masterdiningtable,
        masterarea: state => state.masterarea,
        masteropsimakan: state => state.masteropsimakan,
        masterpromo: state => state.masterpromo,
        masterdigitalpayment: state => state.masterdigitalpayment,
        usertablet: state => state.usertablet,
        cashbankin: state => state.cashbankin,
        cloudcashbankin: state => state.cloudcashbankin,
        cashbankout: state => state.cashbankout,
        cloudcashbankout: state => state.cloudcashbankout,
        purchase: state => state.purchase,
        purchaseitemdetail: state => state.purchaseitemdetail,
        pendingsale: state => state.pendingsale,
        pendingsaleitemdetail: state => state.pendingsaleitemdetail,
        pendingsaleitemdetailingredients: state => state.pendingsaleitemdetailingredients,
        pendingsaleitemproduct: state => state.pendingsaleitemproduct,
        pendingsaleitemdetailmodifier: state => state.pendingsaleitemdetailmodifier,
        pendingsalemodifierdetailingredients: state => state.pendingsalemodifierdetailingredients,
        pendingsaleitemdetailtax: state => state.pendingsaleitemdetailtax,
        stockopname: state => state.stockopname,
        stockopnamedetail: state => state.stockopnamedetail,
        opencloseoutlet: state => state.opencloseoutlet,
        pendingsalediningtabledetail: state => state.pendingsalediningtabledetail,
        pendingsalediscountdetail: state => state.pendingsalediscountdetail,
        latesttransactionid: state => state.latesttransactionid,
        imageitems2: state => state.imageitems2,
        tglexpired: state => state.tglexpired,
        synoption: state => state.synoption,
        unlockSaleCloud: state => state.unlockSaleCloud,
        synlogsync2: state => state.synlogsync2,
        fcmnotreceived: state => state.fcmnotreceived,
        masterpricebytype: state => state.masterpricebytype
    },
    actions: {
        cekopenoutlet({rootState,commit}){
            return new Promise((resolve) => {
                const dataopenid =JSON.parse(localStorage.getItem("opencloseoutlet"));
                for (let index = 0; index < dataopenid.length; index++) {
                    if (dataopenid[index].DeviceID == rootState.auth.outletID
                        && dataopenid[index].PerusahaanNo == rootState.items.options[0].PerusahaanNo
                        && dataopenid[index].DeviceNo == localStorage.getItem("deviceNo")
                        && dataopenid[index].CloseDate=="" 
                        && dataopenid[index].CloseTime=="")
                    {
                        resolve(true)
                        commit('set_isOpenOutlet',true)
                    }
                }
                resolve(false)
                commit('set_isOpenOutlet',false)
            })
        },
        downloadProduct({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getitems",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_product', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_product')
                        reject(error)
                    },
                })
            });
        },
        downloadCategory({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcategory",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_category', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_category')
                        reject(error)
                    },
                });
            })
        },

        downloadcustomer({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcustomer",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        let arrayObj = data.data;
                        if (arrayObj.length>0) {
                            arrayObj.forEach(function(obj){
                                obj.SynMode=0;
                            });
                        }
                        commit('set_customer', {data:arrayObj})
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_customer')
                        reject(error)
                    },
                });
            })
        },

        downloadtotaldownload({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/gettotaldownload",
                    data: {
                        devid: rootState.auth.outletID,
                        varian: "Nuta",
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_totaldownload', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_totaldownload')
                        reject(error)
                    },
                });
            })
        },
        downloadoptions({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getoptions",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_options', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_options')
                        reject(error)
                    },
                });
            })
        },
        downloaddataoptions({ commit, rootState }) {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getoptions",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_options', data)
                    },
                        error: () => {
                    },
                });
        },
        downloadsatuan({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getsatuan",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_satuan', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_satuan')
                        reject(error)
                    },
                });
            })
        },
        downloaditemingredients({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getitemingredients",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_itemingredients', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_itemingredients')
                        reject(error)
                    },
                });
            })
        },
        downloadcashbank({ commit, rootState,dispatch }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcashbank",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_cashbank', data)
                        dispatch('actCekCashBank')
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_cashbank')
                        reject(error)
                    },
                });
            })
        },
        actCekCashBank({ rootState, dispatch }){
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            cashbank = cashbank.filter((item) => {
                return (item.AccountID == 1 && item.DeviceID == rootState.auth.outletID && item.DeviceNo == rootState.auth.deviceNo);
            });
            if(cashbank.length == 0){
                dispatch('actCreateCashBank')
            }
        },
        actCreateCashBank({ rootState, dispatch }) {
            var cashBankData = {
                AccountID: 1,
                AccountType: 1,
                AccountName: "Kasir Perangkat ke-" + rootState.auth.deviceNo,
                BankName: "",
                AccountNumber: "",
                HasEDC: "false",
                ClearingDayEDC: "0",
                Varian: "Nuta",
                DeviceID: rootState.auth.outletID,
                DeviceNo: rootState.auth.deviceNo,
                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                PerusahaanID: "-1",
                CreatedVersionCode: rootState.versionCode,
                EditedVersionCode: "0",
                RowVersion: "1",
                GopayServerKey: "",
                GopayClientKey: "",
                GopayMerchantID: "",
                IsTransfer: 1,
                IsQRStatic: 0,
                IsDigitalPayment: 0,
                IsEDC: 0,
                IsRounding: 0,
                SynMode: 1,
            }

            //push to localstorage cashbank
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            cashbank.push(cashBankData)
            localStorage.setItem('cashbank', JSON.stringify(cashbank))

            // cek jika connect internet untuk sync cashbank
            if(rootState.connectionStatus == "Online"){
                dispatch('actSyncDataCashBank', "1." + rootState.auth.deviceNo);
            }
        },
        downloadsupplier({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getsupplier",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_supplier', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_supplier')
                        reject(error)
                    },
                });
            })
        },
        downloadwaitress({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getwaitress",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_waitress', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_waitress')
                        reject(error)
                    },
                });
            })
        },
        downloadvariant({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getvariant",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_variant', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_variant')
                        reject(error)
                    },
                });
            })
        },
        downloadmastertax({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmastertax",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_mastertax', data)
                        var withOutMynutapos = data.data.filter((tax) => {
                            return (tax.IsMyNutapos == "0");
                        });
                        var dataTax = ({"checkedTax": withOutMynutapos})
                        commit('mutSetTax', dataTax)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_mastertax')
                        reject(error)
                    },
                });
            })
        },
        downloadmasterdiscount({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasterdiscount",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masterdiscount', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masterdiscount')
                        reject(error)
                    },
                });
            })
        },
        downloadmodifier({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmodifier",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_modifier', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_modifier')
                        reject(error)
                    },
                });
            })
        },

        downloadmodifierdetail({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmodifierdetail",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_modifierdetail', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_modifierdetail')
                        reject(error)
                    },
                });
            })
        },

        downloadmodifierdetailingredients({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmodifierdetailingredients",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_modifierdetailingredients', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_modifierdetailingredients')
                        reject(error)
                    },
                });
            })
        },

        downloadmasteritemdetailmodifier({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasteritemdetailmodifier",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masteritemdetailmodifier', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masteritemdetailmodifier')
                        reject(error)
                    },
                });
            })
        },

        downloadmasterdiningtable({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasterdiningtable",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masterdiningtable', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masterdiningtable')
                        reject(error)
                    },
                });
            })
        },

        downloadmasterarea({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasterarea",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masterarea', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masterarea')
                        reject(error)
                    },
                });
            })
        },

        downloadmasteropsimakan({ commit, rootState, dispatch }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasteropsimakan",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masteropsimakan', data)
                        dispatch('actSetOpsiMakanBegin')
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masteropsimakan')
                        reject(error)
                    },
                });
            })
        },

        downloadmasterpromo({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasterpromo",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masterpromo', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masterpromo')
                        reject(error)
                    },
                });
            })
        },

        downloadmasterdigitalpayment({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasterdigitalpayment",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masterdigitalpayment', data)
                        //dispatch('actCekCashBank')
                        //dispatch('actSetUserTablet')
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_masterdigitalpayment')
                        reject(error)
                    },
                });
            })
        },

        downloadusertablet({ commit, rootState, dispatch }) {
            if(rootState.auth.outletID){
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getusertablet",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_usertablet', data)
                        dispatch('actCekUserTablet')
                        dispatch('actCekUserTablet')
                        dispatch('actSetUserTablet')
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_usertablet')
                        reject(error)
                    },
                });
            })
            }
        },
        actCekUserTablet({ rootState, dispatch }){
            if(rootState.auth.outletID){
            var usertablet = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : [];
            usertablet = usertablet.filter((item) => {
                return (item.Level == "999" && item.DeviceID == rootState.auth.outletID);
            });
            if(usertablet.length == 0){
                dispatch('actCreateUserTablet')
            }
            }
        },
        actCreateUserTablet({ rootState, dispatch }) {
            if(rootState.items.options.length > 0){
            var usertablet = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : [];
            var userID = 1
            if(usertablet.length > 0){
                userID = parseFloat(usertablet.length) + 1
            }
            var userTabletData = {
                UserID: userID,
                Level: "999",
                Username: "Admin",
                Password: "12345",
                Email: "",
                AllowKasir: "1",
                AllowEditNamaStand: "1",
                AllowTambahMenu: "1",
                AllowEditMenu: "1",
                AllowHapusMenu: "1",
                AllowEditPenjualan: "1",
                AllowHapusPenjualan: "1",
                AllowHapusOrder: "1",
                AllowZoomIn: "1",
                AllowZoomOut: "1",
                AllowDaftarPenjualan: "1",
                AllowPrINTDaftarPenjualan: "1",
                AllowTambahDataRekening: "1",
                AllowEditDataRekening: "1",
                AllowHapusDataRekening: "1",
                AllowTambahUangMasuk: "1",
                AllowEditUangMasuk: "1",
                AllowHapusUangMasuk: "1",
                AllowTambahUangKeluar: "1",
                AllowEditUangKeluar: "1",
                AllowHapusUangKeluar: "1",
                AllowLaporanPenjualan: "1",
                AllowLaporanRekapPenjualan: "1",
                AllowLaporanTipePenjualan: "1",
                AllowLaporanRekapPembayaran: "1",
                AllowLaporanSaldoKasRekening: "1",
                AllowLaporanLaba: "1",
                AllowLaporanPembelian: "1",
                AllowLaporanRekapPembelian: "1",
                AllowLaporanStok: "1",
                AllowLaporanKartuStok: "1",
                AllowLaporanRekapMutasiStok: "1",
                AllowLaporanAwan: "1",
                AllowPromo: "1",
                AllowPengaturan: "1",
                AllowAktivasi: "1",
                AllowHapusDataTransaksi: "1",
                AllowPembelian: "1",
                AllowTambahItemPembelian: "1",
                AllowEditItemPembelian: "1",
                AllowHapusItemPembelian: "1",
                AllowEditPembelian: "1",
                AllowHapusPembelian: "1",
                AllowTambahSupplier: "1",
                AllowEditSupplier: "1",
                AllowHapusSupplier: "1",
                AllowKoreksiStok: "1",
                AllowTambahItemStok: "1",
                AllowEditItemStok: "1",
                AllowHapusItemStok: "1",
                AllowEditKoreksiStok: "1",
                AllowHapusKoreksiStok: "1",
                AllowDownloadDataAwan: "1",
                AllowPrinter: "1",
                AllowModul: "1",
                AllowPajak: "1",
                AllowDiskon: "1",
                AllowMeja: "1",
                AllowTipePembayaran: "1",
                AllowTipePenjualan: "1",
                AllowCopyDariOutletLain: "1",
                AllowUserDanHakAkses: "1",
                DeviceID: rootState.auth.outletID,
                DeviceNo: rootState.auth.deviceNo,
                PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                CreatedVersionCode: rootState.versionCode,
                EditedVersionCode : "0",
                RowVersion: "1",
                Varian: "Nuta",
                Jabatan: "",
                AllowLaporanMetodePembayaran: "1",
                AllowEditCustomer: "1",
                AllowHapusCustomer: "1",
                AllowSimpanOrder: "1",
                AllowBayar: "1",
                AllowCetakUlangStruk: "1",
                SynMode: 1,
            }

            //push to localstorage usertablet
            usertablet.push(userTabletData)
            localStorage.setItem('usertablet', JSON.stringify(usertablet))

            // cek jika connect internet untuk sync usertablet
            if(rootState.connectionStatus == "Online"){
                dispatch('actSyncDataUserTablet', userID + "." + rootState.auth.deviceNo);
            }
            }
        },

        downloadcashbankin({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcashbankin",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        let arrayObj = data.data;
                        if (arrayObj.length>0) {
                            arrayObj.forEach(function(obj){
                                obj.SynMode=0;
                            });
                        }
                        commit('set_cashbankin', {data: arrayObj})
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_cashbankin')
                        reject(error)
                    },
                });
            })
        },

        downloadcloudcashbankin({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcloudcashbankin",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_cloudcashbankin', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_cloudcashbankin')
                        reject(error)
                    },
                });
            })
        },

        downloadcashbankout({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcashbankout",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_cashbankout', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_cashbankout')
                        reject(error)
                    },
                });
            })
        },

        downloadcloudcashbankout({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getcloudcashbankout",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_cloudcashbankout', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_cloudcashbankout')
                        reject(error)
                    },
                });
            })
        },

        downloadpurchase({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpurchase",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_purchase', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_purchase')
                        reject(error)
                    },
                });
            })
        },

        downloadpurchaseitemdetail({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpurchaseitemdetail",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_purchaseitemdetail', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_purchaseitemdetail')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsale({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsale",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsale', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsale')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsaleitemdetail({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsaleitemdetail",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsaleitemdetail', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsaleitemdetail')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsaleitemdetailingredients({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsaleitemdetailingredients",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsaleitemdetailingredients', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsaleitemdetailingredients')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsaleitemproduct({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsaleitemproduct",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsaleitemproduct', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsaleitemproduct')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsaleitemdetailmodifier({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsaleitemdetailmodifier",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsaleitemdetailmodifier', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsaleitemdetailmodifier')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsalemodifierdetailingredients({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsalemodifierdetailingredients",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsalemodifierdetailingredients', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsalemodifierdetailingredients')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsaleitemdetailtax({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsaleitemdetailtax",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsaleitemdetailtax', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsaleitemdetailtax')
                        reject(error)
                    },
                });
            })
        },

        downloadstockopname({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getstockopname",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_stockopname', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_stockopname')
                        reject(error)
                    },
                });
            })
        },

        downloadstockopnamedetail({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getstockopnamedetail",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_stockopnamedetail', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_stockopnamedetail')
                        reject(error)
                    },
                });
            })
        },

        downloadopencloseoutlet({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getopencloseoutlet",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        let arrayObj = data.data;
                        if (arrayObj.length>0) {
                            arrayObj.forEach(function(obj){
                                obj.SynMode=0;
                            });
                        }
                        commit('set_opencloseoutlet',{data: arrayObj})
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_opencloseoutlet')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsalediningtabledetail({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsalediningtabledetail",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsalediningtabledetail', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsalediningtabledetail')
                        reject(error)
                    },
                });
            })
        },

        downloadpendingsalediscountdetail({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getpendingsalediscountdetail",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_pendingsalediscountdetail', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_pendingsalediscountdetail')
                        reject(error)
                    },
                });
            })
        },

        downloadlatesttransactionid({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getlatesttransactionid",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_latesttransactionid', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_latesttransactionid')
                        reject(error)
                    },
                });
            })
        },

        downloadimageitems2({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getimageitems2",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_imageitems2', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_imageitems2')
                        reject(error)
                    },
                });
            })
        },

        downloadtglexpired({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/gettglexpired",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_tglexpired', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_tglexpired')
                        reject(error)
                    },
                });
            })
        },

        downloadsynoption({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "ws/synoption",
                    data: {
                        devid: rootState.auth.outletID,
                        model: "0xK01",
                        devno: "",
                        devicesender: "",
                        data: "",
                        username: "",
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_synoption', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_synoption')
                        reject(error)
                    },
                });
            })
        },

        downloadsynlogsync2({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "wslogsync/synlogsync2",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_synlogsync2', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_synlogsync2')
                        reject(error)
                    },
                });
            })
        },

        downloadfcmnotreceived({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getfcmnotreceived",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_getfcmnotreceived', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_getfcmnotreceived')
                        reject(error)
                    },
                });
            })
        },

        downloadmasterpricebytype({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "export/getmasterpricebytype",
                    data: {
                        devid: rootState.auth.outletID,
                    },
                    method: "POST",
                    success: (data) => {
                        commit('set_masterpricebytype', data)
                        resolve(data)
                    },
                    error: (error) => {
                        //commit('reset_getfcmnotreceived')
                        reject(error)
                    },
                });
            })
        },
        actReloadAllPendingSaleFromStorage({ commit }) {
            commit('mutReloadAllPendingSaleFromStorage')
        },
    },
    mutations: {
        set_product(state, data) {
            state.products = data.data
            localStorage.setItem('produk', JSON.stringify(data.data))
        },
        set_category(state, data) {
            state.category = data.data
            localStorage.setItem('kategori', JSON.stringify(data.data))
        },
        set_customer(state, data) {
            state.customer = data.data
            localStorage.setItem('pelanggan', JSON.stringify(data.data))
        },

        set_totaldownload(state, data) {
            localStorage.setItem('totaldownload', JSON.stringify(data))
        },
        set_options(state, data) {
            state.options = data.data
            localStorage.setItem('options', JSON.stringify(data.data))
        },
        set_satuan(state, data) {
            state.satuan = data.data
            localStorage.setItem('satuan', JSON.stringify(data.data))
        },
        set_itemingredients(state, data) {
            state.itemingredients = data.data
            localStorage.setItem('itemingredients', JSON.stringify(data.data))
        },
        set_cashbank(state, data) {
            state.cashbank = data.data
            localStorage.setItem('cashbank', JSON.stringify(data.data))
        },
        set_supplier(state, data) {
            state.supplier = data.data
            localStorage.setItem('supplier', JSON.stringify(data.data))
        },
        set_waitress(state, data) {
            state.waitress = data.data
            localStorage.setItem('waitress', JSON.stringify(data.data))
        },
        set_variant(state, data) {
            state.variant = data.data
            localStorage.setItem('variant', JSON.stringify(data.data))
        },
        set_mastertax(state, data) {
            state.mastertax = data.data
            localStorage.setItem('mastertax', JSON.stringify(data.data))
        },
        set_masterdiscount(state, data) {
            state.discount = data.data
            localStorage.setItem('diskon', JSON.stringify(data.data))
        },
        set_modifier(state, data) {
            state.modifier = data.data
            localStorage.setItem('modifier', JSON.stringify(data.data))
        },
        set_modifierdetail(state, data) {
            state.modifierdetail = data.data
            localStorage.setItem('modifierdetail', JSON.stringify(data.data))
        },
        set_modifierdetailingredients(state, data) {
            state.modifierdetailingredients = data.data
            localStorage.setItem('modifierdetailingredients', JSON.stringify(data.data))
        },
        set_masteritemdetailmodifier(state, data) {
            state.masteritemdetailmodifier = data.data
            localStorage.setItem('masteritemdetailmodifier', JSON.stringify(data.data))
        },
        set_masterdiningtable(state, data) {
            state.masterdiningtable = data.data
            localStorage.setItem('masterdiningtable', JSON.stringify(data.data))
        },
        set_masterarea(state, data) {
            state.masterarea = data.data
            localStorage.setItem('masterarea', JSON.stringify(data.data))
        },
        set_masteropsimakan(state, data) {
            state.masteropsimakan = data.data
            localStorage.setItem('masteropsimakan', JSON.stringify(data.data))
        },
        set_masterpromo(state, data) {
            state.masterpromo = data.data
            localStorage.setItem('masterpromo', JSON.stringify(data.data))
        },
        set_masterdigitalpayment(state, data) {
            state.masterdigitalpayment = data.data
            localStorage.setItem('masterdigitalpayment', JSON.stringify(data.data))
        },
        set_usertablet(state, data) {
            state.usertablet = data.data
            localStorage.setItem('usertablet', JSON.stringify(data.data))
        },
        set_cashbankin(state, data) {
            var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            cashbankin = cashbankin.filter((item) => {
                return (item.SynMode == "1" || item.SynMode == "2" || item.SynMode == "3");
            });
            if(cashbankin.length > 0){
                data.data.push(...cashbankin)
            }

            localStorage.setItem('cashbankin', JSON.stringify(data.data))
            state.cashbankin = data.data
        },
        set_cloudcashbankin(state, data) {
            localStorage.setItem('cloudcashbankin', JSON.stringify(data.data))
            state.cloudcashbankin = data.data
        },
        set_cashbankout(state, data) {
            var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            cashbankout = cashbankout.filter((item) => {
                return (item.SynMode == "1" || item.SynMode == "2");
            });
            if(cashbankout.length > 0){
                data.data.push(...cashbankout)
            }

            localStorage.setItem('cashbankout', JSON.stringify(data.data))
            state.cashbankout = data.data
        },
        set_cloudcashbankout(state, data) {
            localStorage.setItem('cloudcashbankout', JSON.stringify(data.data))
            state.cloudcashbankout = data.data
        },
        set_purchase(state, data) {
            state.purchase = data.data
            localStorage.setItem('purchase', JSON.stringify(data.data))
        },
        set_purchaseitemdetail(state, data) {
            state.purchaseitemdetail = data.data
            localStorage.setItem('purchaseitemdetail', JSON.stringify(data.data))
        },
        mutReloadAllPendingSaleFromStorage(state){
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var pendingsaleitemdetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
            var pendingsaleitemdetailingredients = localStorage.getItem("pendingsaleitemdetailingredients") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailingredients")) : [];
            var pendingsaleitemproduct = localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [];
            var pendingsaleitemdetailtax = localStorage.getItem("pendingsaleitemdetailtax") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailtax")) : [];
            var pendingsalediscountdetail = localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [];
            
            state.pendingsale = pendingsale
            state.pendingsaleitemdetail = pendingsaleitemdetail
            state.pendingsaleitemdetailingredients = pendingsaleitemdetailingredients
            state.pendingsaleitemproduct = pendingsaleitemproduct
            state.pendingsaleitemdetailtax = pendingsaleitemdetailtax
            state.pendingsalediscountdetail = pendingsalediscountdetail

        },
        set_pendingsale(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length > 0){
                data.data.push(...pendingsale)
            }

            localStorage.setItem('pendingsale', JSON.stringify(data.data))
            state.pendingsale = data.data
        },
        set_pendingsaleitemdetail(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var pendingsaleitemdetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length > 0){
                pendingsale.forEach(function (pendingsaleData) {
                    var detail = pendingsaleitemdetail.filter((itemdetail) => {
                        return (itemdetail.TransactionID == pendingsaleData.TransactionID && itemdetail.TransactionDeviceNo == pendingsaleData.DeviceNo);
                    });
                    if(detail.length > 0){
                        data.data.push(...detail)
                    }
                });
            }
            localStorage.setItem('pendingsaleitemdetail', JSON.stringify(data.data))
            state.pendingsaleitemdetail = data.data
        },
        set_pendingsaleitemdetailingredients(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var pendingsaleitemdetailingredients = localStorage.getItem("pendingsaleitemdetailingredients") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailingredients")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length > 0){
                pendingsale.forEach(function (pendingsaleData) {
                    var detail = pendingsaleitemdetailingredients.filter((itemdetail) => {
                        return (itemdetail.TransactionID == pendingsaleData.TransactionID && itemdetail.TransactionDeviceNo == pendingsaleData.DeviceNo);
                    });
                    if(detail.length > 0){
                        data.data.push(...detail)
                    }
                });
            }
            localStorage.setItem('pendingsaleitemdetailingredients', JSON.stringify(data.data))
            state.pendingsaleitemdetailingredients = data.data
        },
        set_pendingsaleitemproduct(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var pendingsaleitemproduct = localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length > 0){
                pendingsale.forEach(function (pendingsaleData) {
                    var detail = pendingsaleitemproduct.filter((itemdetail) => {
                        return (itemdetail.TransactionID == pendingsaleData.TransactionID && itemdetail.TransactionDeviceNo == pendingsaleData.DeviceNo);
                    });
                    if(detail.length > 0){
                        data.data.push(...detail)
                    }
                });
            }
            localStorage.setItem('pendingsaleitemproduct', JSON.stringify(data.data))
            state.pendingsaleitemproduct = data.data
        },
        set_pendingsaleitemdetailmodifier(state, data) {
            localStorage.setItem('pendingsaleitemdetailmodifier', JSON.stringify(data.data))
            state.pendingsaleitemdetailmodifier = data.data
        },
        set_pendingsalemodifierdetailingredients(state, data) {
            localStorage.setItem('pendingsalemodifierdetailingredients', JSON.stringify(data.data))
            state.pendingsalemodifierdetailingredients = data.data
        },
        set_pendingsaleitemdetailtax(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var pendingsaleitemdetailtax = localStorage.getItem("pendingsaleitemdetailtax") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailtax")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length > 0){
                pendingsale.forEach(function (pendingsaleData) {
                    var detail = pendingsaleitemdetailtax.filter((itemdetail) => {
                        return (itemdetail.TransactionID == pendingsaleData.TransactionID && itemdetail.TransactionDeviceNo == pendingsaleData.DeviceNo);
                    });
                    if(detail.length > 0){
                        data.data.push(...detail)
                    }
                });
            }
            localStorage.setItem('pendingsaleitemdetailtax', JSON.stringify(data.data))
            state.pendingsaleitemdetailtax = data.data
        },
        set_stockopname(state, data) {
            state.stockopname = data.data
            localStorage.setItem('stockopname', JSON.stringify(data.data))
        },
        set_stockopnamedetail(state, data) {
            state.stockopnamedetail = data.data
            localStorage.setItem('stockopnamedetail', JSON.stringify(data.data))
        },
        set_opencloseoutlet(state, data) {
            var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
            opencloseoutlet = opencloseoutlet.filter((item) => {
                return (item.SynMode == "1" || item.SynMode == "2");
            });
            if(opencloseoutlet.length > 0){
                data.data.push(...opencloseoutlet)
            }

            localStorage.setItem('opencloseoutlet', JSON.stringify(data.data))
            state.opencloseoutlet = data.data
        },
        set_pendingsalediningtabledetail(state, data) {
            state.pendingsalediningtabledetail = data.data
            localStorage.setItem('pendingsalediningtabledetail', JSON.stringify(data.data))
        },
        set_pendingsalediscountdetail(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            var pendingsalediscountdetail = localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length > 0){
                pendingsale.forEach(function (pendingsaleData) {
                    var detail = pendingsalediscountdetail.filter((itemdetail) => {
                        return (itemdetail.TransactionID == pendingsaleData.TransactionID && itemdetail.TransactionDeviceNo == pendingsaleData.DeviceNo);
                    });
                    if(detail.length > 0){
                        data.data.push(...detail)
                    }
                });
            }
            localStorage.setItem('pendingsalediscountdetail', JSON.stringify(data.data))
            state.pendingsalediscountdetail = data.data
        },
        set_latesttransactionid(state, data) {
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            pendingsale = pendingsale.filter((item) => {
                return (item.SynMode == "1");
            });
            if(pendingsale.length >= 0){
                localStorage.setItem('latesttransactionid', JSON.stringify(data.data))
                state.latesttransactionid = data.data
            }
        },
        set_imageitems2(state, data) {
            state.imageitems2 = data.data
            localStorage.setItem('imageitems2', JSON.stringify(data.data))
        },
        set_tglexpired(state, data) {
            state.tglexpired = data.data
            localStorage.setItem('tglexpired', JSON.stringify(data.data))
        },
        set_synoption(state, data) {
            state.synoption = data.data
            localStorage.setItem('synoption', JSON.stringify(data.data))
        },
        set_unlockSaleCloud(state, data) {
            state.unlockSaleCloud = data.data
            localStorage.setItem('unlockSaleCloud', JSON.stringify(data.data))
        },
        set_synlogsync2(state, data) {
            state.synlogsync2 = data.data
            localStorage.setItem('synlogsync2', JSON.stringify(data.data))
        },
        set_fcmnotreceived(state, data) {
            state.fcmnotreceived = data.data
            localStorage.setItem('fcmnotreceived', JSON.stringify(data.data))
        },
        set_masterpricebytype(state, data) {
            state.masterpricebytype = data.data
            localStorage.setItem('masterpricebytype', JSON.stringify(data.data))
        },
        mutResetMasterData(state){
            // remove data master in localstorage and reset state data master
            localStorage.removeItem('produk')
            state.products = []
            localStorage.removeItem('kategori')
            state.category = []
            localStorage.removeItem('diskon')
            state.discount = []
            localStorage.removeItem('pelanggan')
            state.customer = []
            localStorage.removeItem('totaldownload')
            state.totaldownload = []
            localStorage.removeItem('options')
            //state.options = []
            localStorage.removeItem('satuan')
            state.satuan = []
            localStorage.removeItem('itemingredients')
            state.itemingredients = []
            localStorage.removeItem('cashbank')
            state.cashbank = []
            localStorage.removeItem('supplier')
            state.supplier = []
            localStorage.removeItem('waitress')
            state.waitress = []
            localStorage.removeItem('variant')
            state.variant = []
            localStorage.removeItem('mastertax')
            state.mastertax = []
            localStorage.removeItem('modifier')
            state.modifier = []
            localStorage.removeItem('modifierdetail')
            state.modifierdetail = []
            localStorage.removeItem('modifierdetailingredients')
            state.modifierdetailingredients = []
            localStorage.removeItem('masteritemdetailmodifier')
            state.masteritemdetailmodifier = []
            
            localStorage.removeItem('masterdiningtable')
            state.masterdiningtable = []
            localStorage.removeItem('masterarea')
            state.masterarea = []
            localStorage.removeItem('masteropsimakan')
            state.masteropsimakan = []
            localStorage.removeItem('masterpromo')
            state.masterpromo = []
            localStorage.removeItem('masterdigitalpayment')
            state.masterdigitalpayment = []
            localStorage.removeItem('usertablet')
            state.usertablet = []
            localStorage.removeItem('cashbankin')
            state.cashbankin = []
            localStorage.removeItem('cloudcashbankin')
            state.cloudcashbankin = []
            localStorage.removeItem('cashbankout')
            state.cashbankout = []
            localStorage.removeItem('cloudcashbankout')
            state.cloudcashbankout = []
            localStorage.removeItem('purchase')
            state.purchase = []
            localStorage.removeItem('purchaseitemdetail')
            state.purchaseitemdetail = []
            localStorage.removeItem('pendingsale')
            state.pendingsale = []
            localStorage.removeItem('pendingsaleitemdetail')
            state.pendingsaleitemdetail = []
            localStorage.removeItem('pendingsaleitemdetailingredients')
            state.pendingsaleitemdetailingredients = []
            localStorage.removeItem('pendingsaleitemproduct')
            state.pendingsaleitemproduct = []
            
            localStorage.removeItem('pendingsaleitemdetailmodifier')
            state.pendingsaleitemdetailmodifier = []
            localStorage.removeItem('pendingsalemodifierdetailingredients')
            state.pendingsalemodifierdetailingredients = []
            localStorage.removeItem('pendingsaleitemdetailtax')
            state.pendingsaleitemdetailtax = []
            localStorage.removeItem('stockopname')
            state.stockopname = []
            localStorage.removeItem('stockopnamedetail')
            state.stockopnamedetail = []
            localStorage.removeItem('opencloseoutlet')
            state.opencloseoutlet = []
            localStorage.removeItem('pendingsalediningtabledetail')
            state.pendingsalediningtabledetail = []
            localStorage.removeItem('pendingsalediscountdetail')
            state.pendingsalediscountdetail = []
            localStorage.removeItem('latesttransactionid')
            state.latesttransactionid = []
            localStorage.removeItem('imageitems2')
            state.imageitems2 = []
            localStorage.removeItem('tglexpired')
            state.tglexpired = []
            localStorage.removeItem('synoption')
            state.synoption = []
            localStorage.removeItem('unlockSaleCloud')
            state.unlockSaleCloud = []
            localStorage.removeItem('synlogsync2')
            state.synlogsync2 = []
            localStorage.removeItem('fcmnotreceived')
            state.fcmnotreceived = []
            localStorage.removeItem('masterpricebytype')
            state.masterpricebytype = []
        },
        add_customer(state,data) {
            const customer = JSON.parse(localStorage.getItem("pelanggan"));
            let idcustomer = 0
            if (customer.length == 0){
                idcustomer = 1                    
            }
            else{
                idcustomer = parseInt(customer[customer.length-1].CustomerID)+1
            }

            customer.push({
                CustomerID: idcustomer,
                CustomerName:data.data.nama,
                CustomerAddress:data.data.alamat,
                CustomerPhone:data.data.nohp,
                CustomerEmail:data.data.email,
                Note:"",
                Birthday:data.data.tgllahir,
                Varian:"Nuta",
                DeviceID: data.root.auth.outletID,
                PerusahaanNo:data.root.items.options[0].PerusahaanNo,
                PerusahaanID:'-1',
                CreatedVersionCode:data.root.versionCode,
                EditedVersionCode:"0",
                RowVersion:"1",
                TglJamUpdate:"2021-07-21 11:39:00",
                HasBeenDownloaded:"1",
                DeviceNo:data.root.auth.deviceNo,
                SynMode:1
            });
            localStorage.setItem("pelanggan", JSON.stringify(customer));
            state.customer = customer
        },
        update_customer(state,data) {
            const customer = JSON.parse(localStorage.getItem("pelanggan")); 
            for (let index = 0; index < customer.length; index++) {
                if (customer[index].CustomerID == data.idupdate && customer[index].DeviceNo == data.deviceno){
                    customer[index].CustomerName =data.namaupdate;
                    customer[index].CustomerAddress = data.alamatupdate;
                    customer[index].CustomerPhone=data.nohpupdate;
                    customer[index].CustomerEmail= data.emailupdate;
                    customer[index].Birthday = data.tgllahirupdate;
                    customer[index].SynMode = 2;
                }
            }
            localStorage.setItem("pelanggan", JSON.stringify(customer));
            state.customer = customer
        },
        delete_customer(state,data) {
            const customer = JSON.parse(localStorage.getItem("pelanggan")); 
            for (let index = 0; index < customer.length; index++) {
                if (customer[index].CustomerID == data.CustomerID){
                    customer.splice(index,1)
                }
            }
            localStorage.setItem("pelanggan", JSON.stringify(customer));
            state.customer = customer
        },
    }
})