<template>
<div>
  <!-- Modal data pembayaran opsi makan-->
  <div id="modalSimpanOrder" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Simpan Order</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12" v-if="tabHeader.length > 0">
                    <div class="tab justify-content-center">
                        <button v-for="(tab, i) in tabHeader" v-bind:key="i" :class="{'tablinks active': i == '0','tablinks': i != '0'}" :id="tab.ID + '-tab'" @click="openTab(tab.ID)">{{ tab.NamaOpsiMakan}} ({{ tab.Jumlah}})</button>
                    </div>
                    <div v-for="(tab, i) in tabHeader" v-bind:key="i" :id="tab.ID" :class="{'tabcontent active': i == '0','tabcontent': i != '0'}">
                        <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th class="text-center">Pelanggan</th>
                                    <th class="text-center">Item</th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center" v-if="stSetUserTablet.AllowHapusOrder == '1'"></th>
                                </tr>
                            </thead>
                            <tr v-for="(tabData, j) in tabData[tab.ID]" v-bind:key="j">
                                <td><a href="javascript:void(0);" @click="openOrder(tabData)">{{ tabData.DriverName }} {{ tabData.CustomerName }}</a></td>
                                <td><a href="javascript:void(0);" @click="openOrder(tabData)">{{ getDataItem(tabData.TransactionID, tabData.DeviceNo) }}</a></td>
                                <td style="text-align: right;" @click="openOrder(tabData)"><a href="javascript:void(0);">{{ $filters.numberTocurrencyIDR(tabData.Total) }}</a></td>
                                <td style="text-align: center;" v-if="stSetUserTablet.AllowHapusOrder == '1'">
                                  <button
                                  class="btn btn-link"
                                  type="button"
                                  style="display: none;"
                                  @click="cetakUlangSimpanOrder(tabData)" :disabled="submitted" >
                                  Cetak
                                  </button>
                                  <button
                                  class="btn btn-link"
                                  style="color: red !important"
                                  type="button"
                                  @click="hapusSimpanOrder(tabData)" :disabled="submitted" >
                                  Hapus
                                  </button>
                                </td>
                            </tr>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
/* eslint-disable */ //disabled eslint compiler
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SimpanOrderModal_c",
  components: {
  },
  data() {
    return {
        submitted: false,
        tabHeader: [],
        tabData: {},
        dataTransactionID: "0.0",
    };
  },
  
  methods: {
    ...mapActions([
      "actCreateTransaction", 
      "actOpenTransaction", 
      "actHapusOrder",
      ]),
    openOrder(dataTransaction) {
        this.$store.dispatch("actOpenTransaction", dataTransaction);
        $("#modalSimpanOrder").modal("hide");
    },
    openTab(tabName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "block";
        //$("#"+tabName+"-tab").addClass("active");
        //$("#"+tabName+"-tab").attr('class', 'tablinks active');
    },
    tabs() {
        var filterPendingSale = this.pendingsale.filter((sale) => {return (sale.Pending == "true");});
        var opsiMakan = filterPendingSale.reduce((a, c) => {
            let filtered = a.filter((el) => el.OpsiMakanID +'.'+ el.OpsiMakanDeviceNo === c.OpsiMakanID +'.'+ c.OpsiMakanDeviceNo);
            if (filtered.length > 0) {
                a[a.indexOf(filtered[0])].Jumlah += 1;
            } else {
                var z = {
                    "ID": c.OpsiMakanID +'.'+ c.OpsiMakanDeviceNo,
                    "OpsiMakanID": c.OpsiMakanID,
                    "OpsiMakanDeviceNo": c.OpsiMakanDeviceNo,
                    "NamaOpsiMakan": c.NamaOpsiMakan,
                    "Driver": c.DriverName,
                    "Jumlah": 1,
                }
                a.push(z);
            }
            return a;
        }, []);
        
        if(opsiMakan) {
            opsiMakan.sort((a,b) => (a.OpsiMakanID > b.OpsiMakanID) ? 1 : ((b.OpsiMakanID > a.OpsiMakanID) ? -1 : 0));
            this.tabHeader = opsiMakan
            for (let i = 0; i < opsiMakan.length; i++) {
                this.tabData[opsiMakan[i].ID] = this.pendingsale.filter((sale) => {
                    return (
                        sale.OpsiMakanID == opsiMakan[i].OpsiMakanID && sale.OpsiMakanDeviceNo == opsiMakan[i].OpsiMakanDeviceNo && sale.Pending == "true"
                    );
                });
            }
        }
    },
    getDataItem(TransactionID, DeviceNo) {
        var dataFilterd = this.pendingsaleitemdetail.filter((sale) => {
            return (
                sale.TransactionID == TransactionID && sale.TransactionDeviceNo == DeviceNo
            );
        });
        var items = ""
        dataFilterd.forEach(el => {
            items += ", " + el.ItemName
        });
        return items.substring(2)
    },
    cetakUlangSimpanOrder(dataSale) {
      this.dataTransactionID = dataSale.TransactionID +'.'+ dataSale.DeviceNo
      this.$emit('cetakUlangSimpanOrder', this.dataTransactionID)
    },
    hapusSimpanOrder(dataSale) {
      this.$store.dispatch("actHapusOrder", dataSale);
    }
  },
  computed: {
    ...mapGetters(["stOpsiMakan", "pendingsale", "pendingsaleitemdetail", "stSetUserTablet"]),
  },
  watch: {
    pendingsale: function(){
      this.tabs();   
    }
  },
  mounted(){
      this.tabs();
  },
};
</script>
<style scoped>
/* Style the tab */
.tab {
  display: flex;
  overflow: hidden;
  border: transparent;
  background-color: transparent;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: transparent;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: transparent;
  color: #00ae2b;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: none;
}

/* Style the tab content */
.tabcontent.active{
  display: block;
  padding: 6px 12px;
  border: none;
  border-top: none;
}
.table th, .table td {
    vertical-align: unset;
}
</style>