<template>
  <div style="font-size:14px">
  <!-- Modal data produk-->
  <div id="modalPelanggan" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Daftar Pelanggan</h4>
          <button
            type="button"
            class="close"
            v-on:click="hidemodalpelanggan"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12">
                    <div class="form-group row">
                        <label class="control-label-bold col-2" style="margin-top:9px;">Pelanggan</label>
                        <div class="col-6">
                          <input
                            class="form-control"
                            v-model="searchByName"
                            type="text"
                            placeholder="Masukan nama, no hp, atau email pelanggan"
                          />
                        </div>
                        <label 
                            v-on:click="hidemodalpelanggan"
                            class="control-label-bold col-4 text-green" 
                            style="margin-top:9px;text-align: right !important;"
                            data-toggle="modal"
                            data-target="#modaladdPelanggan"
                            >+ Tambah Pelanggan</label>
                  </div>
            </div>
          </div>
          <div class="row">
          <div class="col-12 scrollable">
            <table class="table table-bordered table-striped table-sm">
              <thead>
                <tr style="background-color: #00ae2b">
                  <th class="text-center text-white th-prod" style="width: 40%">Nama</th>
                  <th class="text-center text-white th-prod" style="width: 25%">Email</th>
                  <th class="text-center text-white th-prod" style="width: 25%">No HP</th>
                  <th class="text-center text-white th-prod" style="width: 10%" v-if="(stSetUserTablet.AllowEditCustomer == '1' || stSetUserTablet.AllowHapusCustomer == '1')">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody style="cursor: pointer;" v-if="customer.length == 0">
                  <tr>
                      <td colspan="4" style="height:325px" >
                          Belum terdapat data pelanggan di outlet
                      </td>
                  </tr>
              </tbody>
              <tbody style="cursor: pointer;" v-if="searchPelangganResult.length > 0">
                <tr
                  v-for="dataPelanggan in searchPelangganResult"
                  v-bind:key="dataPelanggan.CustomerID+ '.' + dataPelanggan.DeviceNo"
                >
                  <td class="text-left"
                  @click="setCustomer(dataPelanggan)"
                  >{{ dataPelanggan.CustomerName }}</td>
                  <td class="text-left"
                  @click="setCustomer(dataPelanggan)"
                  >{{ dataPelanggan.CustomerEmail }}</td>
                  <td class="text-left"
                  @click="setCustomer(dataPelanggan)"
                  >{{ dataPelanggan.CustomerPhone }}</td>
                  <td class="text-right" v-if="(stSetUserTablet.AllowEditCustomer == '1' || stSetUserTablet.AllowHapusCustomer == '1')">
                      <div class="nav-item dropdown" style="height:25px;border: 1px solid #ced4da;border-radius:5px">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" style="color:#000;margin-top:-8px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Aksi
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item" href="#" v-on:click="update(dataPelanggan)" v-if="stSetUserTablet.AllowEditCustomer == '1'">Edit</a>
                            <!--<a class="dropdown-item" href="#" >Riwayat</a>-->
                            <a class="dropdown-item" href="#" v-on:click="hapus(dataPelanggan)" v-if="stSetUserTablet.AllowHapusCustomer == '1'">Hapus</a>
                        </div>
                      </div>
                  </td>
                </tr>
              </tbody>
              <tbody style="cursor: pointer;" v-if="searchPelangganResult.length == 0 && customer.length != 0">
                  <tr>
                      <td colspan="4" style="height:325px" >
                          Pelanggan tidak ditemukan
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>

      <!-- Alert Hapus dan Edit -->
      <div  id="alert-update-delete" style="position: absolute; top: 10%; z-index: 1100;left: 35%; display: none;">
          <div class="alert alert-success" role="alert"  >
              Pelanggan berhasil disimpan. 
          </div>
      </div>

      <!-- Alert Hapus dan Edit -->
      <div  id="alert-update-delete2" style="position: absolute; top: 10%; z-index: 1100;left: 35%; display: none;">
          <div class="alert alert-success" role="alert"  >
              Pelanggan berhasil diubah. 
          </div>
      </div>

            <!-- Alert Hapus dan Edit -->
      <div  id="alert-update-delete3" style="position: absolute; top: 10%; z-index: 1100;left: 35%; display: none;">
          <div class="alert alert-success" role="alert"  >
              Pelanggan berhasil dihapus. 
          </div>
      </div>

    </div>
    </div>
    <!-- komponen modal update pelanggan-->
    <PelangganUpdateModal :datapelanggan="setdatapelanggan"/>
    <!-- komponen modal add pelanggan-->
    <PelangganAddModal />
    <!-- komponen modal hapus pelanggan-->
    <KonfirmHapusPelanggan />

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PelangganUpdateModal from "@/components/pelanggan/PelangganUpdateModal.vue";
import PelangganAddModal from "@/components/pelanggan/PelangganAddModal.vue";
import KonfirmHapusPelanggan from "@/components/pelanggan/KonfirmHapusPelanggan.vue";

import $ from "jquery";
export default {
  components: {
    PelangganUpdateModal,
    PelangganAddModal,
    KonfirmHapusPelanggan,
  },
  data() {
    return {
      searchByName: "",
      searchByCategory: "",
      setdatapelanggan: null,
    };
  },
  name: "PelangganModal_c",
  computed: {
    ...mapGetters(["customer", "stSetUserTablet"]),
    searchPelangganResult() {
      let tempPelanggan = this.customer;
      if (this.searchByName != "" && this.searchByName) {
        tempPelanggan = tempPelanggan.filter((item) => {
          return (
            item.CustomerName.toLowerCase().includes(this.searchByName.toLowerCase()) ||
            item.CustomerPhone.toLowerCase().includes(this.searchByName.toLowerCase()) ||
            item.CustomerEmail.toLowerCase().includes(this.searchByName.toLowerCase()) 
          );
        });
      }
      return tempPelanggan;
    },
  },
  methods: {
    ...mapActions(["actSetCustomer"]),
    update(data){
        this.setdatapelanggan = data;
        this.searchByName =  "";
        var dataCustomer = {
          CustomerName: data.CustomerName,
          CustomerID: data.CustomerID,
          CustomerDeviceNo: data.DeviceNo,
        }
        this.$store.dispatch("actSetCustomer", dataCustomer);  
        $("#modalPelanggan").modal("hide");
        $("#modalUpdatePelanggan").modal("show");
    },
    hapus(data){
      var dataCustomer = {
        CustomerName: data.CustomerName,
        CustomerID: data.CustomerID,
        CustomerDeviceNo: data.DeviceNo,
      }
        this.$store.dispatch("actSetCustomer", dataCustomer);  
        $("#modalPelanggan").modal("show");
        $("#modalHapusPelanggan").modal("show");

    },
    setCustomer(data){
      var dataCustomer = {
        CustomerName: data.CustomerName,
        CustomerID: data.CustomerID,
        CustomerDeviceNo: data.DeviceNo,
      }
      this.$store.dispatch("actSetCustomer", dataCustomer);
      this.searchByName = ""
      $("#modalPelanggan").modal("hide");
    },
    hidemodalpelanggan(){
      this.searchByName= "";
      $("#modalPelanggan").modal("hide");
    }
  },
};
</script>
<style scoped>
  .scrollable {
    height: 325px;
    overflow-y: auto;
  }
  .th-prod {
    position: sticky;
    background-color:#00ae2b;
    z-index: 2;
    top: 0;
  }
</style>