<template>
  <div>
    <div id="modalSaldo" class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12" style="text-align: left; padding-bottom: 10px; padding-top: 10px;">
                {{ cekSaldo }}<br />
                Silakan lakukan Topup Saldo melalui website nutacloud.com !
              </div>
            </div>
            <div class="row py-2">
              <div class="col-lg-5"></div>
              <div class="col-lg-7">
                <div class="row">
                  <div class="col-lg-6">
                    <button class="btn btn-link btn-block" type="button" @click="tutupModal">
                      Kembali
                    </button>
                  </div>
                  <div class="col-lg-6" v-if="btnTopupSaldo == 1">
                    <button class="btn btn-green btn-block" type="button" @click="berlangganan">
                      Topup Saldo Sekarang
                    </button>
                  </div>
                  <div class="col-lg-6" v-if="btnTopupSaldo == 2">
                    <button class="btn btn-green btn-block" type="button" @click="cekAgainSaldo">
                      Cek Topup Saldo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from 'jquery';
export default {
  name: "SaldoModal",
  data() {
    return {
      btnTopupSaldo: 1,
      saldoModalMsg: null,
    }
  },
  components: {
  },
  computed: {
    ...mapGetters(["outletID", "options"]),
    cekSaldo() {
      var Msg = ''
      if(this.options[0]) {
        var fee = parseFloat(this.options[0].service_fee);
        var balance = parseFloat(this.options[0].topup_balance);
        var warningBalance = parseFloat(this.options[0].warning_balance);
        
        if(balance < fee) {
          Msg = "Saldo Anda telah habis."
        } else {
          if(balance <= warningBalance) {
            Msg = "Saldo top up Anda sisa Rp "+ this.numberTocurrencyIDR(balance) +","
          } else {
            Msg = "Saldo Anda telah habis."
          }
        }
      }
      return Msg
    }
  },
  watch: {
    
  },
  methods: {
    ...mapActions(["downloaddataoptions"]),
    cekAgainSaldo() {
      this.btnTopupSaldo = 1
      this.$store.dispatch("downloaddataoptions")
      this.dismissModalSaldo()
    },
    dismissModalSaldo(){
      $("#modalSaldo").modal('hide');
    },
    berlangganan(){
      this.btnTopupSaldo = 2
      var devid = this.outletID
      if(devid) {
        window.open("https://www.nutacloud.com/billing/topup?outlet=" + devid, "_blank")
      } else {
        window.open("https://www.nutacloud.com/billing/topup", "_blank")
      }
    },
    tutupModal(){
      this.btnTopupSaldo = 1
      this.dismissModalSaldo()
    },
    numberTocurrencyIDR(value) {
      var a = value;
      var hasMinus = "";
      if ("" != a && null != a) {
        a = a.toString();
        hasMinus = a.search('-')
        a = a.replace("-", "")
        a = a.split(".");
        for (var b = "", c = 0, d = a[0].length; 0 < d; d--)
          c += 1, b = 1 == c % 3 && 1 != c ? a[0].substr(d - 1, 1) + "." + b : a[0].substr(d - 1, 1) + b;
        1 < a.length && (b = 0 < a[1].length ? b + ("," + a[1].substr(0, 2)) : b + ",");
        return (hasMinus == "" ? "-" + b : b);
      }
      return 0;
    },
  },
};
</script>
<style scoped>
.footerModal{
  padding-left: 30px;
  padding-right: 30px
}
</style>