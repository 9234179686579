export default ({
    state: {
    },
    actions: {
        async addCustomer({commit,rootState},dataForm) {
            return new Promise((resolve) => {
                let data = {root:rootState,data:dataForm}
                commit("add_customer",data);
                resolve({error:false});
            });
        },
        async updateCustomer({commit},dataForm) {
            return new Promise((resolve) => {
                commit("update_customer",dataForm);

                const customer = JSON.parse(localStorage.getItem("pelanggan")); 
                for (let index = 0; index < customer.length; index++) {
                    if (customer[index].CustomerID == dataForm.idupdate && customer[index].DeviceNo == dataForm.deviceno){
                        commit("mutSetCustomer", customer[index]);
                    }
                }
                
                resolve({error:false});
            });
        },
        async deleteCustomer({commit},dataForm) {
            return new Promise((resolve) => {
                commit("delete_customer",dataForm);
                commit("mutSetCustomer", null);
                resolve({error:false});
            });
        },
    },
    mutations: {
    
    }
})