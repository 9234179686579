//import { setHeaderToken } from './utils'
//import { removeHeaderToken } from './utils'
import router from '../router'
import axios from 'axios'
import { uuid } from 'vue-uuid'
import $ from "jquery";

export default ({
    state: {
        perusahaan: localStorage.getItem("perusahaan") ? localStorage.getItem("perusahaan") : null,
        outletID: localStorage.getItem("outletID") ? localStorage.getItem("outletID") : null,
        outletName: localStorage.getItem("outletName") ? localStorage.getItem("outletName") : null,
        outletAddress: localStorage.getItem("outletAddress") ? localStorage.getItem("outletAddress") : null,
        username: localStorage.getItem("username") ? localStorage.getItem("username") : null,
        password: localStorage.getItem("password") ? localStorage.getItem("password") : null,
        usernamekasir: localStorage.getItem("usernamekasir") ? localStorage.getItem("usernamekasir") : null,
        email: localStorage.getItem("email") ? localStorage.getItem("email") : null,
        isLoggedIn: localStorage.getItem("isLoggedIn") ? localStorage.getItem("isLoggedIn") : false,
        isLoggedInKasir: localStorage.getItem("isLoggedInKasir") ? localStorage.getItem("isLoggedInKasir") : false,
        isOpenOutlet: localStorage.getItem("isOpenOutlet") ? localStorage.getItem("isOpenOutlet") : false,
        listOutlet: localStorage.getItem("listOutlet") ? JSON.parse(localStorage.getItem("listOutlet")) : [],
        uuid: localStorage.getItem("uuid") ? localStorage.getItem("uuid") : null,
        deviceNo: localStorage.getItem("deviceNo") ? localStorage.getItem("deviceNo") : 1,
        modalawal: localStorage.getItem("modalawal") ? localStorage.getItem("modalawal") : null,
        stSetUserTablet: localStorage.getItem("setUserTablet") ? JSON.parse(localStorage.getItem("setUserTablet")) : {},
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        isLoggedInKasir: state => state.isLoggedInKasir,
        isOpenOutlet: state => state.isOpenOutlet,
        perusahaan: state => state.perusahaan,
        outletID: state => state.outletID,
        outletName: state => state.outletName,
        outletAddress: state => state.outletAddress,
        username: state => state.username,
        password: state => state.password,
        usernamekasir: state => state.usernamekasir,
        email: state => state.email,
        listOutlet: state => state.listOutlet,
        uuid: state => state.uuid,
        deviceNo: state => state.deviceNo,
        modalawal: state => state.modalawal,
        stSetUserTablet: state => state.stSetUserTablet,
    },
    actions: {
        async loginPerusahaan({ commit }, data) {
            return new Promise((resolve, reject) => {
                /*var formData = new FormData();
                formData.append('c', data.perusahaan);
                axios.post('login/checkcompany', formData, {
                    crossDomain: true,
                }).then(response => {
                    if (response.data.status == "OK") {
                        commit('set_perusahaan', response.data)
                    } else {
                        commit('reset_perusahaan')
                    }
                    resolve(response)
                }).catch(err => {
                    commit('reset_perusahaan')
                    reject(err)
                })*/

                $.ajax({
                    url: axios.defaults.baseURL + "login/checkcompany",
                    data: {
                        c: data.perusahaan,
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            commit('set_perusahaan', data)
                        } else {
                            commit('reset_perusahaan')
                        }
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                });
            })
        },
        async loginUser({ commit, state, dispatch }, dataForm) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURL + "login/auth",
                    data: {
                        c: state.perusahaan,
                        u: dataForm.username,
                        p: dataForm.password,
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            dispatch('downloadListOutlet', dataForm);
                            commit('set_username', dataForm.username)
                            commit('set_password', dataForm.password)
                            commit('set_usermail', data)
                            if (localStorage.getItem("uuid") === null) {
                                commit('set_uuid')
                            }
                        } else {
                            commit('reset_username')
                        }
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                });
            })
        },
        async loginUserKasir({ commit, rootState, dispatch }, dataForm) {
            return new Promise((resolve, reject) => {
                var usertablet = JSON.parse(window.localStorage.getItem('usertablet'))
                usertablet = usertablet.filter((item) => {
                    return (item.Username.toLowerCase() == dataForm.username.toLowerCase());
                });
                if (usertablet.length >0){
                    if (usertablet[0].Password != dataForm.password){
                        resolve({ status: "FAIL", message: "Password salah" })
                    }
                    else{ 
                        var today = new Date();
                        $.ajax({
                            url: axios.defaults.baseURLWS + "export/getoptions",
                            //url: axios.defaults.baseURLWS + "export/gettglexpired",
                            data: {
                                devid: rootState.auth.outletID,
                            },
                            method: "POST",
                            success: (data) => {
                                var date1 = data.data[0].TglExpired
                                var date2 = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2)
                                var fee = parseFloat(data.data[0].service_fee);
                                var balance = parseFloat(data.data[0].topup_balance);
                                if (data.data[0].billing_type == "1") {
                                    if (date1 <= date2) {
                                            resolve({ status: "FAIL", message: "NONAKTIF" })
                                    } else {
                                        commit('set_usernamekasir', dataForm.username)
                                        commit('set_isloggedKasir', true)
                                        commit('mutSetUserTablet', usertablet[0])
                                            
                                        dispatch('downloadcashbankin')
                                        dispatch('downloadcashbankout')
                                        dispatch('downloadlatesttransactionid')
                                        resolve({ status: "OK" })
                                    }
                                } else if(data.data[0].billing_type == "2") {
                                    if(balance >= fee) {
                                        commit('set_usernamekasir', dataForm.username)
                                        commit('set_isloggedKasir', true)
                                        commit('mutSetUserTablet', usertablet[0])
                                            
                                        dispatch('downloadcashbankin')
                                        dispatch('downloadcashbankout')
                                        dispatch('downloadlatesttransactionid')
                                        resolve({ status: "OK" })
                                    } else {
                                        resolve({ status: "FAIL", message: "SALDO TIDAK CUKUP" })
                                    }
                                }

                                /*var date1 = data.data.TglExpired
                                var date2 = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2)
                                if (date1 <= date2) {
                                    resolve({ status: "FAIL", message: "Masa aktif Anda telah habis. Silahkan lakukan Aktivasi !" })
                                } else {
                                    commit('set_usernamekasir', dataForm.username)
                                    commit('set_isloggedKasir', true)
                                    commit('mutSetUserTablet', data)
                                    
                                    dispatch('downloadcashbankin')
                                    dispatch('downloadcashbankout')
                                    dispatch('downloadlatesttransactionid')
                                    resolve({ status: "OK" })
                                }*/
                            },
                            error: (error) => {
                                reject({ status: "FAIL", message: error })
                            },
                        })
                    }
                }
                else{
                    resolve({ status: "FAIL", message: "User tidak terdaftar" })
                }
            })
        },
        async downloadListOutlet({ commit, state }, dataForm) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURL + "outlets/getlist",
                    data: {
                        c: state.perusahaan,
                        u: dataForm.username,
                        p: 'login',
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            commit('set_list_outlet', data)
                        } else {
                            commit('reset_list_outlet')
                        }
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                });
            })
        },
        async selectedOutlet({ commit, state }, dataForm) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURL + "login/setactiveoutlet",
                    data: {
                        i: state.uuid,
                        o: dataForm.outletid,
                        dn: state.deviceNo,
                        du: "0",//dataForm.OutletID,
                        devtype: "PC",
                        devname: navigator.appCodeName,
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            var today = new Date();
                            $.ajax({
                                url: axios.defaults.baseURLWS + "export/getoptions",
                                data: {
                                    devid: dataForm.outletid,
                                },
                                method: "POST",
                                success: (result) => {
                                    var date1 = result.data[0].TglExpired
                                    var date2 = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2)
                                    var fee = parseFloat(result.data[0].service_fee);
                                    var balance = parseFloat(result.data[0].topup_balance);
                                    if (result.data[0].billing_type == "1") {
                                        if (date1 <= date2) {
                                            resolve({ status: "FAIL", message: "NONAKTIF" })
                                        }
                                        else {
                                            commit('reset_password')
                                            commit('set_outlet', dataForm)
                                            commit('set_device_no', data)
                                            resolve({ status: "OK" })
                                        }
                                    } else if(result.data[0].billing_type == "2") {
                                        if(balance >= fee) {
                                            commit('reset_password')
                                            commit('set_outlet', dataForm)
                                            commit('set_device_no', data)
                                            resolve({ status: "OK" })
                                        } else {
                                            resolve({ status: "FAIL", message: "SALDO TIDAK CUKUP" })
                                        }
                                    }
                                },
                                error: (error) => {
                                    reject({ status: "FAIL", message: error })
                                },
                            })
                        } else {
                            commit('reset_password')
                            commit('reset_outlet')
                            commit('reset_device_no')
                        }
                    },
                    error: (error) => {
                        reject(error)
                    },
                });
            })
        },
        async actSetUserTablet({ commit, state }) {
            var usertablet = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : {};
            var findusertablet = usertablet.find((b) => b.DeviceID == state.outletID && b.Username == state.usernamekasir);
            if(!findusertablet) {
                findusertablet = {}
            }
            commit('mutSetUserTablet', findusertablet);
        },
        async logoutClearAllData({ commit }) {
            commit('reset_user');
            commit('mutResetMasterData');
            commit('mutResetAllTransactionData');
        },
        async logoutClearAllKasir({ commit, dispatch }) {
            commit('reset_userkasir');
            commit("mutRemoveAllItemCart");
            commit("mutResetTransaction");
            dispatch('downloadusertablet');
        },
        async reDownloadItems() {
            router.push({ name: 'Download Data' })
        },
    },
    mutations: {
        mutSetUserTablet(state, data) {
            state.stSetUserTablet = data
            localStorage.setItem('setUserTablet', JSON.stringify(data))
        },
        set_perusahaan(state, data) {
            state.perusahaan = data.message.idperusahaan
            localStorage.setItem('perusahaan', data.message.idperusahaan)
        },
        reset_perusahaan(state) {
            state.perusahaan = null
            localStorage.removeItem('perusahaan')
        },
        reset_modalawal(state) {
            state.modalawal = null
            localStorage.removeItem('modalawal')
        },
        set_username(state, data) {
            state.username = data
            localStorage.setItem('username', data)
        },
        set_password(state, data) {
            state.password = data
            localStorage.setItem('password', data)
        },
        set_usernamekasir(state, data) {
            state.usernamekasir = data
            localStorage.setItem('usernamekasir', data)
        },
        reset_username(state) {
            state.username = null
            localStorage.removeItem('username')
        },
        reset_password(state) {
            state.password = null
            localStorage.removeItem('password')
        },
        reset_usernamekasir(state) {
            state.usernamekasir = null
            localStorage.removeItem('usernamekasir')
        },
        set_isloggedKasir(state, data) {
            state.isLoggedInKasir = data
            localStorage.setItem('isLoggedInKasir', data)
        },
        set_isOpenOutlet(state, data) {
            state.isOpenOutlet = data
            localStorage.setItem('isOpenOutlet', data)
        },
        set_usermail(state, data) {
            state.email = data.message[0]
            state.isLoggedIn = true
            state.isLoggedInKasir = true
            state.usernamekasir = 'Admin'
            localStorage.setItem('email', data.message[0])
            localStorage.setItem('isLoggedIn', true)
            localStorage.setItem('isLoggedInKasir', true)
            localStorage.setItem('usernamekasir', 'Admin')
        },
        reset_usermail(state) {
            state.email = null
            localStorage.removeItem('email')

            state.isLoggedIn = false
            state.isLoggedInKasir = false
            localStorage.setItem('isLoggedIn', false)
            localStorage.setItem('isLoggedInKasir', false)
        },
        reset_user(state) {
            // remove data user in localstorage and reset state data user
            localStorage.removeItem('perusahaan')
            state.perusahaan = null
            localStorage.removeItem('outletID')
            state.outletID = null
            localStorage.removeItem('listOutlet')
            state.listOutlet = []
            localStorage.removeItem('username')
            state.username = null
            localStorage.removeItem('usernamekasir')
            state.usernamekasir = null
            localStorage.removeItem('email')
            state.email = null
            localStorage.removeItem('outletName')
            state.outletName = null
            localStorage.removeItem('outletAddress')
            state.outletAddress = null

            localStorage.removeItem('setUserTablet')
            state.stSetUserTablet = {}
            localStorage.setItem('isLoggedIn', false)
            state.isLoggedIn = false
            localStorage.setItem('isLoggedInKasir', false)
            state.isLoggedInKasir = false
            router.push({ name: 'Login Perusahaan' })
        },
        reset_userkasir(state) {
            localStorage.removeItem('setUserTablet')
            state.stSetUserTablet = {}

            localStorage.removeItem('usernamekasir')
            state.usernamekasir = null

            localStorage.setItem('isLoggedInKasir', false)
            state.isLoggedInKasir = false
            router.push({ name: 'Login Kasir' })
        },
        set_list_outlet(state, data) {
            state.listOutlet = data.message
            localStorage.setItem('listOutlet', JSON.stringify(data.message))
        },
        reset_list_outlet(state) {
            state.listOutlet = []
            localStorage.removeItem('listOutlet')
        },
        set_outlet(state, data) {
            state.outletID = data.outletid
            state.outletName = data.outletname
            state.outletAddress = data.outletaddress
            localStorage.setItem('outletID', data.outletid)
            localStorage.setItem('outletName', data.outletname)
            localStorage.setItem('outletAddress', data.outletaddress)
        },

        set_modalawal(state, data) {
            state.modalawal = data
            localStorage.setItem('modalawal', data)
        },
        reset_outlet(state) {
            state.outletID = null
            localStorage.removeItem('outletID')
            localStorage.removeItem('outletName')
            localStorage.removeItem('outletAddress')
        },
        set_device_no(state, data) {
            state.deviceNo = data.message[0]
            localStorage.setItem('deviceNo', data.message[0])
        },
        reset_device_no(state) {
            state.deviceNo = null
            localStorage.removeItem('deviceNo')
        },
        set_uuid(state) {
            var datauuid = uuid.v1()
            state.uuid = datauuid
            localStorage.setItem('uuid', datauuid)
        },
    }
})