<template>
    <div>
        <div id="modalRiwayatTutupOutlet" class="modal hide fade in">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-bolder">Riwayat Tutup outlet</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12" style="text-align:center;">
                                <div class="row" style="padding-bottom: 15px">
                                    <div class="col-lg-3">
                                        <input class="form-control" type="date" id="dateStart" name="dateStart" v-model="dateStart">
                                    </div>
                                    <div class="col-lg-3">
                                        <input class="form-control" type="date" id="dateEnd" name="dateEnd" v-model="dateEnd">
                                    </div>
                                    <div class="col-lg-3">
                                        <button
                                            class="btn btn-green btn-block"
                                            v-on:click="filterRiwayatTutupOutlet">
                                            Proses
                                        </button>
                                    </div>
                                </div>
                                
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Tanggal Buka</th>
                                                <th class="text-center">Modal Awal</th>
                                                <th class="text-center">Penjualan Tunai</th>
                                                <th class="text-center">Uang Di Laci</th>
                                                <th class="text-center">Tanggal Tutup</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(el, i) in dataOpenCloseOutlet" v-bind:key="i">
                                                <td>{{ convertDateDBtoIndo(el.OpenDate) }}, {{ el.OpenTime }}</td>
                                                <td class="text-right">Rp {{ $filters.numberTocurrencyIDR(el.StartingCash) }}</td>
                                                <td class="text-right">Rp {{ $filters.numberTocurrencyIDR(el.TotalCashSales) }}</td>
                                                <td class="text-right">Rp {{ $filters.numberTocurrencyIDR(el.ActualBalance) }}</td>
                                                <td><a href="javascript:void(0);" @click="openDetailTutupOutlet(el.OpenID, el.DeviceNo)">{{ convertDateDBtoIndo(el.CloseDate) }}, {{ el.CloseTime }}</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <!-- Detail Riwayat Tutup Outlet -->
        <DetailRiwayatTutupOutlet :dataOpenID="openID" :dataOpenDeviceNo="openDeviceNo" @dataForRingkasanPenjualan="dataRingkasan" @pageHistory="dataPageHistory" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import DetailRiwayatTutupOutlet from "@/components/tutup_outlet/DetailRiwayatTutupOutletModal_c.vue";
export default {
    name: "LihatRiwayatTutupOutletModal_c",
    data() {
        return {
            dateStart: null,
            dateEnd: null,
            dataDate: new Date(),
            openID: 0,
            openDeviceNo: 0,
            dataOpenCloseOutlet: []
        };
    },
    components: {
        DetailRiwayatTutupOutlet
    },
    methods: {
        dataRingkasan(data) {
            this.$emit('dataForRingkasanPenjualan', data);
        },
        dataPageHistory(data){
            this.$emit('pageHistory', data);
        },
        convertDateDBtoIndo(string) {
            var bulanIndo = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep' , 'Okt', 'Nov', 'Des'];
        
            var tanggal = string.split("-")[2];
            var bulan = string.split("-")[1];
            var tahun = string.split("-")[0];
        
            return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
        },
        openDetailTutupOutlet(OpenID, DeviceNo) {
            this.openID = parseFloat(OpenID)
            this.openDeviceNo = parseFloat(DeviceNo)
            $("#modalRiwayatTutupOutlet").modal("hide");
            setTimeout(() => $("#modalDetailRiwayatTutupOutlet").modal("show"), 500);
        },
        setDateFilter(){
            var newDate = new Date()
            var tgl = ((newDate.getDate() < 10) ? "0" : "" ) + newDate.getDate();
            var bln = (((newDate.getMonth()+1) < 10)?"0":"") + (newDate.getMonth()+1);
            var th = newDate.getFullYear();
            this.dateStart = th +'-'+ bln +'-'+ tgl
            this.dateEnd = th +'-'+ bln +'-'+ tgl
            this.filterRiwayatTutupOutlet()
        },
        filterRiwayatTutupOutlet() {
            var filterData = []
            var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
            filterData = opencloseoutlet.filter((el) => {
                return el.DeviceNo == this.deviceNo && el.CloseDate != "" && new Date(el.OpenDate) >= new Date(this.dateStart) && new Date(el.OpenDate) <= new Date(this.dateEnd);
            });
            this.dataOpenCloseOutlet = filterData
        }
    },
    computed: {
        ...mapGetters(["opencloseoutlet", "deviceNo"]),
    },
    mounted() {
        this.setDateFilter();
    }
};
</script>
