<template>
<div>
  <!-- Modal data pembayaran opsi makan-->
  <div id="modalPembayaranOpsiMakan" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Konfirmasi</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <p>Pembayaran dengan total Rp {{ $filters.numberTocurrencyIDR(stTotalHeader) }} akan masuk ke {{ cashBankAccountName }}?</p>
                    <div class="row py-2">
                        <div class="col-7"></div>
                        <div class="col-2">
                            <button
                            class="btn btn-link btn-block"
                            type="button"
                            @click="dismissModalOpsiMakan" :disabled="submitted" >
                            Tidak
                            </button>
                        </div>
                        <div class="col-3">
                            <button
                            class="btn btn-link btn-block"
                            type="button"
                            @click="savePembayaranOpsiMakan" :disabled="submitted" >
                            Ya, Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- komponen modal pembayaran berhasil-->
  <PembayaranBerhasilModal />
</div>
</template>
<script>
/* eslint-disable */ //disabled eslint compiler
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import PembayaranBerhasilModal from "@/components/PembayaranBerhasilModal_c.vue";
export default {
  name: "PembayaranOpsiMakanModal_c",
  data() {
    return {
        submitted: false,
        paymentMode: 23,
        selectedTf: ''
    };
  },
  components: {
    PembayaranBerhasilModal
  },
  computed: {
    ...mapGetters(["stOpsiMakan", "cashbank", "stTotalHeader", "stOpenTransaction"]),
    cashBankAccountName() {
        var cashBankAccountTxt = ""
        if(this.stOpsiMakan) {
          var cashBankAccount = this.cashbank.find((b) => b.AccountID == this.stOpsiMakan.AccountID && b.DeviceNo == this.stOpsiMakan.AccountDeviceNo && b.AccountType == '2');
          if(cashBankAccount) {
              this.selectedTf = cashBankAccount.AccountID + '.' + cashBankAccount.DeviceNo
              cashBankAccountTxt = cashBankAccount.BankName +" "+ cashBankAccount.AccountNumber +" "+ cashBankAccount.AccountName
          }
        }
        return cashBankAccountTxt
    },
  },
  mounted(){

  },
  methods: {
    ...mapActions(["actCreateTransaction", "actUpdateTransaction"]),
    dismissModalOpsiMakan(){
        this.submitted = true
        $("#modalPembayaranOpsiMakan").modal("hide");
        this.submitted = false
        setTimeout(() => $("#modalPembayaran").modal("show"), 500);
    },
    savePembayaranOpsiMakan(){
        this.submitted = true
        let paymentReview = {
          PaymentMode: 23,
          CashPayment: 0,
          Change: null,
          TfPayment: this.selectedTf,
          EDCPayment: '',
          EDCPercent: '',
          EDCTotal: '',
          QRStatisPayment: '',
          QRISPayment: '',
        }
        if(this.stOpenTransaction) {
          this.$store.dispatch("actUpdateTransaction", paymentReview)
          .then((response) => {
              if(response.status == "OK"){
                  $("#modalPembayaranOpsiMakan").modal("hide");
                  setTimeout(() => $("#modalPembayaranBerhasil").modal("show") , 500);
              }
              this.submitted = false
          })
        } else {
          this.$store.dispatch("actCreateTransaction", paymentReview)
          .then((response) => {
              if(response.status == "OK"){
                  $("#modalPembayaranOpsiMakan").modal("hide");
                  setTimeout(() => $("#modalPembayaranBerhasil").modal("show") , 500);
              }
              this.submitted = false
          })
        }
    }
  },
};
</script>
<style scoped>

</style>