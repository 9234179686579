<template>
  <div>
    <div id="modalTutupOutlet" class="modal hide fade in" data-keyboard="false" data-backdrop="static">
      <div class="card card0" style="width: 60%;height: 620px;margin-top: 5%;margin-left: 20%;background-color: #fff;">
        <div class="atas"></div>
          <form @submit.prevent="submitTutupOutlet">
            <div class="modal-dialog modal-lg">
              <div class="modal-content"
                style="width: 90%;height: 530px;margin-top: 30px;border-radius: 10px;border: 0px;">
                <div class="modal-body">
                  <div class="row py-2">
                    <img src="/close_outlet.png" class="iconclose" />
                  </div>
                  <div class="text-center"><strong>Tutup Outlet</strong></div>
                  <div class="text-center">
                    <strong>Perangkat ke - {{ dataCloseOutlet.DeviceNo }}</strong>
                  </div>
                  <div class="row">
                    <div class="col-12 scrollable">
                      <table class="table">
                        <tbody style="cursor: pointer">
                          <tr>
                            <td colspan="2" class="text-left">Buka Outlet</td>
                            <td colspan="2" class="text-left">
                              {{ tglopenoutlet }}, {{ dataCloseOutlet.OpenTime }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">Outlet dibuka oleh</td>
                            <td>:</td>
                            <td>{{ dataCloseOutlet.OpenedBy }}</td>
                            <td class="text-left"></td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">
                              1. Modal Awal perangkat ke - {{ dataCloseOutlet.DeviceNo }}
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td class="text-right">
                              {{ $filters.numberTocurrencyIDR(modalAwal) }}
                            </td>
                          </tr>
                          <tr v-if="perubahanModal != 0">
                            <td class="text-left">
                              &emsp;&emsp; &#8226; Perubahan Modal <i class="fa fa-info-circle" title="Perubahan modal tampil akibat adanya edit / hapus data pada shift atau tanggal sebelumnya"></i>
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td class="text-right">
                              {{ $filters.numberTocurrencyIDR(perubahanModal) }}
                            </td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">
                              2. Total Penjualan Perangkat ke - {{ dataCloseOutlet.DeviceNo }}
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td class="text-right">
                              <a href="#" v-on:click="showringkasanpenjualan" class="text-green">
                                {{ $filters.numberTocurrencyIDR(totalpenjualan) }}
                              </a>
                            </td>
                          </tr>
                          <tr v-for="(detailjual, index) in dataPenjualan.TotalPenjualan[0].Detail" v-bind:key="index">
                            <td v-if="getAllowLaporanTutupOutlet == 1" colspan="4">
                              <div class="row">
                                <div class="col-9">
                                  &emsp;&emsp; &#8226; Pembayaran
                                  {{ detailjual.PaymentName }}
                                </div>
                                <div class="col-3 text-right">
                                  Rp
                                  {{ $filters.numberTocurrencyIDR(detailjual.Total) }}
                                </div>
                              </div>
                              <div class="row py-3" v-for="(detailtrans, index2) in detailjual.detail" v-bind:key="index2">
                                <div class="col-9">
                                  &emsp;&emsp; &emsp;&emsp; -
                                  {{ detailtrans.AccountName }}
                                </div>
                                <div class="col-3 text-right">
                                  Rp
                                  {{ $filters.numberTocurrencyIDR(detailtrans.Total) }}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">3. Pemasukan Lain Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td class="text-right">{{ $filters.numberTocurrencyIDR(dataPenjualan.PemasukanLain)}}</td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">4. Total Pengeluaran Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td class="text-right">{{ $filters.numberTocurrencyIDR(dataPenjualan.TotalPengeluaran) }}</td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">
                              5. Uang di laci (1) + (2a) + (3) - (4)
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td class="text-right">
                              {{ $filters.numberTocurrencyIDR(datauangdilaci) }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">
                              6. Uang yang ditarik di laci (actual)<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;diserahkan ke manager/
                              owner
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td>
                              <currency-input
                                class="form-control text-right"
                                style="width: 150px"
                                v-model="form.withdrawal"
                                type="text"
                                v-on:keyup="perhitungan()"
                                :options="{ locale: 'de-DE',currency: 'EUR',currencyDisplay: 'hidden', autoSign: true, }"
                                autocomplete="off"
                                autocorrect="off"
                              />
                              <label class="mb-1 col-lg-12 text-left error" v-if="errors1">
                                {{ errors1 }}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">
                              7. Sisa uang di laci (Aktual)
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td>
                              <currency-input
                                class="form-control text-right"
                                style="width: 150px"
                                v-model="form.sisauangdilaci"
                                v-on:keyup="perhitungan()"
                                :options="{ locale: 'de-DE',currency: 'EUR',currencyDisplay: 'hidden', autoSign: true, }"
                                type="text"
                                name="sisauangdilaci"
                                autocomplete="off"
                                autocorrect="off"
                              />
                              <label class="mb-1 col-lg-12 text-left error" v-if="errors2">
                                {{ errors2 }}
                              </label>
                            </td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">
                              8. Selisih (Aktual - Sistem) = (5) - (6) - (7)
                            </td>
                            <td>:</td>
                            <td>Rp</td>
                            <td>
                              <currency-input
                                class="form-control text-right"
                                style="width: 150px"
                                v-model="form.selisih"
                                :options="{ locale: 'de-DE',currency: 'EUR',currencyDisplay: 'hidden', autoSign: true, }"
                                type="text"
                                name="selisih"
                                disabled
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label
                    v-on:click="kembali"
                    class="control-label-bold col-7 text-green ml-auto"
                    style="margin-top: 9px; text-align: right !important"
                  >
                    Kembali
                  </label>
                  <button
                    class="col-2 btn btn-green btn-block ml-auto"
                    style="margin-right: 17px"
                    :disabled="submittedTutupShift"
                  >
                    <span v-html="btnSimpan"></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
      </div>
      <!-- start print receipt template small -->
        <div id="ticket-print-close" class="ticket" style="display:none">
            <img
                v-if="getLogo" :src="getLogo" alt="Logo" class="center" style="width: 150px; height: auto" />
                <p class="centered">
                {{ options[0].CompanyName }} <br />{{ options[0].CompanyAddress }} <br />{{ options[0].CompanyPhone }}
            </p>
            <div style="width: 100%">
                <table>
                    <tr>
                        <td class="text-left" style="width: 50%">Buka Outlet</td>
                        <td class="text-left" style="width: 1%">:</td>
                        <td class="text-left" style="width: 49%">
                            {{ tglopenoutlet }}, {{ dataCloseOutlet.OpenTime }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left" style="width: 50%">Dibuka Oleh</td>
                        <td class="text-left" style="width: 1%">:</td>
                        <td class="text-left" style="width: 49%">
                            {{ dataCloseOutlet.OpenedBy }}
                        </td>
                    </tr>
                    <tr v-if="getAllowLaporanTutupOutlet == 1">
                        <td class="text-left" style="width: 50%">Modal Awal</td>
                        <td class="text-left" style="width: 1%">:</td>
                        <td class="text-left" style="width: 49%">
                            Rp {{ $filters.numberTocurrencyIDR(modalAwal) }}
                        </td>
                    </tr>
                    <tr v-if="perubahanModal != 0">
                        <td class="text-left" style="width: 50%">Perubahan Modal</td>
                        <td class="text-left" style="width: 1%">:</td>
                        <td class="text-left" style="width: 49%">
                            Rp {{ $filters.numberTocurrencyIDR(perubahanModal) }}
                        </td>
                    </tr>
                </table>
            </div>
            <div
              v-if="getAllowLaporanTutupOutlet == 1" 
              style="width: 100%; border-top-style: dashed; border-bottom-style: dashed;">
                PENJUALAN
            </div>
            <div 
              v-if="getAllowLaporanTutupOutlet == 1"
              style="width: 100%">
                <table>
                    <tr
                        v-for="(cartData, k) in dataPenjualan.SalesSummary"
                        v-bind:key="k"
                        >
                        <td style="width: 60%">{{ cartData.Item }} x {{ cartData.Qty }}</td>
                        <td> Rp</td>
                        <td align="right">
                            {{ $filters.numberTocurrencyIDR(cartData.Total) }}
                        </td>
                    </tr>
                </table>
            </div>
            <div style="width: 100%; border-top-style: dashed;"></div>
            <div style="width: 100%">
                <table class="table">
                    <tbody style="cursor: pointer">
                        <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">Total Penjualan</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">
                                {{ $filters.numberTocurrencyIDR(totalpenjualan) }}
                            </td>
                        </tr>
                        <span v-if="getAllowLaporanTutupOutlet == 1">
                          <tr v-for="(detailjual, index) in dataPenjualan.TotalPenjualan[0].Detail" v-bind:key="index">
                              <td colspan="5">
                                  <table width="100%">
                                      <tr>
                                          <td style="width: 50%" colspan="2">
                                              &emsp;&#8226; {{ detailjual.PaymentName }}
                                          </td>
                                          <td style="width: 10%">Rp</td>
                                          <td align="right" colspan="2">
                                              {{ $filters.numberTocurrencyIDR(detailjual.Total) }}
                                          </td>
                                      </tr>
                                      <tr v-for="(detailtrans, index2) in detailjual.detail" v-bind:key="index2">
                                          <td style="width: 50%" colspan="2" v-if="detailjual.PaymentName !== 'Tunai'">
                                              &emsp;&emsp; - {{ detailtrans.AccountName }}
                                          </td>
                                          <td style="width: 10%" v-if="detailjual.PaymentName !== 'Tunai'">
                                              Rp
                                          </td>
                                          <td align="right" colspan="2" v-if="detailjual.PaymentName !== 'Tunai'">
                                              {{ $filters.numberTocurrencyIDR(detailtrans.Total) }}
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                        </span>
                        <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">Pemasukan Lain Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">{{ $filters.numberTocurrencyIDR(dataPenjualan.PemasukanLain)}}</td>
                          </tr>
                          <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">Total Pengeluaran Perangkat ke - {{dataCloseOutlet.DeviceNo}}</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">{{ $filters.numberTocurrencyIDR(dataPenjualan.TotalPengeluaran) }}</td>
                          </tr>
                        <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">Uang di laci</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">
                                {{ $filters.numberTocurrencyIDR(datauangdilaci) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">Uang Ditarik</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">
                                {{ $filters.numberTocurrencyIDR(form.withdrawal) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">Sisa Uang Aktual</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">
                                {{ $filters.numberTocurrencyIDR(form.sisauangdilaci) }}
                            </td>
                        </tr>
                        <tr v-if="getAllowLaporanTutupOutlet == 1">
                            <td class="text-left">Selisih</td>
                            <td>:</td>
                            <td>Rp</td>
                            <td align="right">
                                {{ $filters.numberTocurrencyIDR(form.selisih) }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5">Tutup Outlet : {{ tglcloseoutlet }}, {{ timecloseoutlet }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">Ditutup Oleh : {{ closedoutletby }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- end print receipt template small -->  
      </div>

      <!-- Modal tutup outlet-->
      <div id="modaltutupoutletkonfirmasi" class="modal fade">
        <div class="modal-dialog modal-lg" style="margin-top:20%">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Tutup Outlet</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row py-2">
                <div class="col-12">
                  <p style="text-align: left">
                    Apakah Anda yakin untuk menutup shift yang dibuka pada  {{ tglopenoutlet }} <br>
                    pukul {{ dataCloseOutlet.OpenTime }} ? 
                  </p>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-6"></div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6">
                        <label
                          v-on:click="kembalimodal"
                          class="control-label-bold col-7 text-green ml-auto"
                          style="margin-top: 9px; text-align: right !important"
                        >
                          Batal
                        </label>
                    </div>
                    <div class="col-6">
                      <button
                        type="button"
                        @click="prosesTutupOutlet"
                        class="btn btn-green btn-block"
                        :disabled="submitted"
                      >
                        Ya
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import CurrencyInput from "@/components/CurrencyInput_c";

export default {
  components: {
    CurrencyInput,
  },
  data() {
    return {
      //setdataringksanpenjualan: null,
      modalAwal: 0,
      perubahanModal: 0,
      tglopenoutlet: null,
      tglcloseoutlet: null,
      timecloseoutlet: null,
      closedoutletby: null,
      form: {
        withdrawal: null,
        sisauangdilaci: null,
        selisih: 0,
      },
      btnSimpan: "Tutup Shift",
      dataCloseOutlet: {
        OpenID: "",
        OpenDate: "",
        OpenTime: "",
        OpenedBy: "",
        CloseDate: "",
        CloseTime: "",
        ClosedBy: "",
        Varian: "",
        DeviceID: "",
        DeviceNo: "",
        PerusahaanNo: "",
        PerusahaanID: "",
        RowVersion: "",
        CreatedVersionCode: "",
        EditedVersionCode: "",
        TglJamUpdate: "",
        StartingCash: "",
        TotalSales: "",
        TotalCashSales: "",
        OtherIncome: "",
        Expense: "",
        Taken: "",
        Withdrawal: "",
        ExpectedBalance: "",
        ActualBalance: "",
        Difference: "",
        StartingCashOri: "",
      },
      dataPenjualan: {
        ModalAwal: 0,
        PemasukanLain: 0,
        TotalPengeluaran: 0,
        TotalPenjualan: [
          {
            Detail: null,
          },
        ],
      },
      datauangdilaci: 0,
      totalpenjualan: 0,
      errors1: "",
      errors2: "",
      submitted: false,
      submittedTutupShift: true,
    };
  },
  props: {
    dataForTutupOutletModal: Object
  },
  watch: {
    dataForTutupOutletModal: function() {
      this.getDataCloseOutlet()      
    }
  },
  name: "TutupOutlet",
  computed: {
    ...mapGetters(["options", "wsBaseUrl","connectionStatus", "usertablet", "usernamekasir"]),
    getAllowLaporanTutupOutlet() {
      if(this.usertablet && this.usernamekasir) {
        const getusertablet = this.usertablet.filter((user) => 
          user.Username.toLowerCase() == this.usernamekasir.toLowerCase());
        return  getusertablet[0].AllowLaporanTutupOutlet;
      } else {
        return 0;
      }
    },
    getLogo() {
      var logoReceipt = null
            var tempDataOptions = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
            tempDataOptions = tempDataOptions.filter((opt) => {
            return (opt.OnlineImagePath != "");
            });
            if(tempDataOptions.length > 0){
            if(tempDataOptions[0].OnlineImagePath.substring(-1, 4) == "http") {
                logoReceipt = tempDataOptions[0].OnlineImagePath
            } else {
                logoReceipt = this.wsBaseUrl + tempDataOptions[0].OnlineImagePath
            }
            } else {
            var tempLocalImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
            tempLocalImage = tempLocalImage.filter((loc) => {
                return (loc.Table == "options" && loc.TableID == "1");
            });
        if(tempLocalImage.length > 0){
          logoReceipt = tempLocalImage[0].Image
        }
      }
      return logoReceipt
    },
  },
  methods: {
    ...mapActions(["actCloseOutlet", "logoutClearAllKasir"]),
    getDataCloseOutlet() {
      var data = this.dataForTutupOutletModal
      var total = 0;
      this.dataPenjualan = data.dataPenjualan;
      this.datauangdilaci = parseFloat(data.dataPenjualan.ModalAwal) + parseFloat(data.dataPenjualan.PemasukanLain);
      if (data.dataPenjualan.TotalPenjualan[0].Total != null) {
        for (let x = 0; x < data.dataPenjualan.TotalPenjualan.length; x++) {
          if(Array.isArray(data.dataPenjualan.TotalPenjualan[x].Detail)) {
            if(data.dataPenjualan.TotalPenjualan[x].Detail.length > 0) {
              for (let xx = 0; xx < data.dataPenjualan.TotalPenjualan[x].Detail.length; xx++) {
                if(data.dataPenjualan.TotalPenjualan[x].Detail[xx].PaymentName == "Tunai") {
                  this.datauangdilaci += parseFloat(data.dataPenjualan.TotalPenjualan[x].Detail[xx].Total);
                }
              }
            }
          }
          total = total + parseFloat(data.dataPenjualan.TotalPenjualan[0].Total);
        }
      }
      this.datauangdilaci = parseFloat(this.datauangdilaci) - parseFloat(data.dataPenjualan.TotalPengeluaran);
      if(parseFloat(data.dataPenjualan.ModalAwal) != parseFloat(data.dataCloseOutlet.StartingCashOri)) {
        this.perubahanModal = parseFloat(data.dataPenjualan.ModalAwal) - parseFloat(data.dataCloseOutlet.StartingCashOri)
      }
      this.modalAwal = parseFloat(data.dataCloseOutlet.StartingCashOri)
      this.totalpenjualan = total;
      this.dataCloseOutlet = data.dataCloseOutlet;
      this.tglopenoutlet = this.convertDateDBtoIndo(this.dataCloseOutlet.OpenDate);
      this.submittedTutupShift = false
    },
    showringkasanpenjualan() {
      this.$emit('pageHistory', "tutup-outlet");
      $("#modalTutupOutlet").modal("hide");
      setTimeout(() => $("#ringkasanpenjualan").modal("show"), 500);
    },
    perhitungan() {
      this.form.selisih = (this.form.withdrawal - this.datauangdilaci) + this.form.sisauangdilaci;
    },
    submitTutupOutlet() {
      if (this.connectionStatus == "Offline"){
          $("#modalkonfirmasitutupoutlet").modal("show");
      }
      else{
          if (this.form.withdrawal == null || this.form.sisauangdilaci == null) {
            if (this.form.withdrawal == null) {
              this.errors1 = "Uang ditarik tidak boleh kosong";
            } else {
              this.errors1 = "";
            }
            if (this.form.sisauangdilaci == null) {
              this.errors2 = "Sisa uang tidak boleh kosong";
            } else {
              this.errors2 = "";
            }
          }
          else if (this.form.withdrawal < 0 || this.form.sisauangdilaci < 0) {
              if (this.form.withdrawal < 0) {
                this.errors1 = "Uang ditarik tidak boleh Minus";
              } else {
                this.errors1 = "";
              }
              if (this.form.sisauangdilaci < 0) {
                this.errors2 = "Sisa uang tidak boleh Minus";
              } else {
                this.errors2 = "";
              }
          }
          else {
              this.btnSimpan = "<div class='fa fa-circle-notch fa-spin'></div>";
              this.errors1 = "";
              this.errors2 = "";  
              this.btnSimpan = "Tutup Shift";
              $("#modaltutupoutletkonfirmasi").modal("show");
          }
      }
    },
    prosesTutupOutlet() {
      this.submitted = true;
      let data = {
        dataOpenClose: this.dataCloseOutlet,
        dataPenjualan: this.dataPenjualan,
        TotalSales: this.totalpenjualan,
        Withdrawal: this.form.withdrawal,
        ActualBalance: this.form.sisauangdilaci
      };
      this.$store.dispatch("actCloseOutlet", data)
        .then((response) => {
          if (response.error == false) {
            this.tglcloseoutlet = this.convertDateDBtoIndo(response.closeDate);
            this.timecloseoutlet = response.closeTime
            this.closedoutletby = response.closedBy

            this.btnSimpan = "Tutup Shift";
            setTimeout(() => this.printAndCloseAllModal() , 500);
          }
        })
        .catch((error) => {
          console.log(error);
          this.btnSimpan = "Tutup Shift";
          this.submitted = false;
        });
    },
    printAndCloseAllModal(){
      this.printReceiptSmCloseOutlet();
      $("#modaltutupoutletkonfirmasi").modal("hide");
      $("#modalTutupOutlet").modal("hide");
      this.$store.dispatch("logoutClearAllKasir")
      this.$router.push({ name: "Login Kasir" });
    },
    kembalimodal(){
      $("#modaltutupoutletkonfirmasi").modal("hide");
    },
    kembali() {
      this.modalAwal = 0
      this.perubahanModal = 0
      this.form.withdrawal= null
      this.form.sisauangdilaci= null
      this.form.selisih= 0
      this.errors1= ""
      this.errors2= ""
      $("#modalTutupOutlet").modal("hide");
      setTimeout(() => $("#modalTutupOutletBegin").modal("show"), 500);
    },
    convertDateDBtoIndo(string) {
        var bulanIndo = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep' , 'Okt', 'Nov', 'Des'];
    
        var tanggal = string.split("-")[2];
        var bulan = string.split("-")[1];
        var tahun = string.split("-")[0];
    
        return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
    printReceiptSmCloseOutlet() {
      const prtHtml = document.getElementById("ticket-print-close").innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          td,
          th,
          tr,
          table {
            /*border: 1px solid black;*/
            border-collapse: collapse;
            width: 100%;
          }

          td.description {
            text-align: left;
          }

          td.quantity {
            text-align: left;
          }

          td.subtotal {
            text-align: right;
          }

          .centered {
            text-align: center;
            align-content: center;
          }

          .ticket {
            width: 280px;
            max-width: 280px;
          }

          img {
            max-width: inherit;
            width: inherit;
          }
          .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 110px;
            width: auto;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
      setTimeout(() => WinPrint.close(), 1000);

      return true;
    },
  },
  mounted() {
    //this.getCloseOutlet();
  },
};
</script>
<style scoped>
.scrollable {
  margin-top: 10px;
  height: 430px;
  overflow-y: auto;
}

.th-prod {
  position: sticky;
  background-color: #00ae2b;
  z-index: 2;
  top: 0;
}
.atas {
  background-color: #ccefd4;
  width: 100%;
  height: 150px;
  border-radius: 0px 0px 80px 80px;
  position: absolute;
}
.iconclose {
  position: absolute;
  margin: -50px 0px 0px 45%;
  width: 60px;
  height: 60px;
}
.error {
  font-size: 0.5em;
  transform: translateY(-50%) translateX(-9%);
  color: red;
}
td,
th,
tr,
table {
  /*border: 1px solid black;*/
  border-collapse: collapse;
  width: 100%;
}

td.description {
  text-align: left;
}

td.quantity {
  text-align: left;
}

td.subtotal {
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 280px;
  max-width: 280px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 110px;
  width: auto;
}

@media print {
}
</style>