export default ({
    state: {
    },
    actions: {
        //save data footnotes 
        async actSetStruk({ commit, dispatch, rootState }, dataForm) {
            var options = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
            if(options.length > 0){
                var data = null
                options[0].FootNoteSatu = dataForm.FootNoteSatu;
                options[0].FootNoteDua = dataForm.FootNoteDua;
                options[0].FootNoteTiga = dataForm.FootNoteTiga;
                options[0].FootNoteEmpat = dataForm.FootNoteEmpat;

                options[0].CompanyName = dataForm.CompanyName;
                options[0].CompanyAddress = dataForm.CompanyAddress;
                options[0].CompanyPhone = dataForm.CompanyPhone;
                options[0].SynMode = 1;

                data = ({"data": options})
                commit("set_options", data)

                if(rootState.connectionStatus == "Online"){
                    dispatch('actSyncDataOptions')
                }

                var templateData = localStorage.getItem("receipttemplate") ? JSON.parse(localStorage.getItem("receipttemplate")) : [];
                if(templateData.length > 0) {
                    templateData[0].template = dataForm.selectedReceiptTemplate;
                    localStorage.setItem("receipttemplate", JSON.stringify(templateData));
                } else {
                    var tempData = {
                        template: dataForm.selectedReceiptTemplate,
                    }
                    templateData.push(tempData)
                    localStorage.setItem('receipttemplate', JSON.stringify(templateData))
                }
            }
        },
        // delete logo struk
        async actDelLogoStruk({ commit, dispatch, rootState }, dataForm) {
            var options = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
            if(options.length > 0){
                var data = null
                if(dataForm.delLogo == true || dataForm.delLogo == "true"){
                    options[0].OnlineImagePath = "";
                } 
                options[0].SynMode = 1;

                data = ({"data": options})
                commit("set_options", data)

                if(rootState.connectionStatus == "Online"){
                    dispatch('actSyncDataOptions')
                }
            }

            var lImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
            var dataLocal = null
            if(lImage.length > 0){
                dataLocal = lImage.find((b) => b.Table == 'options' && b.TableID == '1');
                if(dataLocal){
                    var idx = lImage.indexOf(dataLocal);
                    if (idx > -1) {
                        lImage.splice(idx, 1);
                    }
                    localStorage.setItem('localimage', JSON.stringify(lImage));
                }
            }
        },
        //save base64 image to local storage
        async actSaveImgLocalStorage({ rootState }, dataForm) {
            var valData = null
            var lImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
            if(lImage.length > 0) {
                var filterImage = lImage.filter((sImage) => {
                    return (sImage.DeviceID == rootState.items.options[0].DeviceID && sImage.DeviceNo == rootState.auth.deviceNo && sImage.PerusahaanNo == rootState.items.options[0].PerusahaanNo && sImage.Table == dataForm.table && sImage.TableID == dataForm.tableid);
                });
                if(filterImage.length > 0){
                    for (var sn = 0; sn < lImage.length; sn++) {
                        if(lImage[sn].Table == dataForm.table && lImage[sn].TableID == dataForm.tableid && lImage[sn].DeviceNo == rootState.auth.deviceNo && lImage[sn].DeviceID == rootState.items.options[0].DeviceID && lImage[sn].PerusahaanNo == rootState.items.options[0].PerusahaanNo){ 
                            lImage[sn].Image = dataForm.image;
                            break;  
                        }
                    }
                    localStorage.setItem("localimage", JSON.stringify(lImage));
                } else {
                    valData = {
                        DeviceID: rootState.items.options[0].DeviceID,
                        DeviceNo: rootState.auth.deviceNo,
                        PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                        Table: dataForm.table,
                        TableID: dataForm.tableid,
                        Image: dataForm.image,
                        SynMode: dataForm.synmode,
                    }
                    lImage.push(valData)
                    localStorage.setItem('localimage', JSON.stringify(lImage))
                }
            } else {
                valData = {
                    DeviceID: rootState.items.options[0].DeviceID,
                    DeviceNo: rootState.auth.deviceNo,
                    PerusahaanNo: rootState.items.options[0].PerusahaanNo,
                    Table: dataForm.table,
                    TableID: dataForm.tableid,
                    Image: dataForm.image,
                    SynMode: dataForm.synmode,
                }
                lImage.push(valData)
                localStorage.setItem('localimage', JSON.stringify(lImage))
            }
        },
    },
    mutations: {
    }
})