<template>
  <!-- Modal data opsi makan-->
  <div id="modalOpsiMakan" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Tipe Penjualan</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="kembali">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row" v-if="masteropsimakan.length > 0">
                <div class="col-lg-12" v-for="(opsiMakan, i) in masteropsimakan" v-bind:key="opsiMakan.OpsiMakanID">
                    <div class="row" v-if="opsiMakan.IsActive == '1'">
                        <div class="col-lg-4">
                            <div class="form-check form-check-inline btn-radio">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="opsiMakanRadio" v-on:change="opsiMakanRadioSelected(opsiMakan)" :id="'rekom'+ i" :value="opsiMakan.OpsiMakanID">
                              <label class="form-check-label" :for="'rekom'+ i">{{ opsiMakan.NamaOpsiMakan }}</label>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row" v-if="opsiMakanRadio == opsiMakan.OpsiMakanID && options[0].booking == '999'">
                              <div class="col-lg-4">
                                <div class="form-check" style="padding-left: 0px; padding-top: 6px;">
                                  <span v-if="opsiMakan.TipeOpsiMakan == 1">
                                    Reservasi
                                  </span>
                                  <span v-else-if="opsiMakan.TipeOpsiMakan == 2">
                                    Katering
                                  </span>
                                  <span v-else-if="opsiMakan.TipeOpsiMakan == 3">
                                    Pemesanan
                                  </span>
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      :value="opsiMakan.OpsiMakanID"
                                      v-model="checkedReservasi"
                                      v-on:change="opsiMakanCheckedReservasi(opsiMakan)"
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-lg-4" v-if="reservasiChecked == opsiMakan.OpsiMakanID">
                                <input
                                  class="form-control"
                                  type="date"
                                  name="dateStart"
                                  v-model="dateStart"
                                />
                              </div>
                              <div class="col-lg-4" v-if="reservasiChecked == opsiMakan.OpsiMakanID">
                                <input
                                  class="form-control"
                                  type="time"
                                  name="timeStart"
                                  v-model="timeStart"
                                />
                              </div>
                            </div>
                            <span v-if="opsiMakan.CustomMarkup == '0' && opsiMakan.MarkupPersen != '0'">Semua harga akan di markup {{ opsiMakan.MarkupPersen }}% secara otomatis
                                <br> Rp 10.000 <i class="fa fa-arrow-right"></i> Rp {{ $filters.numberTocurrencyIDR(((10000/100) * opsiMakan.MarkupPersen) + 10000) }}
                            </span>
                            <span v-if="opsiMakan.CustomMarkup == '1' && opsiMakan.MarkupPersen != '0'">Sebagian harga akan di markup {{ opsiMakan.MarkupPersen }}% secara otomatis<br>
                            </span>
                            <span v-if="opsiMakan.CustomMarkup == '1'">Sebagian harga akan di markup berbeda-beda tiap produk</span>
                        </div>
                    </div>
                    <hr v-if="opsiMakan.IsActive == '1'">
                </div>
            </div>
            <div class="row">
              <div class="col-lg-9"></div>
              <div class="col-lg-3">
                <button class="btn btn-green btn-block" v-on:click="saveSetOpsiMakan">
                  Pilih
                </button>
              </div>
            </div>
            <p v-if="masteropsimakan.length <= 0">
                Tipe Penjualan tidak ditemukan
            </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "OpsiMakanModal_c",
  data() {
    return {
      opsiMakanSelected: {},
      reservasiChecked: null,
      opsiMakanRadio: [],
      checkedReservasi: false,
      dateStart: null,
      timeStart: null,
    };
  },
  computed: {
    ...mapGetters(["masteropsimakan", "stOpsiMakan", "stSaleDate", "stSaleTime", "options"]),
  },
  mounted(){
  },
  methods: {
    ...mapActions(["actSetOpsiMakan"]),
    kembali() {
      if(this.stOpsiMakan) {
        this.opsiMakanRadio = this.stOpsiMakan.OpsiMakanID
      } else {
        this.opsiMakanRadio = []
      }
      this.opsiMakanSelected = this.stOpsiMakan
      if(this.stSaleDate != null) {
        this.checkedReservasi = true
        this.reservasiChecked = this.stOpsiMakan.OpsiMakanID
        this.dateStart = this.stSaleDate
        this.timeStart = this.stSaleTime.substring(0, 5)
      } else {
        this.checkedReservasi = false
        this.reservasiChecked = null
        this.dateStart = null
        this.timeStart = null
      }
      $("#modalOpsiMakan").modal("hide");
    },
    saveSetOpsiMakan() {
      var data = {}
      if(this.checkedReservasi == false) {
        data = {
          opsiMakan: this.opsiMakanSelected,
          SaleDate: null,
          SaleTime: null
        }
      } else {
        data = {
          opsiMakan: this.opsiMakanSelected,
          SaleDate: this.dateStart,
          SaleTime: this.timeStart
        }
      }
      this.$store.dispatch("actSetOpsiMakan", data);
      $("#modalOpsiMakan").modal("hide");
    },
    setOpsiMakan() {
      if(this.stOpsiMakan) {
        this.opsiMakanRadio = this.stOpsiMakan.OpsiMakanID
      } else {
        this.opsiMakanRadio = []
      }
      this.opsiMakanSelected = this.stOpsiMakan
      if(this.stSaleDate != null) {
        this.checkedReservasi = true
        this.reservasiChecked = this.stOpsiMakan.OpsiMakanID
        this.dateStart = this.stSaleDate
        this.timeStart = this.stSaleTime.substring(0, 5)
      } else {
        this.checkedReservasi = false
        this.reservasiChecked = null
        this.dateStart = null
        this.timeStart = null
      }
    },
    opsiMakanRadioSelected(data) {
      this.checkedReservasi = false
      this.reservasiChecked = null
      this.dateStart = null
      this.timeStart = null
      this.opsiMakanSelected = data
    },
    opsiMakanCheckedReservasi(data) {
      if(this.checkedReservasi) {
        this.reservasiChecked = data.OpsiMakanID
      } else {
        this.reservasiChecked = null
      }
      
      var newDate = new Date();
      var tgl = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
      var bln =
        (newDate.getMonth() + 1 < 10 ? "0" : "") + (newDate.getMonth() + 1);
      var th = newDate.getFullYear();
      var H = newDate.getHours();
      //var i = newDate.getMinutes();
      this.dateStart = th + "-" + bln + "-" + tgl;
      this.timeStart = H + 2 + ":00";
    },
  },
  watch: {
    stOpsiMakan: function() {
      this.setOpsiMakan()      
    }
  },
};
</script>
<style scoped>
.btn-radio {
  float: left;
  margin: 5px;
  width: 80%;
  height: 40px;
  position: relative;
}
.btn-radio label,
.btn-radio input {
  text-align: center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  font-size: 16px;
}
.btn-radio input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}
.btn-radio input[type="radio"]:checked+label,
.Checked+label {
  background: #00ae2b;
  color: #ffffff;
}
.btn-radio label {
  padding: 6px;
  border: 1px solid rgb(90, 90, 90);
  cursor: pointer;
  z-index: 90;
}
.btn-radio label:hover {
  background: #DDD;
}
.field-kembali {
  padding-left:0px;
}
.label-pembayaran{
    margin-top: auto; 
    margin-bottom: auto;
    text-align:left;
}
input[readonly] {
  background-color: white;
}
.floating-custom {
    font-size: 14px;
    transform: translateY(90%) translateX(25%);
    background:#fff;
}


.switch {
  margin-left: 3px;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>