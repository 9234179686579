import { createStore } from 'vuex'
import auth from './auth';
import items from './items';
import transaction from './transaction';
import syncSale from './syncSale';
import pelanggan from './pelanggan';
import settingStruk from './settingStruk';
import openCloseOutlet from './openCloseOutlet';

export default createStore({
    state: {
       connectionStatus:"Offline",
       versionCode:"7220",
       apiBaseUrl: (window.location.origin == "https://kasir.nutacloud.com") ? "https://api.nutacloud.com/" : ((window.location.origin == "https://kasir.nutadev.com") ? "https://api.nutadev.com/" : "https://api-dev.nutadev.com/") ,
       wsBaseUrl: (window.location.origin == "https://kasir.nutacloud.com") ? "https://wsup.nutacloud.com/" : ((window.location.origin == "https://kasir.nutadev.com") ? "https://ws.nutadev.com/" : "https://ws-dev.nutadev.com/"),
    },
    getters: {
        connectionStatus: state => state.connectionStatus,
        versionCode: state => state.versionCode,
        apiBaseUrl: state => state.apiBaseUrl,
        wsBaseUrl: state => state.wsBaseUrl,
    },
    actions: {
        async setConnection({ commit }, data) {
            commit("setConnection", data);
        },
    },
    mutations: {
        setConnection(state, data) {
            state.connectionStatus = data;
        },
    },
    modules: {
        auth,
        items,
        transaction,
        syncSale,
        pelanggan,
        settingStruk,
        openCloseOutlet,
    }
})

